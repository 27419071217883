<div *ngIf="height && width">
    <div *ngIf="overlayText" class="overlay-text">{{overlayText}}</div>
    <img class="image no-select" [class.grey]="grey" [width]="width" [height]="height" [class.rounded]="rounded"
         [class.loaded]="imageIsLoaded" (load)="imageLoaded()" [src]="src" [alt]="alt"/>
  </div>
  <div class="image-wrapper" *ngIf="!(height && width)">
    <div *ngIf="overlayText" class="overlay-text">{{overlayText}}</div>
    <img class="image no-select" [class.grey]="grey" [class.rounded]="rounded" [class.loaded]="imageIsLoaded"
         (load)="imageLoaded()" [src]="src" [alt]="alt"/>
  </div>
  