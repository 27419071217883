import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Role, SubRole } from "../../../../users/models/users.entity";

interface GoFurtherLink {
  image: string;
  title: string;
  subTitle: string;
  description: string;
  link: string;
  isExternalLink: boolean;
  queryParams?: any;
}

interface ImagesLinks {
  "tree-in-head": string;
  coach: string;
  train: string;
}

@Component({
  selector: 'app-welcome-links',
  templateUrl: './welcome-links.component.html'
})
export class WelcomeLinksComponent implements OnInit {
  @Input() role!: SubRole;

  @Input() images$!: Observable<ImagesLinks>;


  links!: GoFurtherLink[];

  ngOnInit() {
    if (this.images$) {
      this.images$.subscribe(images => {
        this.links = this.goFurtherLinks(this.role, images);
      });
    }
  }

  goFurtherLinks(role: SubRole, images: ImagesLinks): GoFurtherLink[] {
    return Role.Admin === role ? [] : [
      {
        image: 'assets/images/tree-in-head.png',
        title: "Le service",
        subTitle: "En savoir plus sur We talk",
        description: "Rencontrez en vidéo David Duprez, fondateur, qui vous présente en quelques mots la mission de We talk.",
        link: ['home', 'welcome'].join("/"),
        isExternalLink: false,
        queryParams: { role }
      },
      {
        image: 'assets/images/coach.png',
        title: "Les coachs",
        subTitle: "Découvrez nos Sherpas",
        description: "Nos espaces d'échange sont guidés par des coachs et thérapeutes certifiés, expérimentés, et partageant notre charte de valeurs.",
        link: '/home/sherpas',
        isExternalLink: false
      },
      {
        image: 'assets/images/train.png',
        title: "Les parcours",
        subTitle: "Histoires de Talkers",
        description: "En moyenne, 91% des personnes sont satisfaites des parcours et déclarent que cela a eu un impact réel sur leur vie.",
        link: '/home/talkers',
        isExternalLink: false
      }
    ];
  }
}