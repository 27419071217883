import { Component, Input, OnInit } from '@angular/core';

import { Role, SubRole } from "../../../../users/models/users.entity";
import { GoFurtherLink } from "../go-further-links/go-further-links.component";

@Component({
  selector: 'app-welcome-links',
  templateUrl: './welcome-links.component.html'
})
export class WelcomeLinksComponent implements OnInit {

  @Input() role!: SubRole;

  links!: GoFurtherLink[];

  ngOnInit() {
    this.links = this.goFurtherLinks(this.role);
  }


  goFurtherLinks(role: SubRole): GoFurtherLink[] {
    return Role.Admin === role ? [] : [
      {
        image: 'tree-in-head',
        title: "Le service",
        subTitle: "En savoir plus sur We talk",
        description: "Rencontrez en vidéo David Duprez, fondateur, qui vous présente en quelques mots la mission de We talk.",
        link: [ 'home', 'welcome' ].join("/"),
        isExternalLink: false,
        queryParams: { role }
      },
      {
        image: 'coach',
        title: "Les coachs",
        subTitle: "Découvrez nos Sherpas",
        description: "Nos espaces d'échange sont guidés par des coachs et thérapeutes certifiés, expérimentés, et partageant notre charte de valeurs.",
        link: '/home/sherpas',
        isExternalLink: false
      },
      {
        image: 'train',
        title: "Les parcours",
        subTitle: "Histoires de Talkers",
        description: "En moyenne, 91% des personnes sont satisfaites des parcours et déclarent que cela a eu un impact réel sur leur vie.",
        link: '/home/talkers',
        isExternalLink: false
      },

    ];
  }

}
