<div *ngIf="canSeePage; else subscribeBlock">
  <div class="grow center-children full-height" *ngIf="loading">
    <app-spinner />
  </div>
  <div *ngIf="!loading" [ngClass]="{'padding-wrapper': !talkerOrganization}" #wrapper>

    <h1 class="margin-bottom">Statistiques et impact</h1>

    <div>
      <div class="margin-bottom-xl" >
        <app-in-page-tabs arrowDown [tabs]="tabs" (clickLink)="goTo($event)"/>
      </div>
      <br/>
      <div class="wrap sticky filter-menu-sticky" [ngClass]="{'sticky-top': talkerOrganization }">
        <div class="center-vertically-by-margin">
          <app-tree-checkmarks *ngIf="showSelection" [trees]="organizationTrees" [isFemaleWord]="true"
                              item-name="Organisation sélectionnée"
                              (changeTreeSelection)="updateTreeSelection($event)"
                              (changeListSelection)="updateOrganizationsSelected($event)"
          />
        </div>
        <div (click)="onThematicSelect()" class="bubble pointer center-vertically-by-margin row gap center-children"
            [dropdownTriggerFor]="thematicDropdown" dropdownPosition="start">Thématique
          <app-icon type="chevron-down"/>
        </div>
        <app-dropdown [closeOnClick]="false" #thematicDropdown>
          <div class="wrap gap base-padding">
            <div class="bubble pointer" (click)="selectThematic(thematic.id)"
                *ngFor="let thematic of thematicCheckboxes">{{capitalize(thematic.key)}}</div>
          </div>
        </app-dropdown>
        <div (click)="onDynamicSelect()" class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin"
            [dropdownTriggerFor]="dynamicsDropdown">Dynamique de groupe
          <app-icon type="chevron-down"/>
        </div>
        <app-dropdown [closeOnClick]="false" #dynamicsDropdown>
          <div class="wrap gap base-padding ">
            <div class="bubble pointer" (click)="selectDynamic(dynamic.id)"
                [style.border-color]="dynamic.selected ? dynamic.id === DynamicType.COACHING ? '#8C5FB0': '#FFA135': ''"
                *ngFor="let dynamic of dynamicTypeCheckboxes">{{capitalize(dynamic.key)}}</div>
          </div>
        </app-dropdown>
        <form [formGroup]="dateForm" class="wrap gap">
          <app-input title="Du" formControlName="fromDate" type="date"></app-input>
          <app-input title="Au" formControlName="toDate" type="date"></app-input>
        </form>

        <div class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin" (click)="selectThematic(thematic.id)"
            [style.border-color]="thematic.selected ? '#704c8d': 'grey'"
            *ngFor="let thematic of thematicCheckboxesSelected$.getValue()">{{capitalize(thematic.key)}} <app-icon type="close"/></div>
        <div class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin" (click)="selectDynamic(dynamic.id)"
            [style.border-color]="dynamic.selected ? dynamic.id === DynamicType.COACHING ? '#8C5FB0': '#FFA135': ''"
            *ngFor="let dynamic of dynamicTypeCheckboxesSelected$.getValue()">{{capitalize(dynamic.key)}} <app-icon type="close"/></div>
      </div>


      <div *ngIf="canViewPage; else notEnoughData">

        <div *ngxPermissionsExcept="'sherpa'" >
          <h2 class="cooper margin-bottom no-margin" #talkerSection>Collaborateurs</h2>
          <div class="chart-section" *ngIf="canSeeStatistics">
            <div class="column center-children">
              <app-users-distribution
              [centerText]="'invités'"
              [text1Count]="invitedTalkers.length"
              [text1Color]="'#707070'" 
              [labels]="invitedAgainstActivatedLabels"
              [reload$]="reload$" 
              [talkers]="invitedTalkers"
              [index]="0"
               [dataTransform]="invitedAndActivatedDataTransform"
              />
              <div class="stacked-custom-legend column center-children-vertically gap">
                <div class="column center-children-vertically">
                  <p><span class="tiny-margin-right text-bold" style="color: #8b5eb0;">{{activatedInvitedPercent}}%</span>des personnes invitées </p>
                  <p>se sont <span class="text-bold">inscrites</span></p>
                </div>
              </div>
            </div>
            <div class="column center-children">
              <app-users-distribution
              [centerText]="'inscrits'"
              [text1Count]="activatedTalkers.length"
              [labels]="activatedAgainstTalkersLabels"
              [text1Color]="'#8b5eb0'" 
              [backgroundColors]="['#8b5eb0', '#22c997']"
              [dataTransform]="activatedAndTalkerDataTransform" 
              [reload$]="reload$" 
              [talkers]="invitedTalkers"
              [index]="1"
              />
              <div class="stacked-custom-legend column center-children-vertically gap">
                <div class="column center-children-vertically">
                  <p><span class="tiny-margin-right text-bold" style="color: #22c997;">{{talkerActivatedPercent}}%</span>des personnes inscrites</p>
                  <p>ont fait <span class="text-bold">un parcours</span></p>
                </div>
              </div>
            </div>
            <div class="column center-children">
              <app-users-distribution
              [centerText]="'talkers'"
              [text1Count]="talkersWithTrain.length"
              [text1Color]="'#22c997'" 
              [backgroundColors]="['#707070', '#22c997']"
              [reload$]="reload$" 
              [talkers]="invitedTalkers"
              [dataTransform]="noTrainAndTalkerDataTransform"
              [labels]="invitedAgainstTalkersLabels"
              [index]="2"
              />
              <div class="stacked-custom-legend column center-children-vertically gap">
                <div class="column center-children-vertically">
                  <p><span class="tiny-margin-right text-bold" style="color: #22c997;">{{talkerInvitedPercent}}%</span>des personnes invitées</p>
                  <p>ont fait <span class="text-bold">un parcours</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngxPermissionsExcept="'sherpa'" >
          <div class="chart-section" *ngIf="canSeeStatistics">
            <div class="column gap center-children">
              <p class="text-bold">Historique des inscriptions</p>
              <app-talkers-historical [reload$]="reload$" [dateIntervalSelection$]="dafaultSelectedInterval$"
                                      [talkers$]="talkers$"></app-talkers-historical>
            </div>
          </div>
        </div>
         
        <div *ngxPermissionsOnly="'admin'">
          <div class="half-width-container" *ngIf="!!invitedTalkers.length">
            <div class="row start gap center-children-vertically margin-left">
              <app-icon type="info"></app-icon>
              <div>Cette section n'est pas visible par les Pilotes.</div>
            </div>
            <div class="column center-children-vertically margin-top">
              <p class="text-bold">Genre des Talkers</p>
              <div class="gender-distribution-wrapper">
                <app-gender-distribution [reload$]="reload$" [talkers]="invitedTalkers"/>
              </div>
            </div>
          </div>
        </div>

        <h2 class="cooper margin-bottom" #commitmentSection >Engagement</h2>
        <ng-container *ngxPermissionsOnly="'sherpa'">
          <div class="chart-section" *ngIf="canSeeStatistics">
            <div class="tile">
              <div class="text-xl text-primary cooper">{{talkersWithTrain.length}} </div>
              <div class=" text-bold">Talkers accompagnés</div>
            </div>
            <div class="tile">
              <div class="text-xl text-primary cooper">{{coveredOrganizationsForSherpa.length}} </div>
              <div class=" text-bold">Organisations différentes</div>
            </div>
          </div>
        </ng-container>
        <div class="chart-section" *ngIf="canSeeStatistics">
          <div class="column gap center-children">
            <p class="text-bold"><span class="text-large text-bold text-primary tiny-margin-right">{{trainsInProgress}}</span>parcours en cours</p>
            <p class="text-bold margin-top"><span class="text-large text-bold text-primary tiny-margin-right">{{trainsDone}}</span>parcours terminés</p>
            <p class="text-bold margin-top"><span class="text-large text-bold text-primary tiny-margin-right">{{sessionsDone}}</span>séances réalisées</p>
            <p class="text-bold margin-top"><span class="text-large text-bold text-primary tiny-margin-right">+ de {{hoursDone}} h</span>de développement</p>
            <p class="text-bold"> personnel dispensées grâce à We talk</p>
          </div>

          <div class="column gap center-children">
            <div class="column center-children-vertically">
              <p class="text-bold">Répartition des parcours</p>
              <p class="text-bold">selon la dynamique</p>
            </div>
            <app-dynamic-type-distribution [trainMemberships]="trainMemberships$.getValue()" [reload$]="reload$"
            ></app-dynamic-type-distribution>
          </div>

          <div class="column gap center-children">
            <div class="column center-children-vertically">
              <p class="text-bold">Nombre de parcours</p>
              <p class="text-bold">par Talker</p>
            </div>
            <app-trains-per-talker [trainMemberships]="trainMemberships$.getValue()" [reload$]="reload$"
            ></app-trains-per-talker>
          </div>
        </div>

        <div class="chart-section" *ngIf="canSeeStatistics">
          <div class="column gap center-children">
            <p class="text-bold">Répartition des parcours selon la thématique</p>
            <ng-container *ngIf="trainMemberships$.getValue(); else noThematicData">
              <app-thematic-distribution [trainMemberships]="trainMemberships$.getValue()" [reload$]="reload$"
                                      [thematics]="thematics$.getValue()"></app-thematic-distribution>
            </ng-container>
            <ng-template #noThematicData>
              <span class="text">Aucune donnée pour l'instant</span>
            </ng-template>
          </div>
        </div>

        <div class="gray-container" *ngxPermissionsOnly="'admin'">
          <div class="row start gap center-children-vertically margin-left">
            <app-icon type="info"></app-icon>
            <div>Cette section n'est pas visible par les Pilotes.</div>
          </div>
          <div class="custom-chart-section" *ngIf="canSeeStatistics">
            <p><span class="text-xl text-primary cooper tiny-margin-right">{{sherpasInvolved}}</span><span class="text-bold">Sherpas mobilisés</span></p>
            <p><span class="text-xl text-primary cooper tiny-margin-right">{{speechSherpasInvolved}}</span><span class="text-bold">Sherpas pour Parole en groupe</span></p>
            <p><span class="text-xl text-primary cooper tiny-margin-right">{{coachingSherpasInvolved}}</span><span class="text-bold">Sherpas pour Coaching en groupe</span></p>
          </div>
        </div>
        <h2 *ngxPermissionsExcept="'sherpa'" class="cooper margin-bottom" #satisfactionSection>Résultats</h2>
        <h2 *ngxPermissionsOnly="'sherpa'" class="cooper margin-bottom" #satisfactionSection>Feedback</h2>
        <div class="chart-section result-wrapper">
          <div class="column center-children-vertically tile">
            <app-icon size="50" type="video-conference" color="#8b5eb0"></app-icon>
            <div>Note de satisfaction moyenne</div>
            <div>vis-à-vis des</div>
            <div class="text-bold margin-top">séances</div>
            <div class="text-xl cooper margin-top">
              <ng-container *ngIf="canSeePostSessionNote; else noSessionData">
                <span class="text-xl text-bold" [style.color]="getNoteColorByScore(sessionSatisfactionNote)">
                  {{sessionSatisfactionNote}}
                </span>/10
              </ng-container>
              <ng-template #noSessionData>
                <span>Aucune donnée pour l'instant</span>
              </ng-template>
            </div>
          </div>
          
          <div class="vertical-separator"></div>

          <div class="column center-children-vertically tile">
            <app-icon size="50" type="road" color="#8b5eb0"></app-icon>
            <div>Note de satisfaction moyenne</div>
            <div>vis-à-vis du</div>
            <div class="text-bold margin-top">parcours entier</div>
            <div class="text-xl cooper margin-top">
              <ng-container *ngIf="canSeePostTrainNote; else noTrainData">
                <span class="text-xl text-bold" [style.color]="getNoteColorByScore(trainSatisfactionNote)">
                  {{trainSatisfactionNote}}
                </span>/10
              </ng-container>
              <ng-template #noTrainData>
                <span>Aucune donnée pour l'instant</span>
              </ng-template>
            </div>
          </div>

          <div class="vertical-separator"></div>
          
          <div class="column center-children-vertically tile ">
            <app-icon size="50" type="trainer" color="#8b5eb0"></app-icon>
            <div>Note de satisfaction moyenne</div>
            <div *ngxPermissionsExcept="'sherpa'">vis-à-vis des</div>
            <div *ngxPermissionsOnly="'sherpa'">vis-à-vis du</div>
            <div *ngxPermissionsExcept="'sherpa'" class=" text-bold margin-top">Sherpas</div>
            <div *ngxPermissionsOnly="'sherpa'" class=" text-bold margin-top">Sherpa</div>
            <div class="text-xl cooper margin-top">
              <ng-container *ngIf="canSeePostSessionAndTrainResponsesWithSherpaNote; else noSherpaData">
                <span class="text-xl text-bold" [style.color]="getNoteColorByScore(sherpaSatisfactionNote)">
                  {{sherpaSatisfactionNote}}
                </span>/10
              </ng-container>
              <ng-template #noSherpaData>
                <span>Aucune donnée pour l'instant</span>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="chart-section margin-top">
          <div class="column gap center-children">
            <div class="column center-children-vertically">
              <p>Talkers souhaitant <span class="text-bold">faire un parcours</span></p>
              <p class="text-bold">supplémentaire</p>
            </div>
            
            <ng-container *ngIf="canSeePostSessionAndTrainData; else noWantToDoAnotherTrainData">
              <app-satisfaction-distribution 
                [labels]="['Oui', 'Non', 'Peut-être']" 
                [data]="wantToDoAnotherTrainData" 
                [percentages]="wantToDoAnotherTrainPercentages" 
                [backgroundColors]="['#29C997', '#FC9417', '#FCC417']" 
                [reload$]="reload$" 
                [talkers]="talkersWithTrain"
              />
            </ng-container>
          
            <ng-template #noWantToDoAnotherTrainData>
              <span>Aucune donnée pour l'instant</span>
            </ng-template>
          </div>
          <div class="column gap center-children">
            <div class="column center-children-vertically">
              <p>Talkers susceptibles de <span class="text-bold">recommander un</span></p>
              <p class="text-bold">parcours à un proche</p>
            </div>
            <ng-container *ngIf="canSeeRecommendationData; else noRecommendationData">
              <app-satisfaction-distribution 
                [labels]="['Oui', 'Non', 'Peut-être']" 
                [data]="recommendationCountsData" 
                [percentages]="recommendationPercentages" 
                [backgroundColors]="['#29C997', '#FC9417', '#FCC417']" 
                [reload$]="reload$" 
                [talkers]="talkersWithTrain"
              />
            </ng-container>
            
            <ng-template #noRecommendationData>
              <span>Aucune donnée pour l'instant</span>
            </ng-template>
          </div>
        </div>

        <div class="chart-section">
          <div class="column gap center-children">
            <p class="text-bold">Les Talkers ont-ils atteint leurs objectifs ?</p>
            <ng-container *ngIf="canSeePostSessionAndTrainResponsesWithGoalAchievements; else noPostSessionAndTrainResponsesWithGoalAchievementsData">
              <div class="full-width column" *ngFor="let goalStep of goalsAchievements.reverse()">
                <div class="row gap">
                  <div class="grow">
                    {{goalStep.label}}
                  </div>
                </div>
                <div class="row center-children-vertically horizontal-bar full-width">
                  <div [style]="{ width: goalStep.percent + '%', backgroundColor: goalStep.color }" class="inner-bar tooltip">
                    <span class="tooltiptext">{{goalStep.percent}}%</span>
                  </div>
                  <div class="margin-left">{{goalStep.responses}}</div>
                </div>
              </div>
            </ng-container>
            
            <ng-template #noPostSessionAndTrainResponsesWithGoalAchievementsData>
              <span>Aucune donnée pour l'instant</span>
            </ng-template>
          </div>
          <div class="column gap center-children progression-wrapper">
            <p class="text-bold">Les Talkers ont-ils progressé sur la thématique ?</p>
            <div class="row gap full-width">
              <ng-container *ngIf="canSeePostSessionAndTrainThematicProgressResponses; else noPostSessionAndTrainThematicProgressResponses">
                <app-thematic-progression-distribution [data]="thematicProgress"></app-thematic-progression-distribution>
              </ng-container>
              <ng-template #noPostSessionAndTrainThematicProgressResponses>
                <span>Aucune donnée pour l'instant</span>
              </ng-template>
            </div>
          </div>
        </div>

        <div *ngxPermissionsExcept="'sherpa'">
            <div class="feedbacks-wrapper" *ngIf="filteredFeedbacks.length">
              <p class="margin-bottom text-center text-bold">Feedbacks</p>
              <app-slider *ngIf="filteredFeedbacks" [items]="filteredFeedbacks">
                <ng-template #slide let-item>
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <p class="margin-bottom">{{item}}</p>
                    </div>
                  </div>
                </ng-template>
              </app-slider>
            </div>
        </div>

        <div *ngxPermissionsOnly="'sherpa'">
          <div class="feedbacks-wrapper" *ngIf="sherpaFeedbacks.length">
            <p class="margin-bottom text-center text-bold">Feedbacks</p>
            <app-slider [items]="sherpaFeedbacks">
              <ng-template #slide let-item>
                <div class="testimonial">
                  <div class="testimonial-content">
                    <p class="margin-bottom">{{item}}</p>
                  </div>
                </div>
              </ng-template>
            </app-slider>
          </div>
      </div>


        <ng-container  *ngxPermissionsOnly="'admin'">
          <div *ngIf="canSeeStatistics" class="feedback-container margin-top">
            <div class="row start gap center-children-vertically margin-left">
              <app-icon type="info"></app-icon>
              <div>Cette section n'est pas visible par les Pilotes.</div>
            </div>
            <ng-container *ngIf="getAverageRating() !== '0'; else noFeedbackData">
              <div class="emoji-section">
                <div class="left-part">
                  <div>
                    <div class="text-xl text-primary cooper">{{ getAverageRating() }} /5</div>
                    <div class="text-bold">Satisfaction</div>
                    <div>vis à vis de la plateforme</div>
                  </div>
                </div>
                <div class="center-part row space-between">
                  <div class="emoji-item-container">
                    <app-icon size="50" [type]="'sad-emoji'" [color]="getEmojiColorByType('sad-emoji')"></app-icon>
                    <div class="rating">{{ getLatestRating(1) }}</div>
                  </div>
                  <div class="emoji-item-container">
                    <app-icon size="50" [type]="'woozy-emoji'" [color]="getEmojiColorByType('woozy-emoji')"></app-icon>
                    <div class="rating">{{ getLatestRating(2) }}</div>
                  </div>
                  <div class="emoji-item-container">
                    <app-icon size="50" [type]="'neutral-emoji'" [color]="getEmojiColorByType('neutral-emoji')"></app-icon>
                    <div class="rating">{{ getLatestRating(3) }}</div>
                  </div>
                  <div class="emoji-item-container">
                    <app-icon size="50" [type]="'happy-emoji'" [color]="getEmojiColorByType('happy-emoji')"></app-icon>
                    <div class="rating">{{ getLatestRating(4) }}</div>
                  </div>
                  <div class="emoji-item-container">
                    <app-icon size="50" [type]="'laughy-emoji'" [color]="getEmojiColorByType('laughy-emoji')"></app-icon>
                    <div class="rating">{{ getLatestRating(5) }}</div>
                  </div>
                </div>
                <div class="right-part">
                  <app-button icon="upload" [outline]="true" (click)="downloadCsv()">Exporter</app-button>
                </div>
              </div>
            </ng-container>
            <ng-template #noFeedbackData>
              <span class="no-feeback-data">Aucune donnée pour l'instant</span>
            </ng-template>
          </div>
      </ng-container>
      <ng-container *ngxPermissionsExcept="'sherpa'">
        <app-talkers-emotional-state [dateInterval$]="selectedInterval$" [organizationIds$]="selectedOrganizationIds$"></app-talkers-emotional-state>
        <div *ngxPermissionsExcept="'sherpa'" class="strength-section column center-children-vertically margin-top">
          <p class="margin-bottom text-bold">Forces et faiblesses</p>
          <app-strength-weakness [dateInterval$]="selectedInterval$" [organizationIds$]="selectedOrganizationIds$"></app-strength-weakness>
        </div>
      </ng-container>
      </div>
    </div>
  </div>

  <ng-template #notEnoughData>
    <div class="margin-top margin-bottom">
      <app-info>
        Par souci de confidentialité, les statistiques n'apparaissent qu'à partir du
        moment où 5 collaborateurs ont terminé un parcours.
      </app-info>
    </div>
  </ng-template>

  <ng-template #notEnoughResponse>
    <div class="margin-top margin-bottom">
      <app-info>Les statistiques de cette section n'apparaissent qu'à partir du
        moment où au moins 1 collaborateur à fait son bilan de parcours
      </app-info>
    </div>
  </ng-template>
</div>

<ng-template #subscribeBlock>
  <app-subscribe-page [height]="'85dvh'"></app-subscribe-page>
</ng-template>