import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from "rxjs";

import { TrainMembership } from "../../../trains/models/membership.entity";
import { Talker } from "../../../users/models/talker.entity";
import { computeBarChartDateData, getIntervalsFromFilter } from "../../utils/bar-chart-tools";
import { DateIntervalFilterOption, DEFAULT_FONT } from "../../utils/utils";
import { AbstractChartComponent } from "../abstract.chart.component";

@Component({
  selector: 'app-talkers-involvement',
  templateUrl: './talkers-involvement.component.html',
  styleUrls: [ './talkers-involvement.component.scss' ]
})
export class TalkersInvolvementComponent extends AbstractChartComponent<'bar'> implements OnInit, OnDestroy {

  @Input() talkers$!: BehaviorSubject<Talker[]>;

  @Input() firstTrainMemberships$!: BehaviorSubject<TrainMembership[]>;

  @Input() dateIntervalSelection$!: BehaviorSubject<DateIntervalFilterOption>;

  @Input() showColors = false;

  type: 'bar' = 'bar';

  chartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            family: DEFAULT_FONT
          }
        }
      },
      title: {
        display: true,
        font: {
          family: DEFAULT_FONT
        },
        text: 'Taux d\'engagement'
      }
    }
  };

  inscriptionRate!: number;

  involvementRate!: number;

  fullInvolvementRate!: number;

  ngOnInit() {
    this.onInit([ this.dateIntervalSelection$, this.talkers$, this.firstTrainMemberships$ ]);
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  updateData() {
    const dateIntervals = getIntervalsFromFilter(this.dateIntervalSelection$.getValue());
    this.chartData.labels = dateIntervals.map(interval => interval.label);
    const creationDates = this.talkers$.getValue().map(talker => talker.createdAt);
    const activationDates = this.talkers$.getValue()
      .filter(member => !!member.activatedAt)
      .map(member => member.activatedAt);
    const sessionInscriptionDates = this.firstTrainMemberships$.getValue()
      .map(inscription => inscription.createdAt);
    this.chartData.datasets = [
      {
        label: 'Invités',
        backgroundColor: this.getDefaultColors()[0],
        borderColor: this.getDefaultColors()[0],
        data: computeBarChartDateData(creationDates, dateIntervals)
      },
      {
        label: 'Inscrits',
        backgroundColor: this.getDefaultColors()[2],
        borderColor: this.getDefaultColors()[2],
        data: computeBarChartDateData(activationDates, dateIntervals)
      },
      {
        label: 'Premier parcours',
        backgroundColor: this.getDefaultColors()[1],
        borderColor: this.getDefaultColors()[1],
        data: computeBarChartDateData(sessionInscriptionDates, dateIntervals)
      }
    ];

    this.inscriptionRate = Math.floor((activationDates.length / creationDates.length) * 100);
    this.involvementRate = Math.min(Math.floor((sessionInscriptionDates.length / activationDates.length) * 100), 100);
    this.fullInvolvementRate = Math.floor((sessionInscriptionDates.length / creationDates.length) * 100);
  }

  get width(): number {
    return Math.min(window.innerWidth - 20, 600);
  }

  get height(): number {
    return Math.floor(this.width * 2 / 3);
  }
}
