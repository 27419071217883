import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { TypeformResponseComponent } from "./response/typeform-response.component";
import { TypeformResponsesPanelComponent } from './responses-panel/typeform-responses-panel.component';
import { TypeformByMailComponent } from './typeform-by-mail/typeform-by-mail.component';
import { TypeformFormComponent } from "./typeform-form/typeform-form.component";
import { TypeformPageComponent } from './typeform-page/typeform-page.component';
import { TypeformService } from "./typeform.service";

@NgModule({
  imports: [ SharedModule ],
  providers: [ TypeformService ],
  declarations: [ TypeformFormComponent, TypeformPageComponent, TypeformResponseComponent, TypeformByMailComponent, TypeformResponsesPanelComponent ],
  exports: [
    TypeformResponsesPanelComponent
  ]
})
export class TypeformModule {
}
