import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';

import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { Thematic } from "../models/thematic.model";
import { ThematicRepository } from "../repositories/thematic.repository";


@Injectable()
export class ThematicResolver  {
  constructor(private readonly thematicRepository: ThematicRepository,
              private readonly snackbarService: SnackbarService,
              private readonly router: Router,
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Thematic | undefined> {
    const slug = route.paramMap.get('slug') ?? '';

    if (slug === 'new') {
      return of(undefined);
    }

    return this.thematicRepository.findBySlug(slug)
      .pipe(catchError(err => this.handleError(err)));
  }

  private handleError(err: HttpErrorResponse): Observable<Thematic | undefined> {
    if (err.status === 404) {
      this.snackbarService.pushMessage('La thématique recherchée est introuvable', 'error');
    }
    this.router.navigate([ '/thematics' ]);

    return of(undefined);
  }
}
