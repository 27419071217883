<div>
    <div>
        <h1 class="margin-bottom">Découvrez nos sherpas</h1>
        <div>
            <div class="margin-top"><span class="progress-text">Progresser,</span> <span class="together-text">ensemble.</span></div>
            <div class="margin-top">
                <div class="margin-top">Nos coachs et thérapeutes sont appelés les <strong>Sherpas</strong>.</div>
                <div class="margin-top">Ils assurent, guident, aident à quitter les zones de confort et à relever le défis</div>
                <div class="margin-top margin-bottom">
                    Professionnels aguerris de l'accompagnement, ils contribuent à ce que chacun puisse se (re)connecter à lui-même, 
                    à chaque étape de son parcours de vie en révélant son potentiel intérieur.
                </div>
            </div>
        </div>
        <div>
            <div class="margin-bottom-xl tabs-container" >
                <app-in-page-tabs [withClick]="false" [showArrowIcons]="false" arrowDown [tabs]="tabs"/>
              </div>
        </div>
        <div class="image-container">
            <div *ngFor="let certification of certifications">
                <img [src]="'assets/images/' + certification.image + '.png'" alt="{{ certification.image }}"/>
            </div>
        </div>
    </div>  
    <app-sherpas-card-pagination [newItems$]="newItems$" [mainRepository]="mainRepository">
        <ng-template #card let-item>
            <app-sherpa-card (clickOnDetails)="goToUserDetails($event)" [user]="item"></app-sherpa-card>
        </ng-template>
    </app-sherpas-card-pagination>
</div>
