<div class="available-train-wrapper">
  <div class="header mobile-column">
    <div class="half-part image-slot">
      <div class="image-wrapper center-by-relative-parent">
        <app-image [src]="train.dynamic.thematic.imageUrl" [alt]="train.dynamic.thematic.slug" />
      </div>
    </div>
    <div class="column no-gap half-part">
      <div class="column header-background" [ngStyle]="{'background-color': colorLight }">
        <p class="thematic-name" [ngStyle]="{'background-color': colorMedium }"><strong>{{ train.dynamic.thematic.name }}</strong></p>
        <div class="title">
          <h2 class="cooper margin-bottom">{{train.dynamic.thematic.title}}</h2>
        </div>
      </div>
      <div class="center-children base-padding column gap grow">
        <div class="center-children">
          <span>{{train.dynamic.dynamicType}}</span>
        </div>
        <strong>Lancement le {{dateFormat(train.getFirstSession().date, { full: true })}} à {{timeFormat(train.timeSlot)}}</strong>
        <div class="row gap center-children pointer" *ngIf="train.sherpa" (click)="goToSherpa(train.sherpa)">
          <app-profile-icon class="" [profile]="train.sherpa" [tiny]="true" [editable]="false"></app-profile-icon>
          <span >avec <u>{{train.sherpa.fullName}}</u></span>
        </div>
        <app-button (click)="selectTrain()">Inscription</app-button>
      </div>
    </div>
  </div>
</div>
