import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { Role } from "../../../users/models/users.entity";
import { ProfileService } from "../../../users/services/profile.service";
import { StorageService } from "../../storage/storage.service";

@Component({
    selector: 'app-test-end-banner',
    templateUrl: './test-end-banner.component.html',
    styleUrls: ['./test-end-banner.component.scss']
})

export class TestEndBannerComponent implements OnInit {
    showBanner = false;

    remainingDays = 0;

    @Output() bannerVisibility = new EventEmitter<boolean>();

    constructor( 
        private readonly profileService: ProfileService,
        private readonly storageService: StorageService,
        private readonly router: Router
    ) {}

    destroy$ = new Subject<void>();
    
    ngOnInit(): void {
        this.profileService
            .getObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (profile: any) => {
                  const isTester = profile.role === Role.Talker && profile?.organization?.subscriptionPlan?.slug === 'tester-subscription';
                  const remainingDays = this.getRemainingDays(profile?.organization?.testEndDate);
                  this.remainingDays = remainingDays;
                  this.showBanner = isTester && remainingDays > 0;
                  this.bannerVisibility.emit(this.showBanner);
                  if(isTester && remainingDays <= 0) {
                    this.storageService.logout();
                }
              } });
    }

    getRemainingDays(testEndDate: string): number {
        const end = new Date(testEndDate);
        const today = new Date();
        const diffTime = end.getTime() - today.getTime();
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    goToContact(){
        this.router.navigate([ 'questions' ]);
    }

    close(){
        this.showBanner = false;
    }
}