<div>
    <app-notification class="sticky-notification" title="Enregistrement impossible" color="#e37055" *ngIf="errorMessages.length && !isValidForm">
        <ul>
          <li *ngFor="let error of errorMessages">{{error}}</li>
        </ul>
    </app-notification>
    <div class="content-add-title-wrapper">
        <h2 class="margin-top">{{ isEdition ? 'Modifier' : 'Ajouter une nouvelle' }} {{ isEdition ? 'la' : ''}} ressource</h2>
        <app-icon
            *ngIf="isEdition"
            class="margin-top"
            (click)="deleteContent()"
            type="trash"
            size="30"
            button></app-icon>

    </div>
    
    <form class="margin-top" [formGroup]="contentForm">
        <div class="row gap">
            <div class="create-content-title-wrapper">
                <app-input title="Titre" formControlName="title"></app-input>
            </div>
        </div>
        <div>
            <p>Description</p>
            <textarea class="margin-top" formControlName="description"></textarea>
        </div>
        <div class="column gap">
            <div class="row">
                <app-radio-buttons class="create-content-flex-item" formControlName="objective" title="Objectif" [options]="objectivesNames"></app-radio-buttons>
            </div>
            <div class="row">
                <app-radio-buttons class="create-content-format-item" formControlName="format" title="Format" [options]="formatsNames"></app-radio-buttons>
                <div class="duration-container">
                    <div>Durée</div>
                    <input class="time-input" type="time" step="1" formControlName="readingDuration" value="00:00:00">
                </div>
            </div>
            <ng-container  *ngIf="contentForm?.get('format')?.value">
                <div>Média associé</div>
                <div class="create-content-media-container">
                    <div class="create-content-media-flex-item">
                        <app-upload-file [mimeTypes$]="acceptingMimeTypes$" (updateFile)="updateFile($event)" [update]="!!content" showMessage="false" showFileName="true" [image]="isImageFormat()" [video]="isVideoFormat()" [audio]="isAudioFormat()" [pdf]="isPdfFormat()" hasLimit="false"></app-upload-file>
                    </div>
                    <div class="create-content-media-flex-item or-wrapper">ou</div>
                    <app-input class="create-content-media-flex-item" title="Url" formControlName="resource"></app-input>
                </div>
            </ng-container>
        </div>
        <div class="row gap margin-top">
            <div class="column create-content-flex-item">
                <app-checkmarks title="Pilier(s) liée(s)" [checkboxes]="pillarsAssociatedCheckboxes" item-name="selectionné"
                (changeSelection)="updatePillarsAssociated($event)"></app-checkmarks>
               
                <app-checkmarks title="Thématique(s) liée(s)" [checkboxes]="thematicsAssociatedCheckboxes" item-name="selectionné"
                (changeSelection)="updateThematicsAssociated($event)"></app-checkmarks>
            </div>
            <div class="column create-content-flex-item-2">
                <app-checkmarks title="Catégorie(s)" [checkboxes]="categoriesAssociatedCheckboxes" item-name="selectionné"
                (changeSelection)="updateCategoriesAssociated($event)"></app-checkmarks>
            </div>
        </div>
        <div>
            <p class="margin-bottom">Image de couverture</p>
            <app-upload-file [mimeTypes$]=" coverImageMimeTypes$" [showFileName]="!showCoverImageUrl"  [showImageUrl]="showCoverImageUrl" [imageUrl]="coverImageUrl" (updateFile)="updateCoverImage($event)" [update]="!!content" hasLimit="false" showMessage="false" image></app-upload-file>
            <div class="cover-image-container">
                <img class="cover-image" *ngIf="showCoverImageUrl" [src]="coverImageUrl" alt="Media Image">
            </div>
        </div>
    </form>
</div>
