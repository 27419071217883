import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from "rxjs";

import { lightCategoryColors, mediumCategoryColors, strongCategoryColors } from "../../../../thematics/colors";
import { Thematic } from "../../../../thematics/models/thematic.model";
import { Tag } from "../../tags/tags.component";

@Component({
  selector: 'app-thematic-card',
  templateUrl: './thematic-card.component.html',
  styleUrls: [ './thematic-card.component.scss' ]
})
export class ThematicCardComponent implements OnInit, OnChanges {

  public tags$ = new BehaviorSubject<Tag[]>([]);

  colorMedium!: string;

  colorStrong!: string;

  colorLight!: string;

  hovered = false;


  @Input() thematic!: Thematic;

  @Input('available-dates') availableDates: Date[] = [];

  @Input() withoutTitle = false;

  @Input() big = false;

  @Input() tiny = false;

  ngOnInit() {
    this.colorMedium = this.thematic.category?.slug ? mediumCategoryColors[this.thematic.category.slug] : '';
    this.colorStrong = this.thematic.category?.slug ? strongCategoryColors[this.thematic.category.slug] : '';
    this.colorLight = this.thematic.category?.slug ? lightCategoryColors[this.thematic.category.slug] : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.thematic) {
      this.tags$.next(changes.thematic.currentValue.tags);
    }
  }

  onMouseOver(): void {
    this.hovered = true;
  }

  onMouseLeave(): void {
    this.hovered = false;
  }
}
