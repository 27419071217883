import { Injectable , Inject } from '@angular/core';
import { Observable, of, Subject, takeUntil } from 'rxjs';

import { ProfileService } from "../../users/services/profile.service";
import { ProfileStore } from "../services/profile.storage.service";
import { getUserRole } from "../tool-functions/user";

@Injectable()
export class MatomoResolver  {
  private destroy$ = new Subject<void>();

  constructor(
    private readonly profileStore: ProfileStore,
    private readonly profileService: ProfileService,
    @Inject(String) private readonly pageName: string,
    @Inject(String) private readonly pageCategory: string
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(): void {
    const profile = this.profileStore.getProfile();
    if (profile) {
      this.setTrackerVariables(profile);
    } else {
      this.profileService
        .getObservable()
        .pipe(takeUntil(this.destroy$))
        .subscribe((prof: any) => {
          if (prof) {
            this.setTrackerVariables(prof);
          }
        });
    }
  }

  private setTrackerVariables(profile: any): void {
    // MTM START
    window._mtm.push(
      { 
        'PageName': this.pageName, 
        'PageCategory': this.pageCategory,
        'UserProfile': getUserRole(profile), 
        'UserID': profile.id
      }
    );
    // MTM END
  }
  
  private handleError(): Observable<undefined> {
    return of(undefined);
  }
}