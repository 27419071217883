import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, delay, filter, Subject, takeUntil } from "rxjs";

import { FunnelService } from "../../services/funnel.service";

@Component({ template: '' })
export abstract class AbstractInFunnelComponent implements OnInit, OnDestroy, AfterViewInit {
  destroy$ = new Subject<void>();

  isFunnelFirstStep = false;

  pageUrl: string[];

  previousMessage = 'Retour';

  fadeLeftReverse$ = new BehaviorSubject<boolean>(false);

  fadeLeft$ = new BehaviorSubject<boolean>(false);

  fadeRightReverse$ = new BehaviorSubject<boolean>(false);

  fadeRight$ = new BehaviorSubject<boolean>(false);


  protected constructor(protected readonly funnelService: FunnelService,
                        protected readonly route: ActivatedRoute) {
    this.pageUrl = route.snapshot.url.map(urlSegment => urlSegment.path);
  }


  ngOnInit() {
    this.funnelService.previousPageMessage$.next(this.previousMessage ?? 'Retour');

    this.autoResetAnimations();
  }

  ngAfterViewInit() {
    this.funnelService.nextComponentLoaded$.next();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  autoResetAnimations(): void {
    [ this.fadeLeft$, this.fadeLeftReverse$, this.fadeRight$, this.fadeRightReverse$ ].forEach(subject => {
      subject
        .pipe(filter(value => value),
          delay(500),
          takeUntil(this.destroy$))
        .subscribe(() => {
          subject.next(false);
        });
    });
  }
}
