<div class="header row">
  <h1 class="title">Modifier votre nom/prénom</h1>
  <app-icon button type="close"
            (click)="validate(false)"></app-icon>
</div>

<section>
  <form [formGroup]="namesForm" (submit)="validate(true)">
    <app-input
      formControlName="lastName"
      placeholder="Nom"></app-input>
    <app-input
      formControlName="firstName"
      placeholder="Prénom"></app-input>
    <div class="add-button center-children">
      <app-button  type="submit" title="Modifier">Modifier
      </app-button>
    </div>
  </form>
</section>
