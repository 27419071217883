import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from "rxjs";

import { Organization } from "../../../organizations/models/organizations.entity";
import { TrainMembership } from "../../../trains/models/membership.entity";
import { getDatesFromFilter } from "../../utils/line-chart-tools";
import { DateIntervalFilterOption, DEFAULT_FONT } from "../../utils/utils";
import { AbstractChartComponent } from "../abstract.chart.component";

@Component({
  selector: 'app-units-progression',
  templateUrl: './units-progression.component.html'
})
export class UnitsProgressionComponent extends AbstractChartComponent<'line'> implements OnInit {

  @Input() selectedOrganizations$!: BehaviorSubject<Organization[]>;

  @Input() dateIntervalSelection$!: BehaviorSubject<DateIntervalFilterOption>;

  @Input() trainMemberships!: TrainMembership[];

  type: 'line' = 'line';

  ngOnInit(): void {
    this.onInit([ this.dateIntervalSelection$, this.selectedOrganizations$ ]);
  }

  chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        font: {
          family: DEFAULT_FONT
        },
        text: 'Evolution des unités'
      }
    },
    scales: {
      y: {
        suggestedMin: 0
      }
    }
  };

  override updateData() {
    const dateIntervals = getDatesFromFilter(this.dateIntervalSelection$.getValue());

    let units = this.selectedOrganizations$.getValue().reduce((acc, organization) => acc + organization.units, 0);
    let lastDate = new Date();
    const reverseUnitsProgression: number[] = [];


    this.chartData.labels = dateIntervals.map(dateInterval => dateInterval.label);

    for (const [ index, dateInterval ] of dateIntervals.reverse().entries()) {
      reverseUnitsProgression.push(units);
      if (index === 0) {
        continue;
      }

      units += this.trainMemberships
        .filter(trainMembership => trainMembership && trainMembership.createdAt.getTime() > dateInterval.date.getTime() && (trainMembership.createdAt.getTime() < lastDate.getTime() || index === 1)).length;
      units -= this.selectedOrganizations$.getValue().flatMap(organization => organization.unitsIn ?? [])?.filter(transaction => transaction.createdAt.getTime() > dateInterval.date.getTime() && (transaction.createdAt.getTime() < lastDate.getTime() || index === 1))?.length ?? 0;
      units += this.selectedOrganizations$.getValue().flatMap(organization => organization.unitsOut ?? [])?.filter(transaction => transaction.createdAt.getTime() > dateInterval.date.getTime() && (transaction.createdAt.getTime() < lastDate.getTime() || index === 1))?.length ?? 0;

      lastDate = dateInterval.date;

      if (dateInterval.date.getTime() < Math.min(...this.selectedOrganizations$.getValue().map(o => o.createdAt.getTime()))) {
        units = 0;
      }

      if (index === dateIntervals.length - 1) {
        reverseUnitsProgression.push(units);
      }
    }

    this.chartData.datasets = [
      {
        label: 'Unités',
        borderColor: this.getDefaultColors()[0],
        pointBackgroundColor: this.getDefaultColors()[0],
        backgroundColor: this.getDefaultColors()[0],
        fill: false,
        tension: 0.2,
        data: reverseUnitsProgression.reverse()
      }
    ];
  }

}
