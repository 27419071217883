import { booleanAttribute, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { defaultIfEmpty, Observable, of } from 'rxjs';

import { SnackbarService } from "../snackbar/snackbar.service";

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: [ './upload-file.component.scss' ]
})
export class UploadFileComponent implements OnInit {

  fileName?: string;

  acceptingMimeTypes!: string;

  @Input()  mimeTypes$?: Observable<string>;

  @Input({ transform: booleanAttribute }) image = false;

  @Input({ transform: booleanAttribute }) video = false;

  @Input({ transform: booleanAttribute }) audio = false;

  @Input({ transform: booleanAttribute }) pdf = false;

  @Input({ transform: booleanAttribute }) update = false;

  @Input({ transform: booleanAttribute }) hidden = false;

  @Input({ transform: booleanAttribute }) outline = false;

  @Input({ transform: booleanAttribute }) link = false;

  @Input({ transform: booleanAttribute }) hasLimit = true;

  @Input({ transform: booleanAttribute }) showFileName = false;

  @Input({ transform: booleanAttribute }) showMessage = true;

  @Input({ transform: booleanAttribute }) showImageUrl = false;

  @Input() imageUrl = '';

  @Output() updateFile = new EventEmitter<File>();

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(private readonly snackBarService: SnackbarService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (!this.mimeTypes$) {
      this.mimeTypes$ = of('csv');
    } else {
      this.mimeTypes$ = this.mimeTypes$.pipe(
        defaultIfEmpty('csv')
      );
    }

    this.mimeTypes$.subscribe(mimeTypes => {
      this.acceptingMimeTypes = mimeTypes;
      this.cdr.detectChanges();
    });
  }

  get message() {
    return (this.image && !this.link ?
      (this.update ? "Modifier l'image" : 'Ajouter une image')
      : "");
  }

  onFileSelected(event: EventTarget | null) {
    const file = (event as HTMLInputElement).files?.[0];

    if (file) {
      if (this.hasLimit && file.size > 1E6) {
        this.snackBarService.pushMessage('La taille de l\'image doit être inférieure à 1Mo', 'error');
        return;
      }

      this.fileName = file.name;
      this.updateFile.emit(file);
    }
  }

  click() {
    this.fileInput.nativeElement.click();
  }
}
