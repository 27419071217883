import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { Session } from "../../../../trains/models/session.entity";
import { Train } from "../../../../trains/models/train.entity";
import { TypeformService } from "../../../../typeform/typeform.service";
import { TypeformFormType } from "../../../../typeform/typeform.type";
import { Sherpa } from "../../../../users/models/sherpa.entity";
import { Role } from "../../../../users/models/users.entity";
import { ProfileService } from "../../../../users/services/profile.service";
import { formatDate, formatTime } from "../../../tool-functions/date-tools";


@Component({
  selector: 'app-train-card',
  templateUrl: './train-card.component.html',
  styleUrls: [ './train-card.component.scss' ]
})
export class TrainCardComponent implements OnInit, OnDestroy {

  @Input() train!: Train;

  @Input() apiCalling = false;

  @Output() manage = new EventEmitter<string>();

  @Output() goToDetails = new EventEmitter<string>();

  @Output() goToMeeting = new EventEmitter<string>();

  public alertMessage = '';

  public nextSession?: Session;

  public currentSessionIndex?: number;

  private destroy$ = new Subject<void>();

  public isSherpa = false;

  public evaluationDate?: string;

  public needToEvaluate = false;

  constructor(private readonly router: Router,
              private readonly profileService: ProfileService,
              private readonly typeformService: TypeformService) {
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {
    this.profileService.getMyProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe(profile => {
        this.isSherpa = profile.role === Role.Sherpa;
        if (profile.role === Role.Talker && this.train.isCompleted) {
          const evaluation = profile.postTrainFormResponses?.find(response => response.trainId === this.train.id);
          if (!evaluation) {
            this.needToEvaluate = true;
          } else {
            this.evaluationDate = (new Date(evaluation.createdAt)).toLocaleDateString();
          }
        }
      });
    this.alertMessage = this.train.timeDelta && this.train.timeDelta > 0 ?
      this.train.isStartingSoon
        ? `Débute dans ${ Math.floor(this.train.timeDelta / (60 * 1E3)) } min`
        : ''
      : this.train.getNextSession() === undefined ? '' : 'La séance est en cours';

    this.nextSession = this.train.getNextSession();
    this.currentSessionIndex = this.train.currentSessionIndex;

  }

  joinMeeting() {
    this.router.navigate([ 'meet', this.train.id ]);
  }

  clickOnManage(): void {
    (window._mtm || []).push({ 'event': 'clic-accompagnement', 'nom-parcours': this.train?.dynamic?.thematic?.title });
    this.manage.emit(this.train.id);
  }

  clickOnDetails(): void {
    this.goToDetails.emit(this.train.id);
  }

  detailsOnMobile(): void {
    if (window.innerWidth < 1200) {
      if (this.isSherpa && !this.train.sherpa) {
        this.clickOnManage();
      } else {
        this.clickOnDetails();
      }
    }
  }

  goToSherpa(sherpa: Sherpa): void {
    this.router.navigate([ 'sherpas', sherpa.id ]);
  }

  evaluateTrain() {
    this.typeformService.goToSpecificForm({
      type: TypeformFormType.POST_TRAIN,
      train: this.train
    });
  }

  getTrainTimeDelta(train: Train): string {
    const delta = train.timeDelta;
    if (!delta) {
      return '';
    }
    if (delta < 0) {
      return 'Séance en cours';
    }
    if (delta < 3600 * 1E3) {
      return `Dans ${ Math.floor(delta / (60 * 1E3)) } min`;
    }
    if (delta < 24 * 3600 * 1E3) {
      return `Dans ${ Math.floor(delta / (3600 * 1E3)) } h`;
    }

      return `Dans ${ Math.floor(delta / (24 * 3600 * 1E3)) } j`;

  }

  protected readonly formatDate = formatDate;

  protected readonly formatTime = formatTime;
}
