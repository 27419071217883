import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatestWith, switchMap, takeUntil, tap } from "rxjs";

import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { FunnelService } from "../../shared/services/funnel.service";
import { addDelayToDate, formatDate, formatTimeSlot } from "../../shared/tool-functions/date-tools";
import { TimeSlot } from "../../trains/models/time-slot.entity";
import { Train } from "../../trains/models/train.entity";
import { TrainRepository } from "../../trains/repositories/train.repository";
import { CreateTrainService } from "../create-train.service";
import { Dynamic } from "../models/dynamic.model";
import { Thematic } from "../models/thematic.model";

@Component({
  selector: 'app-train-resume',
  templateUrl: './train-resume.component.html',
  styleUrls: [ './train-resume.component.scss' ]
})
export class TrainResumeComponent extends AbstractInFunnelComponent implements AfterViewInit {

  thematic!: Thematic;

  dynamic!: Dynamic;

  selectedTimeSlot!: TimeSlot;

  selectedDate!: Date;

  nextDates!: Date[];

  selectedTrain?: Train;

  constructor(protected override readonly funnelService: FunnelService,
              private readonly snackBarService: SnackbarService,
              protected override readonly route: ActivatedRoute,
              private readonly router: Router,
              public readonly createTrainService: CreateTrainService,
              private readonly trainRepository: TrainRepository) {
    super(funnelService, route);
  }


  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.funnelService.canValidate$.next(true);
    this.funnelService.validateMessage$.next("Valider mon inscription");
  }

  override ngOnInit() {
    this.createTrainService.thematic$.pipe(
      combineLatestWith(this.createTrainService.dynamic$, this.createTrainService.dateSelected$, this.createTrainService.timeSlotSelected$, this.createTrainService.existingTrain$),
      takeUntil(this.destroy$)
    ).subscribe(([ thematic, dynamic, date, timeSlot, train ]) => {
      if (!thematic) {
        this.router.navigate([ 'thematics' ]);
      } else if (!dynamic) {
        this.router.navigate([ 'thematics', thematic.slug ]);
      } else if (!date) {
        this.router.navigate([ 'thematics', thematic.slug, 'create-train', dynamic.type, 'date' ]);
      } else if (!timeSlot) {
        this.router.navigate([ 'thematics', thematic.slug, 'create-train', dynamic.type, 'time' ]);
      } else {
        this.thematic = thematic;
        this.dynamic = dynamic;
        this.selectedDate = date;
        this.selectedTimeSlot = timeSlot;
        this.nextDates = Array.from(Array(3)).map((_, index) => addDelayToDate(`${ (index + 1) * 14 }d`, this.selectedDate));
        this.funnelService.validateMessage$.next("Valider l'inscription");
        this.selectedTrain = train || undefined;

        super.ngOnInit();
      }
    });

    this.funnelService.validate$
      .pipe(
        tap(() => {
          this.funnelService.apiCalling$.next(true);
          (window._mtm || []).push({ 'event': 'validation-parcours', 'nom-parcours': this.selectedTrain ? this.selectedTrain?.dynamic?.thematic?.title : this.thematic?.title });
        }),
        switchMap(() => (this.selectedTrain
          ? this.trainRepository.joinTrain({ id: this.selectedTrain.id })
          : this.trainRepository.createTrain({
            date: this.selectedDate.getDate(),
            timeSlot: this.selectedTimeSlot,
            month: this.selectedDate.getMonth(),
            year: this.selectedDate.getFullYear(),
            dynamicId: this.dynamic?.id ?? ''
          }).pipe(tap(() => this.createTrainService.clear())))),
          takeUntil(this.destroy$),
          tap({
            next: () => {
              this.funnelService.apiCalling$.next(false);
            },
          error: (error) => {
              if(error.error?.message === 'not_enough_units') {
                this.snackBarService.pushMessage("Votre entreprise n'a pas assez de crédits pour réserver ce parcours", "error");
              }
              this.funnelService.apiCalling$.next(false);
          }
        })
      )
      .subscribe(() => {
        this.router.navigate([ 'reservation-done' ]);
      });

  }

  dateFormat = formatDate;

  timeFormat = formatTimeSlot;
}
