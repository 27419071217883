import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { IPaginationComponent } from "../../shared/repositories/pagination.interface";
import { CsvService } from "../../shared/services/csv.service";
import { ProfileStore } from '../../shared/services/profile.storage.service';
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { SherpaRepository } from "../../users/repositories/sherpa.repository";
import { ProfileService } from '../../users/services/profile.service';
import { Train } from "../models/train.entity";
import { TrainFilterForm, TrainPropertiesFilter, TrainRepository } from "../repositories/train.repository";


@Component({
  selector: 'app-trains-list',
  templateUrl: './trains-list.component.html',
  styleUrls: [ './trains-list.component.scss' ]
})
export class TrainsListComponent implements IPaginationComponent<Train, TrainFilterForm, TrainPropertiesFilter>, OnDestroy {
  mainRepository!: TrainRepository;

  filterForm!: EntityFormGroup<TrainFilterForm>;

  selectedSherpas: Checkbox[] = [];

  extractLoading = false;

  updateSherpasSelected$ = new Subject<void>();

  propertiesFilters$ = new BehaviorSubject<Partial<TrainPropertiesFilter>>({});

  private destroy$ = new Subject<void>();

  constructor(private readonly trainRepository: TrainRepository,
              private readonly csvService: CsvService,
              private readonly formBuilder: FormBuilder,
              private readonly sherpaRepository: SherpaRepository,
              private readonly router: Router,
              private profileStore: ProfileStore,
              private readonly profileService: ProfileService) {
    this.mainRepository = this.trainRepository;
    this.filterForm = this.formBuilder.group({
      startCreationDate: new FormControl('', { nonNullable: true }),
      endCreationDate: new FormControl('', { nonNullable: true }),
      startFirstSessionDate: new FormControl('', { nonNullable: true }),
      endFirstSessionDate: new FormControl('', { nonNullable: true }),
      membersCountMinimum: new FormControl('', { nonNullable: true }),
      membersCountMaximum: new FormControl('', { nonNullable: true }),
      isCoaching: new FormControl('', { nonNullable: true }),
      isSpeech: new FormControl('', { nonNullable: true })
    });
    this.sherpaRepository.getAll().subscribe(sherpas => {
      this.selectedSherpas = sherpas.map(user => {
        const key = user.fullName;
        return {
          id: user.id,
          key,
          selected: false
        };
      });
    });
  }

  newItems$?: Subject<Train[]> | undefined;

  savePreferences?: ((filter: TrainFilterForm) => void) | undefined;

  getFilter?: (() => TrainFilterForm) | undefined;

  ngOnDestroy() {
    this.destroy$.next();
  }

  goToTrain(trainId: string): void {
    this.router.navigate([ 'trains', trainId ]);
  }

  updateSherpasSelected(checkboxes: Checkbox[]) {
    const sherpasSelected = checkboxes?.filter(checkbox => checkbox.selected);
    if (sherpasSelected.length && sherpasSelected.every(s => !!s)) {
      this.propertiesFilters$.next({
        ...this.propertiesFilters$.value,
        sherpaIds: sherpasSelected.map(sherpa => sherpa.id)
      });
    } else {
      this.propertiesFilters$.next({
        ...this.propertiesFilters$.value,
        sherpaIds: undefined
      });
    }
  }

  get otherFilterOptionCount(): number {
    return this.selectedSherpas.filter(checkbox => checkbox.selected).length ? 1 : 0;
  }

  clearFilter(): void {
    this.selectedSherpas.forEach(checkbox => {
      checkbox.selected = false;
    });
    this.updateSherpasSelected(this.selectedSherpas);

    this.updateSherpasSelected$.next();
  }

  downloadTrainsCsv(): void {
    this.extractLoading = true;
    this.trainRepository.getAll()
      .subscribe(trains => {
        this.csvService.downloadTrainCsv(trains);
        this.extractLoading = false;
      });
  }
}
