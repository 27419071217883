import { ChangeDetectorRef, Component } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { map, merge, Subject, takeUntil } from "rxjs";
import { Role } from "src/app/users/models/users.entity";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { allAdditionnalThematics, allMainThematics, contentDurations, contentFormats, pillars } from "../models/contents.entity";
import { ContentsRepository } from "../repositories/contents.repository";
import { TabStoreService } from "../services/content-tab.store";

@Component({
    selector: "app-contents-list",
    templateUrl: "./contents-list.component.html",
    styleUrls: ["./contents-list.component.scss"],
})

export class ContentsListComponent {
    items: any;

    contentsForm!: FormGroup;

    contentsFilterForm!: FormGroup;

    searchForm: FormControl<string | null> = new FormControl<string>("");

    selectedItems: Set<string> = new Set();

    constructor(private router: Router, private route: ActivatedRoute, private readonly contentsRepository: ContentsRepository, private fb: FormBuilder, private cdr: ChangeDetectorRef, private tabStoreService: TabStoreService) { }

    selectedTab: number = 2;

    objectives: string[] = ['inspire', 'prepare'];

    updateFormatsSelected$ = new Subject<void>();

    formatCheckboxes: Checkbox[] = [];

    isDropdownVisible = false;

    destroy$ = new Subject<void>();

    canSeePage = true;

    userProfile: any;

    isRestricted!: boolean;

    loading: boolean = false;

    fetchTabContent(tabIndex: number) {
      this.loading = true;
      this.selectedTab = tabIndex;
      this.tabStoreService.setSelectedTab(tabIndex);
      if(tabIndex === 1){
        this.objectives = ['inspire'];
      }

      if(tabIndex === 2){
        this.objectives = ['act'];
      }

        if(tabIndex === 3){
            this.objectives = ['transform', 'prepare'];
        }

        const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
        const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
        const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
        const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
        this.contentsRepository.findAll({ properties: { objectives : this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe({
          next: (contents: any) => {
            this.items = contents.items;
            this.isRestricted = contents.isRestricted;
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        });
    }

    selectTab(tabIndex: number) {
      this.loading = true;
      this.selectedTab = tabIndex;
      this.tabStoreService.setSelectedTab(tabIndex);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { tab: tabIndex },
        queryParamsHandling: 'merge'
      });
      if(tabIndex === 1){
        this.objectives = ['inspire'];
      }

      if(tabIndex === 2){
        this.objectives = ['act'];
        // this.canSeePage = !(this.userProfile.role === Role.Talker && (!this.userProfile.organization.subscriptionPlan || ['personalized-subscription-individual', 'personalized-subscription-professional'].includes(this.userProfile.organization.subscriptionPlan.slug)));   
      }

        if(tabIndex === 3){
            this.objectives = ['transform', 'prepare'];
            // this.canSeePage = !(this.userProfile.role === Role.Talker && (!this.userProfile.organization.subscriptionPlan || ['personalized-subscription-individual', 'personalized-subscription-professional'].includes(this.userProfile.organization.subscriptionPlan.slug))); 
        }

        const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
        const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
        const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
        const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
        this.contentsRepository.findAll({ properties: { objectives : this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe({
          next: (contents: any) => {
            this.items = contents.items;
            this.isRestricted = contents.isRestricted;
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        });
    }

    

    ngOnInit(): void {

      this.route.data
      .pipe(
          map(data => data.profile),
          takeUntil(this.destroy$)

      ).subscribe((profile: any) => {
        this.loading = true;
        this.userProfile = profile;
        this.canSeePage = !!profile.organization.subscriptionPlan;
        this.route?.queryParams?.subscribe(params => {
          const tab = +params.tab || this.tabStoreService.getSelectedTab() || 2; 
          this.fetchTabContent(tab);
        });
        
        this.contentsForm = this.fb.group({
          allMainThematics: this.fb.array(this.createItems(allMainThematics)),
          allAdditionnalThematics: this.fb.array(this.createItems(allAdditionnalThematics)),
          formats: this.fb.array(this.createItems(contentFormats)),
          durations: this.fb.array(this.createItems(contentDurations)),
          pillars: this.fb.array(this.createItems(pillars)),
        });
          
          this.contentsFilterForm = this.fb.group({
            durationMinimum: new FormControl('', { nonNullable: true }),
            durationMaximum: new FormControl('', { nonNullable: true }),
          });
  
          this.subscribeToChanges();
          const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
          const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
          const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
          const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
          this.contentsRepository.findAll({ properties: { objectives : this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe({
            next: (contents: any) => {
              this.items = contents.items;
              this.isRestricted = contents.isRestricted;
              this.loading = false;
            },
            error: () => {
              this.loading = false;
            }
          });
      });
    }

    private subscribeToChanges() {
        merge(
          this.contentsForm.valueChanges,
          this.contentsFilterForm.valueChanges
        ).subscribe(() => {
          this.loading = true;
          const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
          const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
          const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
          const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
          this.contentsRepository.findAll({ properties: { objectives: this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe({
            next: (contents: any) => {
              this.items = contents.items;
              this.isRestricted = contents.isRestricted;
              this.loading = false;
            },
            error: () => {
              this.loading = false;
            }
          });
        });
    }

    toggleSelection(item: any): void {
      const pillar: any = this.pillars.controls.find((control: any) => control.value.key === item.key);
      if (pillar) {
        pillar.patchValue({ value: !pillar.value.value });
      } 
    }

    createItems(thematicsPart: any[]): FormGroup[] {
      return thematicsPart.map(item => this.fb.group({
        name: [item.label],
        value: [item.selected],
        key: [item.key],
        icon: [item.icon]
      }));
    }

    openMoreThematic(event: Event){
      event.preventDefault();
      this.isDropdownVisible = !this.isDropdownVisible;
    }

    handleEnterPressed(value: string) {
      this.loading = true;
      window._mtm.push({ 'event' : 'recherche-ressource', 'motcle' : `${value.trim()}` });
      const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
      const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
      const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
      const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
      this.contentsRepository.findAll({ search: value.trim(),properties: { objectives: this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe({
        next: (contents: any) => {
          this.items = contents.items;
          this.isRestricted = contents.isRestricted;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
    }

      get pillars() {
        return this.contentsForm.get('pillars') as FormArray;
      }

    
      get formats() {
        return this.contentsForm.get('formats') as FormArray;
      }
    
      get durations() {
        return this.contentsForm.get('durations') as FormArray;
      }

      get thematics(){
        return this.contentsForm.get('allMainThematics') as FormArray;
      }

      get additionnalThematics(){
        return this.contentsForm.get('allAdditionnalThematics') as FormArray;
      }
}