<div class="slider-wrapper" *ngIf="items.length" [class.in-top-position]="arrowInTopPosition" (touchstart)="touchStart($event)"  (touchmove)="touchMove($event)" (touchend)="touchEnd()">
  <div class="row relative">
    <app-arrow-button *ngIf="items.length > 1" class="arrow-button arrow-left" [class.in-top-position]="arrowInTopPosition" (click)="goPrevious()"></app-arrow-button>
    <div class="slider" [class]="slideClass">
      <div class="slide" *ngFor="let item of items; let index = index; trackBy: itemTrackBy">
        <ng-container
          *ngIf="contentTemplateRef"
          [ngTemplateOutlet]="contentTemplateRef"
          [ngTemplateOutletContext]="{$implicit:item}"
        >
        </ng-container>
      </div>
    </div>
    <app-arrow-button *ngIf="items.length > 1" [rotated]="true" (click)="goNext()" class="arrow-button arrow-right" [class.in-top-position]="arrowInTopPosition"></app-arrow-button>
  </div>
  <div class="dots" *ngIf="items.length > 1" >
    <div class="dot pointer" [class.selected]="index === slideShownIndex$.getValue()" (click)="goSpecific(index)"
         *ngFor="let item of items; let index = index;"></div>
  </div>
</div>
