<div class="header row gap">
  <h1 class="title">Veuillez sélectionner les organisations que le talker peut diriger</h1>
  <app-icon button type="close"
              (click)="validate()"></app-icon>
</div>

<section>
  <app-checkmarks title="Liste des organisations du pilote" [isFemaleWord]="true" item-name="Organisation managée" [checkboxes]="organizationCheckboxes" (changeSelection)="updateSelectedOrganizations($event)" ></app-checkmarks>
  <p class="margin-bottom">Si aucune organisation n'est précisée, le talker n'est plus pilote</p>

  <div class="add-button relative">
    <app-button class="center-by-relative-parent"  (click)="validate(true)" title="Enregistrer">Enregistrer</app-button>
  </div>

</section>
