import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-home',
  templateUrl: './main.layout.component.html',
  styleUrls: [ './main.layout.component.scss' ],
})
export class MainLayoutComponent {
  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('We Talk');
  }
}
