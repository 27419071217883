<div class="authentication-wrapper">
  <app-image alt="background2" class="mobile-hidden" src="assets/images/background.png" />
  <div class="dialog-wrapper">
    <div class="logo-authentication">
      <app-image width="150" height="40" src="assets/logos/logo.png" alt="logo" />
    </div>
    <div class="component-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
