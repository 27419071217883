import { addDelayToDate, createCurrentMonthFirstDate, months } from "../../shared/tool-functions/date-tools";
import { stringNumber } from "../../shared/tool-functions/string-number";

import { DateIntervalFilterOption } from "./utils";

export type DateLabelled = {
  date: Date;
  label: string;
}

export function getThreeYearsDates(): DateLabelled[] {
  return Array.from(Array(13)).map((_, index) => {
    const date = createCurrentMonthFirstDate();
    date.setFullYear(date.getFullYear() - 3,date.getMonth() + 3 * index);
    return {
      date,
      label: `${ months[date.getMonth()] } ${ date.getFullYear() }`
    };
  });
}

export function getYearDates(): DateLabelled[] {
  return Array.from(Array(13)).map((_, index) => {
    const date = createCurrentMonthFirstDate();
    date.setFullYear(date.getFullYear() - 1, date.getMonth() + index);
    return {
      date,
      label: `${ months[date.getMonth()] } ${ date.getFullYear() }`
    };
  });
}

export function getQuarterDates(): DateLabelled[] {
  return Array.from(Array(7)).map((_, index) => {
    const date = addDelayToDate(`-${(6 - index) * 15}d`);

    return {
      date,
      label: ` ${ stringNumber(date.getDate()) } ${ months[date.getMonth()] }`
    };
  });
}

export function getMonthDates(): DateLabelled[] {
  return Array.from(Array(5)).map((_, index) => {
    const date = addDelayToDate(`-${(4 - index) * 7}d`);

    return {
      date,
      label: ` ${ stringNumber(date.getDate()) } ${ months[date.getMonth()] }`
    };
  });
}


export function getDatesFromFilter(filter: DateIntervalFilterOption): DateLabelled[] {
  switch (filter) {
    case DateIntervalFilterOption.THREE_YEARS:
      return getThreeYearsDates();
    case DateIntervalFilterOption.YEAR:
      return getYearDates();
    case DateIntervalFilterOption.QUARTER:
      return getQuarterDates();
    case DateIntervalFilterOption.MONTH:
      return getMonthDates();
    default:
      return getMonthDates();

  }
}

