<div class="header row gap">
  <h1 class="title">Veuillez choisir les utilisateurs non-inscrits</h1>
  <app-icon button type="close"
              (click)="close()"></app-icon>
</div>

<section>
  <app-checkmarks [checkboxes]="organizationCheckboxes" (changeSelection)="selectOrganizations($event)" class="margin-bottom" item-name="Organisation sélectionnée" [isFemaleWord]="true" *ngIf="!organizationId"></app-checkmarks>
  <div *ngIf="notActivatedUserCheckBoxes?.length">
    <h2 class="margin-bottom">{{usersSelectedCount}} Utilisateur{{usersSelectedCount > 1 ? 's'  : '' }} sélectionné{{usersSelectedCount > 1 ? 's'  : '' }}</h2>
    <div class="checkbox-list" >
      <app-checkbox *ngFor="let user of notActivatedUserCheckBoxes; let index; trackBy userTrackBy"
                    [ngModel]="user.selected" (ngModelChange)="selectUser(user.id, $event)">{{user.key}}</app-checkbox>
    </div>
  </div>
  <p *ngIf="!notActivatedUserCheckBoxes?.length">Tous les utilisateurs renseignés sont inscrits</p>
  <div class="center-children margin-top">
    <app-button (click)="sendMails()" [disabled]="usersSelectedCount === 0">Envoyer</app-button>
  </div>
</section>
