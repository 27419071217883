import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Subject } from 'rxjs';

import { AbstractChartComponent } from '../abstract.chart.component';

@Component({
  selector: 'app-thematic-progression-distribution',
  templateUrl: './thematic-progression-distribution.component.html',
})
export class ThematicProgressionDistributionComponent extends AbstractChartComponent<'bar'> implements OnInit, AfterViewInit {
  resize$ = new Subject<void>();

  type: 'bar' = 'bar';
  
  width!: number;
  
  height!: number;

  @Input() data: any;

  chartOptions: ChartOptions<'bar'> = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false // Hide the legend
      },
      datalabels: {
        display: true, // Explicitly enable display
        anchor: 'end',
        align: 'top' as const, // Align the label at the top of the bar
        offset: 4, // Add some spacing from bar
        formatter: (value: any) => value // Format the label value
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y}%`;
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          minRotation: 0, 
          maxRotation: 0,
          font: {
            weight: 500
          },
          callback(value, index, values) {
            // Add margin to the last tick label
            // if (index === values.length - 1) {
            //   return `${this.getLabelForValue(value as number)}`; // Add spaces for margin
            // }
            return this.getLabelForValue(value as number);
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        display: false,
        beginAtZero: true,
        max: 100
      }
    },
    datasets: {
      bar: {
        maxBarThickness: 120,  // Height
        barPercentage: 0.9,    // Bar width relative to category (90%)
        categoryPercentage: 0.8 // Category width relative to scale (80%)
      }
    }
  };

  ngOnInit() {
    this.chartPlugins = [ChartDataLabels];
    this.onInit([ this.resize$, this.data ]);
    this.getWidth();
    this.getHeight();
  }

  ngAfterViewInit(): void {
    if (this.chart) {
      this.chart.update();
    }
  }

  updateData() {
    const data = this.data.map((resp: any)=> resp.percent);
    const labels = this.data.map((resp: any) => resp.responses);

    this.chartData = {
      labels: ['pas du tout', '', '', '', '', '', '', '', '', '        énormément', ''],
      datasets: [
        {
          data,
          backgroundColor: ['#FF4F4E', '#FF4F4E', '#FC9418', '#FC9418', '#FCC417', '#FCC417', '#94D82C', '#94D82C', '#22C997', '#22C997', '#22C997'],
          datalabels: {
            display: true,
            anchor: 'end',
            align: 'top',
            offset: 4,
            font: {
              size: 16
            },
            labels: {
              title: {
                display: true,
                formatter: (value: any, context: any) => labels[context.dataIndex]  
              }
            }
          }
        }
      ]
    };
  }

getWidth(): void {
  const BREAKPOINT = 741;
  if (window.innerWidth < BREAKPOINT) {
    this.width = Math.min(Math.floor(window.innerWidth - 40), 350); 
  } else {
    this.width = Math.min(Math.floor((window.innerWidth - 460)), 400);
  }
  this.resize$.next();
}

@HostListener('window:resize')
getHeight(): void {
  const BREAKPOINT = 741;
  if (window.innerWidth < BREAKPOINT) {
    this.height = Math.min(Math.floor(this.width * 0.8), 400); 
  } else {
    this.height = Math.min(Math.floor(this.width * 2 / 3), 200);
  }
  this.resize$.next();
}
}