import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, filter, Subject, takeUntil } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

import { AuthenticationService } from "../../authentication/services/authentication.service";
import { OrganizationMembersRepository } from "../../organizations/repositories/organization.members.repository";
import { ConfirmComponent } from "../../shared/components/confirm/confirm.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Train } from "../../trains/models/train.entity";
import { EditManagedOrganizationsComponent } from "../edit-managed-organizations/edit-managed-organizations.component";
import { Talker } from "../models/talker.entity";
import { Gender, GenderDescription, Role, RoleDescription, User } from "../models/users.entity";
import { TalkerRepository } from "../repositories/talker.repository";
import { UsersRepository } from '../repositories/users.repository';
import { ProfileService } from "../services/profile.service";

@Component({
  selector: 'app-talker-details',
  templateUrl: './talker-details.component.html',
  styleUrls: [ './talker-details.component.scss' ]
})
export class TalkerDetailsComponent implements OnInit, OnDestroy {

  talker!: Talker;

  userTrains$ = new BehaviorSubject<Train[]>([]);

  loading = true;

  private destroy$ = new Subject<void>();

  constructor(private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly profileService: ProfileService,
              private readonly talkerRepository: TalkerRepository,
              private readonly userRepository: UsersRepository,
              private readonly organizationMemberRepository: OrganizationMembersRepository,
              private readonly authService: AuthenticationService,
              private readonly snackBarService: SnackbarService,
              private readonly dialog: DialogService) {

  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        map(data => data.user),
        filter(user => user instanceof Talker),
        tap((user: Talker) => {
          this.talker = user;
          this.organizationMemberRepository.setOrganizationId(user.organization?.id);
        }),
        switchMap(() => this.profileService.getMyProfile()),
        tap((profile: User) => {
          this.loading = profile.role === Role.Admin;
        }),
        filter((profile: User) => profile.role === Role.Admin),
        switchMap(() => this.talkerRepository.getTalkerTrains(this.talker.id)),
        tap((trains: Train[]) => {
          this.userTrains$.next(trains);
        }),
      ).subscribe(() => {
      this.loading = false;
    });
  }

  goToOrganisation() {
    this.router.navigate([ 'organizations', this.talker.organization?.id ]);
  }

  goToTrain(trainId: string): void {
    this.router.navigate([ 'trains', trainId ]);
  }

  sendActivationMail() {
    this.authService.sendActivationEmail(this.talker.email).subscribe(
      () => {
        this.snackBarService.pushMessage('Email d\'inscription envoyé', 'success');
      }
    );
  }


  get role(): string {
    return capitalize(RoleDescription[this.talker.organizationAssociations?.length ? 'pilot' : Role.Talker]);
  }

  get gender(): string {
    return capitalize(GenderDescription[this.talker.gender]);
  }

  get canDeleteUser(): boolean {
    return this.profileService.profile?.role === Role.Admin || (this.profileService.profile?.role === Role.Talker && !this.talker.isPilot);
  }

  deleteUser(): void {
    const confirmationPopinTitle = this.talker.organizationAssociations.length > 1 ? 'Cet utilisateur est Pilote de plusieurs organisations. Êtes-vous sûr de vouloir le retirer de toutes ?' : 'Voulez-vous vraiment supprimer cet utilisateur ?';
    this.dialog.open(ConfirmComponent, { title: confirmationPopinTitle })
      .subscribe(isValidated => {
        if (isValidated) {
          if (this.profileService.profile?.role === Role.Talker) {
            this.organizationMemberRepository.deleteById(this.talker.id).subscribe(() => {
              this.snackBarService.pushMessage('Collaborateur supprimé', 'success');
              this.userRepository.deleteMailchimpContact(this.talker.id, this.talker.email).subscribe({
                next: () => {
                  if(this.profileService?.profile?.role === Role.Admin){
                    this.snackBarService.pushMessage('Contact supprimé dans Mailchimp', 'success');
                  }
                  this.router.navigate([ 'organizations', this.talker.organization?.id ]);
                },
                error: () => {
                  if(this.profileService?.profile?.role === Role.Admin){
                    this.snackBarService.pushMessage('Contact n’a pas pu être supprimé dans Mailchimp', 'error');
                  }
                  this.router.navigate([ 'organizations', this.talker.organization?.id ]);
                }
              });
            });
          } else {
            this.talkerRepository.deleteById(this.talker.id).subscribe(() => {
              this.snackBarService.pushMessage('Talker supprimé', 'success');
              this.userRepository.deleteMailchimpContact(this.talker.id, this.talker.email).subscribe({
                next: () => {
                  if(this.profileService?.profile?.role === Role.Admin){
                    this.snackBarService.pushMessage('Contact supprimé dans Mailchimp', 'success');
                  }
                  this.router.navigate([ 'users' ]);
                },
                error: () => {
                  if(this.profileService?.profile?.role === Role.Admin){
                    this.snackBarService.pushMessage('Contact n’a pas pu être supprimé dans Mailchimp', 'error');
                  }
                  this.router.navigate([ 'users' ]);
                }
              });
            });
          }
        }
      });
  }

  impersonateUser() {
    this.authService.impersonateUser(this.talker.id)
      .subscribe(() => {
          this.router.navigate([ 'home' ]);
        }
      );
  }

  modifyManagedOrganizations() {
    this.dialog.open(EditManagedOrganizationsComponent, { selectedOrganizationsIds: this.talker.organizationAssociations.map(o => o.organization?.id) })
      .pipe(takeUntil(this.destroy$))
      .subscribe(selectedOrganizationIds => {
        if (selectedOrganizationIds) {
          this.talkerRepository.modifyManagedOrganizations(this.talker.id, selectedOrganizationIds)
            .pipe(takeUntil(this.destroy$))
            .subscribe((pilot) => {
              this.talker = pilot;
              this.snackBarService.pushMessage('Modification enregistrée', 'success');
            });
        }
      });
  }

    protected readonly Gender = Gender;
}
