<div class="tags no-select">

  <div *ngIf="allSelectEnabled" (click)="selectAll()" [class.selected]="allSelected" class="tag">Tous</div>
  <div (click)="onTagSelect(tag.name)" [class.highlighted]="(highlightEnabled && tag.highlighted && !tag.selected) || (!highlightEnabled && tag.selected && !disabled)" [class.selected]="(highlightEnabled && tag.selected && !disabled)" [class.disabled]="disabled"
       class="tag"
       *ngFor="let tag of tagsSelected; let index = index; trackBy: cardTrackBy">{{ tag.name }}</div>
  <div *ngIf="addEnabled">
    <div *ngxPermissionsOnly="'admin'" class="tag highlighted" (click)="onAddTag()">
      <app-icon class="pointer"
               type="add"></app-icon>
    </div>
  </div>
</div>
