import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { catchError, delay, of, Subject, takeUntil, tap } from "rxjs";

import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { IsEmailValidator } from "../../shared/validators/email.validator";
import { AuthenticationService } from "../services/authentication.service";


@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: [ './forgotten-password.component.scss' ]
})
export class ForgottenPasswordComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  emailForm: EntityFormGroup<{ email: string }>;

  formError = false;

  errorMessage = '';

  loading = false;

  showSentAnotherTime = false;

  sent = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService
  ) {
    this.titleService.setTitle('Mot de passe oublié');
    this.emailForm = this.formBuilder.group({
      email: new FormControl('', { validators: IsEmailValidator(), nonNullable: true }),
    });
  }

  ngOnInit() {
    this.emailForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.formError) {
          this.formError = false;
          this.errorMessage = '';
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  sendEmail() {
    if (this.emailForm.valid && !this.sent) {
      this.loading = true;
      this.authenticationService.sendForgottenPasswordEmail(this.emailForm.controls.email.value)
        .pipe(
          catchError(() => of(null)),
          tap(() => {
            this.loading = false;
            this.sent = true;
          }),
          delay(5000),
          tap(() => {
            this.showSentAnotherTime = true;
            this.sent = false;
          }),
          takeUntil(this.destroy$))
        .subscribe();
    } else {
      this.formError = true;
      this.errorMessage = 'Veuillez rentrer un email valide';
    }
  }

  get buttonMessage(): string {
    if (this.loading) {
      return 'Envoi en cours...';
    }
    if (this.sent) {
      return 'Envoyé !';
    }
    if (this.showSentAnotherTime) {
      return 'Envoyer à nouveau';
    }
    return 'Envoyer un email';
  }

}
