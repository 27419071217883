import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileStore {
  private profileSubject = new BehaviorSubject<any | null>(null);

  profile$ = this.profileSubject.asObservable();

  saveProfile(profile: any): void {
    this.profileSubject.next(profile);
  }

  getProfile(): any | null {
    return this.profileSubject.getValue();
  }
}