<div class="talker-padding-wrapper">
    <div class="container margin-top margin-bottom margin-left margin-right">
        <app-input
        [margin-bottom]="false"
        [inputField]="searchForm"
        type="search"
        placeholder="Rechercher une ressource..."
    ></app-input>
        <app-button [black]="true" icon="add" (click)="addNewContent()">Ajouter</app-button>
    </div>

    <app-table-custom-pagination
        [propertiesFilters$]="isActivatedFilter$"
        (totalCount)="'100'"
        [searchForm]="searchForm"
        (select)="selectTalkers($event)"
        (goTo)="goToContent($event)"
        [mainRepository]="mainRepository"
        name="collaborateur"
        [tableConfig]="headerColumns"
        [showCheckbox]="false"
        [sortBy$]="sortBy$"
        (onSort)="handleSort($event)"
    ></app-table-custom-pagination>
</div>