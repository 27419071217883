<div class="wrapper">
  <div *ngIf="!expiredLink; then notExpired; else expired"></div>
  <ng-template #notExpired>
    <div class="introducing">
      <h2 class="title">Bienvenue {{ firstName }} !</h2>
      <div>{{ isReactivation ? 'Veuillez insérer un nouveau mot de passe pour accéder à votre compte' : 'Veuillez entrer votre nouveau mot de passe' }}</div>
      <br/>

      <app-password-setter [passwordForm]="passwordForm"
                           (setPassword)="validate()"></app-password-setter>
    </div>
  </ng-template>

  <ng-template #expired>
    <div class="introducing expired">
      <h2 class="title">Lien expiré</h2>
      <div class="margin-bottom">Il semblerait que le lien soit expiré. Veuillez retourner à la page de connexion.
      </div>
    </div>
    <app-button (click)="goToLogin()">Retour à la page de connexion
    </app-button>
  </ng-template>

</div>
