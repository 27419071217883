import { Component, Input, OnChanges } from '@angular/core';

import { Talker } from '../../../users/models/talker.entity';
import { DEFAULT_FONT } from '../../utils/utils';
import { AbstractChartComponent } from "../abstract.chart.component";

@Component({
    selector: 'app-users-distribution',
    templateUrl: './users-distribution.component.html',
})
export class UsersDistributionComponent extends AbstractChartComponent<'doughnut'> implements OnChanges {
    @Input() talkers!: Talker[];

    @Input() text1Count: number = 0;

    @Input() labels: string[] = ['Invités non inscrits', 'Inscrits'];

    @Input() centerText: string = 'invités';

    @Input() text1Color: string = '#000000';

    @Input() textColor: string = '#000000';

    @Input() backgroundColors: string[] = this.getDefaultColors().slice(0, 2);

    @Input() fontSize: number = 228;

    @Input() dataTransform!: (talkers: Talker[]) => number[];

    @Input() chartLabel: string = 'Utilisateurs';

    @Input() index: number = 0;

    type: "doughnut" = 'doughnut';

    width!: number;

    height!: number;

    chartOptions = {
      responsive: false,
      maintainAspectRatio: false,
      layout: {
        padding: {
            bottom: 50 // Add padding for legend
        },

    },
      plugins: {
          legend: {
              position: 'bottom' as const,
              display: true,
              labels: {
                font: {
                  family: DEFAULT_FONT
                }
              },
          }
      }
  };

    ngOnChanges() {
        super.onChanges();
    }

    updateData() {
        this.chartData.labels = this.labels;
        this.width = this.index === 0 ? 285 : 300;
        this.height = this.index === 0 ? 285 : 300;
        this.chartData.datasets = [
            {
            label: this.chartLabel,
              data: this.dataTransform(this.talkers),
              backgroundColor: this.backgroundColors
            }
          ];
    }

    ngAfterViewInit() {
        const centerTextPlugin = {
          id: 'centerText',
          beforeDraw: (chart: any) => {
            const { ctx } = chart;
            const { width, height } = chart;
            
            // Calculate font sizes and center point
            const fontSize1 = (height / this.fontSize).toFixed(2);
            const fontSize2 = (height / (this.fontSize * 1.5)).toFixed(2);
            const centerX = width / 2;
            const centerY = this.index === 0 ? height / 2.3 : height / 2.5;
            
            ctx.restore();
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            
            // Draw first text (number) higher above center
            ctx.font = `600 ${fontSize1}em sans-serif`;
            ctx.fillStyle = this.text1Color;
            ctx.fillText(this.text1Count.toString(), centerX, centerY - 30);
            
            // Draw second text (label) higher above center
            ctx.font = `${fontSize2}em sans-serif`;
            ctx.fillStyle = this.textColor;
            ctx.fillText(this.centerText, centerX, centerY - 10);
            
            ctx.save();
        }
        };
      
        if (this.chart) {
          this.chart.plugins.push(centerTextPlugin);
          this.chart.update();
        }
    }
}