import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from "@angular/forms";

import { TimeSlotPossibility } from "../../admin/edit-timeslots/edit-timeslots.component";
import { ConfigurationRepository } from "../../admin/repositories/configuration.repository";
import { WeekDaysNames } from "../../admin/types/constant";
import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { isSameTimeSlot, parseTimeSlotsToEdit } from "../../shared/tool-functions/time-slot";
import { TimeSlot } from "../../trains/models/time-slot.entity";

@Component({
  selector: 'app-edit-timeslot-preferences',
  templateUrl: './edit-timeslot-preferences.component.html',
  styleUrls: [ './edit-timeslot-preferences.component.scss' ]
})
export class EditTimeslotPreferencesComponent extends DialogComponent<TimeSlot[], {
  timeSlots: TimeSlot[]
}>() implements OnInit {
  timeSlotsSelected!: TimeSlot[];

  allTimeSlots!: TimeSlot[];

  dayTimes: TimeSlotPossibility[] = [];

  daysCheckboxes: Checkbox[] = WeekDaysNames.map((day, index) => ({
    key: day,
    selected: false,
    id: String(index)
  })).sort((checkboxA, checkboxB) => this.sortWeekDayToPutSundayAtLeast(Number(checkboxA.id), Number(checkboxB.id)));

  constructor(
    @Inject(DIALOG_DATA)
    public override readonly data: { timeSlots: TimeSlot[] },
    private readonly formBuilder: FormBuilder,
    private readonly ref: DialogRef<TimeSlot[]>,
    private readonly configurationRepository: ConfigurationRepository,
  ) {
    super(ref);

    this.timeSlotsSelected = data.timeSlots;
  }

  ngOnInit(): void {
    super.onInit();
    this.configurationRepository.getWeeklyTimeSlots().subscribe(
      timeslots => {
        const metadata = parseTimeSlotsToEdit(timeslots);
        this.allTimeSlots = metadata.timeSlotsSelectedSorted;

        this.daysCheckboxes = this.daysCheckboxes.map(checkbox => ({
          ...checkbox, selected: metadata.selectedDays.includes(Number(checkbox.id))
        }));
        this.dayTimes = metadata.timeSlotsPossibilities
          .filter(slot => !!timeslots.find(timeSlot => slot.hours === timeSlot.hours && slot.minutes === timeSlot.minutes))
          .map(slot => ({
            ...slot,
            selected: slot.selected && !!this.timeSlotsSelected.find(timeSlotSelected => isSameTimeSlot(timeSlotSelected, slot)),
            hidden: !slot.selected
          }));
      }
    );
  }

  private sortWeekDayToPutSundayAtLeast(weekDayA: number, weekDayB: number): number {
    if (weekDayA === 0) {
      return 1;
    }
    if (weekDayB === 0) {
      return -1;
    }
    return weekDayA - weekDayB;
  }

  get weekDayAvailable(): number[] {
    return this.daysCheckboxes.filter(checkbox => checkbox.selected).map(checkbox => Number(checkbox.id)).sort(this.sortWeekDayToPutSundayAtLeast);
  }

  selectDayTime(index: number) {
    this.dayTimes[index].selected = !this.dayTimes[index].selected;
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  validate(isValidated: boolean): void {
    if (isValidated) {
      super.close(this.dayTimes
        .filter(dayTime => this.weekDayAvailable.includes(dayTime.weekDay)
          && dayTime.selected)
        .map(dayTime => this.allTimeSlots
          .find(timeSlot => isSameTimeSlot(dayTime, timeSlot))!));
    } else {
      super.close();
    }
  }
}
