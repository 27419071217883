import { OverlayRef } from '@angular/cdk/overlay';
import { delay, of, Subject } from 'rxjs';

export class DialogRef<T> {
  public afterClose$ = new Subject<T | undefined>();

  constructor(private readonly overlayRef: OverlayRef) {
  }

  public close(result?: T) {
    this.overlayRef.addPanelClass('closing');
    of(result).pipe(delay(500)).subscribe(
      res => {
        this.overlayRef.dispose();
        this.afterClose$.next(res);
        this.afterClose$.complete();
      }
    );
  }
}
