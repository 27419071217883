import { Role, User } from "../../users/models/users.entity";

export function computeUserProfileRouterUrl(user: User): string[] {
  switch (user.role) {
    case Role.Sherpa:
      return ['sherpas', user.id];
    case Role.Talker:
      return ['talkers', user.id];
    default :
      return ['admins', user.id];
  }
}
