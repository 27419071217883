import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { ConfirmComponent } from "../../shared/components/confirm/confirm.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { UploadFileService } from "../../shared/components/upload-file/upload-file.service";
import { FunnelService } from "../../shared/services/funnel.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { decimalMinutesToHHMMSS, HHMMSSToDecimalMinutes } from "../../shared/tool-functions/date-tools";
import { ThematicRepository } from "../../thematics/repositories/thematic.repository";
import { contentFormats, contentObjectives, pillars } from "../models/contents.entity";
import { ContentsRepository } from "../repositories/contents.repository";
import { getFormatNameBySlug, getObjectiveNameBySlug } from "../utils/utils";

@Component({
    selector: "app-admin-create-content",
    templateUrl: "./admin-create-content.component.html",
    styleUrls: ["./admin-create-content.component.scss"],
})

export class AdminCreateContentComponent extends AbstractInFunnelComponent {
    contentForm!: FormGroup<{
        title: FormControl<string>;
        description: FormControl<string>;
        objective: FormControl<string>;
        format: FormControl<string>;
        readingDuration: FormControl<string>;
        resource: FormControl<string>;
    }>;

    content?: any;

    isEdition: boolean = false;

    objectives = contentObjectives;

    objectivesNames = contentObjectives.map(objective => capitalize(objective.label!));

    formats = contentFormats;

    formatsNames = contentFormats.map(format => capitalize(format.label));

    thematicsAssociatedCheckboxes: Checkbox[] = [];

    thematicsAssociated: string[] = [];

    categoriesAssociatedCheckboxes: Checkbox[] = [];
    
    categoriesAssociated: string[] = [];

    modification$ = new Subject<void>();

    pillarsAssociatedCheckboxes: Checkbox[] = [];

    pillarsAssociated: string[] = [];

    file?: File;

    coverImage?: File;

    errorMessages: string[] = [];

    format: string = '';

    loading = true;

    coverImageUrl = '';

    showCoverImageUrl = false;

    isValidForm = true;

    acceptingMimeTypes$: BehaviorSubject<string> = new BehaviorSubject<string>('.mp4,.avi,.mov');

    coverImageMimeTypes$: BehaviorSubject<string> = new BehaviorSubject<string>('.png,.jpg,.jpeg');

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly thematicRepository: ThematicRepository,
        protected override readonly route: ActivatedRoute,
        protected override readonly funnelService: FunnelService,
        private readonly uploadService: UploadFileService,
        private readonly snackBarService: SnackbarService,
        private readonly contentsRepository: ContentsRepository,
        private readonly dialog: DialogService,
        private cdr: ChangeDetectorRef,
        private readonly router: Router,
    ) { 
        super(funnelService, route);
    }

    init(){
        this.loading = true;

        this.coverImageUrl = this.content?.image ?? '';

        this.showCoverImageUrl = !!this.content?.image.trim().length;
        
        this.contentForm = this.formBuilder.group({
            title: new FormControl<string>(this.content?.title ?? '', { validators: Validators.required, nonNullable: true }),
            description: new FormControl<string>(this.content?.description ?? '', { validators: Validators.required, nonNullable: true }),
            objective: new FormControl<string>(getObjectiveNameBySlug(this.content?.objective) ?? '', { validators: Validators.required, nonNullable: true }),
            format: new FormControl<string>(getFormatNameBySlug(this.content?.format) ?? '', { validators: Validators.required, nonNullable: true }),
            readingDuration: new FormControl<string>(decimalMinutesToHHMMSS(this.content?.readingDuration) ?? 0, { validators: [Validators.required], nonNullable: true, }),
            resource: new FormControl<string>(this.content?.resource ?? '', { validators: Validators.required, nonNullable: true }),
          });

        this.loading = false;

        this.funnelService.validate$.pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.save();
            this.funnelService.canValidate$.next(false);
          });
    
        this.modification$.next();
    }

    override ngAfterViewInit() {
        super.ngAfterViewInit();
    
        setTimeout(() => {
          this.modification$
            .pipe(
              takeUntil(this.destroy$))
            .subscribe(() => {
              if (this.isValid()) {
                this.funnelService.canValidate$.next(true);
                this.funnelService.validateMessage$.next( "Enregistrer");
              } else {
                this.funnelService.canValidate$.next(false);
              }
            });
    
          this.modification$.next();
        }, 1000);
    
      }

    override ngOnInit(): void { 
        this.route.data.pipe(
            takeUntil(this.destroy$)
        )
            .subscribe((data) => {
                
                    this.content = data.content;
                    this.isEdition = data.isEdition;
                
                    this.init();
    
                    this.previousMessage = 'Retour à la liste des ressources';
    
                    super.ngOnInit();
    
                    this.thematicRepository.getCategories().subscribe(categories => {
                        this.categoriesAssociatedCheckboxes = categories.map(category => ({
                            id: category.id,
                            key: capitalize(category.name),
                            selected: this.content ? this.content.thematicCategories.map((c: any) => c.id).includes(category.id) : false,
                        }));
                        this.acceptingMimeTypes$.next(this.getMimeTypeByFormat(this.content?.format));

                      });
            
                    this.thematicRepository.getAll().subscribe(thematics => {
                        this.pillarsAssociatedCheckboxes = pillars.map((pillar) => ({
                          id: pillar.key,
                          key: pillar.label,
                          selected: this.content ? this.content.pillars.map((p: any) => p.name).includes(pillar.key) : false,
                        }));
                        this.thematicsAssociatedCheckboxes = thematics.map(thematic => ({
                            id: thematic.id,
                            key: thematic.name,
                            selected: this.content ? this.content.thematics.map((t: any) => t.id).includes(thematic.id) : false,
                        }));
            
                    });
    
                    this.contentForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
                        this.modification$.next();
                      });
    
                      this.contentForm.get('format')?.valueChanges.subscribe(value => {
                        this.acceptingMimeTypes$.next(this.getMimeTypeByFormat(value));
                        this.format = value ?? '';
                        this.modification$.next();
                        this.cdr.detectChanges();
                      });
    
                    this.loading = false;
            });
    }

    isValid(): boolean {
        this.errorMessages = [];

        if (!this.contentForm.controls.title.valid) {
          this.errorMessages.push('Titre manquant');
        }
        if (!this.contentForm.controls.description.valid) {
          this.errorMessages.push('Description manquante');
        }
        if (!this.contentForm.controls.format.valid) {
          this.errorMessages.push('Format manquant');
        }
        if (!this.contentForm.controls.readingDuration.valid) {
          this.errorMessages.push('Temps de lecture manquant');
        }
        if (!this.categoriesAssociated.length) {
          this.errorMessages.push('Catégorie manquante');
        }
        if (!this.pillarsAssociated.length) {
          this.errorMessages.push('Pilier manquant');
        }
        if (!this.thematicsAssociated.length) {
          this.errorMessages.push('Thématique manquante');
        }
        if (!this.file && !this.contentForm.get('resource')?.value?.length && !this.content?.resource) {
          this.errorMessages.push('Fichier manquant');
        }
        if (!this.coverImage && !this.content?.image) {
          this.errorMessages.push('Image de couverture manquante');
        }
        return !this.errorMessages.length;
    }

    updateCategoriesAssociated(checkboxes: Checkbox[]){
        this.categoriesAssociated = checkboxes.filter(checkbox => checkbox.selected).map(checkbox => checkbox.id);
        this.modification$.next();
    }

    updatePillarsAssociated(checkboxes: Checkbox[]){
        this.pillarsAssociated = checkboxes.filter(checkbox => checkbox.selected).map(checkbox => checkbox.id);
        this.modification$.next();
    }

    updateThematicsAssociated(checkboxes: Checkbox[]){
        this.thematicsAssociated = checkboxes.filter(checkbox => checkbox.selected).map(checkbox => checkbox.id);
        this.modification$.next();
    }

    updateFile(file: File): void {
        this.file = file;
        this.modification$.next();
    }

    updateCoverImage(file: File): void {
        this.coverImage = file;
        this.showCoverImageUrl = false;
        this.coverImageUrl = '';
        this.modification$.next();
    }

    save(): void {

        if (!this.isValid()) {
          this.isValidForm = false;
          this.cdr.detectChanges();
          return;
        }
    
        this.funnelService.componentLoading$.next(true);
        
        this.isValidForm = true;

        const result = {
          ...this.contentForm.value,
            readingDuration: HHMMSSToDecimalMinutes(this.contentForm.get('readingDuration')?.value ?? '00:00:00'),
            categories: this.categoriesAssociated,
            pillars: this.pillarsAssociated,
            thematics: this.thematicsAssociated
        } as any;
    
    
        this.loading = true;
        if (this.content) {
          this.contentsRepository.patch(this.content.id, result)
            .subscribe(content => {
    
              this.content = content;
              this.init();
              if (this.file) {
                this.uploadService.uploadFile(this.file, "contents", content.id).subscribe(() => {
                    if(this.coverImage){
                        this.uploadService.uploadFile(this.coverImage, "contents", content.id, true).subscribe(() => {
                            this.snackBarService.pushMessage('Ressource enregistrée', 'success');
                            this.loading = false;
                            this.funnelService.componentLoading$.next(false);
                            this.router.navigate([ 'resources', 'admin-resources' ]);
                        });
                    }
                });
              } else if(this.coverImage){
                    this.uploadService.uploadFile(this.coverImage, "contents", content.id, true).subscribe(() => {
                        this.snackBarService.pushMessage('Ressource enregistrée', 'success');
                        this.loading = false;
                        this.funnelService.componentLoading$.next(false);
                        this.router.navigate([ 'resources', 'admin-resources' ]);
                    });
              } else {
                this.snackBarService.pushMessage('success');
                this.funnelService.componentLoading$.next(false);
                this.router.navigate([ 'resources', 'admin-resources' ]);
              }
              this.loading = false;
            });
        }
        if (!this.content) {
          this.contentsRepository.create(result)
            .subscribe(content => {
              this.content = content;
              this.init();
              if (this.file) {
                this.uploadService.uploadFile(this.file, "contents", content.id).subscribe(() => {
                    if(this.coverImage){
                        this.uploadService.uploadFile(this.coverImage, "contents", content.id, true).subscribe(() => {
                            this.snackBarService.pushMessage('Ressource enregistrée', 'success');
                            this.loading = false;
                            this.funnelService.componentLoading$.next(false);
                            this.router.navigate([ 'resources', 'admin-resources' ]);
                        });
                    }
                });
              } else if(this.coverImage){
                    this.uploadService.uploadFile(this.coverImage, "contents", content.id, true).subscribe(() => {
                        this.snackBarService.pushMessage('Ressource enregistrée', 'success');
                        this.loading = false;
                        this.funnelService.componentLoading$.next(false);
                        this.router.navigate([ 'resources', 'admin-resources' ]);
                    });
              } else {
                this.loading = false;
                this.funnelService.componentLoading$.next(false);
              }
            });
        }
      }
    
      deleteContent(){
        this.dialog
        .open(ConfirmComponent, {
            title: "Êtes-vous sûr de vouloir supprimer cette ressource ?",
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((isValidated) => {
            if(isValidated){
                this.contentsRepository.deleteById(this.content.id).subscribe(() => {
                    this.snackBarService.pushMessage('Ressource supprimée', 'success');
                    this.router.navigate([ 'resources', 'admin-resources' ]);
                    this.funnelService.componentLoading$.next(false);
                  });
            }
        });
      }

      isVideoFormat(): boolean {
        return this.format === 'Vidéo';
      }
    
      isAudioFormat(): boolean {
        return this.format === 'Podcast';
      }
    
      isPdfFormat(): boolean {
        return this.format === 'Fiche pratique';
      }
    
      isImageFormat(): boolean {
        return this.format === 'Infographie';
      }

      getMimeTypeByFormat(format: string): string {
        switch (format) {
          case 'Vidéo':
            return '.mp4,.avi,.mov';
          case 'Podcast':
            return '.mp3,.wav,.ogg';
          case 'Fiche pratique':
            return '.pdf';
          case 'Infographie':
            return '.png,.jpg,.jpeg';
          default:
            return '.mp4,.avi,.mov';
        }
      }
}

