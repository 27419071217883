import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subject, takeUntil, tap } from "rxjs";

import { CongratsComponent, CongratsType } from "../../shared/components/congrats/congrats.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { IsEmailValidator } from "../../shared/validators/email.validator";
import { DemandsService } from "../services/demands.service";


export type NewSherpaForm = {
    email: string;
    content: string;
}

@Component({
    selector: 'app-new-sherpa',
    templateUrl: './new-sherpa.component.html',
    styleUrls: [ './new-sherpa.component.scss' ]
})
export class NewSherpaComponent implements OnInit, OnDestroy {

    newSherpaForm: EntityFormGroup<NewSherpaForm>;

    formError = false;

    private destroy$ = new Subject<void>();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly titleService: Title,
        private readonly demandsService: DemandsService,
        private readonly dialog: DialogService,
        private readonly router: Router
    ) {
        this.titleService.setTitle('Devenir Sherpa');
        this.newSherpaForm = this.formBuilder.group({
            email: new FormControl('', { validators: IsEmailValidator(), nonNullable: true }),
            content: new FormControl('', { validators: Validators.required, nonNullable: true })
        });
    }

    ngOnInit() {
        this.newSherpaForm.controls.email.valueChanges
            .pipe(
                tap(() => {
                    this.formError = false;
                }),
                takeUntil(this.destroy$))
            .subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    sendEmail() {
        if (!this.newSherpaForm.controls.email.valid) {
            this.formError = true;
            return;
        }
        this.demandsService.sendNewSherpaDemand({
            email: this.newSherpaForm.controls.email.value,
            content: this.newSherpaForm.controls.content.value
        })
            .subscribe(() => {
                this.dialog.open(CongratsComponent, {
                    title: `Un premier pas de fait !`,
                    message: 'C\'est le début de votre aventure avec Wetalk ! 😊 \nNous reviendrons vers vous très prochainement par mail pour donner suite à votre candidature.',
                    type: CongratsType.POST_NEW_SHERPA
                }).subscribe(() => {
                    this.router.navigate([ 'home' ]);
                });
            });

    }

}
