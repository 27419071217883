// store train data common to train-details and funnel components
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, Observable, switchMap } from "rxjs";

import { Train } from "../../trains/models/train.entity";
import { TrainRepository } from "../../trains/repositories/train.repository";
import { ProfileService } from "../../users/services/profile.service";
import { CongratsComponent, CongratsType } from "../components/congrats/congrats.component";
import { DialogService } from "../components/dialog/dialog.service";
import { capitalize } from "../tool-functions/capitalize";

@Injectable({
    providedIn: "root"
  })
  export class FunnelTrainService {
    trains: Train[] = [];

    trainsCompleted: Train[] = [];

    availableTrains: Train[] = [];
    
    state$ = new BehaviorSubject<any>(null);

    showTakenTrainMessage$ = new BehaviorSubject<boolean>(false);

    constructor(private readonly trainRepository: TrainRepository,
                private readonly dialog: DialogService,
                private readonly profileService: ProfileService,
                ){}

    congratsAfterManageTrain(): Observable<undefined> {
        this.state$.next({ ...this.state$.getValue(), train: { ...this.state$.getValue().train, sherpaId: this.state$.getValue()?.user?.id, sherpa: this.state$.getValue()?.user } });
        return this.dialog.open(CongratsComponent, {
          title: `Merci ${ capitalize(this.profileService.profile?.firstName ?? '') } !`,
          message: 'Ta prise en charge du parcours est bien validée !\n\nTu recevras prochainement par email toutes les informations nécessaires à l’animation de ce parcours : nombre de Talkers, confirmation des dates de session, etc.\n\nBon parcours We Talk !',
          type: CongratsType.POST_TRAIN_CONFIRM
        });
      }
    
      alertForMissedTrain(): Observable<undefined> {
        this.showTakenTrainMessage$.next(true);
        this.state$.next({ ...this.state$.getValue(), train: { ...this.state$.getValue().train, sherpaId: `${this.state$.getValue()?.user?.id}_different`, sherpa: { ...this.state$.getValue()?.user, id: `${this.state$.getValue()?.user?.id}_different` } } });
        return this.dialog.open(CongratsComponent, {
          title: `Bonjour ${ capitalize(this.profileService.profile?.firstName ?? '') } !`,
          message: 'Merci pour ta réactivité, malheureusement un autre sherpa a déjà été validé sur ce parcours.\n' +
            '\n' +
            'Pas d’inquiétude, d’autres opportunités s’ouvriront rapidement !\n' +
            '\n' +
            'L’équipe We Talk\n',
          type: CongratsType.MISSED_TRAIN
        });
      }

    manageTrain(trainId: string) {
        this.trainRepository.manageTrain(trainId).pipe(
            switchMap(() => this.congratsAfterManageTrain()),
            catchError(() => this.alertForMissedTrain())).subscribe(() => {
            this.trainRepository.getSherpaAvailableTrains().subscribe(trains => {
              this.availableTrains = trains;
            });
      
            this.trainRepository.getMyTrains().subscribe(trains => {
              this.trains = trains.filter(t => !t.isCompleted);
              this.trainsCompleted = trains.filter(t => t.isCompleted);
            });
          });
    }
  }