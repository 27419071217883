import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Directive, ElementRef, Injector, Input, OnDestroy, ViewContainerRef } from "@angular/core";
import { merge, Subject, takeUntil } from "rxjs";

import { TooltipComponent } from "../components/tooltip/tooltip.component";

@Directive({
  selector: '[tooltip]',
  host: {
    '(click)': 'toggleTooltip()',
    '[class.pointer]': 'true'
  }
})
export class TooltipDirective implements OnDestroy {

  @Input('tooltip') tooltipText? = '';

  private isShown = false;

  private readonly destroy$ = new Subject<void>();


  constructor(
    private readonly overlay: Overlay,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly injector: Injector,
    private readonly elementRef: ElementRef) {
  }

  toggleTooltip() {
    return this.isShown ? this.destroyTooltip() : this.openTooltip();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private destroyTooltip() {
    this.destroy$.next();
  }


  private openTooltip() {
    this.isShown = true;

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([ {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 0
      } ]);

    const overlayRef = this.overlay.create({
      positionStrategy,
      backdropClass: '',
      panelClass: 'overlay-panel',
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    const componentPortal = new ComponentPortal(
      TooltipComponent,
      this.viewContainerRef,
      Injector.create({
        parent: this.injector,
        providers: [
          { provide: 'TOOLTIP_TEXT', useValue: this.tooltipText }
        ]
      })
    );

    overlayRef.attach(componentPortal);
    const referenceSize = this.elementRef.nativeElement.getBoundingClientRect();
    overlayRef.updateSize({ width: referenceSize.width });

    merge(overlayRef.backdropClick(), overlayRef.detachments())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.isShown = false;
          overlayRef.detach();
        }
      );

  }
}
