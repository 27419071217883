import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

import { TimeSlot } from "../trains/models/time-slot.entity";
import { Train } from "../trains/models/train.entity";

import { Dynamic } from "./models/dynamic.model";
import { Thematic } from "./models/thematic.model";
import { ThematicRepository } from "./repositories/thematic.repository";

@Injectable({ providedIn: 'root' })
export class CreateTrainService {
  timeslots$ = new BehaviorSubject<TimeSlot[]>([]);

  dateSelected$ = new BehaviorSubject<Date | null>(null);

  timeSlotSelected$ = new BehaviorSubject<TimeSlot | null>(null);

  thematic$ = new BehaviorSubject<Thematic | null>(null);

  dynamic$ = new BehaviorSubject<Dynamic | null>(null);

  existingTrain$ = new BehaviorSubject<Train | null>(null);

  constructor(private readonly router: Router,
              private readonly thematicRepository: ThematicRepository) {
  }

  clear(): void {
    this.timeslots$.next([]);
    this.timeSlotSelected$.next(null);
    this.dateSelected$.next(null);
    this.thematic$.next(null);
    this.dynamic$.next(null);
    this.existingTrain$.next(null);
  }

  selectTrain(train: Train): void {
    this.timeslots$.next([ train.timeSlot ]);
    this.timeSlotSelected$.next(train.timeSlot);
    this.dateSelected$.next(train.getFirstSession().date);
    this.dynamic$.next(train.dynamic);
    this.thematic$.next(train.dynamic.thematic);
    this.existingTrain$.next(train);
    this.router.navigate([ 'thematics', train.dynamic.thematic.slug, 'create-train', train.dynamic.type, 'resume' ]);
  }

  selectDynamic(dynamic: Dynamic, thematic: Thematic, gotAvailableTrains: boolean): void {
    this.thematic$.next(thematic);
    this.dynamic$.next(dynamic);
    this.thematicRepository.getTimeSlots(thematic.slug, dynamic.type)
      .subscribe(timeslots => {
        this.timeslots$.next(timeslots);
        if (gotAvailableTrains) {

          this.router.navigate([ 'thematics', thematic.slug, 'create-train', dynamic.type, 'available-trains' ]);

        } else {
          this.router.navigate([ 'thematics', thematic.slug, 'create-train', dynamic.type, 'date' ]);
        }
      });
  }

  selectThematic(thematic: Thematic): void {
    this.thematic$.next(thematic);
    const dynamic = this.dynamic$.getValue();
    if (dynamic !== null) {
      this.router.navigate([ 'thematics', thematic.slug, 'create-train', dynamic.type, 'date' ]);
    } else {
      this.router.navigate([ 'thematics', thematic.slug, 'create-train', 'chose-dynamic' ]);
    }
  }
}
