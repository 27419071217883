import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

import { SnackBarMessage, SnackBarType } from "./snackbar.type";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private subject: Subject<SnackBarMessage> = new Subject<SnackBarMessage>();

  state = this.subject.asObservable();

  constructor() { }

  pushMessage(content:string, type: SnackBarType = 'default', options: Partial<Omit<SnackBarMessage, 'content' | 'type'>> = {}) {
    this.subject.next({
      content,
      type,
      ...options
    });
  }
}
