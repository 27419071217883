import { Type } from "class-transformer";

import { TimeSlot } from "../../trains/models/time-slot.entity";
import { Train } from "../../trains/models/train.entity";
import { User } from "../../users/models/users.entity";

import { Thematic } from "./thematic.model";

export enum DynamicType {
  COACHING = 'coaching',
  SPEECH = 'speech'
}

export class Dynamic {
  id!: string;

  type!: DynamicType;

  description!: string;

  isArchived!: boolean;

  isEnabled!: boolean;

  @Type(() => User)
  sherpasAllowed!: User[];

  @Type(() => Train)
  trains!: Train[];

  @Type(() => TimeSlot)
  timeSlots!: TimeSlot[];

  @Type(() => Thematic)
  thematic!: Thematic;

  get dynamicType(): string {
    return this.type === DynamicType.COACHING ? 'Coaching en groupe' : 'Parole en groupe';
  }
}

export type CreateDynamic = {
  type: DynamicType,
  descriptions: string[],
  sherpasAllowed: string[];
};


export interface SelectDynamicByEmotionalState {
  thematic: Thematic,
  emotionalState: number
}

export function splitThematics(thematics: Thematic[]): { speechDynamics: Dynamic[], coachingDynamics: Dynamic[] } {
  const speechDynamics: Dynamic[] = [];
  const coachingDynamics: Dynamic[] = [];
  thematics.forEach(thematic => {
    const speechDynamic = thematic.dynamics?.find(dynamic => dynamic.type === DynamicType.SPEECH);
    const coachingDynamic = thematic.dynamics?.find(dynamic => dynamic.type === DynamicType.COACHING);
    if (speechDynamic) {
      speechDynamics.push(speechDynamic);
    }
    if (coachingDynamic) {
      coachingDynamics.push(coachingDynamic);
    }
  });

  return { speechDynamics, coachingDynamics };
}
