import { Type } from "class-transformer";

import { Talker } from "../../users/models/talker.entity";

import { Train } from "./train.entity";

export class TrainMembership {

  @Type(() => Train)
  train!: Train;

  @Type(() => Date)
  createdAt!: Date;

  startEmotionalState?: number;

  endEmotionalState?: number;

  @Type(() => Talker)
  user!: Talker;

  talkerId!: string;
}
