<div class="wrapper">

  <h1 class="margin-bottom">Crédits</h1>

  <div class="units-resume mobile-column gap margin-bottom-xl">
    <div class="units-resume-header column center-children base-padding">
      <div class="row gap margin-bottom center-children ">
        <div class="text-primary-120">
          <app-icon type="crawn" />
        </div>
        <div class="text-primary text-xl cooper">{{organization.units}}</div>
        <div class="cooper">crédit{{ organization.units ? 's' : ''}} disponible{{ organization.units ? 's' : ''}}</div>
      </div>
      <div class="text-primary-120 text-small">1 crédit = 1 appel d’orientation</div>
      <div class="text-primary-120 text-small">6 crédits = 1 parcours de Parole</div>
      <div class="text-primary-120 text-small">10 crédits = 1 parcours de Coaching</div>
    </div>
    <div class="mobile-column grow gap base-padding center-children space-between">
      <p class="grow contact-us">Pour obtenir de nouveaux crédits, contactez We talk</p>
      <app-button icon="arrow-right" (click)="goToQuestions()">Nous contacter</app-button>
    </div>
  </div>
  <div class="row margin-bottom margin-top-xl center-children">
    <h2 class="margin-bottom grow">Historique de consommation</h2>
    <app-button icon="upload" [outline]="true" (click)="downloadCsv()">Exporter</app-button>
  </div>
  <app-table (sort)="sortTransactions($event)" [tableConfig]="headerColumns" [items]="transactions"></app-table>
</div>
