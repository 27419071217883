import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { takeUntil } from "rxjs";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { CreateThematicTag, ThematicTag } from "../models/tag.model";
import { TagRepository } from "../repositories/tag.repository";
import { ThematicRepository } from "../repositories/thematic.repository";

@Component({
  selector: 'app-add-tag',
  templateUrl: './new-tag.component.html',
  styleUrls: [ './new-tag.component.scss' ]
})
export class NewTagComponent extends DialogComponent<CreateThematicTag | 'delete', CreateThematicTag>() implements OnInit, OnDestroy {

  newTagForm!: EntityFormGroup<{ name: string }>;

  nameWorks = true;

  thematicsAssociated: string[] = [];

  thematicsCheckboxes: Checkbox[] = [];

  existingTags: ThematicTag[] = [];


  constructor(
    @Inject(DIALOG_DATA)
    public override readonly data: CreateThematicTag,
    private readonly formBuilder: FormBuilder,
    private readonly ref: DialogRef<CreateThematicTag | 'delete'>,
    private readonly thematicRepository: ThematicRepository,
    private readonly tagRepository: TagRepository
  ) {
    super(ref);

    this.tagRepository.getAll().subscribe(tags => {
      this.existingTags = tags.filter(tag => tag.name !== this.data?.name);
    });

    this.thematicRepository.getAll().subscribe(thematics => {

      this.thematicsCheckboxes = thematics.map(thematic => ({
        id: thematic.id,
        key: thematic.name,
        selected: !!this.data?.thematics?.find(t => t === thematic.name)
      }));
    });
    this.newTagForm = this.formBuilder.group({
      name: new FormControl(this.data?.name ?? '', { validators: Validators.required, nonNullable: true })
    });
    this.newTagForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(
      value => {
        this.nameWorks = !this.existingTags.find(tag => tag.name.toLowerCase() === value.name?.toLowerCase());
      }
    );
  }

  ngOnInit(): void {
    super.onInit();
  }

  get formValid(): boolean {
    return this.newTagForm.valid && this.nameWorks && this.thematicsCheckboxes.some(t => t.selected);
  }

  get title(): string {
    return this.data ? "Modification d'une catégorie" : "Création d'une catégorie";
  }


  get submitMessage(): string {
    return this.data ? "Modifier" : "Ajouter cette catégorie";
  }


  validate(validated: boolean = false) {
    if (this.formValid && validated) {
      super.close({
        name: this.newTagForm.controls.name.value,
        thematics: this.thematicsAssociated
      });
    } else {
      super.close();
    }
  }

  delete() {
    this.tagRepository.deleteById(this.data?.id ?? '').subscribe(
      () => {
        super.close('delete');
      }
    );
  }

  updateThematicsAssociated(checkboxes: Checkbox[]) {
    this.thematicsAssociated = checkboxes.filter(checkbox => checkbox.selected).map(checkbox => checkbox.id);
  }


  ngOnDestroy() {
    super.onDestroy();
  }


}
