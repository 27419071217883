<app-card>
  <ng-container class="pointer margin-bottom">
    <p>
      <strong>{{ organization.name.toUpperCase() }}</strong>
    </p>
    <div>
      <span>{{ organizationInfos }}</span>
    </div>
  </ng-container>
</app-card>
<div class="column gap organization-children" *ngIf="organization.children && organization.children.length">
  <app-organization-card
    *ngFor="let child of organization.children; let index = index; trackBy: cardTrackBy"
    [organization]="child"
    (click)="goToDetails($event, child.id)"
  ></app-organization-card>
</div>