import { Component, EventEmitter, Input, Output } from '@angular/core';

export type BreadcrumbInput = {
  displayName: string;
  url?: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: [ './breadcrumb.component.scss' ]
})
export class BreadcrumbComponent {
  @Input() breadcrumbInputs!: BreadcrumbInput[];

  @Output() urlClicked = new EventEmitter<string>();

  get parts(): BreadcrumbInput[] {
    return [ ...this.breadcrumbInputs ].reverse();
  }

  goToUrl(url?: string) {
    if (url) {
      this.urlClicked.emit(url);
    }
  }
}
