import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { Organization } from "../models/organizations.entity";
import { OrganizationRepository } from "../repositories/organization.repository";
import { OrganizationStoreService } from "../services/organization.store.service";

@Component({
  selector: 'app-upload-talkers',
  templateUrl: './upload-talkers.component.html'
})
export class UploadTalkersComponent implements OnInit, OnDestroy {

  file?: File;

  organization!: Organization;

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly organizationStoreService: OrganizationStoreService,
              private readonly router: Router,
              private readonly snackBarService: SnackbarService,
              private readonly organizationRepository: OrganizationRepository,
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {
    this.organizationStoreService.getObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(organization => {
        this.organization = organization!;
      });
  }

  selectFile(file: File): void {
    this.file = file;
  }

  validate(): void {
    const { file } = this;
    if (file) {
      this.organizationRepository.uploadTalkersFile(this.organization.id, file)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result) => {
            this.snackBarService.pushMessage(`${ result.added } collaborateurs ajoutés`, 'success');
            this.router.navigate([ 'organizations', this.organization.id, 'talkers' ]);
          }, error: () => {
            this.snackBarService.pushMessage("Votre fichier n'a pas le bon format", 'error');

          }
        });
    }
  }
}
