import { Component, OnInit } from '@angular/core';
import { getTimezone } from 'countries-and-timezones';

@Component({
  selector: 'app-timezone-warning',
  templateUrl: './timezone-warning.component.html',
  styleUrls: [ './timezone-warning.component.scss' ]
})
export class TimezoneWarningComponent implements OnInit {

  public userHasNotFranceTimezone = false;

  constructor() {
  }

  ngOnInit(): void {
    if (getTimezone('Europe/Paris').utcOffset + (new Date()).getTimezoneOffset() !== 0) {
      this.userHasNotFranceTimezone = true;
    }
  }

}
