<div
  class="column gap"
  [class.fade-left]="fadeLeft$ | async"
  [class.fade-left-reverse]="fadeLeftReverse$ | async"
  [class.fade-right]="fadeRight$ | async"
  [class.fade-right-reverse]="fadeRightReverse$ | async"
>
  <strong class="margin-top">Choisissez l'heure de la séance.</strong>
  <div class="column center-children-horizontally full-width">
    <app-timezone-warning></app-timezone-warning>
    <div class="wrap full-width">
      <div
        (click)="selectTime(timeSlot.id)"
        class="timeslot center-children pointer"
        [class.selected]="timeSlotSelected?.id === timeSlot.id"
        *ngFor="let timeSlot of availableTimes"
      >
        {{ formatTime(timeSlot) }}
      </div>
    </div>
  </div>
</div>
