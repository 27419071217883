import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MeetService } from "./meet.service";
import { MeetingComponent } from './meeting/meeting.component';



@NgModule({
  declarations: [
    MeetingComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MeetingComponent
  ],
  providers: [ MeetService ]
})
export class MeetModule { }
