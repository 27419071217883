<div class="panel-wrapper" [class.outline]="outline">
  <div class="border" *ngIf="color" [style.background-color]="color"></div>
  <div class="content row gap center-children-vertically">
    <div *ngIf="icon" class="panel-icon" [class.outline]="outline" [class.plain-style]="plainStyle"><app-icon [type]="icon"></app-icon></div>
    <div class="mobile-column gap grow center-children-vertically">
      <div class="column gap grow">
        <p class="cooper text-large text-black" *ngIf="title"> {{title}}</p>
        <ng-content></ng-content>
      </div>
      <div class="center-children row gap margin-top-mobile" *ngIf="checkMessage">
        <app-icon type="check-dot" />
        <span>{{checkMessage}}</span>
      </div>
      <div class="center-children margin-top-mobile" 
        [class.full-width]="buttonWidth === 'full'"
        [class.auto-width]="buttonWidth === 'auto'"
        [class.fixed-width]="buttonWidth === 'fixed'"
        [style.width]="buttonWidth === 'fixed' ? fixedWidth : buttonWidth === 'none' ? null : '200px'"
        *ngIf="checkMessage === undefined && buttonMessage && onButtonClick.observed">
        <app-button [primary]="isPrimaryCta" icon="arrow-right" (onClick)="onPanelButtonClick()">{{buttonMessage}}</app-button>
      </div>
    </div>
  </div>
</div>