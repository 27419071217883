import { Type } from "class-transformer";

import { Thematic } from "../../thematics/models/thematic.model";
import { TrainMembership } from "../../trains/models/membership.entity";
import { OrganizationPilot, Talker } from "../../users/models/talker.entity";
import { CreateUser } from "../../users/models/users.entity";

export interface ISubscriptionPlan {
  id: string;
  name: string;
  slug: string;
}

export interface IOrganization {
  id: string;
  name: string;
  units: number;
  createdAt: Date;
  talkerQuota: number | null;
  members?: Talker[];
  parent?: IOrganization;
  children?: IOrganization[];
  subscriptionPlan?:  ISubscriptionPlan;
  testEndDate?: string;
}

export enum TransactionType {
  ADMIN_MODIFICATION = "admin-modification",
  TRAIN_MEMBERSHIP = "train-membership",
  ORGANIZATION_TRANSFER = "organization-transfer",
  ORIENTATION_CALL = "orientation-call",
  SPEECH_TRAIN_MEMBERSHIP = "speech-train-membership",
  COACHING_TRAIN_MEMBERSHIP = "coaching-train-membership",
}

export type OrganizationTransactionParsed = {
  id: string;
  date: Date;
  units: string;
  restAmount: number;
  description: string;
};

export class Transaction {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  units!: number;

  @Type(() => Organization)
  sender?: Organization;

  @Type(() => Organization)
  receiver?: Organization;

  type!: TransactionType;

  description!: string;
}

export class Organization implements IOrganization {
  id!: string;

  name!: string;

  units!: number;

  @Type(() => Date)
  createdAt!: Date;

  extraOrganizationMatching: boolean = true;

  intraOrganizationMatching: boolean = false;

  yearlyTalkerQuota: boolean = false;

  talkerQuota: number | null = 1;

  get nameWithUnits(): string {
    return `${this.name.toUpperCase()} (${this.units} crédit${
      this.units > 1 ? "s" : ""
    } disponible${this.units > 1 ? "s" : ""})`;
  }

  @Type(() => Talker)
  members!: Talker[];

  @Type(() => OrganizationPilot)
  pilotAssociations?: OrganizationPilot[];

  get pilots(): Talker[] {
    return (
      this.pilotAssociations
        ?.map((association) => association.pilot)
        .filter((pilot): pilot is Talker => !!pilot) ?? []
    );
  }

  @Type(() => Transaction)
  unitsIn?: Transaction[];

  @Type(() => Transaction)
  unitsOut?: Transaction[];

  @Type(() => Organization)
  parent?: Organization;

  @Type(() => Organization)
  children?: Organization[];

  @Type(() => Organization)
  forbiddenOrganizations?: Organization[];

  @Type(() => Thematic)
  forbiddenThematics?: Thematic[];

  subscriptionPlan?: ISubscriptionPlan;

  testEndDate?: string;

  get forbiddenThematicIds(): string[] | undefined {
    return this.forbiddenThematics?.map((thematic) => thematic.id);
  }
}

export type OrganizationTrainRules = {
  intraOrganizationMatching: boolean;
  extraOrganizationMatching: boolean;
  talkerQuota: number | null;
  forbiddenThematicIds?: string[];
  forbiddenOrganizationIds?: string[];
  allowedOrganizationIds?: string[];
};

export type CreateOrganization = OrganizationTrainRules & {
  name: string;
  units: number;
  addUnitsFrom?: string;
  pilots?: CreateUser[];
  pilotId?: string;
  parent?: string;
  children?: string[];
  subscriptionPlan?: string;
  yearlyTalkerQuota?: boolean;
  testEndDate?: string;
};

export type OrganizationTrainRulesForm = {
  intraOrganizationMatching: boolean;
  talkerQuota: number | null;
};

export type CreateOrganizationForm = {
  name: string;
  gotUnits: boolean;
  units: number;
  addUnitsFrom: string;
  intraOrganizationMatching: boolean;
  gotQuota: boolean;
  talkerQuota: number | null;
  gotPilot: boolean;
  gotFormula: boolean;
  pilot: string;
  gotParent: boolean;
  parent: string;
  gotChildren: boolean;
  gotForbiddenThematics: boolean;
  gotForbiddenOrganizations: boolean;
  subscriptionPlan?: string;
  testEndDate?: string;
};

export class OrganizationTrainMembership {
  id!: string;

  @Type(() => TrainMembership)
  trainMemberships!: TrainMembership[];

  @Type(() => TrainMembership)
  firstTrainMemberships!: TrainMembership[];
}

export type OrganizationDataResume = {
  quotaDemands: number;
  unitDemands: number;
  members: number;
  activatedMembers: number;
  talkers: number;
  trainSatisfactionPercent?: number;
  relativesRecommendationPercent?: number;
  goalsAchievementPercent?: number;
  lifeSatisfactionScore: number;
  workSatisfactionScore: number;
  affectAndRelationScore: number;
  canSeeStats: boolean;
};

export type OrganizationToDeleteInfo = Organization & {
  siblings: Organization[];
};

export interface IOrganizationTransfer {
  fromOrganizationId: string;
  destinationOrganizationId: string;
}
