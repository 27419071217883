<div class="padding-wrapper">
  <div class="wrapper" *ngIf="profile">
    <h1 class="margin-bottom">Changer de compte</h1>
    <h2 class="cooper margin-top margin-bottom">Votre compte Talker</h2>


    <div class="row gap center-children-vertically margin-bottom">
      <div class="profile" (click)="goToTalkerProfile()">
        <div class="profile-icon">
          <app-profile-icon color="#8B5EB0" [isOnTabs]="true" [profile]="profile"></app-profile-icon>
        </div>
        <div class="center-horizontally-by-margin mobile-hidden">{{ profile.fullName }}</div>
      </div>
      <p *ngIf="!organizationSelected">Vous êtes connecté à ce compte</p>
    </div>

    <div class="row gap margin-top margin-bottom center-children-vertically">

      <h2 class="cooper ">Vos comptes en tant que Pilote</h2>
      <p>{{organizations.length}} organisation{{organizations.length > 1 ? 's' : '' }}</p>
    </div>
    <div class="input-search">
      <app-input class="input" type="search" placeholder="Rechercher un compte"
                 [inputField]="searchForm"/>
    </div>
    <div class="organizations-list">
      <div class="organization-item row gap center-children-vertically" *ngFor="let organization of organizationsFiltered">
        <div class="organization-icon center-children">
          <app-icon type="building" class="mobile-hidden"/>
          <app-icon type="building" class="desktop-hidden" size="10"/>
        </div>
        <p class="grow">{{organization.name.toUpperCase()}}</p>
        <app-button [outline]="true" *ngIf="!organizationSelected || organizationSelected.id !== organization.id" (click)="clickOnOrganization(organization)">Aller
          vers ce compte
        </app-button>
        <p *ngIf="organizationSelected && organizationSelected.id === organization.id">Vous êtes connecté à ce compte</p>
      </div>
    </div>
  </div>
</div>
