import { Transform, Type } from "class-transformer";

import { capitalize } from "../../shared/tool-functions/capitalize";

import { ThematicCategory } from "./category.model";
import { CreateDynamic, Dynamic } from "./dynamic.model";
import { KeyNumber } from "./key-number.model";
import { Testimonial } from "./testimonial.model";

export interface IThematic {
  id: string;
  name: string;
  dynamics: Dynamic[];
  title: string;
  resume: string;
  workingOn: string;
  slug: string;
  testimonials?: Testimonial[];
  category?: ThematicCategory;
  keyNumbers?: KeyNumber[];
}

export class Thematic implements IThematic {
  id!: string;

  @Transform(({ value }) => capitalize(value))
  name!: string;

  slug!: string;

  @Type(() => Dynamic)
  dynamics!: Dynamic[];

  testimonials?: Testimonial[];

  category?: ThematicCategory;

  keyNumbers?: KeyNumber[];

  @Transform(({ value }) => capitalize(value))
  title!: string;

  resume!: string;

  workingOn!: string;

  imageUrl!:string;

  detailsImageUrl!:string;

  isEnabled!: boolean;

  isArchived?: boolean;
}

export type CreateTestimonial = {
  author: string;
  description: string;
}

export type CreateKeyNumber = {
  stringNumber: string;
  description: string;
}


export type CreateThematic = {
  slug: string;
  name: string;
  title: string;
  resume: string;
  workingOn: string[];
  category: string;
  dynamics: CreateDynamic[];
  testimonials: CreateTestimonial[];
  keyNumbers: CreateKeyNumber[];
}

export type CreateThematicForm = {
  slug: string;
  name: string;
  title: string;
  resume: string;
  category: string;
  speech: boolean;
  coaching: boolean;
}

export type CreateThematicWithFile = CreateThematic & {
  file?: File,
}

