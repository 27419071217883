import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { filter, map, takeUntil } from "rxjs";

import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { Role } from '../../users/models/users.entity';
import { CreateTrainService } from "../create-train.service";
import { Thematic } from "../models/thematic.model";


@Component({
  selector: 'app-create-train',
  templateUrl: './create-train.component.html'
})
export class CreateTrainComponent extends AbstractInFunnelComponent {
  thematic!: Thematic;

  currentPageUrl!: string[];

  previousPageUrl!: string[];

  override previousMessage = 'Étape précédente';

  startPageUrl = [ 'thematics' ];

  canSeePage = true;
  
  constructor(protected override readonly route: ActivatedRoute,
              protected override readonly funnelService: FunnelService,
              private readonly createTrainService: CreateTrainService) {
    super(funnelService, route);
  }

  override ngOnInit() {
    this.route.data
      .pipe(
        filter((data): data is {
          thematic: Thematic
        } => !!data.thematic),
        map((data: any) => ({
          thematic: data.thematic,
          profile: data.profile
        })),
        takeUntil(this.destroy$))
      .subscribe((data: { thematic: Thematic, profile: any }) => {
        this.canSeePage = data.profile.role === Role.Talker ? !!data.profile.organization.subscriptionPlan : true;
        this.thematic = data.thematic;
        this.createTrainService.thematic$.next(data.thematic);
        this.currentPageUrl = [ 'thematics', this.thematic?.slug ?? '', 'create-train', this.createTrainService.dynamic$.getValue()?.type ?? '' ];
        this.previousPageUrl = [ 'thematics', this.thematic?.slug ?? '' ];
        super.ngOnInit();
        // this.funnelService.goingToPreviousPage$
        //   .pipe(takeUntil(this.destroy$))
        //   .subscribe((event) => {
        //     this.fadeRight$.next(!event.enteredPage.toString().includes(this.currentPageUrl.toString()));
        //   });
        //
        // this.funnelService.goingToNextPage$
        //   .pipe(takeUntil(this.destroy$))
        //   .subscribe((event) => {
        //     this.fadeRightReverse$.next(event.enteredPage.toString().includes(this.currentPageUrl.toString()));
        //   });
      });
  }
}
