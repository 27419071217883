<div class="header row">
  <h1 class="title">Numéro de téléphone</h1>
  <app-icon button type="close"
              (click)="close()"></app-icon>
</div>

<section>
  <form [formGroup]="phoneForm" (submit)="validate(true)">
    <app-input
      [correct]="phoneWork"
      formControlName="phoneNumber" type="phone"
      placeholder="0612345678"
      [phoneCode]="phoneRegionCode"
      (selectPhoneCode)="selectPhoneRegionCode($event)"></app-input>
    <div class="add-button center-children">
      <app-button [disabled]="!phoneWork" type="submit" title="Modifier">Modifier
      </app-button>
    </div>
  </form>
</section>
