export interface IContentReaction {
    userId: string;
    contentId: string;
    liked: boolean;
}

export interface IUpdateContentReactionsPayload {
    contentId: string;
    liked: boolean;
}

export class ContentReaction implements IContentReaction {
    userId!: string;

    contentId!: string;

    liked!: boolean;
}