<h1 class="margin-bottom">Histoires de talkers</h1>
<div>
    <h3 class="cooper">Le récit d'un voyage transformationnel</h3>
    <div class="margin-top">
        <strong class="text-primary">Quel était votre état émotionnel avant We talk ?</strong>
    </div>
    <div>
        Avant de découvrir WeTalk, je me sentais souvent submergée par les défis professionnels et la pression au travail.
        La communication avec mes collègues était parfois tendue, et je sentais que cela affectait ma performance et mon
        bien-être.
    </div>
    <div class="margin-top">
        <strong class="text-primary">Racontez-nous votre parcours en Coaching en groupe.</strong>
    </div>
    <div>
        L'expérience a été révélatrice. Les séances de groupe, animées par des thérapeutes expérimentés, m'ont permis de me
        connecter avec des collègues d'autres services, partageant des défis similaires. Chaque session était un mélange de
        partage d'expériences, d'exercices pratiques et de conseils personnalisés.
    </div>
    <div class="margin-top">
        <strong class="text-secondary-100 cooper text-medium">“We Talk a été pour moi une bouée de sauvetage. Cela m'a non
          seulement aidée à développer des compétences professionnelles essentielles, mais a aussi grandement contribué à mon
          épanouissement personnel.”</strong>
    </div>
    <div class="margin-top">
        <strong class="text-primary">Quel impact personnel et professionnel cela vous a-t-il apporté ?</strong>
     </div>
    <div>
        Depuis que j'ai commencé ce parcours avec We Talk, j'ai remarqué un changement significatif dans ma manière de
        gérer le stress et de communiquer au travail. Je me sens plus confiante, plus ouverte aux feedbacks et capable de
        gérer les situations difficiles avec plus de sérénité. Mes collègues ont également remarqué cette évolution, ce qui a
        renforcé notre esprit d'équipe.
    </div>

</div>

<div class="margin-top-xl">
    <h3 class="cooper">Talkers : ce qu'ils pensent de leurs parcours</h3>
    <div class="wrap row-gap margin-top-xl">
        <app-talker-testimonial-quote [class.half-width-desktop]="index < 4" [color]="testimonial.color" [content]="testimonial.content" [author]="testimonial.author" *ngFor="let testimonial of testimonials; let index = index" [bubblePosition]="index % 2 === 0 ? 'left' : 'right'" />
    </div>
</div>

<div class="margin-top-xl">
    <h3 class="cooper">La genèse de We talk pendant la période COVID</h3>
    <div class="margin-top">
        En 2020 pendant le confinement, David, fondateur de We talk, propose à plusieurs personnes de se retrouver toutes
        les semaines en visio-conférence afin d'échanger, d'évacuer, de s'écouter et de se soutenir. Les participant.e.s sont unanimes et David décide, après le COVID, de proposer
        ces espaces de parole sécurisés au plus grand nombre. 
    </div>
    <div class="testimonial-videos-container margin-top-xl">
        <div class="video-wrapper ratio-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/fKfdAeip6hw" frameborder="0" allowfullscreen></iframe>
        </div>
        <div class="video-wrapper ratio-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/wH3-5xIQOjk" frameborder="0" allowfullscreen></iframe>
        </div>
        <div class="video-wrapper ratio-container">
            <iframe width="560" height="315"  src="https://www.youtube.com/embed/w6vKY7qE0Og" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
</div>
