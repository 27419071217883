<h1 class="margin-bottom">Quelques mots...</h1>
<app-notification class="margin-bottom" color="#FFA135"
                  title="Évolution et expérience avec We talk : un voyage transformationnel.">
  <i>Retrouvez ici les retours de vos Talkers, découvrez ce qu'ils ont pensé du parcours.</i>
</app-notification>
<div class="wrap row-gap margin-top-xl">
  <app-talker-testimonial-quote [class.half-width-desktop]="index < 4" [color]="testimonial.color" [content]="testimonial.content" [author]="testimonial.author" *ngFor="let testimonial of testimonials; let index = index" [bubblePosition]="index % 2 === 0 ? 'left' : 'right'" />
</div>

<div class="mobile-column gap margin-top-xl center-children">
  <app-image alt="sunset-girl" src="assets/images/sunset-girl.jpg" width="400" height="200" />
  <app-image alt="flours-and-bubbles" src="assets/images/flours-and-bubbles.jpg" width="400" height="200" />
</div>

<div class="center-children margin-top-xl">
  <app-image alt="colored-quotes" src="assets/images/colored-quotes.png" width="50" height="100" />
</div>
