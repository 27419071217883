<div class="wrapper">
  <h1 class="margin-bottom">
    {{ totalCount ?? "..." }} Collaborateur{{
      totalCount && totalCount > 1 ? "s" : ""
    }}
    {{
      selectedTalkerIds.length
        ? "(" + selectedTalkerIds.length + " sélectionés)"
        : ""
    }}
  </h1>

  <div class="row">
    <div class="grow row gap margin-bottom">
      <app-input
        [margin-bottom]="false"
        [inputField]="searchForm"
        type="search"
        placeholder="Rechercher un collaborateur..."
      ></app-input>
      <div
        class="bubble pointer center-vertically-by-margin row gap center-children"
        [dropdownTriggerFor]="filterDropdown"
        dropdownPosition="start"
      >
        Afficher
        <app-icon type="chevron-down" />
      </div>
      <div class="row gap" *ngIf="selectedTalkerIds.length">
        <app-button
          (click)="changeOrganization()"
          *ngxPermissionsOnly="'pilot-with-multiple-organizations'"
          >Changer d'organisation</app-button
        >
        <app-button (click)="sendOnboarding()"
          >Réenvoyer un mail d'inscription</app-button
        >
        <app-button (click)="removeTalkers()" [outline]="true"
          >Supprimer</app-button
        >
      </div>
      <app-dropdown #filterDropdown>
        <div class="column">
          <div
            class="base-padding pointer"
            (click)="selectFilter('all')"
            [class.text-primary]="filterSelected$.getValue() === 'all'"
          >
            Tous les collaborateurs
          </div>
          <div
            class="base-padding pointer"
            (click)="selectFilter('activated')"
            [class.text-primary]="filterSelected$.getValue() === 'activated'"
          >
            Les inscrits
          </div>
          <div
            class="base-padding pointer"
            (click)="selectFilter('created')"
            [class.text-primary]="filterSelected$.getValue() === 'created'"
          >
            Les non-inscrits
          </div>
        </div>
      </app-dropdown>
    </div>
    <div class="row gap">
      <app-button [outline]="true" icon="upload" (click)="downloadCsv()"
        >Exporter</app-button
      >
      <app-button icon="chevron-down" [dropdownTriggerFor]="addDropdown"
        ><app-icon type="add"
      /></app-button>
      <app-dropdown #addDropdown>
        <div class="column">
          <div class="base-padding pointer" (click)="addTalker()">
            Inviter un collaborateur
          </div>
          <div class="base-padding pointer" (click)="addTalkers()">
            Importer une liste de collaborateurs (CSV)
          </div>
        </div>
      </app-dropdown>
    </div>
  </div>

  <app-table-custom-pagination
    [propertiesFilters$]="isActivatedFilter$"
    (totalCount)="getTotalCount($event)"
    [searchForm]="searchForm"
    (select)="selectTalkers($event)"
    (goTo)="goToTalker($event)"
    [mainRepository]="mainRepository"
    name="collaborateur"
    [tableConfig]="headerColumns"
  ></app-table-custom-pagination>
</div>
