<h1 class="margin-bottom">L'histoire d'un talker</h1>
<app-notification class="margin-bottom" color="#FFA135"
                  title="Mon évolution et mon expérience avec We talk : un voyage transformationnel.">
  <i>« WeTalk a transformé ma manière de voir les défis professionnels. Je me sens désormais armée pour affronter les
    situations stressantes et j'ai appris l'importance de la communication et du bien-être au travail. Un vrai
    changement de jeu pour moi ! »</i>
</app-notification>
<div class="video-wrapper margin-top">
  <iframe width="560" height="349" src="https://www.youtube.com/embed/wH3-5xIQOjk" allowfullscreen></iframe>
</div>

<div class="margin-top-xl margin-bottom">
  <strong class="text-primary">Quel était votre état émotionnel avant We talk ?</strong>
</div>
<div>Avant de découvrir WeTalk, je me sentais souvent submergée par les défis professionnels et la pression au travail.
  La communication avec mes collègues était parfois tendue, et je sentais que cela affectait ma performance et mon
  bien-être.
</div>
<div class="margin-top-xl margin-bottom">
  <strong class="text-primary">Racontez-nous votre parcours en Coaching en groupe.</strong>
</div>
<div>L'expérience a été révélatrice. Les séances de groupe, animées par des thérapeutes expérimentés, m'ont permis de me
  connecter avec des collègues d'autres services, partageant des défis similaires. Chaque session était un mélange de
  partage d'expériences, d'exercices pratiques et de conseils personnalisés.
</div>

<div class="margin-top-xl margin-bottom">
  <strong class="text-secondary cooper text-large">“We Talk a été pour moi une bouée de sauvetage. Cela m'a non
    seulement aidée à développer des compétences professionnelles essentielles, mais a aussi grandement contribué à mon
    épanouissement personnel.”</strong>
</div>

<div class="margin-top-xl margin-bottom">
  <strong class="text-primary">Quel impact personnel et professionnel cela vous a-t-il apporté ?</strong>
</div>
<div>Depuis que j'ai commencé ce parcours avec We Talk, j'ai remarqué un changement significatif dans ma manière de
  gérer le stress et de communiquer au travail. Je me sens plus confiante, plus ouverte aux feedbacks et capable de
  gérer les situations difficiles avec plus de sérénité. Mes collègues ont également remarqué cette évolution, ce qui a
  renforcé notre esprit d'équipe.
</div>
