import { MonthNames, WeekDaysNames } from "../../admin/types/constant";
import { TimeSlot } from "../../trains/models/time-slot.entity";
import { Train } from "../../trains/models/train.entity";

import { stringNumber } from "./string-number";

export function addDelayToDate(delay: string, date: Date = new Date()): Date {
  if (delay === '') {
    throw Error('Format not allowed');
  }

  const suffixAllowed = [ 's', 'm', 'h', 'd', 'w', 'y' ]; // second minute hour day week year
  const delaySuffix = delay.charAt(delay.length - 1);
  const delayNumber = delay.slice(0, delay.length - 1);

  if (!suffixAllowed.includes(delaySuffix) || Number.isNaN(Number(delayNumber))) {
    throw Error('Format not allowed');
  }

  const millisecondsPerSuffix: Record<string, number> = {
    s: 1E3,
    m: 1E3 * 60,
    h: 1E3 * 60 * 60,
    d: 1E3 * 60 * 60 * 24,
    w: 1E3 * 60 * 60 * 24 * 7,
    y: 1E3 * 60 * 60 * 24 * 365,
  };

  return new Date(date.getTime() + millisecondsPerSuffix[delaySuffix] * Number(delayNumber));
}

export function isSameDate(dateA: Date, dateB: Date): boolean {
  return dateA.getDate() === dateB.getDate()
    && dateA.getMonth() === dateB.getMonth()
    && dateA.getFullYear() === dateB.getFullYear();
}

/**
 *
 * @param dateA
 * @param dateB
 *
 * @return dateA minus dateB or currentDate in days
 */
export function dateDelta(dateA: Date, dateB?: Date): number {
  if (!dateB) {
    return Math.floor(((new Date()).getTime() - dateA.getTime()) / (1000 * 60 * 60 * 24));
  }
  return Math.floor((dateA.getTime() - dateB.getTime()) / (1000 * 60 * 60 * 24));
}

export function newDateWithoutTime(): Date {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

/**
 *
 * @return The date like 'Lundi 06 Mars'
 * @param date
 * @param options
 */
export function formatDate(date: Date, options: { full: boolean } = { full : false }): string {
  return `${ WeekDaysNames[date.getDay()] } ${ stringNumber(date.getDate()) } ${ options.full ? MonthNames[date.getMonth()] :  (MonthNames[date.getMonth()]?.slice(0, MonthNames[date.getMonth()].length === 4 ? 4 : 3)) }${ MonthNames[date.getMonth()].length === 4 || options.full ? '' : '.' } ${ options.full ? date.getFullYear() : ''}`;
}

/**
 *
 * @return The dayTime of the timeSlot like 08:15
 * @param timeSlot
 * @param seperator
 */
export function formatTimeSlot(timeSlot?: TimeSlot, seperator: 'h' | ':' = ':'): string {
  return timeSlot ? `${ stringNumber(timeSlot.hours) }${ seperator }${ stringNumber(timeSlot.minutes) }` : '??';
}

/**
 *
 * @return The dayTime of the timeSlot like 08:15
 * @param time
 * @param seperator
 */
export function formatTime(time: Date, seperator: 'h' | ':' = ':'): string {
  return time ? `${ stringNumber(time.getHours()) }${ seperator }${ stringNumber(time.getMinutes()) }` : '??';
}

/**
 *
 * @return The dayTime of the first session like 08:15
 * @param train
 */
export function formatFirstSessionTimeOfTrain(train: Train): string {
  return `${ stringNumber(train.getFirstSession().date.getHours()) }:${ stringNumber(train.getFirstSession().date.getMinutes()) }`;
}

/**
 * @return first Date of current month with hour, minutes, seconds equal 0 and date equals 1 default is the current month one
 */
export function createCurrentMonthFirstDate(payload?: {
  monthIndex?: number,
  allowedWeekDays?: number[],
  year?: number
}): Date {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  if (payload?.year) {
    date.setFullYear(payload?.year);
  }
  date.setDate(1);

  if (payload?.monthIndex !== undefined) {
    date.setMonth(payload?.monthIndex);
  }

  if (payload && payload?.allowedWeekDays?.length && Math.max(...payload.allowedWeekDays) < 7 && Math.min(...payload.allowedWeekDays) >= 0) {
    while (!payload?.allowedWeekDays.includes(date.getDay())) {
      date.setDate(date.getDate() + 1);
    }
  }

  return date;
}

/**
 * @return last Date of month with hour, minutes, seconds equal 0 and date equals 1, default is the current month one
 */
export function createCurrentMonthLastDate(monthIndex?: number, allowedWeekDays?: number[]): Date {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setMonth(date.getMonth() + 1, -1);

  if (monthIndex !== undefined) {
    date.setMonth(monthIndex, -1);
  }


  if (allowedWeekDays?.length && Math.max(...allowedWeekDays) < 7 && Math.min(...allowedWeekDays) >= 0) {
    while (!allowedWeekDays.includes(date.getDay())) {
      date.setDate(date.getDate() - 1);
    }
  }

  return date;
}

/**
 * Return minimum of a date array
 * @param dates
 *@return Date
 *
 */
export function dateMinimum(dates: Date[]): Date {
  return dates.reduce((minimum, date) => (date.getTime() < minimum.getTime() ? date : minimum), dates[0]);
}

export const months = [ "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre" ];

export const decimalMinutesToHHMMSS = (minutes: number): string  =>{
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.floor((minutes * 60) % 60);

  const hoursStr = hours.toString().padStart(2, '0');
  const minsStr = mins.toString().padStart(2, '0');
  const secsStr = secs.toString().padStart(2, '0');

  return `${hoursStr}:${minsStr}:${secsStr}`;
};

export const HHMMSSToDecimalMinutes = (time: string): number => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 60 + minutes + seconds / 60;
};

export const decimalMinutesToReadableFormat = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.floor((minutes * 60) % 60);

  let result = '';

  if (hours > 0) {
    result += `${hours} h `;
  }
  if (mins > 0) {
    result += `${mins} mn `;
  }
  if (secs > 0) {
    result += `${secs} s`;
  }

  return result.trim();
};


