import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { Editor, Toolbar } from 'ngx-editor';
import { Subject, switchMap, takeUntil, tap } from "rxjs";


import { AuthenticationService } from "../../authentication/services/authentication.service";
import { ConfirmComponent } from "../../shared/components/confirm/confirm.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { UploadFileService } from "../../shared/components/upload-file/upload-file.service";
import { StorageService } from "../../shared/storage/storage.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { getColorByRole } from '../../shared/tool-functions/user';
import { extractVideoIdFromYoutubeUrl } from '../../shared/tool-functions/video';
import { isYoutubeLinkValidator } from '../../shared/validators/youtube-link.validator';
import { EditGenderComponent } from "../edit-gender/edit-gender.component";
import { EditPasswordComponent } from "../edit-password/edit-password.component";
import { EditPhoneComponent } from "../edit-phone/edit-phone.component";
import { EditTimeslotPreferencesComponent } from "../edit-timeslot-preferences/edit-timeslot-preferences.component";
import { EditUserNameComponent } from "../edit-user-name/edit-user-name.component";
import { Sherpa } from "../models/sherpa.entity";
import { Talker } from "../models/talker.entity";
import { GenderDescription, Role, RoleDescription } from "../models/users.entity";
import { Profile, ProfileService } from "../services/profile.service";

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: [ './my-profile.component.scss' ],
})
export class MyProfileComponent implements OnInit, OnDestroy {

  profile!: Profile;

  reload$ = new Subject<void>();

  loading = false;

  destroy$ = new Subject<void>();

  isSherpa = false;

  editingDescription = false;

  addingDescription = false;

  role!: string;

  gender!: string;

  organization!: string | boolean;

  showAddVideoLink = false;

  videoLinkForm!: any;

  formError = false;

  errorMessage = '';

  videoThumbnail = '';

  editor: Editor;

  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["ordered_list", "bullet_list"],
    ["link"],
    ["align_left", "align_center", "align_right", "align_justify"],
  ];

  description = '';


  constructor(private readonly profileService: ProfileService,
              private readonly authService: AuthenticationService,
              private readonly router: Router,
              private readonly snackBarService: SnackbarService,
              private readonly uploadService: UploadFileService,
              private readonly storageService: StorageService,
              private readonly dialog: DialogService,
              private readonly formBuilder: FormBuilder,
              private sanitizer: DomSanitizer) {
                this.videoLinkForm = this.formBuilder.group({
                  link: new FormControl('', { validators: isYoutubeLinkValidator(), nonNullable: true }),
                });
              this.editor = new Editor();
  }

  ngOnInit() {
    this.profileService.getObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(profile => {
        this.profile = profile;

        this.role = capitalize(RoleDescription[profile.role === Role.Talker && profile.organizationAssociations.length ? 'pilot' : profile.role]);
        this.gender = capitalize(GenderDescription[profile.gender]);
        this.organization = profile instanceof Talker && (profile as Talker).organization.name.toUpperCase();
        this.isSherpa = profile.role === Role.Sherpa;
        this.videoThumbnail =  profile.personalInformation.presentingVideoLink ?`https://img.youtube.com/vi/${extractVideoIdFromYoutubeUrl(profile.personalInformation.presentingVideoLink)}/0.jpg` : '';

      });

    this.videoLinkForm.valueChanges
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      if (this.formError) {
        this.formError = false;
        this.errorMessage = '';
      }
    });
  }

  ngOnDestroy() {
    this.editor.destroy();
    this.destroy$.next();
  }


  deleteAccount() {
    this.dialog.open(ConfirmComponent, { title: 'Voulez-vous vraiment supprimer votre compte ?\n Cette action est irréversible' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(isValidated => {
        if (isValidated) {
          this.profileService.deleteAccount()
            .pipe(tap(() => this.storageService.logout()))
            .subscribe(() => {
              this.router.navigate([ 'home' ]);
            });
        }
      });
  }

  updateProfile(): void {
    this.profileService.updateProfile({
      description: undefined,
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.snackBarService.pushMessage('Modification enregistrée', 'success');
      });

  }

  updateImage(file: File): void {
    if (file.name) {
      this.loading = true;
      this.uploadService.uploadFile(file, 'my-profile')
        .pipe(switchMap(() => this.profileService.loadProfile()))
        .subscribe({
          next: () => {
            this.snackBarService.pushMessage('Modification enregistrée', 'success');
            this.reload$.next();
            this.loading = false;
          }, error: () => {
            this.snackBarService.pushMessage('Une erreur s\'est produite', 'error');
          }

        });
    }
  }

  modifyPhone() {
    this.dialog.open(EditPhoneComponent, { phoneNumber: this.profile.phoneNumber ?? '' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(newPhoneNumber => {
        if (newPhoneNumber !== undefined) {
          this.profileService.updateProfile({ phoneNumber: newPhoneNumber }).subscribe({
            next: () => {
              this.snackBarService.pushMessage('Modification enregistrée', 'success');
              this.profileService.loadProfile().subscribe();
            }
          });
        }
      });
  }


  modifyPassword() {
    this.dialog.open(EditPasswordComponent)
      .pipe(takeUntil(this.destroy$))
      .subscribe(newPassword => {
        if (newPassword !== undefined) {
          this.authService.modifyPassword(newPassword).subscribe({
            next: () => {
              this.snackBarService.pushMessage('Modification enregistrée', 'success');
            }
          });
        }
      });
  }

  modifyTimeSlotPreferences() {
    const { profile } = this;
    if (profile instanceof Sherpa) {
      this.dialog.open(EditTimeslotPreferencesComponent, {
        timeSlots: profile.timeSlotPreferences ?? []
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(newTimeSlots => {
          if (newTimeSlots !== undefined) {
            this.profileService.updateMyTimeSlotPreferences(newTimeSlots).subscribe({
              next: () => {
                this.snackBarService.pushMessage('Modification enregistrée', 'success');
              }
            });
          }
        });
    }
  }

  switchProfile() {
    this.router.navigate([ 'switch-profile' ]);
  }

  modifyNames() {
    this.dialog.open(EditUserNameComponent, {
      firstName: capitalize(this.profile.firstName),
      lastName: capitalize(this.profile.lastName),

    })
      .pipe(takeUntil(this.destroy$))
      .subscribe((values) => {
        if (values) {
          this.profileService.updateProfile(values)
            .subscribe(() => {
              this.snackBarService.pushMessage('Modification enregistrée', 'success');
            });
        }
      });
  }


  modifyGender() {
    this.dialog.open(EditGenderComponent, {
      gender: this.profile.gender

    })
      .pipe(takeUntil(this.destroy$))
      .subscribe((gender) => {
        if (gender) {
          this.profileService.updateProfile({ gender })
            .subscribe(() => {
              this.snackBarService.pushMessage('Modification enregistrée', 'success');
            });
        }
      });
  }

  showAddVideo() {
    this.showAddVideoLink = true;
  }

  validate(){
    if(this.videoLinkForm.valid){
      const videoId = extractVideoIdFromYoutubeUrl(this.videoLinkForm.controls.link.value);
      this.profileService.updateProfile({
        presentingVideoLink: `https://youtu.be/${videoId}`,
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.showAddVideoLink = false;
          this.videoLinkForm.reset();
          this.snackBarService.pushMessage('Modification enregistrée', 'success');
        });
    } else {  
      this.formError = true;
      this.errorMessage = 'Vous devez insérer un lien Youtube.';
    }
  }

  addDescription() {
    this.addingDescription = true;
  }

  modifyDescription() {
    this.description = this.profile.personalInformation.description ?? '';
    this.editingDescription = true;
  }

  updateDescription(){
    this.profileService.updateProfile({
      description: this.description,
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.addingDescription = false;
        this.editingDescription = false;
        this.snackBarService.pushMessage('Modification enregistrée', 'success');
      });
  }

  onDescriptionChange(html: string) {
    this.description = html;
  }

  deletePresentingVideo() {
    this.dialog.open(ConfirmComponent, { title: 'Voulez-vous vraiment supprimer cette vidéo de présentation ?' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(isValidated => {
        if (isValidated) {
          this.profileService.deletePresentingVideo()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.profile.personalInformation.presentingVideoLink = undefined;
              this.snackBarService.pushMessage('Modification enregistrée', 'success');
            });
        }
      });
  }

  deleteDescription() {
    this.dialog.open(ConfirmComponent, { title: 'Voulez-vous vraiment supprimer ce texte de présentation ?' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(isValidated => {
        if (isValidated) {
          this.profileService.deleteDescription()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.description = '';
              this.addingDescription = false;
              this.profile.personalInformation.description = undefined;
              this.snackBarService.pushMessage('Modification enregistrée', 'success');
            });
        }
      });
  }

  cancelDescriptionEdit() {
    this.editingDescription = false;
    this.addingDescription = false;
  }

  getProfileInitialColorByRole(){
    return getColorByRole(this.profile.role);
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html.replace(/<p><\/p>/g, '<br>'));
  }

  protected readonly capitalize = capitalize;
}
