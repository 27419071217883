import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { startWith, Subject, takeUntil } from "rxjs";

import { plural } from "../../tool-functions/plural";
import { Checkbox } from "../checkmarks/checkmarks.component";

export type TreeCheckbox = Checkbox & {
  children?: TreeCheckbox[];
  expanded: boolean;
}

@Component({
  selector: 'app-tree-checkmarks',
  templateUrl: './tree-checkmarks.component.html',
  styleUrls: ['./tree-checkmarks.component.scss']
})
export class TreeCheckmarksComponent {

  private destroy$ = new Subject<void>();

  private firstLoad = false;

  public readonly treeCheckboxTrackBy = (index: number, treeCheckbox: TreeCheckbox) => treeCheckbox.key;

  public resumeFormControl = new FormControl('');

  public treesState: TreeCheckbox[] = [];

  public allSelected = false;

  @Input() update$ = new Subject<void>();

  @Input() trees: TreeCheckbox[] = [];

  @Input('item-name') itemName: string = '';

  @Input() isFemaleWord = false;

  @Output() changeTreeSelection = new EventEmitter<TreeCheckbox[]>();

  @Output() changeListSelection = new EventEmitter<string[]>();



  ngOnInit(): void {
    this.update$.pipe(
      startWith(undefined),
      takeUntil(this.destroy$)).subscribe(() => {
      const selectedList = this.trees.flatMap(tree => this.getSelectedListUpdate(tree));
      this.resumeFormControl.setValue(`${ selectedList.length } ${ plural(this.itemName, selectedList.length > 1) }`);
      this.allSelected = this.isAllSelected(this.trees);
      if (this.firstLoad) {
        this.changeTreeSelection.emit(this.treesState);
        this.changeListSelection.emit(selectedList);
      } else {
        this.firstLoad = true;
      }
    });
  }

  ngOnChanges(): void {
    this.treesState = this.trees;
    this.update$.next();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  selectAll(value: boolean, tree?: TreeCheckbox): void {
    if (tree) {
        tree.selected = value;
        tree?.children?.forEach(child => {
          this.selectAll(value, child);
        });
    }
    if (!tree) {
      this.treesState.forEach(checkbox => {
        checkbox.selected = value;
        checkbox?.children?.forEach(child => {
          this.selectAll(value, child);
        });

      });
      this.update$.next();
    }
  }

  updateTree(event: TreeCheckbox): void {
    const index = this.trees.findIndex(tree => tree.id === event.id);
    this.trees[index] = event;

    this.update$.next();
  }

  private getSelectedListUpdate(tree: TreeCheckbox): string[] {
    const result: string[] = [];
    if (tree.selected) {
      result.push(tree.id);
    }
    if (tree.children?.length) {
      return result.concat(...tree.children.flatMap(child => this.getSelectedListUpdate(child)));
    }
    return result;
  }

  private isAllSelected(trees: TreeCheckbox[] = []): boolean {
    return trees.every(tree => tree.selected && this.isAllSelected(tree.children));
  }


}
