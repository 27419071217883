import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, Observable, of, switchMap } from 'rxjs';

import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { Talker } from "../../users/models/talker.entity";
import { OrganizationMembersRepository } from "../repositories/organization.members.repository";
import { OrganizationStoreService } from "../services/organization.store.service";

@Injectable()
export class OrganizationMemberResolver {
  constructor(private readonly organizationMemberRepository: OrganizationMembersRepository,
              private readonly snackbarService: SnackbarService,
              private readonly router: Router,
              private readonly organizationStoreService: OrganizationStoreService,
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Talker | undefined> {
    const id = route.paramMap.get('id') ?? '';
    const memberId = route.paramMap.get('memberId') ?? '';

    if (memberId === '') {
      return this.organizationStoreService.getObservable()
        .pipe(
          switchMap((organization) => {
            this.organizationMemberRepository.setOrganizationId(organization?.id ?? '');
            return this.organizationMemberRepository.findById(id);
          }),
          catchError(err => this.handleError(err)));
    }
    this.organizationMemberRepository.setOrganizationId(id);
    return this.organizationMemberRepository.findById(memberId)
      .pipe(catchError(err => this.handleError(err)));
  }

  private handleError(err: HttpErrorResponse): Observable<Talker | undefined> {
    if (err.status === 404) {
      this.snackbarService.pushMessage('L\'utilisateur recherché est introuvable', 'error');
    }
    this.router.navigate([ '/users' ]);

    return of(undefined);
  }
}
