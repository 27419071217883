<div class="pagination-footer">
  <div class="pagination-buttons">
    <button
      *ngIf="currentPage !== 1"
      [disabled]="currentPage === 1"
      (click)="changePage(1)"
    >
      1
    </button>
    <span *ngIf="currentPage > 3">...</span>
    <button
      *ngIf="currentPage > 2"
      [disabled]="currentPage === 1"
      (click)="changePage(currentPage - 1)"
    >
      {{ currentPage - 1 }}
    </button>
    <button class="current-page">
      {{ currentPage }}
    </button>
    <button
      *ngIf="currentPage < totalPages - 1"
      (click)="changePage(currentPage + 1)"
    >
      {{ currentPage + 1 }}
    </button>
    <span *ngIf="currentPage < totalPages - 2">...</span>
    <button
      *ngIf="currentPage !== totalPages"
      [disabled]="currentPage === totalPages"
      (click)="changePage(totalPages)"
    >
      {{ totalPages }}
    </button>
  </div>
  <div class="pagination-info">
    <span>Items par page:</span>
    <select [(ngModel)]="rowsPerPage" (change)="onRowsPerPageChange()">
      <option value="50">50</option>
      <option value="75">75</option>
      <option value="100">100</option>
    </select>
  </div>
</div>
