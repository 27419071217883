<div class="wrapper">
    <div class="header row gap center-children-horizontally">
      <h2 class="confirm-title cooper">Êtes-vous satisfait.e de votre plateforme We talk ?</h2>
    </div>
    <section class="column center-children-vertically margin-top">
        <div class="font-18 subtitle">Votre retour est très important, il nous permet d'améliorer notre plateforme pour vous offrir la meilleure expérience possible.</div>
        <div class="font-18 subtitle">Arrivez-vous facilement à naviguer sur votre espace ? Trouvez-vous tout ce qu'il vous faut ?</div>
        <div class="font-18">Souhaiteriez-vous pouvoir faire plus de choses sur We talk ? Dîtes-nous tout !</div>
        <form class="feedback-form content margin-top" [formGroup]="feedbackForm" (submit)="sendFeedback()">
            <div class="column gap center-children">
                <app-emoji-selector [emojiSelected]="emojiSelected" formControlName="rating"/>
                <textarea class="margin-bottom" formControlName="comment" placeholder="Vos remarques, difficultés, envies... (facultatif)"></textarea>
                <div class="row gap margin-top">
                    <app-button  [disabled]="!feedbackForm.valid || loading" type="submit" title="Confirmer"  [outline]="true">
                      Confirmer<span *ngIf="loading">...</span>
                    </app-button>
                    <app-button title="Annuler" (onClick)="handleCancel()" type="button">
                        Plus tard
                      </app-button>
                </div>
            </div>
        </form>
    </section>
</div>