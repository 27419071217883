<div class="wrapper">
  <div class="header row gap">
    <h2 class="confirm-title cooper" *ngIf="data.title">{{data.title}}</h2>
  </div>
  <section class="column center-children">
    <app-select
      *ngIf="data.step === 1 || data.step === 3"
      (optionSelected)="handleOptionSelected($event)"
      [options]="this.data.organizations"
      [placeholder]="'Choisir une organisation'"
    ></app-select>
    <div
      class="confirm-message"
      [innerHTML]="parseMessageToHtml(data.message)"
    ></div>
    <div class="confirm-buttons">
      <app-button
        [disabled]="apiCalling"
        [outline]="!data.reverseColor"
        [disabled]="(data.step === 1 && !selectedOrganization) || (data.step === 3 && !selectedOrganization)"
        type="button"
        (click)="validate()"
        title="Confirmer"
        >{{data.confirmMessage ?? 'Confimer' }}
      </app-button>

      <app-button
        *ngIf="data.step !== 2"
        [outline]="!!data.reverseColor"
        type="button"
        (click)="cancel()"
        title="Annuler"
        >{{data.cancelMessage ?? 'Annuler' }}
      </app-button>
    </div>
  </section>
</div>
