import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: [ './info.component.scss' ]
})
export class InfoComponent {

  @Input() buttonMessage?: string;

  @Input() checkMessage?: string;

  @Input() outline = false;

  @Input() title?: string;

  @Output() onButtonClick = new EventEmitter<void>();

  onPanelButtonClick() {
    this.onButtonClick.emit();
  }
}
