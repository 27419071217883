import { Component } from '@angular/core';

@Component({
  selector: 'app-dynamics-distinction',
  templateUrl: './dynamics-distinction.component.html',
  styleUrls: ['./dynamics-distinction.component.scss']
})
export class DynamicsDistinctionComponent {


}
