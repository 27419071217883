<div class="calendar-wrapper" *ngIf="weekDaysAvailable.length">
  <div class="week-days" [class]="weekDaysClass">
    <div class="week-day"
         [class.hidden]="!weekDaysAvailable.includes(day)"
         *ngFor="let day of globalWeekDaysAvailable"><div class="week-day-name">
      <span>{{weekDaysNames[day].charAt(0)}}</span><span
      class="week-day-full-name">{{weekDaysNames[day].slice(1)}}</span></div></div>
  </div>
  <div [class]="weekDaysClass" class="content">
    <div *ngFor="let dayTime of dayTimes; let index = index;" class="day-cell"
         [class.hidden]="!weekDaysAvailable.includes(dayTime.weekDay)"
    >
        <div class="tag center-horizontally-by-margin" [class.hidden-tag]="dayTime.hidden && hideTimeSlots" [class.primary]="dayTime.selected" (click)="selectDayTime(index)">
          {{ getDayTimeDescription(dayTime) }}
        </div>
    </div>
  </div>
</div>
