<div class="login-wrapper no-select">
  <div class="login-component">
    <div class="center-children margin-top-xl">
      <app-image class="logo-authentication " src="assets/logos/logo_wetalk.png" alt="logo"
           height="46" width="212" priority />
    </div>
    <div class="grow column center-children ">
      <div class="header margin-top">
        <h2>Bienvenue</h2>
      </div>
      <form class="login-form" [formGroup]="loginForm" (submit)="login()">
        <app-input [error]="formError" [error-message]="''" formControlName="email" type="email"
                   placeholder="Email"></app-input>
        <app-input formControlName="password" type="password" placeholder="Mot de passe"
                   [login-password]="true" [error-message]="formError ? errorMessage : ''"
                   [error]="formError"></app-input>
        <div *ngIf="showSubscribeError">
          <p class="margin-bottom">
            Votre test a expiré. 
            <span class="contact-link" (click)="sendEmail()">Contactez-nous</span> pour le prolonger ou pour vous abonner à We talk.
          </p>
        </div>
        <div class="reset-password">
          <app-checkbox formControlName="rememberMe">Se souvenir de moi</app-checkbox>
          <div class="column gap">
            <a (click)="handleForgotPasswordClick()" href="auth/forgotten-password">Mot de passe oublié ?</a>
            <a href="auth/activate-account">Activer mon compte</a>
          </div>
        </div>
        <div class="column gap center-children">
          <app-button [disabled]="apiCalling" class="submit-button margin-bottom" type="submit">Se connecter</app-button>
          <app-separator/>
        </div>
      </form>
      <div class="column gap center-children">
        <div (click)="googleLoginClick()" #google></div>
        <button class="microsoft-button" type="button" (click)="loginByAzure()">
          <img src="assets/logos/microsoft.png" alt="microsoft">
          Se connecter avec Microsoft
        </button>
      </div>
    </div>


    <div class="mobile-column gap margin-top-xl center-children">
      <a class="half-width center-children" href="https://www.wetalk.life" target="_blank">WeTalk.life</a>
      <a class="half-width center-children" href="auth/new-sherpa">Devenir Coach</a>
    </div>


  </div>
  <div class="introduce-part">
    <img class="full-screen" src="assets/images/background.png" alt="background2"/>
    <div class="introducing-text">
      <img class="quote"  src="assets/images/quotes.svg" alt="quotes"/>
      <h1 style="text-transform: initial">Relève tes défis, traverse les épreuves, entame ta transformation…</h1>
    </div>
  </div>

  <div *ngIf="loading" class="mask center-children">
    <app-spinner></app-spinner>
  </div>


</div>
