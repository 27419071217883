<form [formGroup]="passwordForm" (submit)="onSubmit()">
  <app-input [correct]="passwordWork" class="form-input" id="password" formControlName="password" type="password"
             placeholder="Mot de passe"
             [login-password]="true"></app-input>
  <app-input [correct]="isValidated" class="form-input" id="confirmation-password" formControlName="confirmation" type="password"
             placeholder="Confirmation"
             [login-password]="true"></app-input>

  <div class="indications">
    Avec
    <span [class]="{'correct': hasMoreThanEightCharacters}">8 caractères</span>,
    <span [class]="{'correct': hasUpperCaseLetter}">une majuscule</span>,
    <span [class]="{'correct': hasLowerCaseLetter}">une minuscule</span> et
    <span [class]="{'correct': hasNumber}">un chiffre</span>
  </div>

  <div class="center-children">
    <app-button [disabled]="!isValidated || !externalCondition" type="submit">Valider</app-button>
  </div>
</form>


