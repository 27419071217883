import { ChangeDetectorRef, Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { filter, fromEvent, map, startWith, Subscription, takeUntil } from "rxjs";


import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { IconType } from "../../shared/components/icon/icon.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { decimalMinutesToReadableFormat } from "../../shared/tool-functions/date-tools";
import { extractVideoIdFromYoutubeUrl } from "../../shared/tool-functions/video";
import { mediumCategoryColors } from "../../thematics/colors";
import { ThematicCategorySlug } from "../../thematics/models/category.model";
import { Content } from "../models/contents.entity";
import { ContentReactionsRepository } from "../repositories/content-reactions.repository";
import { contentThematicCategoryTranslation } from "../translate";


@Component({
    selector: "app-content-details",
    templateUrl: "./content-details.component.html",
    styleUrls: ["./content-details.component.scss"],
})

export class ContentDetailsComponent extends AbstractInFunnelComponent  {
    
    public content!: Content;

    public liked: boolean | undefined;

    public videoUrl: string = '';

    isPlayingAudio = false;

    selectedCategory: any;

    isSmallScreen: boolean = false;

    resizeSubscription: Subscription = new Subscription();

    constructor(
        protected override readonly route: ActivatedRoute,
        protected override readonly funnelService: FunnelService,
        private readonly contentReactionsRepository: ContentReactionsRepository,
        private changeDetectorRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
    ) {
        super(funnelService, route);
     }

    override ngOnInit(): void {
        this.resizeSubscription = fromEvent(window, 'resize')
        .pipe(
          startWith(this.getScreenWidth),
          map(() => window.innerWidth < 768)
        )
        .subscribe(isSmall => {
            this.isSmallScreen = isSmall;
        });
        this.route.data
        .pipe(
            map(data => data.content),
            filter(content => content instanceof Content),
            takeUntil(this.destroy$)
        )
        .subscribe({
            next: (content: Content) => {
                this.content = content;
                this.videoUrl = this.getSafeUrl(content.resource);
            }
        });
    }

    override ngAfterViewInit(): void {
        this.contentReactionsRepository.getContentReactionByContentIdAndUserId(this.content.id).subscribe((contentReaction) => {
            this.liked = contentReaction?.liked;
            this.changeDetectorRef.detectChanges();
        });
    }

    override ngOnDestroy(): void {
        this.resizeSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    getPillarIcon(pillar: any): IconType {
        if(pillar.name === 'individual'){
            return 'candle';
        }

        if(pillar.name === 'environment'){
            return 'house-rustic';
        }

        if(pillar.name === 'event'){
            return 'storm';
        }

        return 'candle';
    }

    handleReaction(liked: boolean): void {
        if (this.liked === undefined) {
            this.contentReactionsRepository.createReaction({ contentId: this.content.id, liked }).subscribe(() => {
                this.liked = liked;
                this.changeDetectorRef.detectChanges();
            });
        } else if (this.liked === liked) {
            this.contentReactionsRepository.deleteContentReaction(this.content.id).subscribe(() => {
                this.liked = undefined;
                this.changeDetectorRef.detectChanges();
            });
        } else {
            this.contentReactionsRepository.updateReaction({ contentId: this.content.id, liked }).subscribe(() => {
                this.liked = liked;
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    onAudioStatusChange(status: boolean){
        this.isPlayingAudio = status;
    }

    handleLikeReaction(): void {
        this.handleReaction(true);
    }

    handleDislikeReaction(): void {
        this.handleReaction(false);
    }
    
    getColorBySlug(slug: ThematicCategorySlug): any {
        return mediumCategoryColors[slug] || '';
    }

    getCategoryNameBySlug(slug: ThematicCategorySlug): string {
        return capitalize(contentThematicCategoryTranslation[slug]) || '';
    }

    getSafeUrl(url: string): any {
        const link = url.includes('s3') ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${extractVideoIdFromYoutubeUrl(url)}`);
        console.log('Link ', link);
        return link;
    }

    downloadFile(): void {
        window.open(this.content.resource, '_blank');
    }

    onSelectCategory(category: any): void {
        this.selectedCategory = category;
    }

    capitalizeName(name: string): string {
        return capitalize(name);
    }

    get getScreenWidth() {
        return window.innerWidth;
      }
    
    get readableReadingTime(): string {
        return decimalMinutesToReadableFormat(this.content.readingDuration);
    }
}