<div class="padding-wrapper">
  <div class="wrapper">
    <h1 class="margin-bottom text-primary">
      Bienvenue {{ capitalize(firstName) }}
    </h1>
    <!--    <div class="home-notif" *ngxPermissionsOnly="['talker']">-->
    <!--      <app-notification [outline]="true"  class="margin-bottom" [buttonMessage]=" hasDoneInitialResponse ? 'Accéder à mon espace' : 'Réaliser mon bilan émotionnel'" (onButtonClick)="goToMySpace()" color="#8C5FB0" [icon]="hasDoneInitialResponse ? 'check' : 'bell'" title="Faites le point sur votre évolution">-->
    <!--        {{hasDoneInitialResponse ? 'Vous avez réalisé le bilan initial. C’est dans votre espace personnel que vous pourrez consulter l’évolution de votre état émotionnel.' : 'Réalisez votre bilan émotionnel pour identifier les parcours qui vous correspondent.'}}-->
    <!--      </app-notification>-->
    <!--    </div>-->

    <app-notifications-resume />

    <app-home-page-tabs />

    <div *ngxPermissionsOnly="'admin'" class="base-margin column gap">
      <h3>Configuration admin</h3>
      <div class="row gap space-between">
        <p *ngIf="smsBalance" [class.color-danger]="smsBalance < 5">
          Solde SMS Vonage: {{ smsBalance }}€
        </p>
        <div
          *ngIf="canDeactivateSendingEmail"
          class="row center-children gap space-between"
        >
          <span>Envoi des mails</span>
          <app-checkbox
            (ngModelChange)="onSwitchChange($event)"
            [ngModel]="isSendingEmailActivated"
            [type]="'switch'"
          />
        </div>
      </div>
      <app-button
        [outline]="true"
        (onClick)="editPlatformConfiguration()"
        title="Modifier la configuration"
        >Modifier la configuration
      </app-button>
      <app-button
        [outline]="true"
        (onClick)="editWeeklyTimeSlots()"
        title="Modifier les créneaux"
        >Modifier les créneaux horaires des séances
      </app-button>
      <app-button
        *ngIf="canResetData"
        [danger]="true"
        [outline]="true"
        (onClick)="restTestData()"
        title="Réinitialiser les données"
        >Réinitialiser les données <span *ngIf="resetDataLoading">...</span>
      </app-button>
    </div>

    <div class="margin-top-xl" *ngxPermissionsOnly="'talker'">
      <h1 class="margin-bottom">Vous ne savez pas par où commencer ?</h1>
        <div class="orientation-container">
          <div class="orientation-left-content wrap row-gap margin-top-xl">
            <div class="left">

              <div class="testimonial-wrapper">
                <div [style.background-color]="'#704c8d'"  class="cooper text-large center-children author-bubble"><app-icon class="white" size="30" type="question-mark"></app-icon></div>
                <div class="base-padding cooper" >
                  “Je ne suis pas sûr.e de la thématique à choisir par rapport à ma situation...”
                </div>
              </div>
            </div>
            <div class="left">
              <div class="testimonial-wrapper">
                <div [style.background-color]="'#ba9fd0'"  class="cooper text-large center-children author-bubble"><app-icon class="black" size="30" type="question-mark"></app-icon></div>
                <div class="base-padding cooper" >
                  “Est-ce qu'il est possible de participer à plusieurs thématiques : pro et perso ?”
                </div>
              </div>
            </div>
            <div class="left">
              <div class="testimonial-wrapper">
                <div [style.background-color]="'#ffa135'"  class="cooper text-large center-children author-bubble"><app-icon class="white" size="30" type="question-mark"></app-icon></div>
                <div class="base-padding cooper" >
                  “Je me pose beaucoup de questions, j'ai plusieurs sujets à aborder. Quel parcours est le plus adapté ?”
                </div>
              </div>
            </div>
          </div>
          <div class="orientation-button-container">
            <app-button icon="arrow-right" (onClick)="goToOrientation()">Être rappelé.e</app-button>
          </div>
        </div>
    </div>

    <div class="margin-top-xl" *ngxPermissionsExcept="'admin'">
      <h1 class="margin-bottom">Votre démarche commence ici</h1>
      <app-welcome-links [role]="role" />
    </div>
  </div>
</div>
