<div>
  <div class="wrapper">

    <div class="header">
      <div class="center-children">
        <app-profile-icon [big]="true" [profile]="sherpa"></app-profile-icon>
      </div>
      <div class="user-description">
        <div class="row gap center-children-vertically">
          <h2>{{sherpa.fullName}}          </h2>
          <app-icon *ngxPermissionsOnly="'admin'" button type="user-profile-right" title="Prendre la place"
                    (click)="impersonateUser()"></app-icon>
        </div>
        <p>Email : {{sherpa.email}}</p>
        <p>Genre : {{gender}}</p>
        <p>Numéro : {{sherpa.phoneNumber ?? 'Pas de numéro renseigné'}}</p>
        <p *ngxPermissionsExcept="['talker']">Rôle : {{role}}</p>
        <p *ngxPermissionsExcept="['talker']">Invité{{ sherpa.gender === Gender.WOMAN ? 'e' : '' }} le : {{sherpa.createdAt.toLocaleDateString()}}</p>
        <div *ngxPermissionsExcept="['talker']">
          <p *ngIf="sherpa.activatedAt">Inscrit{{ sherpa.gender === Gender.WOMAN ? 'e' : '' }} le : {{sherpa.activatedAt.toLocaleDateString()}}</p>
          <p *ngIf="!sherpa.activatedAt" class="margin-bottom">Pas encore inscrit{{ sherpa.gender === Gender.WOMAN ? 'e' : '' }}</p>
        </div>

      </div>
    </div>

    <div *ngIf="sherpa.personalInformation.description">
      <h2>Ma présentation</h2>
      <div>
        <div *ngIf="sherpa.personalInformation.presentingVideoLink" class="video-wrapper margin-top">
          <iframe width="280" height="175" [src]="getSafeUrl(sherpa.personalInformation.presentingVideoLink)" allowfullscreen></iframe>
        </div>
        <div class="margin-top" [innerHTML]="sanitizeHtml(sherpa.personalInformation.description)"></div>
      </div>
    </div>
    <div *ngxPermissionsOnly="['admin']">
      <div *ngIf="!loading" class="center-children thematics-part">
        <div>
          <h3 class="margin-bottom">Coaching en groupe</h3>
          <app-checkmarks [checkboxes]="coachingDynamicAllowed" item-name="Thématique associée" [isFemaleWord]="true"
                          (changeSelection)="updateDynamicAllowed()"></app-checkmarks>
        </div>

        <div>
          <h3 class="margin-bottom">Parole en groupe</h3>
          <app-checkmarks [checkboxes]="speechDynamicAllowed" item-name="Thématique associée" [isFemaleWord]="true"
                          (changeSelection)="updateDynamicAllowed()"></app-checkmarks>
        </div>

      </div>
    </div>
    <div *ngxPermissionsOnly="['admin']">
      <div class="center-children">
        <div *ngIf="!loading" class="trains">
          <h3 *ngIf="sherpaTrains$.getValue().length">Ses parcours</h3>
          <app-train-card (goToDetails)="goToTrain($event)" *ngFor="let train of sherpaTrains$.getValue()"
                          [train]="train"></app-train-card>
          <p *ngIf="!sherpaTrains$.getValue().length && sherpa.activatedAt">Aucun parcours en cours</p>
        </div>
        <div *ngIf="loading" class="center-children">
          <app-spinner></app-spinner>
        </div>
      </div>
    </div>

    <div *ngxPermissionsOnly="['admin']" class="center-children column">
      <app-button class="margin-bottom" *ngIf="!sherpa.activatedAt" [outline]="true" (click)="sendActivationMail()"
                  title="Renvoyer un email d'inscription">Renvoyer un email d'inscription
      </app-button>

      <a *ngIf="canDeleteUser" (click)="deleteUser()">Retirer cet
        utilisateur
      </a>
    </div>
  </div>
</div>
