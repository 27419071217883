<div class="involvement-wrapper">

  <canvas baseChart
          class="chart"
          [width]="width"
          [height]="height"
          [data]="chartData"
          [type]="type"
          [options]="chartOptions"
  ></canvas>

  <div class="column gap section center-horizontally-by-margin">
    <div class="center-children column gap">
      <p><strong>Taux d'inscription plateforme
        <app-icon tooltip="Nombre de collaborateurs inscrits / Nombre de collaborateurs invités"
                 type="info"></app-icon>
      </strong></p>
      <h1 class="cooper"
        [class]="{ 'success': showColors && inscriptionRate > 30, 'warning': showColors && inscriptionRate <= 30, 'danger': showColors && inscriptionRate < 20 }">{{inscriptionRate}}
        %
      </h1>
    </div>
    <div class="center-children column gap">
      <p><strong>Taux d'inscription parcours
        <app-icon
          tooltip="Nombre de collaborateurs inscrits à un parcours / Nombre de collaborateurs inscrits sur la plateforme"
          type="info"></app-icon>
      </strong></p>
      <h1 class="cooper"
        [class]="{ 'success': showColors && involvementRate > 50, 'warning': showColors && involvementRate <= 50, 'danger': showColors && involvementRate < 40 }">{{involvementRate}}
        %
      </h1>
    </div>
    <div class="center-children column gap">
      <p><strong>Taux de transformation plateforme
        <app-icon tooltip="Nombre de collaborateurs inscrits à un parcours / Nombre de collaborateurs invités"
                 type="info"></app-icon>
      </strong></p>
      <h1 class="cooper"
        [class]="{'success': showColors && fullInvolvementRate > 10, 'warning': showColors && fullInvolvementRate <= 10, 'danger': showColors && fullInvolvementRate < 5 }">{{fullInvolvementRate}}
        %
      </h1>
    </div>
  </div>
</div>

