<div class="column gap">
  <div class="row">
    <div class="space pointer" *ngIf="tree && tree.children?.length" (click)="tree.expanded = !tree.expanded">
      <app-icon *ngIf="tree.expanded" type="chevron-down"/>
      <app-icon *ngIf="!tree.expanded" type="chevron-right"/>
    </div>
    <div *ngIf="tree && !tree.children?.length" class="space"></div>
    <app-checkbox (ngModelChange)="onCheckboxUpdate($event)"
                  [ngModel]="tree.selected">{{ tree.key}}</app-checkbox>
  </div>
  <div class="row" *ngIf="tree.expanded">
    <div class="space"></div>
    <div class="column gap">
      <app-tree-checkbox *ngFor="let child of tree.children" [tree]="child"
                         (update)="onTreeUpdate($event)"></app-tree-checkbox>
    </div>
  </div>
</div>
