
<div class="padding-wrapper">

  <h1 class="margin-bottom-xl">Parcours</h1>
  <div *ngIf="!loading && availableTrains.length" class="base-margin column gap">
    <h3>Nouvelles séances disponibles</h3>
    <div *ngIf="availableTrains.length === 0">
      Les parcours s'afficheront ici !
    </div>
    <div *ngIf="availableTrains.length > 0" class="column gap">
      <app-train-card [apiCalling]="apiCalling" (goToDetails)="goToTrain($event)" *ngFor="let train of availableTrains"
                      (manage)="manageTrain($event)" [train]="train"></app-train-card>
    </div>
  </div>
  <div *ngIf="!loading && trains.length" class="base-margin column gap">
    <h3>Vos parcours en cours</h3>
    <div *ngIf="trains.length === 0">
      Vos parcours s'afficheront ici !
    </div>
    <div *ngIf="trains.length > 0" class="column gap">
      <app-train-card [apiCalling]="apiCalling" (goToDetails)="goToTrain($event)" *ngFor="let train of trains"
                      (manage)="manageTrain($event)" [train]="train"></app-train-card>
    </div>
  </div>
  <div *ngIf="!loading && trainsCompleted.length" class="base-margin column gap">
    <h3>Vo{{trainsCompleted.length > 1 ? 's' : 'tre'}} parcours finalisé{{trainsCompleted.length > 1 ? 's' : ''}}</h3>
    <div *ngIf="trainsCompleted.length > 0" class="column gap">
      <app-train-card [apiCalling]="apiCalling" (goToDetails)="goToTrain($event)" *ngFor="let train of trainsCompleted"
                      (manage)="manageTrain($event)" [train]="train"></app-train-card>
    </div>
  </div>
  <app-spinner class="center-horizontally-by-margin" *ngIf="loading"></app-spinner>
</div>
