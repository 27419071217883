<div class="wrapper">
  <div class="header">
    <h1 class="title">{{title}}</h1>
    <app-button title="Fermer" [outline]="true" [no-border]="true" class="close-button" icon="close"
                (onClick)="validate()"></app-button>
  </div>


  <section>
    <form [formGroup]="newTagForm" (submit)="validate(true)">
      <app-input [correct]="formValid"
                 formControlName="name"
                 [error]="!nameWorks"
                 error-message="Nom de catégorie déjà utilisé"
                 placeholder="Nom de la nouvelle catégorie"></app-input>
      <app-checkmarks [checkboxes]="thematicsCheckboxes" item-name="Thématique associée" [isFemaleWord]="true"
                      (changeSelection)="updateThematicsAssociated($event)"></app-checkmarks>
      <div class="add-button relative">
        <app-button class="center-by-relative-parent" [disabled]="!formValid" type="submit">{{submitMessage}}
        </app-button>
      </div>
      <div *ngIf="data" class="add-button relative">
        <app-button class="center-by-relative-parent" (click)="delete()" [danger]="true"  type="button">Supprimer
        </app-button>
      </div>
    </form>
  </section>


</div>
