import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { startWith, Subject, switchMap, takeUntil } from "rxjs";

import { OrganizationRepository } from "../../organizations/repositories/organization.repository";
import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { UsersRepository } from "../repositories/users.repository";

@Component({
  selector: 'app-send-reminder',
  templateUrl: './send-reminder.component.html',
  styleUrls: [ './send-reminder.component.scss' ]
})
export class SendReminderComponent extends DialogComponent<string[], string>() implements AfterViewInit, OnDestroy {

  notActivatedUserCheckBoxes!: Checkbox[];

  organizationCheckboxes: Checkbox[] = [];

  organizationsSelection$ = new Subject<string[]>();

  usersSelectedCount: number = 0;

  public readonly userTrackBy = (index: number, item: Checkbox) => item.id;

  constructor(
    @Inject(DIALOG_DATA)
    public readonly organizationId: string,
    private readonly ref: DialogRef<string[]>,
    private readonly organizationRepository: OrganizationRepository,
    private readonly usersRepository: UsersRepository) {
    super(ref);
  }

  ngAfterViewInit(): void {
    super.onInit();

    this.organizationsSelection$.pipe(
      startWith(undefined),
      switchMap((organizationIds) => this.usersRepository.getUsersNotActivated(this.organizationId ? [ this.organizationId ] : organizationIds)),
      takeUntil(this.destroy$)
    ).subscribe(users => {
      this.notActivatedUserCheckBoxes = users.map(user => ({
        key: user.fullName,
        id: user.id,
        selected: true,
      }));
      this.updateUsersSelectedCount();
    });

    if (!this.organizationId) {
      this.organizationRepository.findAll().subscribe(organizations => {
        this.organizationCheckboxes = organizations.map(organization => ({
          id: organization.id,
          key: organization.name.toUpperCase(),
          selected: false
        }));
      });
    }
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  selectOrganizations(organizationCheckboxes: Checkbox[]): void {
    this.organizationsSelection$.next(organizationCheckboxes
      .filter(organizationCheckbox => organizationCheckbox.selected)
      .map(organizationCheckbox => organizationCheckbox.id));
  }

  updateUsersSelectedCount(): void {
    this.usersSelectedCount = this.notActivatedUserCheckBoxes.filter(checkbox => checkbox.selected).length;
  }

  selectUser(userId: string, selected: boolean): void {
    const checkboxSelected = this.notActivatedUserCheckBoxes.find(checkbox => checkbox.id === userId);
    if (checkboxSelected) {
      checkboxSelected.selected = selected;
    }
    this.updateUsersSelectedCount();
  }

  sendMails(): void {
    this.close(this.notActivatedUserCheckBoxes
      .filter(checkbox => checkbox.selected)
      .map(checkbox => checkbox.id));
  }
}
