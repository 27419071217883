import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, Observable, of, tap } from 'rxjs';

import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { Organization } from "../models/organizations.entity";
import { OrganizationRepository } from "../repositories/organization.repository";
import { OrganizationStoreService } from "../services/organization.store.service";

@Injectable()
export class OrganizationResolver {
  constructor(private readonly organizationRepository: OrganizationRepository,
              private readonly snackbarService: SnackbarService,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly router: Router,
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Organization | undefined> {
    const id = route.paramMap.get('id') ?? '';

    return this.organizationRepository.findById(id)
      .pipe(
        tap(organization => {
          // FIX ME : should be only object 
          if(Array.isArray(organization) && organization.length > 0) {
            this.organizationStoreService.setOrganization(organization[0]);
          } else {
            this.organizationStoreService.setOrganization(organization);
          }
}),
        catchError(err => this.handleError(err)));
  }

  private handleError(err: HttpErrorResponse): Observable<Organization | undefined> {
    if (err.status === 404) {
      this.snackbarService.pushMessage('L\'organisation recherchée est introuvable', 'error');
    }
    this.router.navigate([ 'organizations' ]);

    return of(undefined);
  }
}
