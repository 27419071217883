import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
// import { MatomoTracker } from 'ngx-matomo-client';
import { combineLatestWith, of, Subject, switchMap, takeUntil, tap } from "rxjs";

import { MeetService } from "../../../meet/meet.service";
import { Organization } from "../../../organizations/models/organizations.entity";
import { OrganizationRepository } from "../../../organizations/repositories/organization.repository";
import { OrganizationStoreService } from "../../../organizations/services/organization.store.service";
import { Train } from "../../../trains/models/train.entity";
import { TrainRepository } from "../../../trains/repositories/train.repository";
import { TypeformService } from "../../../typeform/typeform.service";
import { TypeformFormResponse, TypeformFormType } from "../../../typeform/typeform.type";
import { Role } from "../../../users/models/users.entity";
import { ProfileService } from "../../../users/services/profile.service";

export enum NotificationType {
  TRAIN_GOING_TO_START,
  TRAIN_CURRENTLY_RUNNING,

  NEED_TO_DO_INITIAL_TYPEFORM,
  NEED_TO_DO_POST_TRAIN_TYPEFORM,
  NEED_TO_DO_SIX_MONTHS_POST_TRAIN_TYPEFORM,
  HAS_DONE_INITIAL_TYPEFORM,

  TALKER_WANT_MORE_CREDITS,
  TALKER_WANT_MORE_QUOTA,

  TRAIN_NEEDS_SHERPA,
}

export const NOTIFICATION_PRIORITY_ORDER: Record<NotificationType, number> = {
  [NotificationType.TRAIN_GOING_TO_START]: 1,
  [NotificationType.TRAIN_CURRENTLY_RUNNING]: 0,

  [NotificationType.NEED_TO_DO_INITIAL_TYPEFORM]: 2,
  [NotificationType.NEED_TO_DO_POST_TRAIN_TYPEFORM]: 3,
  [NotificationType.NEED_TO_DO_SIX_MONTHS_POST_TRAIN_TYPEFORM]: 4,
  [NotificationType.HAS_DONE_INITIAL_TYPEFORM]: Infinity,

  [NotificationType.TALKER_WANT_MORE_CREDITS]: 3,
  [NotificationType.TALKER_WANT_MORE_QUOTA]: 3,

  [NotificationType.TRAIN_NEEDS_SHERPA]: 2,
};

export type Notification = {
  ctaAction: () => any;
  ctaLabel: string;
  title: string;
  content: string;
  type: NotificationType;
  icon: 'bell' | 'check';
  isPrimaryCta: boolean;
}

@Component({
  selector: 'app-notifications-resume',
  templateUrl: './notifications-resume.component.html',
})
export class NotificationsResumeComponent implements OnInit, OnDestroy {

  currentNotification?: Notification;

  trainWhichGoingToStart?: Train;

  trainCurrentlyRunning?: Train;

  myTrains$: Subject<Train[]> = new Subject<Train[]>();

  trainWithNoSherpa?: Train;

  organization?: Organization;

  typeformResponses$: Subject<TypeformFormResponse[]> = new Subject<TypeformFormResponse[]>();

  notificationsRecord: Record<NotificationType, Notification> = {
    [NotificationType.NEED_TO_DO_INITIAL_TYPEFORM]: {
      ctaLabel: "Réaliser mon bilan émotionnel",
      title: "Faites le point sur votre évolution",
      content: "Réalisez votre bilan émotionnel pour identifier les parcours qui vous correspondent.",
      icon: 'bell',
      type: NotificationType.NEED_TO_DO_INITIAL_TYPEFORM,
      ctaAction: () => {
        (window._mtm || []).push({ 'event': 'demarrage-bilan' });
        // this.tracker.trackEvent(TrackerCategory.MYSPACE_TALKER, TrackerAction.CLICK, 'evaluationButton');
        this.typeformService.goToSpecificForm({ type: TypeformFormType.INITIAL });
      },
      isPrimaryCta: false
    }, [NotificationType.HAS_DONE_INITIAL_TYPEFORM]: {
      ctaLabel: "Accéder à mon espace",
      title: "Faites le point sur votre évolution",
      content: "Vous avez réalisé le bilan initial. C’est dans votre espace personnel que vous pourrez consulter l’évolution de votre état émotionnel.",
      icon: 'check',
      type: NotificationType.HAS_DONE_INITIAL_TYPEFORM,
      ctaAction: () => this.router.navigate([ 'my-space' ]),
      isPrimaryCta: false
    },
    [NotificationType.NEED_TO_DO_POST_TRAIN_TYPEFORM]: {
      ctaLabel: "Réaliser mon bilan de parcours",
      title: "Faites le point sur votre évolution",
      content: "Nous sommes ravis de vous avoir accompagné tout au long de votre parcours.\n Comment vous sentez-vous après cette expérience ? Exprimez-vous en faisant votre bilan émotionnel ici.",
      icon: 'bell',
      type: NotificationType.NEED_TO_DO_POST_TRAIN_TYPEFORM,
      ctaAction: () => this.typeformService.goToSpecificForm({
        type: TypeformFormType.POST_TRAIN,
        train: this.typeformService.trainsWithoutResponse[0]
      }),
      isPrimaryCta: false
    },
    [NotificationType.NEED_TO_DO_SIX_MONTHS_POST_TRAIN_TYPEFORM]: {
      ctaLabel: "Réaliser le bilan",
      title: "Faites le point sur votre évolution",
      type: NotificationType.NEED_TO_DO_SIX_MONTHS_POST_TRAIN_TYPEFORM,
      content: "Cela fait 6 mois que vous avez fini votre premier parcours\n Comment vous sentez-vous après tout ce temps ? Exprimez-vous en faisant votre bilan émotionnel ici.",
      icon: 'bell',
      ctaAction: () => this.typeformService.goToSpecificForm({
        type: TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN,
        train: this.typeformService.firstTrain!
      }),
      isPrimaryCta: false
    },
    [NotificationType.TRAIN_GOING_TO_START]: {
      ctaLabel: "Accéder à la séance",
      title: "Votre séance est sur le point de commencer",
      content: "Rejoignez dès à présent votre séance en cliquant ici.",
      icon: 'bell',
      type: NotificationType.TRAIN_GOING_TO_START,
      ctaAction: () => this.trainWhichGoingToStart && this.meetService.joinMeet(this.trainWhichGoingToStart),
      isPrimaryCta: true
    },
    [NotificationType.TRAIN_CURRENTLY_RUNNING]: {
      ctaLabel: "Accéder à la séance",
      title: "Votre séance est en cours",
      content: "Rejoignez dès à présent votre séance en cliquant ici.",
      type: NotificationType.TRAIN_CURRENTLY_RUNNING,
      icon: 'bell',
      ctaAction: () => this.trainCurrentlyRunning && this.meetService.joinMeet(this.trainCurrentlyRunning),
      isPrimaryCta: true
    },
    [NotificationType.TALKER_WANT_MORE_CREDITS]: {
      ctaLabel: "Demander plus d'unités",
      title: "Vos collaborateurs réclament plus d'unités",
      content: "Certains de vos collaborateurs ne peuvent plus participer à des parcours car votre organisation ne possèdent plus d'unités, demandez en dès à présent en cliquant ici",
      icon: 'bell',
      type: NotificationType.TALKER_WANT_MORE_CREDITS,
      ctaAction: () => this.router.navigate([ 'questions' ]),
      isPrimaryCta: false
    },
    [NotificationType.TALKER_WANT_MORE_QUOTA]: {
      ctaLabel: "Modifier les quotas",
      title: "Vos collaborateurs réclament plus de quotas",
      type: NotificationType.TALKER_WANT_MORE_QUOTA,
      content: "Certains de vos collaborateurs ne peuvent plus participer à des parcours car leur quota a été atteint, modifiez le quota de votre organisation dès à présent en cliquant ici",
      icon: 'bell',
      ctaAction: () => this.router.navigate([ 'organizations', this.organization?.id, 'edit' ]),
      isPrimaryCta: false
    },
    [NotificationType.TRAIN_NEEDS_SHERPA]: {
      ctaLabel: "Voir les parcours",
      title: "Un parcours est disponible",
      type: NotificationType.TRAIN_NEEDS_SHERPA,
      content: `Un parcours a besoin d'un sherpa pour l'accompagner. \nVoir la liste des parcours disponibles en cliquant ici`,
      icon: 'bell',
      ctaAction: () => this.router.navigate([ 'my-trains' ]),
      isPrimaryCta: false
    },
  };

  destroy$ = new Subject<void>();

  // private readonly tracker = inject(MatomoTracker);

  constructor(private readonly profileService: ProfileService,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly organizationRepository: OrganizationRepository,
              private readonly typeformService: TypeformService,
              private readonly trainRepository: TrainRepository,
              private readonly meetService: MeetService,
              private readonly router: Router) {
  }

  ngOnInit() {
    this.organizationStoreService.getObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(organization => {
        this.organization = organization;
      });

    this.myTrains$
      .pipe(takeUntil(this.destroy$))
      .subscribe(trains => {
        this.trainCurrentlyRunning = trains.find(train => train.isPlaying);
        if (this.trainCurrentlyRunning) {
          this.setCurrentNotification(NotificationType.TRAIN_CURRENTLY_RUNNING);
        }
        this.trainCurrentlyRunning = trains.find(train => train.isStartingSoon);
        if (this.trainWhichGoingToStart) {
          this.setCurrentNotification(NotificationType.TRAIN_GOING_TO_START);
        }
      });

    this.profileService.getObservable()
      .pipe(
        combineLatestWith(this.organizationStoreService.getObservable()
          .pipe(
            tap(organization => {
              this.organization = organization;
            }),
            switchMap((organization) => {
              if (organization) {
                // TODO: add units quota
                return this.organizationRepository.getQuotaDemands(organization.id);
              }
              return of(undefined);
            })
          )),
        takeUntil(this.destroy$))
      .subscribe(([ profile, organizationDemands ]) => {
        this.resetCurrentNotification();

        if (profile.role === Role.Talker && !this.organization) {
          this.trainRepository.getMyTrains()
            .pipe(takeUntil(this.destroy$))
            .subscribe(trains => {
              this.myTrains$.next(trains);
            });

          if (this.typeformService.initialFormResponse) {
            this.setCurrentNotification(NotificationType.HAS_DONE_INITIAL_TYPEFORM);
          } else {
            this.setCurrentNotification(NotificationType.NEED_TO_DO_INITIAL_TYPEFORM);
          }
        }

        if (profile.role === Role.Sherpa) {
          this.trainRepository.getMyTrains()
            .pipe(
              combineLatestWith(this.trainRepository.getSherpaAvailableTrains()),
              takeUntil(this.destroy$))
            .subscribe(([ myTrains, availableTrains ]) => {
              this.myTrains$.next(myTrains);
              this.trainWithNoSherpa = availableTrains.find(t => !!t);
              if (this.trainWithNoSherpa) {
                this.setCurrentNotification(NotificationType.TRAIN_NEEDS_SHERPA);
              }
            });
        }

        if (this.organization && organizationDemands) {
          this.setCurrentNotification(NotificationType.TALKER_WANT_MORE_QUOTA);
        }


      });
  }

  private setCurrentNotification(type: NotificationType) {
    if (!this.currentNotification) {
      this.currentNotification = this.notificationsRecord[type];
      return;
    }

    if (NOTIFICATION_PRIORITY_ORDER[this.currentNotification.type] > NOTIFICATION_PRIORITY_ORDER[type]) {
      this.currentNotification = this.notificationsRecord[type];

    }
  }

  private resetCurrentNotification() {
    this.currentNotification = undefined;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

}
