<h2 class="cooper margin-bottom margin-top" >Baromètre</h2>
<div>
  Pour mesurer l’impact de sa solution et vous aider à estimer le bien-être psychologique de vos collaborateurs ainsi que son évolution, We talk a créé son
  propre baromètre : le bilan émotionnel. Basé sur l’indice de bien-être de l’OMS (1999), le test Positive And Negative Affects Schedule (2007), le test
  Flourishing Scale (2009) et l'Échelle d’Engagement au Travail (2021), ce bilan vise à questionner le collaborateur sur son ressenti émotionnel en tant
  qu’individu, au sein de son environnement, de ses relations, de ses activités, ainsi que dans sa vie professionnelle.
</div>

<div class="impact-section column center-children-vertically margin-top" #impactSection>
    <p class="margin-bottom text-bold">État émotionnel actuel des collaborateurs</p>
    <div class="satisfaction-container">
        <div *ngFor="let variable of typeformVariables" class="column center-children-vertically">
            <ng-container *ngIf="(getMetricData$(variable) | async)?.average !== 0; else noMetricData">
                <app-icon size="35" [type]="getIconType(variable)" color="#8b5eb0"/>
                <p class="margin-top">Score de <span class="text-bold">{{ getMetricLabel(variable) }}</span></p>
                <div class="margin-top">
                    <app-vertical-bar-chart [data$]="getMetricData$(variable)"></app-vertical-bar-chart>
                </div>
                <div class="column center-children-vertically">
                    <p class="text-bold margin-top">Moyenne :</p>
                    <p>
                        <span class="text-bold text-xl" 
                            [style.color]="getColorByScore((getMetricData$(variable) | async)?.average ?? 0)">
                        {{ (getMetricData$(variable) | async)?.average }}
                        </span>
                        <span class="text-bold text-xl">/100</span>
                    </p>
                </div>
            </ng-container>
            <ng-template #noMetricData>
                <span>Aucune donnée pour l'instant</span>
            </ng-template>
        </div>
    </div>
</div>

<div class="impact-section column center-children-vertically margin-top margin-bottom-xl">
    <p class="margin-bottom text-bold">Impact : évolution de l’état émotionnel avec We talk</p>
    <div class="margin-top margin-bottom-xl">
        <div class="satisfaction-container"> 
            <div *ngFor="let variable of typeformVariables" class="column center-children-vertically">
              <app-icon size="35" [type]="getIconType(variable)" color="#8b5eb0"/>
              <p class="margin-top">Score moyen de <span class="text-bold">{{ getMetricLabel(variable) }}</span></p>
              <div class="margin-top">
                <ng-container *ngIf="(getProgressionData$(variable) | async) as progressionData">
                  <ng-container *ngIf="hasProgressionData(progressionData)" else noProgressionDataTemplate>
                    <app-impact-chart 
                      [data$]="getProgressionData$(variable)"
                      [counts$]="getProgressionCounts$(variable)">
                    </app-impact-chart>
                  </ng-container>
                </ng-container>
                <ng-template #noProgressionDataTemplate>
                  <span>Aucune donnée pour l'instant</span>
                </ng-template>
              </div>
            </div>
          </div>
    </div>
</div>
        