<div class="panel-wrapper">
  <div class="content row gap center-children-vertically">
    <div class="panel-icon"><app-icon type="info"></app-icon></div>
    <div class="row gap grow center-children-vertically">
      <div class="column gap grow">
        <p class="text-large text-bold text-black" *ngIf="title"> {{title}}</p>
        <ng-content></ng-content>
      </div>
      <div class="center-children row gap margin-top-mobile" *ngIf="checkMessage">
        <app-icon type="check-dot" />
        <span>{{checkMessage}}</span>
      </div>
      <div class="center-children margin-top-mobile" *ngIf="checkMessage === undefined && buttonMessage && onButtonClick.observed">
        <app-button icon="arrow-right" (onClick)="onPanelButtonClick()">{{buttonMessage}}</app-button>
      </div>
    </div>
  </div>

</div>
