<div *ngIf="thematics">
  <h1 class="cooper margin-bottom">Gérer les thématiques</h1>
  <app-info title="Sélectionnez les thématiques auxquelles vos collaborateurs pourront participer">{{thematics.length}}
    thématiques sont disponibles sur la plateforme We talk. Sélectionnez celles que vous souhaitez ouvrir à vos
    collaborateurs et celles qui seront masquées.
  </app-info>

  <div class="wrap margin-bottom margin-top">
    <div class="bubble pointer center-vertically-by-margin row gap center-children"
         [dropdownTriggerFor]="categoriesDropdown" dropdownPosition="start">Catégories
      <app-icon type="chevron-down"/>
    </div>
    <app-dropdown [closeOnClick]="false" #categoriesDropdown>
      <div class="column gap base-padding">
        <div class="bubble pointer" (click)="selectCategory(category.id)"
             [style.border-color]="category.selected ? mediumCategoryColors[category.id]: 'grey'"
             *ngFor="let category of categoryCheckboxes">{{capitalize(category.key)}}</div>
      </div>
    </app-dropdown>
    <div class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin"
         [dropdownTriggerFor]="dynamicsDropdown" dropdownPosition="center">Dynamique de groupe
      <app-icon type="chevron-down"/>
    </div>
    <app-dropdown [closeOnClick]="false" #dynamicsDropdown>
      <div class="column gap base-padding ">
        <div class="bubble pointer" (click)="selectDynamic(dynamic.id)"
             [style.border-color]="dynamic.selected ? dynamic.id === DynamicType.COACHING ? '#8C5FB0': '#FFA135': ''"
             *ngFor="let dynamic of dynamicTypeCheckboxes">{{capitalize(dynamic.key)}}</div>
      </div>
    </app-dropdown>
    <div class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin"
         (click)="selectCategory(category.id)"
         [style.border-color]="category.selected ? mediumCategoryColors[category.id]: 'grey'"
         *ngFor="let category of categoryCheckboxesSelected$.getValue()">{{capitalize(category.key)}}
      <app-icon type="close"/>
    </div>
    <div class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin"
         (click)="selectDynamic(dynamic.id)"
         [style.border-color]="dynamic.selected ? dynamic.id === DynamicType.COACHING ? '#8C5FB0': '#FFA135': ''"
         *ngFor="let dynamic of dynamicTypeCheckboxesSelected$.getValue()">{{capitalize(dynamic.key)}}
      <app-icon type="close"/>
    </div>
  </div>

  <div class="column gap margin-top">
    <div class="header row" *ngFor="let checkbox of shownThematics">
      <div class="image-slot">
        <app-image [alt]="checkbox.thematic.slug" [width]="248" [height]="192" [src]="checkbox.thematic.imageUrl"/>
      </div>
      <div class="column gap grow">
        <div class="space-between row">
          <p class="thematic-name" [ngStyle]="{'background-color': getCategoryColor(checkbox.thematic)}">
            <strong>{{ checkbox.thematic.name }}</strong></p>
        </div>
        <div class="header-description column no-gap">
          <h3 class="cooper margin-bottom">{{checkbox.thematic.title}}</h3>
          <div class="text-ellipsis row gap mobile-hidden">
            <div class="grow">
              <p class="thematic-resume text-ellipsis overflow-hidden">{{checkbox.thematic.resume}}</p>
            </div>
            <div class="shrink discover-button row gap">
              <app-button [outline]="true" (click)="goToThematic(checkbox.thematic)">
                Découvrir
              </app-button>
              <app-checkbox type="switch" [formControl]="checkbox.form"></app-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
