import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { Admin } from "../models/admin.entity";
import { CreateUser } from "../models/users.entity";

@Injectable()
export class AdminRepository extends AbstractRepository<Admin, CreateUser> {
  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              protected override readonly http: HttpClient) {
    super(http, Admin);
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return "admins";
  }

  getAll(): Observable<Admin[]> {
    return this.http.get<Admin[]>([ this.apiUrl, this.getEntityName() ].join('/')).pipe(map(result => result.map(item => this.instantiate(item))));
  }

}
