import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { sortTrains, Train } from "../../trains/models/train.entity";
import { CreateSherpa, Sherpa } from "../models/sherpa.entity";


@Injectable()
export class SherpaRepository extends AbstractRepository<Sherpa, CreateSherpa> {
  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              protected override readonly http: HttpClient) {
    super(http, Sherpa);
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return "sherpas";
  }

  getAll(): Observable<Sherpa[]> {
    return this.http.get<Sherpa[]>([ this.apiUrl, this.getEntityName() ].join('/')).pipe(map(result => result.map(item => this.instantiate(item))));
  }

  getAllWithTrains(): Observable<Sherpa[]> {
    return this.http.get<Sherpa[]>([ this.apiUrl, this.getEntityName(), 'trains' ].join('/')).pipe(map(result => result.map(item => this.instantiate(item))));
  }

  updateDynamicsAllowed(userId: string, dynamics: string[]): Observable<void> {
    return this.http.post<void>([ this.apiUrl, this.getEntityName(), userId, 'dynamics' ].join('/'), { dynamics });
  }

  getSherpaTrains(sherpa: Sherpa): Observable<Train[]> {
    return this.http.get<Train[]>([ this.apiUrl, this.getEntityName(), sherpa.id, 'trains' ].join('/'))
      .pipe(map(trains => trains
        .map(train => {
          train.sherpa = sherpa;
          return train;
        })
        .map(train => plainToInstance(Train, train))
        .sort(sortTrains)
      ));
  }

}
