import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';

import { TrainMembership } from "../../../trains/models/membership.entity";
import { DEFAULT_FONT } from "../../utils/utils";
import { AbstractChartComponent } from "../abstract.chart.component";

@Component({
  selector: 'app-trains-progression',
  templateUrl: './trains-progression.component.html',
})
export class TrainsProgressionComponent extends AbstractChartComponent<'doughnut'> implements AfterViewInit, OnChanges {

  @Input() trainMemberships!: TrainMembership[];

  type: 'doughnut' = 'doughnut';

  chartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            family: DEFAULT_FONT
          }
        },
        position: 'bottom' as const
      }
    }
  };

  ngAfterViewInit(): void {
    this.chartData.labels = [ 'À venir', 'En cours', 'Terminés' ];
    this.chart?.update();
  }


  ngOnChanges() {
    super.onChanges();
  }

  updateData() {
    const trains = this.trainMemberships.map(membership => membership.train);
    this.chartData.datasets = [
      {
        label: 'Parcours',
        data: [
          trains.filter(train => train.getFirstSession().date?.getTime() > (new Date()).getTime()).length,
          trains.filter(train => train.getFirstSession().date?.getTime() < (new Date()).getTime() && train.getNextSession() !== undefined).length,
          trains.filter(train => train.getNextSession() === undefined).length
        ],
        backgroundColor: this.getDefaultColors().slice(0, 3)
      }

    ];
  }


}
