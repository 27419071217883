import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { TimeSlot } from "../../trains/models/time-slot.entity";
import { ThematicCategory } from "../models/category.model";
import { DynamicType } from "../models/dynamic.model";
import { CreateThematic, Thematic } from "../models/thematic.model";

@Injectable()
export class ThematicRepository extends AbstractRepository<Thematic, CreateThematic> {
  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              protected override readonly http: HttpClient) {
    super(http, Thematic);
    this.apiUrl = this.appConfig.apiUrl;
  }

  protected getEntityName(): string {
    return "thematics";
  }

  findBySlug(slug: string): Observable<Thematic> {
    return this.http.get<Thematic>([ this.apiUrl, this.getEntityName(), slug, 'details' ].join('/')).pipe(map(result => this.instantiate(result)));
  }

  getAll(): Observable<Thematic[]> {
    return this.http.get<Thematic[]>([ this.apiUrl, this.getEntityName(), 'all' ].join('/')).pipe(map(result => result.map(item => this.instantiate(item))));
  }

  getAvailableOnes(): Observable<Thematic[]> {
    return this.http.get<Thematic[]>([ this.apiUrl, this.getEntityName(), 'available' ].join('/')).pipe(map(result => result.map(item => this.instantiate(item))));
  }

  getCategories(): Observable<ThematicCategory[]> {
    return this.http.get<ThematicCategory[]>([ this.apiUrl, this.getEntityName(), 'categories' ].join('/')).pipe(map(result => result.map(item => plainToInstance(ThematicCategory, item))));
  }

  getTimeSlots(thematicSlug: string, dynamicSlug: DynamicType): Observable<TimeSlot[]> {
    return this.http.get<TimeSlot[]>([ this.apiUrl, this.getEntityName(), thematicSlug, dynamicSlug, 'timeslots' ].join('/'))
      .pipe(
        map(result => result.map(item => plainToInstance(TimeSlot, item))));
  }

  updateFavoriteThematic(id: string): Observable<boolean> {
    return this.http.get<boolean>([this.apiUrl, this.getEntityName(), id, 'update-favorite'].join('/'));
  }

  getThematicsOrderByTrains(): Observable<Thematic[]> {
    return this.http.get<Thematic[]>([this.apiUrl, this.getEntityName(), 'preferences-order'].join('/'))
      .pipe(map(result => result.map(item => this.instantiate(item))));
  }
 }
