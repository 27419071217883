import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, filter, Subject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

import { AuthenticationService } from "../../authentication/services/authentication.service";
import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { ConfirmComponent } from "../../shared/components/confirm/confirm.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { splitThematics } from "../../thematics/models/dynamic.model";
import { Thematic } from "../../thematics/models/thematic.model";
import { ThematicRepository } from "../../thematics/repositories/thematic.repository";
import { Train } from "../../trains/models/train.entity";
import { TrainRepository } from "../../trains/repositories/train.repository";
import { Sherpa } from "../models/sherpa.entity";
import { Gender, GenderDescription, Role, RoleDescription, User } from "../models/users.entity";
import { SherpaRepository } from "../repositories/sherpa.repository";
import { UsersRepository } from "../repositories/users.repository";
import { ProfileService } from "../services/profile.service";

@Component({
  selector: 'app-sherpa-details',
  templateUrl: './sherpa-details.component.html',
  styleUrls: [ './sherpa-details.component.scss' ],
})
export class SherpaDetailsComponent implements OnInit, OnDestroy {

  sherpa!: Sherpa;

  sherpaTrains$ = new BehaviorSubject<Train[]>([]);

  needThematics$ = new Subject<void>();

  loading = true;

  coachingDynamicAllowed: Checkbox[] = [];

  speechDynamicAllowed: Checkbox[] = [];

  private destroy$ = new Subject<void>();

  constructor(private readonly route: ActivatedRoute,
              private readonly formBuilder: FormBuilder,
              private readonly router: Router,
              private readonly profileService: ProfileService,
              private readonly trainRepository: TrainRepository,
              private readonly sherpaRepository: SherpaRepository,
              private readonly userRepository: UsersRepository,
              private readonly authService: AuthenticationService,
              private readonly snackBarService: SnackbarService,
              private readonly thematicRepository: ThematicRepository,
              private readonly dialog: DialogService,
              private sanitizer: DomSanitizer) {

  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit(): void {

    this.route.data
      .pipe(
        map(data => data.user),
        tap((user: Sherpa) => {
          this.sherpa = user;
          this.sherpa.personalInformation.presentingVideoLink = this.sherpa.personalInformation?.presentingVideoLink?.replace('https://youtu.be/', 'https://www.youtube.com/embed/');
        }),
        switchMap(() => this.profileService.getMyProfile()),
        filter((profile: User) => profile.role === Role.Admin),
        switchMap(() => this.sherpaRepository.getSherpaTrains(this.sherpa)),
        tap((trains: Train[]) => {
          this.sherpaTrains$.next(trains);
        }),
        tap(() => {
          this.needThematics$.next();
        }),
        switchMap(() => this.thematicRepository.getAll()))
      .subscribe({
        next: (thematics: Thematic[]) => {
          const { speechDynamics, coachingDynamics } = splitThematics(thematics);

          this.speechDynamicAllowed = speechDynamics.map(dynamic => ({
            id: dynamic.id,
            key: thematics.find(thematic => thematic.dynamics.map(d => d.id).includes(dynamic.id))?.name ?? '',
            selected: this.sherpa.dynamicsAuthorized.map(d => d.id).includes(dynamic.id)
          }));
          this.coachingDynamicAllowed = coachingDynamics.map(dynamic => ({
            id: dynamic.id,
            key: thematics.find(thematic => thematic.dynamics.map(d => d.id).includes(dynamic.id))?.name ?? '',
            selected: this.sherpa.dynamicsAuthorized.map(d => d.id).includes(dynamic.id)
          }));

          this.loading = false;
        }
      });
  }

  goToTrain(trainId: string): void {
    this.router.navigate([ 'trains', trainId ]);
  }

  sendActivationMail() {
    this.authService.sendActivationEmail(this.sherpa.email).subscribe(
      () => {
        this.snackBarService.pushMessage('Email d\'inscription envoyé', 'success');
      }
    );
  }


  get role(): string {
    return capitalize(RoleDescription[this.sherpa.role]);
  }

  get gender(): string {
    return capitalize(GenderDescription[this.sherpa.gender]);
  }

  get canDeleteUser(): boolean {
    return this.profileService.profile?.role === Role.Admin;
  }

  deleteUser(): void {
    this.dialog.open(ConfirmComponent, { title: 'Voulez-vous vraiment supprimer ce sherpa ?' })
      .subscribe(isValidated => {
        if (isValidated) {
          this.sherpaRepository.deleteById(this.sherpa.id).subscribe(() => {
            this.userRepository.deleteMailchimpContact(this.sherpa.id, this.sherpa.email).subscribe({
              next: () => {
                if(this.profileService?.profile?.role === Role.Admin){
                  this.snackBarService.pushMessage('Contact supprimé dans Mailchimp', 'success');
                }
                this.router.navigate([ 'users' ]);
              },
              error: () => {
                if(this.profileService?.profile?.role === Role.Admin){
                  this.snackBarService.pushMessage('Contact n’a pas pu être supprimé dans Mailchimp', 'error');
                }
                this.router.navigate([ 'users' ]);
              }
            });
          });
        }
      });
  }

  impersonateUser() {
    this.authService.impersonateUser(this.sherpa.id).subscribe(() => {
        this.router.navigate([ 'home' ]);
      }
    );
  }

  updateDynamicAllowed() {
    if (!this.loading) {
      this.sherpaRepository.updateDynamicsAllowed(this.sherpa.id, (this.speechDynamicAllowed.filter(checkbox => checkbox.selected)).concat(this.coachingDynamicAllowed.filter(checkbox => checkbox.selected)).map(checkbox => checkbox.id)).subscribe(
        () => {
          this.snackBarService.pushMessage('Modification enregistrée', 'success');
        }
      );
    }
  }

  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html.replace(/<p><\/p>/g, '<br>'));
  }

    protected readonly Gender = Gender;
}
