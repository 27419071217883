import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { catchError, Observable, of } from "rxjs";

import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { Content } from "../models/contents.entity";
import { ContentsRepository } from "../repositories/contents.repository";

@Injectable()
export class ContentResolver {

    constructor(private readonly contentsRepository: ContentsRepository,
        private readonly snackbarService: SnackbarService,
        private readonly router: Router) { 
        this.handleError = this.handleError.bind(this);    
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Content | undefined> {
        const id = route.paramMap.get('id') ?? '';
        return id !== 'add' ? this.contentsRepository.findById(id)
          .pipe(catchError(err => this.handleError(err))) : of(undefined);
    }

    private handleError(err: HttpErrorResponse): Observable<Content | undefined> {

        if (err.status === 404) {
          this.snackbarService.pushMessage('Le contenu recherché est introuvable', 'error');
        }
        this.router.navigate([ 'home' ]);
    
        return of(undefined);
      }
}