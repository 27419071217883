import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, of } from "rxjs";

import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { ProfileService } from "../../users/services/profile.service";

@Injectable()
export class ContentListResolver {

    constructor(private readonly profileService: ProfileService,
        private readonly snackbarService: SnackbarService,
        private readonly router: Router) { 
        this.handleError = this.handleError.bind(this);    
    }

    resolve(): Observable<any | undefined> {    
        return this.profileService.getMyProfile()
          .pipe(catchError(err => this.handleError(err)));
    }

    private handleError(err: HttpErrorResponse): Observable<any | undefined> {

        if (err.status === 404) {
          this.snackbarService.pushMessage('Le contenu recherché est introuvable', 'error');
        }
        this.router.navigate([ 'home' ]);
    
        return of(undefined);
      }
}