<div class="wrapper">

  <div class="introducing">
    <h2 class="title">Mot de passe oublié</h2>
    <div>Pour réinitialiser votre mot de passe, entrez l’adresse e-mail que vous pensez avoir utilisée pour votre compte Wetalk.</div>
  </div>

  <form class="content margin-top" [formGroup]="emailForm" (submit)="sendEmail()">
    <app-input class="form-input"  [error]="formError" [error-message]="errorMessage" formControlName="email" type="email" placeholder="Email"></app-input>

    <app-button type="submit">{{ buttonMessage }}</app-button>
    <div>
      <a href="auth/login">Revenir à la page de connexion</a>
    </div>
  </form>
</div>

