import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: [ './tooltip.component.scss' ]
})
export class TooltipComponent {

  constructor(@Inject('TOOLTIP_TEXT')
              public readonly tooltipText: string) {

  }

}
