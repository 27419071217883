import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { createErrorHandler } from "@sentry/angular-ivy";
import { NgxPermissionsModule } from "ngx-permissions";

import { AdminModule } from "./admin/admin.module";
import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { AuthenticationModule } from "./authentication/authentication.module";
import { AuthenticationInterceptor } from "./authentication/services/authentication.interceptor";
import { APP_CONFIG, GOOGLE_CONFIG } from "./config/config";
import { appConfigFactory, azureConfigFactory, googleConfigFactory } from "./config/config.factory";
import { ContentsModule } from "./contents/contents.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AddFeedbackComponent } from "./feedbacks/add-feedback/add-feedback.component";
import { HomeModule } from "./home/home.module";
import { MainLayoutComponent } from './main-layout/main.layout.component';
import { MeetModule } from "./meet/meet.module";
import { MySpaceComponent } from './my-space/my-space.component';
import { OrganizationsModule } from "./organizations/organizations.module";
import { OrganizationStoreService } from "./organizations/services/organization.store.service";
import { QuestionsComponent } from './questions/questions.component';
import { MySpaceMtmResolver } from "./resolvers/my-space-mtm.resolver";
import { DialogService } from "./shared/components/dialog/dialog.service";
import { SnackbarService } from "./shared/components/snackbar/snackbar.service";
import { MatomoResolver } from "./shared/resolvers/matomo.resolver";
import { FunnelService } from "./shared/services/funnel.service";
import { NavigationService } from "./shared/services/navigation.service";
import { SharedModule } from "./shared/shared.module";
import { StorageService } from "./shared/storage/storage.service";
import { TabsComponent } from './tabs/tabs.component';
import { CreateTrainService } from "./thematics/create-train.service";
import { ThematicsModule } from "./thematics/thematics.module";
import { TrainsModule } from "./trains/trains.module";
import { TypeformModule } from "./typeform/typeform.module";
import { ProfileService } from "./users/services/profile.service";
import { UsersModule } from "./users/users.module";

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    TabsComponent,
    QuestionsComponent,
    MySpaceComponent,
    AddFeedbackComponent
  ],
  imports: [
    SharedModule,
    AppRouting,
    BrowserModule,
    BrowserAnimationsModule,
    UsersModule,
    HomeModule,
    OrganizationsModule,
    ContentsModule,
    AuthenticationModule,
    ThematicsModule,
    NgxPermissionsModule.forRoot(),
    AdminModule,
    TrainsModule,
    DashboardModule,
    MeetModule,
    TypeformModule,
    MsalModule.forRoot(new PublicClientApplication(azureConfigFactory()), {
      interactionType: InteractionType.Redirect
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map()
    }),
    // MatomoModule.forRoot({
    //   scriptUrl: 'https://cdn.matomo.cloud/illuintech.matomo.cloud/container_FgkkVgIl.js',
    //   // trackers: [
    //   //   {
    //   //     trackerUrl: 'https://illuintech.matomo.cloud/',
    //   //     trackerUrlSuffix: 'matomo.php?mtmPreviewMode=1',
    //   //     // @ts-ignore
    //   //     siteId: window.WETALK_CONFIG.matomoSiteId,
    //   //   }
    //   // ]
    // }),
    // MatomoRouterModule
  ],
  providers: [
    {
      provide: APP_CONFIG,
      useFactory: appConfigFactory
    },
    {
      provide: GOOGLE_CONFIG,
      useFactory: googleConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: true,
      }),
    },
    StorageService,
    OrganizationStoreService,
    SnackbarService,
    DialogService, FunnelService,
    NavigationService,
    CreateTrainService,
    ProfileService,
    MatomoResolver,
    MySpaceMtmResolver
  ],
  bootstrap: [ AppComponent, MsalRedirectComponent ]
})
export class AppModule {
}
