import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";

import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { EntityFormGroup } from "../../shared/types/entity-form-group";

@Component({
  selector: 'app-edit-name',
  templateUrl: './edit-user-name.component.html',
  styleUrls: [ './edit-user-name.component.scss' ]
})
export class EditUserNameComponent extends DialogComponent<{ firstName: string, lastName: string }, { firstName: string, lastName: string }>() implements OnInit {

  namesForm: EntityFormGroup<{ firstName: string, lastName: string }>;

  constructor(
    @Inject(DIALOG_DATA)
    public override readonly data: { firstName: string, lastName: string },
    private readonly formBuilder: FormBuilder,
    private readonly ref: DialogRef<string>,
  ) {

    super(ref);

    this.namesForm = this.formBuilder.group({
      firstName: new FormControl(capitalize(data.firstName) ?? '', { nonNullable: true }),
      lastName: new FormControl(capitalize(data.lastName) ?? '', { nonNullable: true }),
    });
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  validate(isValidated: boolean): void {
    if (isValidated) {
      super.close({
        firstName: this.namesForm.controls.firstName.value,
        lastName: this.namesForm.controls.lastName.value,
      });
    } else {
      super.close();
    }
  }

}
