import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject, combineLatestWith, filter, startWith, Subject, takeUntil } from "rxjs";

import { Organization } from "../../organizations/models/organizations.entity";
import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Sherpa } from "../../users/models/sherpa.entity";
import { Role } from "../../users/models/users.entity";
import { ProfileService } from "../../users/services/profile.service";
import { mediumCategoryColors } from "../colors";
import { ThematicCategory, ThematicCategorySlug } from "../models/category.model";
import { DynamicType } from "../models/dynamic.model";
import { Thematic } from "../models/thematic.model";
import { ThematicRepository } from "../repositories/thematic.repository";


@Component({
  selector: 'app-sherpa-thematics',
  templateUrl: './sherpa-thematics.component.html',
  styleUrl: './sherpa-thematics.component.scss'
})
export class SherpaThematicsComponent implements OnInit, OnDestroy {
  loading = true;

  thematics!: Thematic[];

  organization!: Organization;

  selectedThematicIds: string[] = [];

  destroy$ = new Subject<void>();

  selectedThematicIds$!: BehaviorSubject<string[]>;

  categories: ThematicCategory[] = [];

  categoryCheckboxes: Checkbox<ThematicCategorySlug>[] = [];

  onlyMyThematics = new FormControl<boolean>(false, { nonNullable: true });

  categoryCheckboxesSelected$: BehaviorSubject<Checkbox<ThematicCategorySlug>[]> = new BehaviorSubject<Checkbox<ThematicCategorySlug>[]>([]);

  dynamicTypeCheckboxes: Checkbox<DynamicType>[] = [ {
    id: DynamicType.COACHING,
    key: "Coaching en groupe",
    selected: false,
  },
    {
      id: DynamicType.SPEECH,
      key: "Parole en groupe",
      selected: false,
    } ];

  selectionThematicCheckboxes: { thematic: Thematic, checked: boolean }[] = [];

  shownThematics: { thematic: Thematic, checked: boolean }[] = [];

  dynamicTypeCheckboxesSelected$: BehaviorSubject<Checkbox<DynamicType>[]> = new BehaviorSubject<Checkbox<DynamicType>[]>([]);

  constructor(private readonly router: Router,
              private readonly thematicRepository: ThematicRepository,
              private readonly profileService: ProfileService,
  ) {
  }

  ngOnInit() {
    this.dynamicTypeCheckboxesSelected$
      .pipe(combineLatestWith(this.categoryCheckboxesSelected$, this.onlyMyThematics.valueChanges.pipe(startWith(false))),
        takeUntil(this.destroy$))
      .subscribe(([ dynamicCheckboxes, categoryCheckboxes, onlyMyThematics ]) => {
        const dynamicTypes = dynamicCheckboxes.filter(c => c.selected).map(c => c.id);
        const categoriesChosen = categoryCheckboxes.filter(c => c.selected).map(c => c.id);

        this.shownThematics = this.selectionThematicCheckboxes
            ?.filter(checkbox =>
              (!dynamicTypes.length || dynamicTypes.some(type => checkbox.thematic.dynamics.map(d => d.type).includes(type)))
              && (!categoriesChosen.length || (checkbox.thematic.category?.slug && categoriesChosen.includes(checkbox.thematic.category.slug))))
            ?.filter(checkbox => checkbox.checked || !onlyMyThematics)
          ?? [];
      });


    this.thematicRepository.getAll()
      .pipe(
        combineLatestWith(
          this.profileService.getObservable()
            .pipe(filter((profile): profile is Sherpa => profile.role === Role.Sherpa)),
          this.thematicRepository.getCategories()
        ),
        takeUntil(this.destroy$))
      .subscribe(([ thematics, profile, categories ]) => {
        this.thematics = thematics;
        const dynamicAuthorizedIds = profile.dynamicsAuthorized?.map(d => d.id) ?? [];
        this.selectedThematicIds = thematics.filter(thematic => thematic.dynamics.some(d => dynamicAuthorizedIds.includes(d.id))).map(t => t.id);
        this.selectedThematicIds$ = new BehaviorSubject<string[]>(this.selectedThematicIds);
        this.categories = categories;
        this.categoryCheckboxes = categories.map(category => ({
          selected: false,
          id: category.slug,
          key: category.name
        }));


        this.selectionThematicCheckboxes = this.thematics.map(thematic => ({
          thematic,
          checked: this.selectedThematicIds.includes(thematic.id)
        }));


        this.shownThematics = this.selectionThematicCheckboxes;

      });

  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  selectCategory(slug: string) {
    const category = this.categoryCheckboxes.find(c => c.id === slug);
    if (category) {
      category.selected = !category.selected;
    }
    this.categoryCheckboxesSelected$.next(this.categoryCheckboxes.filter(checkbox => checkbox.selected));
  }

  selectDynamic(type: string) {
    const dynamic = this.dynamicTypeCheckboxes.find(c => c.id === type);
    if (dynamic) {
      dynamic.selected = !dynamic.selected;
    }
    this.dynamicTypeCheckboxesSelected$.next(this.dynamicTypeCheckboxes.filter(checkbox => checkbox.selected));
  }

  getCategoryColor(thematic: Thematic): string {
    return thematic.category?.slug ? mediumCategoryColors[thematic.category.slug] : 'white';
  }

  goToThematic(thematic: Thematic) {
    this.router.navigate([ 'thematics', thematic.slug ]);
  }

  protected readonly DynamicType = DynamicType;

  protected readonly capitalize = capitalize;

  protected readonly mediumCategoryColors = mediumCategoryColors;
}
