import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { TimeSlot } from "../../trains/models/time-slot.entity";
import {
  ConstantsConfiguration,
  DateDelta,
  DateDeltaConstantType,
  ISendingEmailConfiguration,
  NumberConstantType,
} from "../types/constant";
import { WeeklyTimeslots } from "../types/weekly-timeslot";

@Injectable()
export class ConfigurationRepository {
  private readonly apiUrl!: string;

  constructor(
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
    private readonly http: HttpClient
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return "configuration";
  }

  createConfiguration(result: any): ConstantsConfiguration {
    return plainToInstance(ConstantsConfiguration, result);
  }

  createTimeSlot(result: any): TimeSlot {
    return plainToInstance(TimeSlot, result);
  }

  getNumberConstant(
    numberName: NumberConstantType
  ): Observable<{ value: number }> {
    return this.http.get<{ value: number }>(
      [this.apiUrl, this.getEntityName(), "number", numberName].join("/")
    );
  }

  getDateDeltaConstant(
    dateDeltaType: DateDeltaConstantType
  ): Observable<{ value: DateDelta }> {
    return this.http.get<{ value: DateDelta }>(
      [this.apiUrl, this.getEntityName(), "date-delta", dateDeltaType].join("/")
    );
  }

  getSmsBalance(): Observable<number> {
    return this.http.get<number>(
      [this.apiUrl, this.getEntityName(), "sms-balance"].join("/")
    );
  }

  getConstantsConfiguration(): Observable<ConstantsConfiguration> {
    return this.http
      .get<ConstantsConfiguration>(
        [this.apiUrl, this.getEntityName()].join("/")
      )
      .pipe(map((configuration) => this.createConfiguration(configuration)));
  }

  updateConstantsConfiguration(
    configuration: ConstantsConfiguration
  ): Observable<void> {
    return this.http.post<void>([this.apiUrl, this.getEntityName()].join("/"), {
      ...configuration,
    });
  }

  getWeeklyTimeSlots(): Observable<TimeSlot[]> {
    return this.http
      .get<TimeSlot[]>(
        [this.apiUrl, this.getEntityName(), "weekly-timeslots"].join("/")
      )
      .pipe(
        map((timeslots) =>
          timeslots.map((timeslot) => this.createTimeSlot(timeslot))
        )
      );
  }

  updateWeeklyTimeSlots(
    timeSlotsConfiguration: WeeklyTimeslots
  ): Observable<void> {
    return this.http.post<void>(
      [this.apiUrl, this.getEntityName(), "weekly-timeslots"].join("/"),
      { ...timeSlotsConfiguration }
    );
  }

  getPreprodEmailSendingConfig(): Observable<ISendingEmailConfiguration> {
    return this.http.get<ISendingEmailConfiguration>(
      [this.apiUrl, this.getEntityName(), "send-email-status"].join("/")
    );
  }

  updatePreprodEmailSendingConfig(
    sendingEmailCOnfiguration: ISendingEmailConfiguration
  ): Observable<void> {
    return this.http.post<void>(
      [this.apiUrl, this.getEntityName(), "send-email-status"].join("/"),
      { ...sendingEmailCOnfiguration }
    );
  }

  resetTestData(): Observable<void> {
    return this.http.post<void>(
      [this.apiUrl, this.getEntityName(), "reset-test-data"].join("/"),
      {}
    );
  }
}
