import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  merge,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { IPaginationRepository } from "src/app/shared/repositories/pagination.interface";

import { SnackbarService } from "../../snackbar/snackbar.service";
import { SortOrder, TableConfig } from "../../table/table.component";

@Component({
  selector: "app-table-custom-pagination",
  templateUrl: "./table-custom-pagination.component.html",
  styleUrls: ["./table-custom-pagination.component.scss"],
})
export class TableCustomPaginationComponent<
  Entity extends { id: string },
  EntityFilterForm extends {
    [key: string]: string | boolean;
  },
  UniqueKey extends keyof Entity,
  EntityPropertiesFilter extends EntityFilterForm = EntityFilterForm
> implements OnInit, OnDestroy {
  @Input("unique-key") uniqueKey: UniqueKey = "id" as UniqueKey;

  @Input() propertiesFilters$?: BehaviorSubject<
    Partial<EntityPropertiesFilter>
  >;

  @Input() tableConfig!: TableConfig<Entity>;

  @Input() searchForm!: FormControl<string | null>;

  @Input() mainRepository!: IPaginationRepository<Entity, EntityFilterForm>;

  @Input() showCheckbox = false;

  @Input() sortBy$: BehaviorSubject<SortOrder<Entity> | undefined> =
  new BehaviorSubject<SortOrder<Entity> | undefined>(undefined);

  @Output() select = new EventEmitter<Entity[UniqueKey][]>();

  @Output() onSort = new EventEmitter<SortOrder<Entity> | undefined>();

  @Output() goTo = new EventEmitter<Entity[UniqueKey]>();

  @Output("totalCount") totalCountEmitter = new EventEmitter<number>();


  orderFilter$: BehaviorSubject<SortOrder<Entity> | undefined> =
    new BehaviorSubject<SortOrder<Entity> | undefined>(undefined);

  items$ = new BehaviorSubject<Entity[]>([]);

  loading$ = new BehaviorSubject<boolean>(true);

  page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(50);

  currentPage: number = 1;

  totalPages: number = 1;

  totalCount: number = 50;

  protected destroy$ = new Subject<void>();

  constructor(private readonly snackBarService: SnackbarService) {
    if (this.orderFilter$) {
      this.orderFilter$ = this.sortBy$;
    }
  }

  ngOnInit() {
    combineLatest([this.page$, this.sortBy$])
    .pipe(
      tap(() => {
        this.loading$.next(true);
      }),
      debounceTime(300),
      switchMap(([page, sortBy]) =>
        this.mainRepository.paginate({
          page,
          pageSize: +this.pageSize$.getValue(),
          search: this.searchForm.value ?? "",
          properties: this.propertiesFilters$?.getValue(),
          sortBy,
        })
      ),
      takeUntil(this.destroy$)
    )
      .subscribe(({ items, totalCount }) => {
        this.items$.next(items);
        this.totalCountEmitter.emit(totalCount);
        this.totalCount = totalCount;
        this.loading$.next(false);
        this.totalPages = Math.ceil(totalCount / this.pageSize$.getValue());
        this.currentPage = this.page$.getValue();
      });

    merge(
      this.searchForm.valueChanges,
      this.propertiesFilters$ ?? new Observable(),
      this.pageSize$,
      this.mainRepository.reload$,
      this.orderFilter$
    ).subscribe({
      next: () => {
        this.page$.next(1);
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  selectRows(id: Entity[UniqueKey][]) {
    this.select.emit(id);
  }

  goToRow(id: Entity[UniqueKey]) {
    this.goTo.emit(id);
  }

  sortData(option: SortOrder<Entity> | undefined) {
    this.onSort.emit(option);
    this.orderFilter$.next(option);
  }

  changePage({ size, page }: { size: number; page: number }) {
    this.pageSize$.next(size);
    this.page$.next(page);
  }

  changeRowsPerPage(rowsPerPage: number) {
    this.pageSize$.next(+rowsPerPage);
  }
}
