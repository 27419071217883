import { AfterViewInit, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: 'app-radio-buttons',
  templateUrl: './radio-buttons.component.html',
  styleUrls: [ './radio-buttons.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => RadioButtonsComponent)
  } ]
})
export class RadioButtonsComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {

  private onChange: (value: string | boolean | null) => void = (_: string  | boolean | null) => {
  };

  private onTouched: () => void = () => {
  };

  private destroy$ = new Subject<void>();

  @Input() inputField?: FormControl;

  @Input() title: string = '';

  @Input() options?: string[];

  optionSelected: string = '';

  booleanOptions = [ 'Oui', 'Non' ];

  ngAfterViewInit(): void {
    this.inputField?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.onChange(value);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  registerOnChange(fn: (value: string  | boolean | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string | boolean): void {
    this.onChange(value);
    if (typeof value === 'boolean') {
      this.optionSelected = value ? 'Oui': 'Non';
    } else {
      this.optionSelected = value;
    }
  }
}
