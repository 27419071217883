export type ResponseResult = {
  frontier: number;
  maximum: number;
  titleLow: string;
  titleHigh: string;
  descriptionLow: string;
  descriptionHigh: string;
  color: string;
}

export type ResponseResultType = 'omsMood' | 'flourishing' | 'workSatisfaction';

export const RESPONSE_DATA: Record<`${ ResponseResultType }Data`, ResponseResult> = {
  omsMoodData: {
    frontier: 50,
    maximum: 100,
    color: '#FF8B05',
    titleLow: 'Vous ne vous sentez pas au mieux, en ce moment.',
    titleHigh: "Vous vous sentez plutôt bien ces temps-ci.",
    descriptionLow: "Vous éprouvez un certain manque d'énergie, des périodes de stress ou de désarroi. Vous êtes parfois préoccupé(e), et votre quotidien peut être source d'émotions désagréables.\n" +
      "\n" +
      "Heureusement, cela peut s'améliorer en apprenant à adapter votre environnement, vos habitudes, et à réguler vos émotions.",
    descriptionHigh: "Vous êtes en forme et dans l'ensemble serein(e), vous avez la sensation d'évoluer dans un environnement plutôt plaisant et enthousiasmant. Vous arrivez à gérer vos préoccupations pour qu'elles ne pèsent pas trop sur votre moral, et votre quotidien est régulièrement source d'émotions agréables."
  },

  flourishingData: {
    frontier: 38,
    maximum: 56,
    color: '#8C5FB0',
    titleLow: "Vous n'êtes pas tout à fait épanoui.e dernièrement.",
    titleHigh: "Vous êtes à l'aise avec la vie que vous menez aujourd'hui.",
    descriptionLow: "Vous ne vous sentez pas toujours à votre place dans la vie que vous menez aujourd'hui... Vous aspirez à cultiver des relations plus enrichissantes, à vous sentir plus utile et stimulé.e dans vos activités, professionnelles ou non, et à vivre plus en accord avec vos valeurs. En outre, vous avez besoin de vous projeter à nouveau avec entrain dans des perspectives futures.\n" +
      "\n" +
      "Bonne nouvelle, les sherpas We Talk peuvent vous y aider.",
    descriptionHigh: "Tout n'est sans doute pas parfait, mais vous cultivez des relations globalement saines et enrichissantes, vous menez certaines activités stimulantes qui vous apportent du sens et de la gratification, et vous trouvez plaisir à former des petits et grands projets pour la suite."
  },

  workSatisfactionData: {
    frontier: 28,
    maximum: 50,
    color: '#2D7DC8',
    titleLow: "Sur le plan professionnel, ce n'est pas facile tous les jours.",
    titleHigh: "Vous semblez vous épanouir sur le plan professionnel.",
    descriptionHigh: "Vous êtes engagé(e) au travail, et la plupart du temps enthousiaste de retrouver vos collègues et vos projets pro le lundi. Vous avez à cœur de faire les choses bien, et êtes plutôt optimiste quant à la suite de votre parcours.\n" +
      "\n" +
      "Sachez que vous pouvez encore gagner en confiance, en sérénité et en énergie grâce à l'accompagnement We Talk.",
    descriptionLow: "Tantôt vous ressentez un manque d'enthousiasme ou de motivation envers votre travail, tantôt celui-ci vous apporte de l'inquiétude, du stress, et beaucoup de questionnements. Vos relations professionnelles sont parfois frustrantes, sans doute devez-vous composer avec des styles de travail ou de communication qui ne sont pas idéaux pour vous. \n" +
      "Vous aimeriez retrouver de l'élan, un sentiment d'accomplissement, et un meilleur équilibre avec votre vie perso, et même, pourquoi pas, de l'enthousiasme en vous levant le lundi matin.\n" +
      "\n" +
      "Si vous souhaitez progresser sur ces aspects, sachez que l'accompagnement We Talk peut tout à fait vous y aider, pour que vous retrouviez confiance et énergie au travail."
  }
};
