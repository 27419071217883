<div class="media-card margin-top" (click)="goToContent()">
    <div class="media">
      <img [src]="content.image.length ? content.image : 'assets/images/noimage.png'" alt="Media Image">
      <div *ngIf="content.format === 'video' || content.format === 'podcast'" class="play-button-overlay">
        <app-icon type="play-contain" size="50" color="#FFFFFF" class="play-button"></app-icon>
      </div>
    </div>
    <div class="details">
      <h2 class="card-title">{{ content.title }}</h2>
      <div [class.category-space-between]="isSmallScreen" class="row gap margin-top">
        <div class="row gap">
          <div *ngFor="let pillar of content.pillars">
            <app-icon [type]="getPillarIcon(pillar)" size="25"></app-icon>
          </div>
        </div>
        <div class="row gap" *ngIf="isSmallScreen;">
          <div [dropdownTriggerFor]="categoryDropdown" (click)="onSelectCategory($event, thematicCategory)" [ngStyle]="{'background-color': getColorBySlug(thematicCategory.slug) }" class="category-container" *ngFor="let thematicCategory of content.thematicCategories">
            <span class="category-name">{{ getCategoryNameBySlug(thematicCategory.slug) }}</span>
          </div>
          <app-dropdown [hasPadding]="false" [closeOnClick]="false" #categoryDropdown>
            <div *ngIf="selectedCategory" [ngStyle]="{'background-color': getColorBySlug(selectedCategory?.slug) }" class="wrap category-name-dropdown">
              <div class="category-name-dropdown-container" [ngStyle]="{'color': 'white' }">{{ capitalizeName(getCategoryNameBySlug(selectedCategory.slug)) }}</div>
            </div>
          </app-dropdown>
        </div>
        <div *ngIf="!isSmallScreen" class="category-container" #bigScreen>
          <div (click)="onSelectCategory($event, thematicCategory)" [ngStyle]="{'background-color': getColorBySlug(thematicCategory.slug) }" *ngFor="let thematicCategory of content.thematicCategories">
            <span class="category-name">{{ getCategoryNameBySlug(thematicCategory.slug) }}</span>
          </div>
        </div>
      </div>
      <div class="margin-top">
        <p class="description-container">
            {{ content.description }}
        </p>
      </div>
      <div *ngIf="content.thematics.length" class="margin-top padding-bottom thematic-container">
          Thématiques associées : {{associatedThematics}} 
          <span *ngIf="content.thematics.length > 2"> {{ ';+' + remainingThematicslength }}</span>
      </div>
    </div>
</div>