import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
// import { MatomoTracker } from 'ngx-matomo-client';
import { BehaviorSubject, filter, Subject, takeUntil } from "rxjs";

import { SortOrder, TableConfig } from "../../shared/components/table/table.component";
import { CsvService } from "../../shared/services/csv.service";
import {
  Organization,
  OrganizationTransactionParsed,
  Transaction,
  TransactionType
} from "../models/organizations.entity";
import { OrganizationStoreService } from "../services/organization.store.service";

@Component({
  selector: 'app-organization-units-history',
  templateUrl: './organization-units-history.component.html',
  styleUrls: [ './organization-units-history.component.scss' ]
})
export class OrganizationUnitsHistoryComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  headerColumns: TableConfig<OrganizationTransactionParsed> = {
    date: {
      displayName: 'Date',
      transformFunction: (date: Date) => date.toLocaleDateString(),
      sortable: true
    },
    units: {
      displayName: 'Evolution',
      styleFunction: (units: string) => (units.includes('+') ? 'color: #6aa33e; font-weight: 500 ' : 'color: #704c8d; font-weight: 500')
    },
    restAmount: {
      displayName: 'Solde',
      style: 'font-weight: 500;'
    },
    description: {
      displayName: "Nature d'action",
      sortable: true
    }
  };

  transactions: OrganizationTransactionParsed[] = [];

  sortOptionChosen$: BehaviorSubject<SortOrder<OrganizationTransactionParsed> | undefined> = new BehaviorSubject<SortOrder<OrganizationTransactionParsed> | undefined>(undefined);

  organization!: Organization;

  // private readonly tracker = inject(MatomoTracker);

  constructor(private readonly organizationStoreService: OrganizationStoreService,
              private readonly router: Router,
              private readonly csvService: CsvService
  ) {

  }

  ngOnInit() {

    this.organizationStoreService.getObservable()
      .pipe(
        filter((organization): organization is Organization => !!organization),
        takeUntil(this.destroy$))
      .subscribe(organization => {
        this.organization = organization;

        const { unitsIn } = organization;
        unitsIn?.forEach(t => {
          t.receiver = organization;
        });
        const { unitsOut } = organization;
        unitsOut?.forEach(t => {
          t.sender = organization;
        });

        this.sortOptionChosen$
          .pipe(takeUntil(this.destroy$))
          .subscribe(sortOption => {

            let restAmount = organization.units;
            this.transactions = unitsIn
                ?.concat(...unitsOut ?? [])
                ?.sort((transactionA, transactionB) => transactionB.createdAt.getTime() - transactionA.createdAt.getTime())
                ?.map((transaction): OrganizationTransactionParsed => {
                  const organizationIsSender = !!transaction.sender && transaction.sender.id === organization.id;
                  const stepAmount = restAmount;

                  restAmount = organizationIsSender ? restAmount + transaction.units : restAmount - transaction.units;

                  return {
                    id: transaction.id,
                    date: transaction.createdAt,
                    units: organizationIsSender ? `-${ transaction.units }` : `+${ transaction.units }`,
                    restAmount: stepAmount,
                    description: this.getTransactionActionNature(transaction, organizationIsSender)
                  };
                })
                ?.sort((transactionA, transactionB) => {
                  if (sortOption?.sort === 'date') {
                    return (transactionA.date.getTime() - transactionB.date.getTime()) * (sortOption.order === 'DESC' ? -1 : 1);
                  }
                  if (sortOption?.sort === 'description') {
                    return transactionA.description.toLowerCase() < transactionB.description.toLowerCase() ? (-1 * (sortOption.order === 'DESC' ? -1 : 1)) : (sortOption.order === 'DESC' ? -1 : 1);
                  }
                  return transactionB.date.getTime() - transactionA.date.getTime();
                })
              ?? [];
          });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  goToQuestions() {
    window._mtm.push({ "event": "clic-credits" });
    // this.tracker.trackEvent(TrackerCategory.CREDITS_PILOT, TrackerAction.CLICK, 'addCreditsButton');
    this.router.navigate([ 'questions' ]);
  }

  getTransactionActionNature(transaction: Transaction, organizationIsSender: boolean): string {

    const isPlural = Math.abs(transaction.units) > 1;

    switch (transaction.type) {
      case TransactionType.ORGANIZATION_TRANSFER:
        if (organizationIsSender) {
          return `Transfert d'unité${ isPlural ? 's' : '' } vers l'organisation (${ transaction.receiver?.name?.toUpperCase() })`;
        }
        return `Transfert d'unité${ isPlural ? 's' : '' } depuis l'organisation (${ transaction.sender?.name?.toUpperCase() })`;
      case TransactionType.ADMIN_MODIFICATION:
        if (organizationIsSender) {
          return `Retrait d'unité${ isPlural ? 's' : '' } par administrateur WeTalk`;
        }
        return `Ajout d’unité${ isPlural ? 's' : '' } par administrateur We talk`;
      case TransactionType.TRAIN_MEMBERSHIP:
        if (organizationIsSender) {
          return `Inscription ${ isPlural ? 'à des' : 'à un' } parcours`;
        }
        return `Remboursement ${ isPlural ? 'des' : 'd\'un' } parcours  annulé${ isPlural ? 's' : '' }`;
      default:
        return "Nature inconnue";
    }
  }

  downloadCsv() {
    // this.tracker.trackEvent(TrackerCategory.CREDITS_PILOT, TrackerAction.CLICK, 'exportButton');
    this.csvService.downloadOrganizationsTransactionsParsed(this.transactions);
  }

  sortTransactions(sortOption?: SortOrder<OrganizationTransactionParsed>) {
    this.sortOptionChosen$.next(sortOption);
  }

}
