<div class="center-children margin-top margin-bottom no-select">
  <div class="row gap">
    <h3 class="center-children no-select month-title">{{ months[monthIndex] }} {{year}}</h3>
  </div>
</div>
<div class="calendar-wrapper no-select">
  <app-arrow-button class="arrow-button left" (click)="goToPreviousMonth()"
                    [class.not-visible]="deltaMonthIndexSelected === 0"></app-arrow-button>
  <app-arrow-button class="arrow-button right" [rotated]="true" (click)="goToNextMonth()"></app-arrow-button>


  <div class="week-days" [class]="weekDaysClass">
    <div class="week-day"
         [class.hidden]="!weekDaysAvailable.includes(day)" *ngFor="let day of globalWeekDaysAvailable">
      <div class="week-day-name"><span>{{weekDaysNames[day].charAt(0)}}</span><span
        class="week-day-full-name">{{weekDaysNames[day].slice(1, 3)}}</span></div>
    </div>
  </div>
  <div [class]="weekDaysClass" class="content">
    <div *ngFor="let day of days; let index = index;"
         [class.disabled]="day.date.getTime() < firstAvailableDate.getTime() "
         class="day-cell"
         [class.hidden]="!weekDaysAvailable.includes(day.weekDay)"
    >
      <div *ngIf="day.date.getMonth() === monthIndex" class="day-bubble no-select center-children"
           [class.disabled]="day.date.getTime() < firstAvailableDate.getTime()"
           [class.primary]="day.date.getTime() === selectedDate?.getTime()"
           (click)="onClickOnDay(index)">
        {{day.title}}
      </div>
    </div>
  </div>
</div>
