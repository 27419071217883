import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  catchError,
  debounceTime,
  filter,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { Organization } from '../../organizations/models/organizations.entity';
import { OrganizationRepository } from '../../organizations/repositories/organization.repository';
import { Checkbox } from '../../shared/components/checkmarks/checkmarks.component';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { DialogRef } from '../../shared/components/dialog/dialog.ref';
import { DIALOG_DATA } from '../../shared/components/dialog/dialog.token';
import { capitalize } from '../../shared/tool-functions/capitalize';
import { EntityFormGroup } from '../../shared/types/entity-form-group';
import { splitThematics } from '../../thematics/models/dynamic.model';
import { Thematic } from '../../thematics/models/thematic.model';
import { ThematicRepository } from '../../thematics/repositories/thematic.repository';
import {
  CreateUserForm,
  CreateUserOptions,
  Gender,
  GenderDescription,
  Role,
  RoleDescription,
  SubRole,
} from '../models/users.entity';
import { UsersRepository } from '../repositories/users.repository';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent
  extends DialogComponent<CreateUserOptions, SubRole>()
  implements OnInit, OnDestroy {
  newUserForm!: EntityFormGroup<CreateUserForm>;

  organizationNames: string[] = [];

  organizations: Organization[] = [];

  rolePossibilities = Object.values(RoleDescription).map((role) =>
    capitalize(role),
  );

  genderPossibilities = Object.values(GenderDescription).map((gender) =>
    capitalize(gender),
  );

  emailAlreadyTaken = false;

  loading = false;

  coachingDynamicAllowed: Checkbox[] = [];

  speechDynamicAllowed: Checkbox[] = [];

  constructor(
    @Inject(DIALOG_DATA)
    public readonly role: Role,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly ref: DialogRef<CreateUserOptions | { file: File }>,
    private readonly usersRepository: UsersRepository,
    private readonly organizationRepository: OrganizationRepository,
    private readonly thematicRepository: ThematicRepository,
  ) {
    super(ref);
    this.newUserForm = this.formBuilder.group({
      role: new FormControl(this.role ?? '', {
        validators: Validators.required,
        nonNullable: true,
      }),
      email: new FormControl('', {
        validators: [Validators.email, Validators.required],
        nonNullable: true,
      }),
      organization: new FormControl('', { nonNullable: true }),
      firstName: new FormControl('', {
        validators: Validators.min(2),
        nonNullable: true,
      }),
      lastName: new FormControl('', {
        validators: Validators.min(2),
        nonNullable: true,
      }),
      gender: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
    });
  }

  ngOnInit() {
    super.onInit();
    this.newUserForm.controls.email.valueChanges
      .pipe(
        tap(() => {
          this.loading = true;
          this.emailAlreadyTaken = false;
        }),
        debounceTime(500),
        filter<string>(() => this.newUserForm.controls.email.valid),
        switchMap((email) => this.usersRepository.isEmailTaken(email)),
        catchError(() => of(false)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (isTaken) => {
          this.loading = false;
          this.emailAlreadyTaken = isTaken;
        },
      });

    this.thematicRepository.getAll().subscribe({
      next: (thematics: Thematic[]) => {
        const { speechDynamics, coachingDynamics } = splitThematics(thematics);

        this.speechDynamicAllowed = speechDynamics.map((dynamic) => ({
          id: dynamic.id,
          key:
            thematics.find((thematic) =>
              thematic.dynamics.map((d) => d.id).includes(dynamic.id),
            )?.name ?? '',
          selected: false,
        }));
        this.coachingDynamicAllowed = coachingDynamics.map((dynamic) => ({
          id: dynamic.id,
          key:
            thematics.find((thematic) =>
              thematic.dynamics.map((d) => d.id).includes(dynamic.id),
            )?.name ?? '',
          selected: false,
        }));
      },
    });

    if (!this.role) {
      this.organizationRepository.findAll().subscribe((organizations) => {
        this.organizations = organizations;
        this.organizationNames = organizations
          .map((organization) => organization.name.toUpperCase())
          .sort((organizationA, organizationB) => {
            if (organizationA < organizationB) {
              return -1;
            }
            return 1;
          });
      });
    }
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get organization(): Organization | undefined {
    return this.organizations.find(
      (organization) =>
        organization.name ===
        this.newUserForm.controls.organization.value.toLowerCase(),
    );
  }

  get emailWorks(): boolean {
    return (
      this.newUserForm.controls.email.valid &&
      !this.emailAlreadyTaken &&
      !this.loading
    );
  }

  get needToPreciseOrganization(): boolean {
    return (
      [Role.Talker, 'pilot' as const]
        .map((role) => RoleDescription[role])
        .includes(
          this.newUserForm?.controls?.role?.value?.toLowerCase() as Role,
        ) && !this.role
    );
  }

  get needToPreciseDynamics(): boolean {
    return (
      RoleDescription[Role.Sherpa] ===
      this.newUserForm.controls.role.value.toLowerCase()
    );
  }

  get formIsValid(): boolean {
    return (
      this.newUserForm.valid &&
      this.emailWorks &&
      (!this.needToPreciseOrganization || !!this.organization)
    );
  }

  get roleDescription(): string {
    return this.role ? RoleDescription[this.role] : 'utilisateur';
  }

  createOrganization(): void {
    super.close();
    this.router.navigate(['organizations'], { queryParams: { create: true } });
  }

  validate(isValidated: boolean): void {
    if (this.formIsValid && isValidated) {
      super.close({
        email: this.newUserForm.controls.email.value.trim(),
        firstName: this.newUserForm.controls.firstName.value.toLowerCase(),
        lastName: this.newUserForm.controls.lastName.value.toLowerCase(),
        gender:
          (Object.values(Gender) as Gender[]).find(
            (gender) =>
              GenderDescription[gender] ===
              this.newUserForm.controls.gender.value,
          ) ?? Gender.OTHER,
        role:
          this.role ??
          (Object.values(Role) as Role[]).find(
            (role: Role) =>
              RoleDescription[role] ===
              this.newUserForm.controls.role.value.toLowerCase(),
          ) ??
          Role.Talker,
        organization: this.needToPreciseOrganization
          ? this.organization
          : undefined,
        dynamicsAuthorized: this.needToPreciseDynamics
          ? this.coachingDynamicAllowed
              .filter((checkbox) => checkbox.selected)
              .concat(
                this.speechDynamicAllowed.filter(
                  (checkbox) => checkbox.selected,
                ),
              )
              .map((dynamic) => dynamic.id)
          : undefined,
        isPilot:
          this.newUserForm.controls.role.value.toLowerCase() === 'pilote',
      });
    } else {
      super.close();
    }
  }
}
