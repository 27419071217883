import { ContentPillar, ContentThematicCategory } from "../models/contents.entity";

export const contentPillarTranslation = {
    [ContentPillar.INDIVIDUAL]: "individuelle",
    [ContentPillar.ENVIRONMENT]: "environnement",
    [ContentPillar.EVENT]: "événement",
};

export const contentThematicCategoryTranslation = {
    [ContentThematicCategory.PERSONAL_LIFE]: "vie personnelle",
    [ContentThematicCategory.BETTER_LIFE]: "mieux-être",
    [ContentThematicCategory.PROFESSIONAL_LIFE]: "vie professionnelle",
    [ContentThematicCategory.SELF_REFLECTION]: "travail sur soi",
};