<div class="pointer" [class.button-hidden]="hidden" (click)="fileInput.click()">
  <app-button *ngIf="!link" [outline]="outline" type="button" class="add-button" [title]="message" icon="upload">
    {{fileName || showImageUrl  ? 'Changer de fichier' : 'Choisir votre fichier' }}
  </app-button>
  <input #fileInput class="hidden" [accept]="acceptingMimeTypes" type="file" (change)="onFileSelected($event.target)">
  <a *ngIf="link">

    <ng-content></ng-content></a>
  <div class="text">{{showFileName && !!fileName?.length ? fileName :  (showMessage ? message : '' )}}</div>
</div>

