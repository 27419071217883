<div class="progress-bar" [class]="progressClass">
  <div class="circles center-by-relative-parent">
    <div class="circle color-primary outline" [class]="{'pointer': index < stepsDone }"
         (click)="goToSpecificStep(index)" *ngFor="let step of [].constructor(maxSteps); let index = index;">
      <div *ngIf="index < stepsDone; then check; else notCheck "></div>
      <ng-template #check>
        <app-icon type="check" />
      </ng-template>
      <ng-template #notCheck>
        <div class="center-by-relative-parent">{{index + 1}}</div>
      </ng-template>
    </div>
  </div>
</div>
