import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { plainToInstance } from "class-transformer";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../config/config";
import { AppConfig } from "../config/config.type";
import { dateDelta } from "../shared/tool-functions/date-tools";
import { Session } from "../trains/models/session.entity";
import { Train } from "../trains/models/train.entity";
import { Talker } from "../users/models/talker.entity";
import { Role } from "../users/models/users.entity";
import { Profile, ProfileService } from "../users/services/profile.service";

import {
  InitialFormResponse,
  PostSessionFormResponse,
  PostTrainFormResponse,
  SixMonthsPostFirstTrainFormResponse,
  TypeformFormNeeded,
  TypeformFormType,
  TypeformResponseFromApi,
  TypeformResponseWithPageResult
} from "./typeform.type";

@Injectable({ providedIn: 'root' })
export class TypeformService {

  private apiUrl!: string;

  public firstTrain?: Train;

  public isLoaded$ = new BehaviorSubject<boolean>(false);

  public trainsWithoutResponse!: Train[];

  public isResponding = false;

  public talker!: Talker;

  public needConfirmToQuit = false;

  public initialFormResponse?: InitialFormResponse;

  public postSessionFormResponses: PostSessionFormResponse[] = [];

  public postTrainFormResponses: PostTrainFormResponse[] = [];

  public sixMonthsPostFirstTrainFormResponse?: SixMonthsPostFirstTrainFormResponse;

  constructor(private readonly profileService: ProfileService,
              private readonly router: Router,
              @Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private readonly http: HttpClient) {
    this.apiUrl = this.appConfig.apiUrl;
    this.loadProfile();

  }

  private loadProfile(): void {
    this.isLoaded$.next(false);
    this.profileService.getObservable()
      .subscribe((profile) => {
        if (profile.role === Role.Talker) {
          this.talker = profile;
          this.initialFormResponse = profile.initialFormResponse;
          this.postSessionFormResponses = profile.postSessionFormResponses ?? [];
          this.postTrainFormResponses = profile.postTrainFormResponses ?? [];
          this.sixMonthsPostFirstTrainFormResponse = profile.sixMonthsPostFirstTrainFormResponse;
          const trains = profile.trainMemberships.map(trainMembership => trainMembership.train);
          this.firstTrain = trains.length ? trains.sort((trainA, trainB) => trainA.getFirstSession().date.getTime() - trainB.getFirstSession().date.getTime())[0] : undefined;
          this.trainsWithoutResponse = trains.filter(train => train.isCompleted && !this.postTrainFormResponses.map(response => response.trainId).includes(train.id));
        }
        this.isLoaded$.next(true);
      });
  }

  get isSixMonthsAfterFirstTrain(): boolean {
    return this.firstTrain ? dateDelta(this.firstTrain.getLastSession().date) > (30 * 6) : false;
  }

  startResponding(): void {
    this.isResponding = true;
  }

  stopResponding(): void {
    this.isResponding = false;
  }

  goToSpecificForm(payload: TypeformFormNeeded) {
    let id: string | undefined;
    if (payload.type === TypeformFormType.POST_TRAIN) {
      id = payload.train.id;
    }
    if (payload.type === TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN) {
      id = payload.train.id;
    }
    if (payload.type === TypeformFormType.POST_SESSION) {
      id = payload.session.id;
    }

    this.router.navigate([ 'typeform', payload.type, id ].filter(e => !!e));
  }

  goToResult(result: TypeformResponseWithPageResult) {
    let id: string | undefined;
    if (result.type === TypeformFormType.POST_TRAIN) {
      id = result.trainId;
    }
    if (result.type === TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN) {
      id = result.trainId;
    }

    this.router.navigate([ 'diagnostic', result.type, id ].filter(e => !!e));
  }

  getFormTrain(type: TypeformFormType.POST_TRAIN | TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN, id: string): Train | undefined {
    if (type === TypeformFormType.POST_TRAIN) {
      return this.talker.trainMemberships.map(tM => tM.train).find(train => id === train.id);
    }
    if (type === TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN) {
      return this.talker.trainMemberships.map(tM => tM.train).find(train => train.id === id);
    }
    return undefined;
  }

  getFormSessionAndTrain(id: string): {
    session: Session,
    train: Train
  } | undefined {
    const train = this.talker.trainMemberships.map(tM => tM.train).find(t => t.sessions.find(s => s.id === id));
    if (train) {
      const session = train.sessions.find(s => s.id === id);
      if (session) {
        return { train, session };
      }
    }
    return undefined;
  }

  retrieveResponse(type: TypeformFormType, entityId?: string): Observable<TypeformResponseWithPageResult | null> {
    return this.http.post<TypeformResponseWithPageResult | null>([ this.apiUrl, 'typeform', 'retrieve-response' ].join('/'), {
      type,
      entityId
    }).pipe(
      map(result => plainToInstance(TypeformResponseFromApi, { data: result })),
      map(response => response.data)
    );
  }

  refreshProfile():Observable<Profile> {
    return this.profileService.loadProfile()
      .pipe(tap(() => {
        this.loadProfile();
      }));
  }
}
