import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, filter, pairwise } from "rxjs";

@Injectable({ providedIn: 'root' })
export class NavigationService {
  lastPage$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private readonly router: Router) {
    router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd), pairwise())
      .subscribe(([ lastEvent, _ ]) => {
        this.lastPage$.next(lastEvent.url);
      });
  }
}
