<h1 class="margin-bottom">Quelques mots...</h1>
<app-notification class="margin-bottom" color="#FFA135"
                  title="Évolution et expérience avec We talk : un voyage transformationnel.">
  <i>Retrouvez ici les retours de vos Talkers, découvrez ce qu'ils ont pensé du parcours.</i>
</app-notification>
<div class="wrap row-gap margin-top-xl">
  <app-talker-testimonial-quote [class.half-width-desktop]="index < 4" [color]="testimonial.color" [content]="testimonial.content" [author]="testimonial.author" *ngFor="let testimonial of testimonials; let index = index" [bubblePosition]="index % 2 === 0 ? 'left' : 'right'" />
</div>


<div class="column gap margin-top-xl">
  <div class="text-bold text-primary text-large">Ce que les Talkers retiennent</div>

  <div class="mobile-column gap center-children">
    <div class="text-bold cooper half-width-desktop">“Saisir le moment où je bascule dans la non-diplomatie/non/non écoute. Respirer : cohérence cardiaque. Établir ce que je souhaite faire. Prendre comme entraînement la façon d'aborder ma collègue : humour et créativité.”</div>
    <app-image alt="sunset-girl" src="assets/images/sunset-girl.jpg" width="400" height="200" />
  </div>

  <div class="mobile-column-reverse gap center-children">
    <div class="text-bold cooper half-width-desktop">“J'ai apprécié la proposition de travailler sur la croyance limitante que la vie était un combat, que je devais lutter pour y arriver ; et d'accepter l'idée qu’il est possible que la vie puisse être bienveillante avec moi en faisant ma part sans batailler.”</div>
    <app-image alt="flours-and-bubbles" src="assets/images/flours-and-bubbles.jpg" width="400" height="200" />
  </div>
</div>


<div class="center-children margin-top-xl">
  <app-image alt="colored-quotes" src="assets/images/colored-quotes.png" width="50" height="100" />
</div>
