import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, Subject, takeUntil } from "rxjs";

import { SnackbarService } from "./snackbar.service";
import { SnackBarMessage } from "./snackbar.type";

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: [ './snackbar.component.scss' ]
})
export class SnackbarComponent implements OnInit, OnDestroy {

  messages: SnackBarMessage[] = [];

  private destroy$ = new Subject<void>();

  constructor(private readonly snackBarService: SnackbarService) {
  }

  ngOnInit(): void {
    this.snackBarService.state
      .pipe(
        debounceTime(5000),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
          this.messages = [];
        }
      );

    this.snackBarService.state
      .pipe(takeUntil(this.destroy$))
      .subscribe((message) => {
        this.messages.push(message);
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

}
