<div class="list-wrapper">
  <app-cards-list
    [items]="items$"
    [loading]="loading$"
    [scroll]="scroll$"
    [unique-key]="uniqueKey"
    [itemsCount]="totalCount"
    [newItems$]="newItems$"
    (loadNewPage)="loadNextPage()"
    (scroll)="onScrollEvent()"
    class="list"
  >
    <ng-template let-item #content>
      <ng-container
        *ngIf="cardTemplateRef"
        [ngTemplateOutlet]="cardTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: item }"
      >
      </ng-container>
    </ng-template>
  </app-cards-list>
  </div>