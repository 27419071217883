export type ResponseResult = {
  frontier: number;
  maximum: number;
  titleLow: string;
  titleHigh: string;
  descriptionLow: string;
  descriptionHigh: string;
  color: string;
}

export type ResponseResultType = 'omsMood' | 'flourishing' | 'workSatisfaction';

export const RESPONSE_DATA: Record<`${ ResponseResultType }Data`, ResponseResult> = {
  omsMoodData: {
    frontier: 50,
    maximum: 100,
    color: '#FF8B05',
    titleLow: 'Vous ne vous sentez pas au mieux, en ce moment.',
    titleHigh: "Vous vous sentez plutôt bien ces temps-ci.",
    descriptionLow: "Vous éprouvez un certain manque d'énergie, des périodes de stress ou de désarroi. Vous êtes parfois préoccupé(e), et votre quotidien peut être source d'émotions désagréables.\n" +
      "\n" +
      "Heureusement, cela peut s'améliorer en apprenant à adapter votre environnement, vos habitudes, et à réguler vos émotions.",
    descriptionHigh: "Vous êtes en forme et dans l'ensemble serein(e), vous avez la sensation d'évoluer dans un environnement plutôt plaisant et enthousiasmant. Vous arrivez à gérer vos préoccupations pour qu'elles ne pèsent pas trop sur votre moral, et votre quotidien est régulièrement source d'émotions agréables."
  },

  flourishingData: {
    frontier: 50,
    maximum: 100,
    color: '#8C5FB0',
    titleLow: "Vous n'êtes pas tout à fait épanoui.e dernièrement.",
    titleHigh: "Vous êtes à l'aise avec la vie que vous menez aujourd'hui.",
    descriptionLow: "Vous ne vous sentez pas toujours à votre place dans la vie que vous menez aujourd'hui... Vous aspirez à cultiver des relations plus enrichissantes, à vous sentir plus utile et stimulé.e dans vos activités, professionnelles ou non, et à vivre plus en accord avec vos valeurs. En outre, vous avez besoin de vous projeter à nouveau avec entrain dans des perspectives futures.\n" +
      "\n" +
      "Bonne nouvelle, les sherpas We Talk peuvent vous y aider.",
    descriptionHigh: "Tout n'est sans doute pas parfait, mais vous cultivez des relations globalement saines et enrichissantes, vous menez certaines activités stimulantes qui vous apportent du sens et de la gratification, et vous trouvez plaisir à former des petits et grands projets pour la suite."
  },

  workSatisfactionData: {
    frontier: 50,
    maximum: 100,
    color: '#2D7DC8',
    titleLow: "Sur le plan professionnel, ce n'est pas facile tous les jours.",
    titleHigh: "Vous semblez vous épanouir sur le plan professionnel.",
    descriptionHigh: "Vous êtes engagé(e) au travail, et la plupart du temps enthousiaste de retrouver vos collègues et vos projets pro le lundi. Vous avez à cœur de faire les choses bien, et êtes plutôt optimiste quant à la suite de votre parcours.\n" +
      "\n" +
      "Sachez que vous pouvez encore gagner en confiance, en sérénité et en énergie grâce à l'accompagnement We Talk.",
    descriptionLow: "Tantôt vous ressentez un manque d'enthousiasme ou de motivation envers votre travail, tantôt celui-ci vous apporte de l'inquiétude, du stress, et beaucoup de questionnements. Vos relations professionnelles sont parfois frustrantes, sans doute devez-vous composer avec des styles de travail ou de communication qui ne sont pas idéaux pour vous. \n" +
      "Vous aimeriez retrouver de l'élan, un sentiment d'accomplissement, et un meilleur équilibre avec votre vie perso, et même, pourquoi pas, de l'enthousiasme en vous levant le lundi matin.\n" +
      "\n" +
      "Si vous souhaitez progresser sur ces aspects, sachez que l'accompagnement We Talk peut tout à fait vous y aider, pour que vous retrouviez confiance et énergie au travail."
  }
};

export interface ScoreRange {
  min: number;
  max: number;
  title: string;
  description: string;
  color: string;
}

export const lifeSatisfactionRanges: ScoreRange[] = [
  {
    min: 0,
    max: 24,
    title: "Votre vie vous semble difficile à accepter à l’heure actuelle.", 
    description: 
    `
      <p>
        Vous avez du mal à vous accepter, à vous projeter, à apprécier les moments de votre quotidien et le sens de votre vie. Vous ressentez peut-être sentiment d'épuisement, et vous avez l'impression de subir votre chemin.
      </p>
      <br/>
      <i>
        C’est le moment d’accueillir vos émotions sans jugement et de reconnaître que vous n’êtes pas seul(e) dans ce défi. 
        Saurez-vous identifier ce qui vous procure de la paix, et vous en féliciter ? We talk peut vous y aider : nous vous conseillons vivement un parcours de Parole afin de retrouver du souffle et l'envie d'aller de l'avant.
      </i>
    `,
    color: "#FF0000"
  },
  {
    min: 25,
    max: 49,
    title: "Vous n'êtes pas tout à fait épanoui.e dernièrement.",
    description: 
    `
      <p>
        Vous ne vous sentez pas toujours à votre place dans la vie que vous menez aujourd'hui... Vous aspirez à cultiver des relations plus enrichissantes, à vous sentir plus utile et stimulé.e dans vos activités, et à vivre plus en accord avec vos valeurs. 
        En outre, vous avez besoin de vous projeter à nouveau avec entrain dans des perspectives futures.
      </p>
      <br/>  
      <i>
        Envisagez de prendre du recul sur votre situation, de partager vos ressentis, d’explorer des outils ou un accompagnement qui pourraient vous aider à retrouver votre équilibre.
      </i>
    `,
    color: "#FF8B05"
  },
  {
    min: 50,
    max: 74,
    title: "Vous êtes à l'aise avec la vie que vous menez aujourd'hui.",
    description: 
    `
      <p>
        Tout n'est sans doute pas parfait, mais vous cultivez des relations globalement saines et enrichissantes, vous menez certaines activités stimulantes qui vous apportent du sens et de la gratification, et vous trouvez plaisir à former des petits et grands projets pour la suite.
      </p>
      <br/>
      <i>
        Continuez à explorer ces sources de satisfaction et à entretenir les aspects positifs de votre vie. Un parcours de Coaching pourra vous aider à naviguer avec confiance vers vos objectifs.
      </i>
    `,
    color: "#8C5FB0"
  },
  {
    min: 75,
    max: 100,
    title: "Vous semblez être dans une période de grande sérénité et d’épanouissement.",
    description: 
    `
      <p>
        Vous investissez votre temps et votre énergie dans des activités qui résonnent avec vos valeurs, qui vous stimulent intellectuellement ou émotionnellement, et qui vous procurent un sentiment d’accomplissement durable.
        Votre capacité à vous projeter dans des projets motivants, tout en savourant le moment présent, témoigne d’un bel alignement entre vos aspirations et vos actions.
      </p>
      <br/>
      <i>
        Prenez le temps de célébrer cet équilibre et continuez à nourrir votre bien-être !
      </i>
    `,
    color: "#2D7DC8"
  }
];

export const affectRanges: ScoreRange[] = [
  {
    min: 0,
    max: 24,
    title: "Ce score confirme le mal être que vous ressentez en ce moment.",
    description: 
    `
      <p>
        Vous êtes dans une phase où vos émotions, désagréables, signalent que vous avez besoin de soutien et d’un changement bénéfique. 
        Vous êtes peut-être inquiet ou découragé au moins depuis quelques mois, et vos relations peuvent sembler moins soutenantes qu'elles ne devraient l'être. 
        Ce constat doit être pris non comme un jugement mais comme une opportunité pour enfin prendre soin de vous.
      </p>
      <br/>
      <i>
        Nous vous encourageons à explorer vos besoins, ajuster votre environnement, et retrouver un équilibre émotionnel. 
        Avec un accompagnement adapté, ce moment peut devenir un tremplin vers un mieux-être.
        Nous vous aiguillons pour cela vers un parcours de Parole en groupe.
      </i>
    `,
    color: "#FF0000"
  },
  {
    min: 25,
    max: 49,
    title: "Vous ne vous sentez pas au mieux en ce moment.",
    description: 
    `
      <p>
        Vous éprouvez un certain manque d'énergie, des périodes de stress ou de désarroi. Vous êtes parfois préoccupé(e), votre quotidien peut être source d'émotions désagréables et vos relations peuvent manquer de profondeur ou d’équilibre.
        Heureusement, cela peut s'améliorer en apprenant à adapter votre environnement, vos habitudes, et à réguler vos émotions. 
      </p>
      <br/>
      <i>
        Saurez-vous prioriser ce qui vous pèse le plus et en faire un objectif prioritaire ? Les Sherpas We Talk peuvent vous y aider.
      </i>
    `,
    color: "#FF8B05"
  },
  {
    min: 50,
    max: 74,
    title: "Vous vous sentez plutôt bien ces temps-ci.",
    description: 
    `
      <p>
        Vous êtes en forme et dans l'ensemble serein(e), vous avez la sensation d'évoluer dans un environnement plutôt plaisant et enthousiasmant, et vous avez trouvé un certain équilibre entre vos relations. 
        Vous arrivez à gérer vos préoccupations pour qu'elles ne pèsent pas trop sur votre moral, et votre quotidien est régulièrement source d'émotions agréables.
      </p>
      <br/>
      <i>
        Il semble que vous ayez trouvé des repères pour gérer vos émotions. Continuez à cultiver cet équilibre et à rester attentif(ve) à vos besoins : si vous souhaitez aller plus loin, dirigez-vous vers un parcours de Coaching.
      </i>
    `,
    color: "#8C5FB0"
  },
  {
    min: 75,
    max: 100,
    title: "Vous savez prendre soin de vous et gérer vos émotions afin de vous sentir en paix.",
    description: 
    `
      <p>
        Vous évoluez dans un environnement qui vous nourrit et vous donne de l’énergie. Vos relations sont non seulement équilibrées, mais elles sont aussi enrichissantes, vous offrant un sentiment d’appartenance et de soutien. 
        Vous semblez avoir un tempérament optimiste et arrivez à ne pas vous laisser trop impacter par les événements extérieurs.
      </p>
      <br/>
      <i>
        Continuez à savourer cet état d’épanouissement, tout en sachant accueillir chez vous ou dans votre entourage des émotions désagréables ou des manifestations de vulnérabilité avec bienveillance.
      </i>
    `,
    color: "#2D7DC8"
  }
];

export const workSatisfactionRanges: ScoreRange[] = [
  {
    min: 0,
    max: 24,
    title: "Vous traversez actuellement une période difficile sur le plan professionnel qui vous impacte sur le plan personnel.",
    description: 
    `
      <p>
        Vous ressentez un véritable manque d’enthousiasme et de motivation dans votre travail. Votre activité professionnelle est source de stress ou d’épuisement, avec peu de moments de gratification ou de reconnaissance. 
        Des incompréhensions ou des conflits peuvent peser sur votre moral. Vous avez peut-être du mal à équilibrer votre vie pro/perso, ce qui amplifie votre sentiment de surcharge ou de déséquilibre.
      </p>
      <br/>
      <i>
        Un parcours de Parole vous permettra de trouver du soutien d’autres personnes partageant les mêmes problématiques, et le Sherpa saura vous aider à trouver des solutions pour alléger votre charge mentale et faire des choix positifs. 
        Vous pourriez ainsi retrouver un sentiment d’accomplissement et reconstruire votre confiance pas à pas.
      </i>
    `, 
    color: "#FF0000"
  },
  {
    min: 25,
    max: 49,
    title: "Sur le plan professionnel, ce n'est pas facile tous les jours.",
    description:
    `
      <p>
        Tantôt vous ressentez un manque d'enthousiasme ou de motivation envers votre travail, tantôt celui-ci vous apporte de l'inquiétude, du stress, et beaucoup de questionnements. 
        Vos relations professionnelles sont parfois frustrantes, sans doute devez-vous composer avec des styles de travail ou de communication qui ne sont pas idéaux pour vous. 
        Vous aimeriez retrouver de l'élan, un sentiment d'accomplissement, et un meilleur équilibre avec votre vie perso, et même, pourquoi pas, de l'enthousiasme en vous levant le lundi matin.
      </p>
      <br/>
      <i>
        Si vous souhaitez progresser sur ces aspects, sachez que l'accompagnement We Talk peut tout à fait vous y aider, pour que vous retrouviez confiance et énergie au travail.
      </i>
    `,
    color: "#FF8B05"
  },
  {
    min: 50,
    max: 75,
    title: "Vous semblez vous épanouir sur le plan professionnel.",
    description: 
    `
      <p>
        Globalement, vous parvenez à trouver un équilibre satisfaisant et une certaine motivation dans votre travail, vous êtes engagé(e), même si des périodes de doute subsistent parfois.  
        Vous êtes capable d’avancer dans vos projets tout en préservant un certain équilibre avec votre vie personnelle. Vous avez à cœur de faire les choses bien, et êtes plutôt optimiste quant à la suite de votre parcours.
      </p>
      <br/>
      <i>
        Continuez à cultiver ce qui nourrit votre épanouissement. Si vous souhaitez affiner davantage vos projets, réfléchir à de nouvelles opportunités professionnelles, ou atteindre de nouveaux objectifs, les Sherpas peuvent vous accompagner via les parcours de Coaching.
      </i>
    `,
    color: "#8C5FB0"
  },
  {
    min: 75,
    max: 100,
    title: "Votre vie professionnelle semble être une véritable source d’accomplissement et d’énergie.",
    description: 
    `
      <p>
        Vous semblez passionné, heureux et y trouver la reconnaissance que vous méritez. Vous ressentez une motivation durable et un enthousiasme dans vos activités, ainsi qu’une réelle satisfaction dans les relations professionnelles que vous entretenez. 
        Vous parvenez à faire face aux défis de manière proactive, et vos succès renforcent votre sentiment de confiance et d’épanouissement.
      </p>
      <br/>
      <i>
        Continuez à cultiver cet enthousiasme en identifiant et maintenant vos lieux de ressourcement, et en explorant les opportunités de croissance autant personnelles que professionnelles.
      </i>
    `,
    color: "#2D7DC8"
  }
];


export function getAffectRange(score: number): ScoreRange {
  return affectRanges.find(range => score >= range.min && score <= range.max) || affectRanges[0];
}

export function getLifeSatisfactionRange(score: number): ScoreRange {
  return lifeSatisfactionRanges.find(range => score >= range.min && score <= range.max) || lifeSatisfactionRanges[0];
}

export function getWorkSatisfactionRange(score: number): ScoreRange {
  return workSatisfactionRanges.find(range => score >= range.min && score <= range.max) || workSatisfactionRanges[0];
}
