import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';

import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { Talker } from "../models/talker.entity";
import { TalkerRepository } from "../repositories/talker.repository";

@Injectable()
export class TalkerResolver  {
  constructor(private readonly talkerRepository: TalkerRepository,
              private readonly snackbarService: SnackbarService,
              private readonly router: Router,
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Talker | undefined> {
    const id = route.paramMap.get('id') ?? '';

    return this.talkerRepository.findById(id)
      .pipe(catchError(err => this.handleError(err)));
  }

  private handleError(err: HttpErrorResponse): Observable<Talker | undefined> {
    if (err.status === 404) {
      this.snackbarService.pushMessage('L\'utilisateur recherché est introuvable', 'error');
    }
    this.router.navigate([ '/users' ]);

    return of(undefined);
  }
}
