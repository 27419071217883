import { booleanAttribute, Component, Input, numberAttribute } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss'
})
export class ImageComponent {

  public imageIsLoaded = false;

  @Input() src!: string;

  @Input() alt!: string;

  @Input({ transform: booleanAttribute }) rounded = false;

  @Input({ transform: numberAttribute }) width? : number;

  @Input({ transform: numberAttribute }) height? : number;

  @Input({ transform: booleanAttribute }) grey = false;

  imageLoaded() {
    this.imageIsLoaded = true;
  }

}
