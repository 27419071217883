import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import jwtDecode from "jwt-decode";
import { Subject, takeUntil } from "rxjs";

import { CanComponentDeactivate } from "../../shared/guard/candeactivate.guard";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { TypeformService } from "../typeform.service";
import { TypeformFormType, TypeformHiddenFields, TypeformTokenPayload } from "../typeform.type";

@Component({
  selector: 'app-typeform-by-mail',
  templateUrl: './typeform-by-mail.component.html',
  styleUrls: [ './typeform-by-mail.component.scss' ]
})
export class TypeformByMailComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  public type!: TypeformFormType;

  private readonly destroy$ = new Subject<void>();

  public hiddenFields!: TypeformHiddenFields;

  constructor(private readonly route: ActivatedRoute,
              private readonly typeformService: TypeformService) {

  }

  ngOnInit(): void {
    this.route.queryParamMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(queryParams => {
        if (queryParams.has('token')) {
          const {
            trainId,
            thematicName,
            userId,
            date,
            type
          } = jwtDecode<TypeformTokenPayload>(queryParams.get('token') ?? '');

          this.type = type;
          this.hiddenFields = {
            id_parcours: trainId,
            theme_title_fr: capitalize(thematicName ?? ''),
            id_user: userId,
            date_latest_session: date,
            source: "email",
          };
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return !this.typeformService.needConfirmToQuit;
  }
}
