import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: [ './progress-bar.component.scss' ]
})
export class ProgressBarComponent {

  @Input() maxSteps!: number;

  @Input() stepsDone!: number;

  @Output() onStepClick: EventEmitter<number> = new EventEmitter<number>();


  get progressClass(): string {
    return `progress-${ Math.min(Math.floor(this.stepsDone / (this.maxSteps - 1) * 100), 98) }`;
  }

  goToSpecificStep(step: number): void {
    if(step >= this.stepsDone) {
      return;
    }
    this.onStepClick.emit(step);
  }
}
