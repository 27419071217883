<div class="sized-wrapper">
  <div class="column gap center-children wrapper congrats-wrapper">
    <div class="check">
      <app-icon size="56" type="check"></app-icon>
    </div>
    <p class="text-center text-bold text-large">Merci, votre réservation est bien prise en compte.</p>
    <p class="text-center">Vous recevrez par email les informations, les participants, le cadre… propices à une bonne session.</p>
    <app-button icon="house" (click)="goBackToHome()">Retourner à l'accueil</app-button>
  </div>
</div>
