import { Injectable } from '@angular/core';
import { Observable, of, Subject, takeUntil } from 'rxjs';

import { ProfileStore } from "../../shared/services/profile.storage.service";
import { getUserRole } from "../../shared/tool-functions/user";
import { ProfileService } from "../../users/services/profile.service";

@Injectable()
export class TrainsMtmResolver  {
  private destroy$ = new Subject<void>();

  constructor(private readonly profileService: ProfileService,
              private readonly profileStore: ProfileStore,
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(): void {
    const profile = this.profileStore.getProfile();
    if (profile) {
      this.setTrackerVariables(profile);
    } else {
      this.profileService
        .getObservable()
        .pipe(takeUntil(this.destroy$))
        .subscribe((prof: any) => {
          if (prof) {
            this.setTrackerVariables(prof);
          }
        });
    }
  }

  private setTrackerVariables(profile: any): void {
    // MTM START
    window._mtm.push(
        { 
          'PageName': 'TrainsList', 
          'PageCategory': 'Parcours',
          'UserProfile': getUserRole(profile), 
          'UserID': profile.id
        }
      );
      // MTM END
  }
  
  private handleError(): Observable<undefined> {
    return of(undefined);
  }
}
