<div class="wrapper">
  <div class="header">
    <h1 class="title">Changer la date de la séance #{{sessionIndex}}</h1>
    <app-button title="Fermer" [outline]="true" [no-border]="true" class="close-button" icon="close"
                (onClick)="validate()"></app-button>
  </div>


  <section>
    <form [formGroup]="dateForm" (submit)="validate(true)">
      <p>Jour</p>
      <app-input type="date" formControlName="date"></app-input>

      <p>Horaire</p>
      <app-input type="time" formControlName="time"></app-input>
      <div class="add-button relative">
        <app-button class="center-by-relative-parent" type="submit">Sauvegarder
        </app-button>
      </div>
    </form>
  </section>
</div>
