<div class="wrapper">
  <div *ngIf="!!image" class="header">
    <app-image  class="header-image" alt="congrats" [src]="image" />
    <h2 class="information-title" *ngIf="data.title">{{ data.title }}</h2>
    <hr/>
  </div>
  <div *ngIf="!image" class="margin-bottom"></div>
  <section>
    <div class="information-message" [class.text-center]="!image" [innerHTML]="parseMessageToHtml(data.message)"></div>
    <div class="add-button relative">
      <app-button class="center-by-relative-parent"  type="button" (click)="close()"
                  title="Confirmer">{{confirmMessage}}</app-button>
    </div>
  </section>
</div>


