import { TimeSlotPossibility } from "../../admin/edit-timeslots/edit-timeslots.component";
import { TimeSlot } from "../../trains/models/time-slot.entity";

import { findGCD } from "./gcd";

export interface ITimeSlot {
  hours: number;
  minutes: number;
  weekDay: number;
}

export type TimeSlotMetadata = {
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
  delayValue: number;
  delayUnit: 'heures' | 'minutes';
  timeSlotsPossibilities: TimeSlotPossibility[];
  timeSlotsSelectedSorted: TimeSlot[];
  selectedDays: number[];
}

export function parseTimeSlotsToEdit(timeSlots: TimeSlot[]): TimeSlotMetadata {
  const timeSlotsSelectedSorted = timeSlots
    .sort((timeslotA, timeslotB) =>
      ((timeslotA.weekDay - timeslotB.weekDay) * 24 * 60
        + (timeslotA.hours - timeslotB.hours) * 60
        + (timeslotA.minutes - timeslotB.minutes)));
  const selectedDays = timeSlots.map(timeslot => timeslot.weekDay);
  const startHour = Math.min(...timeSlots.map(timeSlot => timeSlot.hours));
  const startMinute = Math.min(...timeSlots.map(timeSlot => timeSlot.minutes));

  const endHour = Math.max(...timeSlots.map(timeSlot => timeSlot.hours));
  const endMinute = Math.max(...timeSlots.map(timeSlot => timeSlot.minutes));

  const dayTimeSlots: TimeSlot[] = [];
  timeSlotsSelectedSorted.forEach(timeslot => {
    if (!dayTimeSlots.find(dayTimeSlot => dayTimeSlot.hours === timeslot.hours && dayTimeSlot.minutes === timeslot.minutes)) {
      dayTimeSlots.push(timeslot);
    }
  });

  const minimumDelayInMinutes: number = findGCD(dayTimeSlots.flatMap(timeSlotA => {
    const delta: number[] = [];
    dayTimeSlots.forEach(timeSlotB => {
      if (timeSlotA.hours !== timeSlotB.hours || timeSlotA.minutes !== timeSlotB.minutes) {
        delta.push(Math.abs(timeSlotA.hours - timeSlotB.hours) * 60 + Math.abs(timeSlotA.minutes - timeSlotB.minutes));
      }
    });
    return delta;
  }));

  const isInHours = minimumDelayInMinutes % 60 === 0;
  const slotsCount = Math.floor(((endHour - startHour) * 60 + (endMinute - startMinute)) / minimumDelayInMinutes);

  return {
    startHour,
    startMinute,
    endHour,
    endMinute,
    selectedDays,
    delayValue: minimumDelayInMinutes !== 24 * 60 ? isInHours ? minimumDelayInMinutes / 60 : minimumDelayInMinutes : 0,
    delayUnit: isInHours ? 'heures' : 'minutes',
    timeSlotsSelectedSorted,
    timeSlotsPossibilities: Array.from(Array(slotsCount + 1))
      .flatMap((_, slotIndex) => Array.from(Array(7))
        .map((__, weekDay) => {
          const hours = Number(startHour) + Math.floor(slotIndex * minimumDelayInMinutes / 60);
          const minutes = (Number(startMinute) + (slotIndex * minimumDelayInMinutes)) % 60;
          return{
          hours,
          minutes,
          weekDay,
          selected: !!timeSlotsSelectedSorted.find(timeSlot => isSameTimeSlot(timeSlot, { hours, minutes, weekDay })),
        };
})),
  };
}

export function isSameTimeSlot(timeSlotA: ITimeSlot, timeSlotB: ITimeSlot): boolean {
  return timeSlotA.hours === timeSlotB.hours
    && timeSlotA.minutes === timeSlotB.minutes
    && timeSlotA.weekDay === timeSlotB.weekDay;
}
