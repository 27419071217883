import { Injectable } from "@angular/core";
import { BehaviorSubject, skip, tap } from "rxjs";

import { StorageService } from "../../shared/storage/storage.service";
import { Organization } from "../models/organizations.entity";
import { OrganizationRepository } from "../repositories/organization.repository";

@Injectable({ providedIn: 'root' })
export class OrganizationStoreService {

  private readonly organizationStored$ = new BehaviorSubject<Organization | undefined>(undefined);

  constructor(private readonly storageService: StorageService,
              private readonly organizationRepository: OrganizationRepository) {

    this.organizationStored$
      .pipe(skip(1))
      .subscribe(organization => {
        this.storageService.setOrganizationSelectedId(organization?.id);
      });
  }

  setOrganization(organization: Organization) {
    this.organizationStored$.next(organization);
  }

  reloadOrganization() {
    return this.organizationRepository.findById(this.organizationStored$.getValue()?.id ?? '')
      .pipe(tap(organization => {
        this.organizationStored$.next(organization);
      }));
  }

  removeOrganization() {
    this.organizationStored$.next(undefined);
  }

  getObservable() {
    return this.organizationStored$.asObservable();
  }
}
