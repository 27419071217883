import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Observable, Subject, Subscription } from 'rxjs';

import { AbstractChartComponent } from '../abstract.chart.component';

// Chart.register(ChartDataLabels);

@Component({
  selector: 'app-horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
})
export class HorizontalBarChartComponent extends AbstractChartComponent<'bar'> implements OnInit, AfterViewInit, OnDestroy {
    resize$ = new Subject<void>();

    type: 'bar' = 'bar';

    width!: number;

    height!: number;

    @Input() data$: any;

    data: any;

    private dataSubscription!: Subscription;

    chartOptions: any = {
      indexAxis: 'y' as const,
      responsive: false,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: false,
          grid: {
            display: false
          }
        },
        y: {
          display: false, // Hide default y-axis labels
          grid: {
            display: false
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        },
        datalabels: {
          align: 'start',
          anchor: 'end',
          offset: 10,
          color: '#000',
          font: {
            size: 12,
            family: 'Arial'
          },
          formatter: (value: any, context: any) => {
            const labels = [
              "je n'ai pas du tout atteint mes objectifs",
              "Je les ai atteints en partie",
              "J'ai atteint la plupart de mes objectifs",
              "Je les ai totalement atteints voire dépassés"
            ];
            return labels[context.dataIndex];
          }
        }
      },
      layout: {
        padding: {
          top: 30,
          right: 50,
          left: 10,  // Minimal left padding to avoid moving bars
          bottom: 10
        }
      }
    };

    ngOnInit() {
      this.onInit([
        this.resize$,
        ...(this.data$ && this.data$ instanceof Observable ? [this.data$] : [])
        ]);
      this.dataSubscription = this.data$.subscribe((data: any) => {
        this.data = data;
        this.updateData();
        this.chart?.update();
      });
      this.getWidth();
      this.getHeight();
    }

    ngAfterViewInit(): void {
      if (this.chart) {
        this.chart.update();
      }
    }

    ngOnDestroy(): void {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
    }

    updateData() {
      this.chartData = {
        labels: [
          "je n'ai pas du tout atteint mes objectifs",
          "Je les ai atteints en partie",
          "J'ai atteint la plupart de mes objectifs",
          "Je les ai totalement atteints voire dépassés"
        ],
        datasets: [{
          data: this.data?.map((item: any) => item.count),
          backgroundColor: ['#FF4F4E', '#FC9418', '#94D82C', '#22C997']
        }]
      };
    }

    @HostListener('window:resize')
    getWidth(): void {
      this.width = Math.min(Math.floor((window.innerWidth - 460) / 3), 300);
      this.resize$.next();
    }

    @HostListener('window:resize')
    getHeight(): void {
      this.height = Math.min(Math.floor(this.width * 2 / 3), 200);
      this.resize$.next();
    }
}