<div class="column gap">
    <h2 class="space-between row">{{content.title}}</h2>
    <div [class.category-container]="isSmallScreen" class="row gap center-children-vertically">
        <div class="row gap">
            <div *ngFor="let pillar of content.pillars">
                <app-icon [type]="getPillarIcon(pillar)" size="25"></app-icon>
            </div>
         </div>
         <div class="row gap" *ngIf="isSmallScreen; else bigScreen">
            <div (click)="onSelectCategory(category)" [dropdownTriggerFor]="categoryDropdown" [ngStyle]="{'background-color': getColorBySlug(category.slug) }" class="thematic-category-name-wrapper" *ngFor="let category of content.thematicCategories">
                <span class="category-name">{{ getCategoryNameBySlug(category.slug) }}</span>
            </div>
            <div class="dropdown-container">
                <app-dropdown [hasPadding]="false" [closeOnClick]="false" #categoryDropdown>
                    <div *ngIf="selectedCategory" [ngStyle]="{'background-color': getColorBySlug(selectedCategory?.slug) }" class="wrap category-name-dropdown">
                    <div [ngStyle]="{'color': 'white' }">{{ capitalizeName(selectedCategory?.i18nNames[0]?.content) }}</div>
                    </div>
                </app-dropdown>
            </div>
         </div>
        <ng-template class="row gap" #bigScreen>
            <div (click)="onSelectCategory(category)" [ngStyle]="{'background-color': getColorBySlug(category.slug) }" class="thematic-category-name-wrapper" *ngFor="let category of content.thematicCategories">
                <span class="category-name">{{ getCategoryNameBySlug(category.slug) }}</span>
            </div>
        </ng-template>
    </div>
    <div class="details-wrapper row gap margin-top">
        <div class="content-details-main">
            <p class="content-details-description-text">
                {{content.description}}
            </p>
            <div class="row space-between margin-top margin-bottom-xl"> 
                <div class="row center-children-vertically" *ngIf="content.readingDuration">
                    <app-icon size="24" type="clock-circle"></app-icon>
                    <div class="duration-wrapper">{{ readableReadingTime }}</div>
                </div>
                <div class="row">
                    <app-icon class="reaction-icon" size="24" [type]="liked === false ? 'thumb-dislike-active' : 'thumb-dislike-inactive'" (click)="handleDislikeReaction()"></app-icon>
                    <app-icon class="reaction-icon" size="24" [type]="liked === true ? 'thumb-like-active' : 'thumb-like-inactive'" (click)="handleLikeReaction()"></app-icon>
                </div>
            </div>
            <div *ngIf="content.format === 'pratical_sheet'" class="image-container center-horizontally-by-margin">
                <img [src]="content.image.trim().length > 0 ? content.image : 'assets/images/noimage.png'" alt="Description of image">
                <div (click)="downloadFile()" class="row download-button"><span>TÉLÉCHARGER</span><app-icon type="download" size="25"></app-icon></div>
            </div>
            <div class="column" *ngIf="content.format === 'podcast'">
                <img [src]="isPlayingAudio ? 'assets/images/animation-podcast.gif' : 'assets/images/animation-static.jpg'" alt="Loading animation" class="animation-gif">
                <app-audio-player (playingStatusChanged)="onAudioStatusChange($event)" [audioSrc]="content.resource"></app-audio-player>
            </div>

            <div class="video-wrapper" *ngIf="content.format === 'video'">
                <div class="video-container" *ngIf="content.resource.includes('s3.gra.io')">
                    <video width="280" height="175" controls>
                        <source [src]="content.resource" type="video/mp4">
                        Your browser does not support the video tag.
                    </video> 
                </div>
                <div class="iframe-container" *ngIf="!content.resource.includes('s3.gra.io')">
                    <iframe [src]="this.videoUrl" allowfullscreen></iframe>
                </div>
            </div>

        </div>
        <div class="divider"></div>
        <div class="column column-gap content-details-aside">
            <p class="margin-bottom">
                <strong>Approfondir ce sujet :</strong>
            </p>
            <p>
                Pour aller plus loin et être accompagné par nos Sherpas sur ce sujet, nous vous recommandons les parcours suivants :
            </p>
            <div class="margin-top column custom-gap" >
                <div *ngFor="let thematic of content.thematics">
                    <app-content-thematic class="margin-top-xl" [thematic]="thematic"></app-content-thematic>
                </div>
            </div>
        </div>
    </div>
</div>