<div class="padding-wrapper">
  <div class="wrapper">
    <h1 class="margin-bottom text-primary">
      Bienvenue {{ capitalize(firstName) }}
    </h1>
    <!--    <div class="home-notif" *ngxPermissionsOnly="['talker']">-->
    <!--      <app-notification [outline]="true"  class="margin-bottom" [buttonMessage]=" hasDoneInitialResponse ? 'Accéder à mon espace' : 'Réaliser mon bilan émotionnel'" (onButtonClick)="goToMySpace()" color="#8C5FB0" [icon]="hasDoneInitialResponse ? 'check' : 'bell'" title="Faites le point sur votre évolution">-->
    <!--        {{hasDoneInitialResponse ? 'Vous avez réalisé le bilan initial. C’est dans votre espace personnel que vous pourrez consulter l’évolution de votre état émotionnel.' : 'Réalisez votre bilan émotionnel pour identifier les parcours qui vous correspondent.'}}-->
    <!--      </app-notification>-->
    <!--    </div>-->

    <app-notifications-resume />
    <ng-container *ngxPermissionsOnly="'sherpa'">
      <div class="notification-resume-wrapper" *ngIf="sherpaFeedbacks.length">
        <div class="resume-wrapper">
          <div class="feedbacks-wrapper">
            <p class="margin-bottom text-center text-bold">Feedbacks</p>
            <app-slider [items]="sherpaFeedbacks">
              <ng-template #slide let-item>
                <div class="testimonial">
                  <div class="testimonial-content">
                    <p class="margin-bottom">{{item}}</p>
                  </div>
                </div>
              </ng-template>
            </app-slider>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngxPermissionsOnly="'talker'">
      <h1 class="margin-top margin-bottom">Comment We Talk peut vous aider aujourd'hui ?</h1>
      <section class="hero-section">
        <div class="onboarding-container icon-buttons-container">
          <div class="row center-align">
            <div class="onboarding-item-left"></div>
            <div class="onboarding-item-right first-icon">
              <button (click)="openOnboardingDialog('progress')" class="onboarding-button primary">Je veux progresser.</button>
              <div class="clickable-icon" (click)="openOnboardingDialog('progress')">
                <app-icon type="onboarding-chart" size="125"/>
              </div>  
            </div>
          </div>
      
          <div class="row">
            <div class="onboarding-item-left second-icon-container">
              <div class="second-icon">
                <button (click)="openOnboardingDialog('trial')" class="onboarding-button secondary">
                  <div>Je ne suis pas bien.</div> 
                  <div>Je vis une période difficile.</div>
                </button>
                <div (click)="openOnboardingDialog('trial')" class="clickable-icon">
                  <app-icon type="onboarding-rain" size="125"/>
                </div>
              </div>
            </div>
            <div class="onboarding-item-right"> </div>
          </div>
      
          <div class="row space-between">
            <div class="row space-between last-icon-container">
              <div class="onboarding-last-item-left">
                <div class="last-item-left">
                  <button (click)="openOnboardingDialog('lost')" class="onboarding-button black">
                    <div>Je ne sais pas par où</div>
                    <div>commencer.</div>
                  </button>
                  <div class="clickable-icon" (click)="openOnboardingDialog('lost')">
                    <app-icon type="onboarding-question" size="125"/>
                  </div>
                </div>
              </div>
              <div class="onboarding-last-item-right">
                <div class="last-item-right">
                  <button (click)="openOnboardingDialog('explore')" class="onboarding-button indigo">
                    <div>Je veux explorer et </div>
                    <div>découvrir.</div>
                  </button>
                  <div class="clickable-icon" (click)="openOnboardingDialog('explore')">
                    <app-icon type="onboarding-chatting" size="125"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>

    <div *ngxPermissionsOnly="'talker'" class="testimony-section">
      <h1 class="margin-top margin-bottom">Témoignage du mois</h1>
      <div class="testimony-container">
        <div class="video-column">
          <div class="video-wrapper">
            <youtube-player [playerVars]="playerVars" disableCookies="true" videoId="ff6AjZS-NBk"></youtube-player>
          </div>
        </div>
        <div class="text-column">
          <div class="testimony-quote">
            <app-icon type="onboarding-citation" size="30" />
            <p class="testimony-top">
              J'ai vécu une situation personnelle très difficile pendant pas mal de temps, et quand je suis
              sortie de cette situation, j'ai vraiment eu envie de me dire "À quoi je me dédie maintenant, en 
              cohérence avec ce qui compte pour moi ?". 
            </p>
          </div>
          <p class="testimony-bottom">
            Anne a suivi un parcours sur la thématique "Trouver du sens à ma vie"
          </p>
        </div>
      </div>
    </div>

    <app-home-page-tabs />

    <div *ngxPermissionsOnly="'admin'" class="base-margin column gap">
      <h3>Configuration admin</h3>
      <div class="row gap space-between">
        <p *ngIf="smsBalance" [class.color-danger]="smsBalance < 5">
          Solde SMS Vonage: {{ smsBalance }}€
        </p>
        <div
          *ngIf="canDeactivateSendingEmail"
          class="email-config-wrapper row center-children gap space-between"
        >
          <span>Envoi des mails</span>
          <app-checkbox
            (ngModelChange)="onSwitchChange($event)"
            [ngModel]="isSendingEmailActivated"
            [type]="'switch'"
          />
        </div>
      </div>
      <app-button
        [outline]="true"
        (onClick)="editPlatformConfiguration()"
        title="Modifier la configuration"
        >Modifier la configuration
      </app-button>
      <app-button
        [outline]="true"
        (onClick)="editWeeklyTimeSlots()"
        title="Modifier les créneaux"
        >Modifier les créneaux horaires des séances
      </app-button>
      <app-button
        *ngIf="canResetData"
        [danger]="true"
        [outline]="true"
        (onClick)="restTestData()"
        title="Réinitialiser les données"
        >Réinitialiser les données <span *ngIf="resetDataLoading">...</span>
      </app-button>
    </div>

    <div class="margin-top-xl" *ngxPermissionsExcept="'admin'">
      <h1 class="margin-bottom">Votre démarche commence ici</h1>
      <app-welcome-links [images$]="imagesLinks$" [role]="role" />
    </div>
  </div>
</div>
