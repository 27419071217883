import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
// import { MatomoTracker } from 'ngx-matomo-client';
import { Subject, takeUntil } from "rxjs";

import { APP_CONFIG } from "../../../../config/config";
import { AppConfig } from "../../../../config/config.type";
import { OrganizationRepository } from "../../../../organizations/repositories/organization.repository";
import { mediumCategoryColors } from "../../../../thematics/colors";
import { CreateTrainService } from "../../../../thematics/create-train.service";
import { Thematic } from "../../../../thematics/models/thematic.model";
import { Train } from "../../../../trains/models/train.entity";
import { Sherpa } from "../../../../users/models/sherpa.entity";
import { FunnelService } from "../../../services/funnel.service";
import { formatDate, formatTimeSlot } from "../../../tool-functions/date-tools";
import { SnackbarService } from "../../snackbar/snackbar.service";

type TrainByThematic = { thematic: Thematic, trains: Train[] };

@Component({
  selector: 'app-thematic-trains',
  templateUrl: './thematic-trains.component.html',
  styleUrls: [ './thematic-trains.component.scss' ]
})
export class ThematicTrainsComponent implements OnInit, OnDestroy {

  @Input() trainsByThematics!: TrainByThematic;

  colorMedium!: string;

  destroys$ = new Subject<void>();

  // private readonly tracker = inject(MatomoTracker);

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private readonly router: Router,
              private readonly funnelService: FunnelService,
              private readonly creatTrainService: CreateTrainService,
              private readonly snackBarService: SnackbarService,
              private readonly organizationRepository: OrganizationRepository,
              private readonly navigation: Router
            ) {
  }

  ngOnInit() {
    this.colorMedium = this.trainsByThematics.thematic.category?.slug ? mediumCategoryColors[this.trainsByThematics.thematic.category.slug] : '';
  }

  ngOnDestroy() {
    this.destroys$.next();
  }

  dateFormat = formatDate;

  timeFormat = formatTimeSlot;

  goToSherpa(sherpa: Sherpa): void {
    this.router.navigate([ 'sherpas', sherpa.id ]);
  }

  goToThematic(): void {
    this.router.navigate([ 'thematics', this.trainsByThematics.thematic.slug ]);
  }

  selectTrain(train: Train) {
    // this.tracker.trackEvent(TrackerCategory.TRAINS_TALKER, TrackerAction.CLICK, 'existingTrainSignup');
    const currentUrl = this.router.url;
    const isThematicPage = currentUrl.startsWith('/thematics');
    (window._mtm || []).push({ 'event':  isThematicPage ? 'inscription-parcours-thematique' : 'inscription-parcours', 'nom-parcours': train.dynamic.thematic.title });
    this.organizationRepository.canConsumeUnit()
      .pipe(takeUntil(this.destroys$))
      .subscribe(can => {
        if (can.unitAvailable && can.quota) {
          this.creatTrainService.selectTrain(train);
        } else {
          this.snackBarService.pushMessage(!can.unitAvailable ? "Votre entreprise n'a plus d'unité disponible" : "Vous avez consommé le quota d'unités qui vous a été accordé par votre entreprise.", "error");
        }
      });
  }

  goToCreateTrain(): void {
    (window._mtm || []).push({ 'event': 'nouvel-horaire-parcours', 'nom-parcours': this.trainsByThematics.thematic.title });
    // this.tracker.trackEvent(TrackerCategory.TRAINS_TALKER, TrackerAction.CLICK, 'newTrain');
    this.creatTrainService.selectThematic(this.trainsByThematics.thematic);
  }
}
