import { ComponentType } from "@angular/cdk/overlay";
import { Subject, takeUntil, tap } from "rxjs";

import { DialogRef } from "./dialog.ref";

export type TDialogComponent<ReturnType, Config> =  ComponentType<{
  destroy$: Subject<void>;
  closing$: Subject<ReturnType | undefined>;
  isClosing: boolean;
  data: Config;
  onInit(): void;
  onDestroy(): void;
  close(payload?: ReturnType): void;
}> & { isPopIn: boolean };

export function DialogComponent<ReturnType = undefined, ConfigType = undefined>(): TDialogComponent<ReturnType, ConfigType> {
  class AbstractDialogComponent {

    static isPopIn = false;

    destroy$ = new Subject<void>();

    closing$ = new Subject<ReturnType | undefined>();

    isClosing = false;

    data!: ConfigType;

    constructor(
      protected readonly dialogRef: DialogRef<ReturnType>,
      protected readonly config?: ConfigType,
    ) {
    }

    onInit() {
      this.closing$
        .pipe(
          tap(() => {
            this.isClosing = true;
          }),
          takeUntil(this.destroy$))
        .subscribe(
          (payload) => this.dialogRef.close(payload)
        );
    }

    onDestroy() {
      this.destroy$.next();
    }

    close(payload?: ReturnType): void {
      this.closing$.next(payload);
    }

  }

  return AbstractDialogComponent;

}
