import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TabStoreService {

  private readonly selectedTab$ = new BehaviorSubject<number | undefined>(undefined);

  constructor() {}

  setSelectedTab(tab: number) {
    this.selectedTab$.next(tab);
  }

  getSelectedTab() {
    return this.selectedTab$.getValue();
  }

  removeSelectedTab() {
    this.selectedTab$.next(undefined);
  }

  getObservable() {
    return this.selectedTab$.asObservable();
  }
}