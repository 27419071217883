import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { FilterInterface, FindResult } from "../../shared/repositories/pagination.interface";
import { Content } from "../models/contents.entity";


@Injectable()
export class ContentsRepository extends AbstractRepository<Content> {
  constructor(
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
    protected override readonly http: HttpClient
  ) {
    super(http, Content);
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return "contents";
  }

  findAll(data: any): Observable<Content[]> {
    return this.http
      .post<any>([this.apiUrl, this.getEntityName(), 'all'].join("/"), data)
      .pipe(
        map(({ items, ...rest }) => ({
          ...rest,
          items: items.map((item: any) => this.instantiate(item)),
        }))
      );
  }

  talkerPaginate(filter: FilterInterface<any, 'createdAt' | 'activatedAt'>): Observable<FindResult<Content>> {

    return this.http.post<FindResult<Content>>([ this.apiUrl, this.getEntityName(), 'talker-paginate' ].join('/'), filter).pipe(
      map(({ items, ...rest }) => ({
        ...rest,
        items: items.map(item => this.instantiate(item))
      }))
    );
  }

  paginate(filter: FilterInterface<any, 'createdAt' | 'activatedAt'>): Observable<FindResult<Content>> {

    return this.http.post<FindResult<Content>>([ this.apiUrl, this.getEntityName(), 'paginate' ].join('/'), filter).pipe(
      map(({ items, ...rest }) => ({
        ...rest,
        items: items.map(item => this.instantiate(item))
      }))
    );
  }
}