import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PasswordForm } from "../../../authentication/reset-password/reset-password.component";
import { EntityFormGroup } from "../../types/entity-form-group";

@Component({
  selector: 'app-password-setter',
  templateUrl: './password-setter.component.html',
  styleUrls: [ './password-setter.component.scss' ]
})
export class PasswordSetterComponent {

  @Input() passwordForm!: EntityFormGroup<PasswordForm>;

  @Input() externalCondition = true;

  @Output() setPassword: EventEmitter<void> = new EventEmitter<void>();


  get passwordMatch(): boolean {
    return this.passwordForm.controls.password.value === this.passwordForm.controls.confirmation.value;
  }

  get hasMoreThanEightCharacters(): boolean {
    return String(this.passwordForm.controls.password.value).length >= 8;
  }

  get hasLowerCaseLetter(): boolean {
    return !!String(this.passwordForm.controls.password.value).match(/[a-z]/g)?.length;
  }

  get hasUpperCaseLetter(): boolean {
    return !!String(this.passwordForm.controls.password.value).match(/[A-Z]/g)?.length;
  }

  get hasNumber(): boolean {
    return !!String(this.passwordForm.controls.password.value).match(/[0-9]/g)?.length;
  }

  get passwordWork(): boolean {
    return this.hasLowerCaseLetter && this.hasUpperCaseLetter && this.hasMoreThanEightCharacters && this.hasNumber;
  }

  get isValidated(): boolean {
    return this.passwordWork && this.passwordMatch;
  }

  onSubmit() {
    if (this.isValidated && this.externalCondition) {
      this.setPassword.emit();
    }
  }
}
