<div class="row gap base-padding full-width">
    <div class="half-width result column">
      <div class="row gap result-bars">
        <div class=" column grow" [style]="{ paddingTop: step.marginTop, width: 33 +'%' }"
             *ngFor="let step of result.steps; let index">
          <div class="column gap center-children-vertically">
            <div class="mobile-hidden">{{step.responses}}</div>
            <div class="half-width" [style]="{ height: step.percent * 3 + 'px', backgroundColor: step.color }">
            </div>
          </div>
          <div [style]="{transform: 'translateY(30px)', height: 0, textAlign: 'center'}">{{step.description}}</div>
        </div>
      </div>
  
    </div>
  </div>
  