import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { takeUntil } from "rxjs";

import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { Organization } from "../models/organizations.entity";

@Component({
  selector: 'app-add-units',
  templateUrl: './add-units.component.html',
  styleUrls: [ './add-units.component.scss' ]
})
export class AddUnitsComponent extends DialogComponent<{ units: number | null, organizationId?: string }, {
  units: number | null,
  organizations: Organization[],
  isAdmin?: boolean
}>() implements OnInit, OnDestroy {
  unitForm!: EntityFormGroup<{ units: number | null, organizationName: string }>;

  loading = false;

  existingOrganizationNames: string[] = [];

  organizationChosen?: Organization;

  constructor(
    @Inject(DIALOG_DATA)
    public override readonly data: { units: number | null, organizations: Organization[], isAdmin?: boolean },
    private readonly formBuilder: FormBuilder,
    private readonly ref: DialogRef<{ units: number, organizationId?: string }>,
  ) {
    super(ref);
    this.unitForm = this.formBuilder.group({
      units: new FormControl<number | null>(null, { nonNullable: true }),
      organizationName: new FormControl('', { nonNullable: true })
    });
    this.existingOrganizationNames = data.organizations
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        return 1;
      })
      .map(o => o.nameWithUnits);

    this.unitForm.controls.organizationName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.organizationChosen = data.organizations.find(o => o.nameWithUnits === value);
    });
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get formValidated(): boolean {
    return !!this.unitForm.controls.units.value 
      && ((!this.organizationChosen && this.data.isAdmin)
        || (!!this.organizationChosen && !!this.unitForm.controls.units.value && this.organizationChosen.units >= this.unitForm.controls.units.value));
  }


  validate(isValidated = false): void {
    if (isValidated && this.formValidated) {
      super.close({ units: this.unitForm.controls.units.value, organizationId: this.organizationChosen?.id });
    } else {
      super.close();
    }
  }
}
