import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../config/config";
import { AppConfig } from "../config/config.type";
import { OrganizationTrainMembership } from "../organizations/models/organizations.entity";
import { TypeformFormResponse, TypeformResponseFromApi } from "../typeform/typeform.type";
import { Talker } from "../users/models/talker.entity";

interface EmotionalStateParams {
  startDate?: string;
  endDate?: string;
  organizationIds?: string[];
}

@Injectable()
export class DashboardRepository {
  private readonly apiUrl!: string;

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private readonly http: HttpClient) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return "dashboard";
  }

  getMembersData(): Observable<Talker[]> {
    return this.http.get<Talker[]>([ this.apiUrl, this.getEntityName(), 'members-data' ].join('/')).pipe(map((result) => result.map(t => plainToInstance(Talker, t))));
  }

  getTrainsData(): Observable<OrganizationTrainMembership[]> {
    return this.http.get<OrganizationTrainMembership[]>([ this.apiUrl, this.getEntityName(), 'trains-data' ].join('/')).pipe(map((result) => result.map(t => plainToInstance(OrganizationTrainMembership, t))));
  }

  getTypeformData(): Observable<TypeformResponseFromApi[]> {
    return this.http.get<TypeformFormResponse[]>([ this.apiUrl, this.getEntityName(), 'typeform-data' ].join('/')).pipe(map((result) => result.map(t => plainToInstance(TypeformResponseFromApi, t))));
  }

  getTypeformTalkerData(): Observable<any> {
    return this.http.get<any>([ this.apiUrl, this.getEntityName(), 'typeform-talker-data' ].join('/'));
  }

  getTypeformTalkerDataPerNote(): Observable<any> {
    return this.http.get<any>([ this.apiUrl, this.getEntityName(), 'typeform-talker-data-per-note' ].join('/'));
  }

  getTalkersEmotionalState(params?: EmotionalStateParams): Observable<any> {
    return this.http.post<any>(
      [this.apiUrl, this.getEntityName(), 'talkers-emotional-state'].join('/'),
      params || {}
    );
  }

  getTalkersEmotionalStateProgression(params?: EmotionalStateParams): Observable<any> {
    return this.http.post<any>(
      [this.apiUrl, this.getEntityName(), 'talkers-emotional-state-progression'].join('/'),
      params || {}
    );
  }

  getTalkersStrengthsAndWeakness(params?: EmotionalStateParams): Observable<any> {
    return this.http.post<any>(
      [this.apiUrl, this.getEntityName(), 'talkers-strenghts-weakness'].join('/'),
      params || {}
    );
  }

  getPostSessionFeedbacks(orgIds: string []): Observable<any> {
    return this.http.post<any>([ this.apiUrl, this.getEntityName(), 'post-session-feedbacks' ].join('/'), { organizationIds: orgIds });
  }

  getSherpaPostSessionFeedbacks(): Observable<any> {
    return this.http.get<any>([ this.apiUrl, this.getEntityName(), 'sherpa-post-session-feedbacks' ].join('/'));
  }

  getTalkerLatestEmotionnalResponse(): Observable<any> {
    return this.http.get<any>([ this.apiUrl, this.getEntityName(), 'talker-latest-emotional-response' ].join('/'));
  }
}
