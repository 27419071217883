import { InPageTab } from "../../shared/components/in-page-tabs/in-page-tabs/in-page-tabs.component";

export const tabs: InPageTab[] = [
    {
      icon: "users",
      color: "#8b5eb0",
      title: "Collaborateurs",
      description: "",
      link: "talkers"
    },
    {
      icon: "check-dot",
      color: "#22c997",
      title: "Engagement",
      description: "",
      link: "commit"
    },
    {
      icon: "gauge",
      color: "#E37055",
      title: "Résultats",
      description: "",
      link: "satisfaction"
    },
    {
      icon: "heart",
      color: "#FF8B05",
      title: "Baromètre",
      description: "",
      link: "impact"
    },
  ];