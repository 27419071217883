import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map, Subject, takeUntil } from "rxjs";

import { Role } from "../../users/models/users.entity";

@Component({
    selector: 'app-contents-container',
    templateUrl: './contents-container.component.html',
  }
)

export class ContentsContainerComponent implements OnInit {
    
  private destroy$ = new Subject<void>();

  isAdmin: boolean = false;
  
  constructor(private readonly route: ActivatedRoute, private readonly router: Router) { }

    ngOnInit(): void {
      this.route.data
      .pipe(
          map(data => data.content),
          takeUntil(this.destroy$)
      )
      .subscribe({
          next: (content: any) => {
              this.isAdmin = content.role === Role.Admin;
              if(content.role === Role.Admin){
                this.router.navigate(['admin-contents-list']);
              } else {
                this.router.navigate(['contents-list']);
              }
          }
      });
    }

}
  
  