<h1 class="cooper">Faire la différence entre :</h1>
<div class="row gap margin-top">
  <h2 class="speech-title center-children cooper">Parole en groupe</h2>
  <h2 class="coaching-title center-children cooper">Coaching en groupe</h2>
</div>

<div class="column gap">
  <h3 class="cooper title">C'est quoi ?</h3>
  <div class="row gap">
    <div class="speech">La parole en groupe est un espace dans lequel je peux, en toute confidentialité, libérer mon trop plein d'émotions, me soulager et retrouver du souffle en me libérant par la parole. L'accompagnant est un thérapeuthe qui est dans une posture d’écoute, de bienveillance et d’empathie. Le groupe me permet de me sentir moins seul, être soutenu et enfin écouté.</div>
    <div class="coaching">Le coaching en groupe est un espace dans lequel je peux, en toute confidentialité, avancer sur la thématique de mon choix. Au-delà de me libérer, j’avance et je transforme cette période en opportunité pour atteindre mes objectifs.
      J'ai besoin d’aide pour identifier (ou j’ai déjà identifié) les axes sur lesquels je souhaite travailler. J’aimerais être challengé sur ces sujets. Je veux améliorer mes compétences et mes performances tout en étant plus en alignement avec moi-même. J’intègre une démarche active et délibérée de progrès.
    </div>
  </div>

  <h3 class="cooper title">Pour qui ?</h3>
  <div class="row gap">
    <div class="speech">Je choisis cette dynamique car ma charge émotionnelle est beaucoup trop forte. J’ai du mal à formuler ce qui se passe en moi. Je ne sais pas bien ce que je vais dire, je vis une épreuve. Je ressens le besoin de prendre du temps pour évacuer le bruit dans ma tête pour être soulagé.</div>
    <div class="coaching">Pour toutes les personnes désireuses de se développer, renforcer ses ressources.</div>
  </div>

  <h3 class="cooper title">Comment ça se passe ?</h3>
  <div class="row gap">
    <div class="speech">La parole en groupe est marquée par une alternance entre moment de libération et moment d'écoute. Ces deux moments sont tout aussi importants l'un que l'autre. Ce groupe de parole se base sur une relation de confiance entre les participants du groupe pour que chacun ressente qu'il peut déposer ses difficultés et se libérer.

      La parole en groupe se base véritablement sur une relation sincère et authentique dans le respect de la parole de chacun.
    </div>
    <div class="coaching">Le coach me guide dans cette approche tout au long du parcours. Il me donne le cadre nécessaire pour avancer et atteindre mes objectifs et il m'aide à trouver des solutions adaptées à moi et à ma situation. Le coach m'aide à me libérer de mes croyances limitantes et de mes peurs éventuelles.
      Ses questions sont challengeantes et m’invitent à progresser.
    </div>
  </div>

  <h3 class="cooper title">Bénéfices</h3>
  <div class="row gap">
    <div class="speech">Grâce à cette dynamique d’accompagnement , vous allez retrouver plus d’énergie, retrouver un équilibre, évacuer le bruit qui prend de la place dans votre esprit. Vous vous sentirez mieux dans votre tête, dans corps et votre cœur. Vous renouez avec de l’engagement et l’envie d’aller de l’avant.</div>
    <div class="coaching">Grâce à cette dynamique d’accompagnement vous allez vous sentir de plus en plus aligné avec vous-même et en énergie pour avancer sur vos projets pro ou perso. Votre volonté sera plus ancrée et plus forte pour atteindre vos objectifs.</div>
  </div>


  <h3 class="cooper title">Témoignages des participants</h3>
  <div class="row gap">
    <div class="speech">Le format est très instructif, challengeant et permet de comprendre le chemin à construire pour atteindre notre objectif

      Incroyable de bienveillance, d'authenticité et d'apport que ces RDV !!!

      Les deux choses qui ont fait de mon expérience un succès sont : le cadre proposé et la bonne volonté des participants de s'ouvrir. Merci

      Le témoignage des autres permet de s'acquérir de leur vécu et d'apprendre à faire les choses autrement. Sabrina fût très à l'écoute et nous a donné pas mal de matières et de direction afin de gérer au mieux notre stress et nos émotions.

      J'ai  été accompagnée par une superbe coach. La formation va me permettre de m' épanouir dans mon quotidien, de lâcher prise.
    </div>
    <div class="coaching">C'était très pro, intéressant, constructif. J'ai été impressionné d'avoir autant appris juste en écoutant les autres situations et accompagnements. Cette posture de "non co-développement" a été pour moi très riche. Très belle expérience, merci.


      Merci, j'ai réalisé plein de choses grâce à We Talk et j'ai compris le QUOI de ce qui m'anime à moi de trouver le COMMENT !

      Un grand merci j'avais une problématique qui me semblait assez lourde et qui l'était d'ailleurs...Le coaching m'a permis de dépasser cela très vite

      Merci c'était une superbe expérience !
    </div>
  </div>


</div>
