<div class="center-children">
  <app-input class="pointer" [margin-bottom]="false" [dropdownTriggerFor]="dropdown" type="dropdown" dropdownPosition="start" [readonly]="true"
             [inputField]="resumeFormControl" [isCheckmarks]="true"></app-input>
</div>
<app-dropdown [closeOnClick]="false" #dropdown class="dropdown-wrapper">
  <div class="checkbox margin-left">
    <app-checkbox class="option" (ngModelChange)="selectAll($event)" [ngModel]="allSelected" >{{ isFemaleWord ? 'Toutes' : 'Tous' }}</app-checkbox>
  </div>
  <hr />
  <div class="checkbox" *ngFor="let tree of treesState; let index = index; trackBy: treeCheckboxTrackBy">
    <app-tree-checkbox (update)="updateTree($event)" [tree]="tree"></app-tree-checkbox>
  </div>
</app-dropdown>
