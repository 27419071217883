import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";

import { StorageService } from "../../shared/storage/storage.service";

@Injectable()
export class LoginGuardService  {
  constructor(private readonly storageService: StorageService, private readonly router: Router) {
  }

  canActivate(): boolean | UrlTree {
    if (this.storageService.getAccessToken() !== '') {
      return this.router.createUrlTree([ '' ]);
    }

    return true;
  }
}
