import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { BehaviorSubject, scan, skip, Subject, takeUntil } from "rxjs";

export type ColumnConfig<PropertyType> = {
  displayName: string,
  transformFunction?: (element: PropertyType, item?: any) => string,
  style?: string,
  styleFunction?: (element: PropertyType) => string,
  sortable?: boolean;
}

export type TableConfig<EntityType> = {
  [key in keyof EntityType]?: ColumnConfig<EntityType[key]>
}


export type SortOrder<Entity> = {
  sort: keyof Entity & string;
  order: 'ASC' | 'DESC';
}


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: [ './table.component.scss' ]
})
export class TableComponent<Entity extends { id: string }, UniqueKey extends keyof Entity>
  implements OnInit, OnChanges, OnDestroy {
  @Input() tableConfig!: TableConfig<Entity>;

  @Input() items: Entity[] = [];

  @Input() showCheckbox = true;

  @Output() select = new EventEmitter<Entity[UniqueKey][]>();

  @Output() sort = new EventEmitter<SortOrder<Entity> | undefined>();

  @Output() goTo = new EventEmitter<Entity[UniqueKey]>();

  @Input('unique-key') uniqueKey: UniqueKey = 'id' as UniqueKey;

  changes$ = new Subject<void>();

  destroy$ = new Subject<void>();

  checkboxPressed$ = new Subject<number>();

  selectionCheckboxes: { id: Entity[UniqueKey], form: FormControl<boolean> }[] = [];

  allSelectForm = new FormControl<boolean>(false, { nonNullable: true });

  shiftKeyIsDown = false;

  lastSortOption$: BehaviorSubject<SortOrder<Entity> | undefined> = new BehaviorSubject<SortOrder<Entity> | undefined>(undefined);

  ngOnInit() {
    this.lastSortOption$
      .pipe(
        skip(1),
        takeUntil(this.destroy$))
      .subscribe(sortOptionChosen => {
        this.sort.emit(sortOptionChosen);
      });
  }

  ngOnChanges() {
    this.changes$.next();

    if (this.select.observed) {
      this.selectionCheckboxes = this.items.map(item => ({
        id: item[this.uniqueKey],
        form: new FormControl<boolean>(false, { nonNullable: true })
      }));

      this.selectionCheckboxes.forEach((checkbox, index) => {
        checkbox.form.valueChanges
          .pipe(takeUntil(this.changes$))
          .subscribe(() => {
            this.checkboxPressed$.next(index);
          });
      });


      this.checkboxPressed$
        .pipe(
          scan((lastIndexPressed, indexPressed) => {
            if (this.shiftKeyIsDown) {
              const isSelected = this.selectionCheckboxes[indexPressed].form.value;

              this.selectionCheckboxes.forEach((checkbox, index) => {
                if ((index <= indexPressed && index >= lastIndexPressed)
                  || (index >= indexPressed && index <= lastIndexPressed)) {
                  checkbox.form.setValue(isSelected, { emitEvent: false });
                }
              });
            }

            return indexPressed;
          }),
          takeUntil(this.changes$))
        .subscribe(() => {
          const idsSelected = this.selectionCheckboxes.map(checkbox => checkbox.form.value)
            .map((isSelected, index) => (isSelected ? this.items[index][this.uniqueKey] : undefined))
            .filter((element): element is Entity[UniqueKey] => !!element);

          if (idsSelected.length && idsSelected.length === this.items.length) {
            this.allSelectForm.setValue(true, { emitEvent: false });
          } else {
            this.allSelectForm.setValue(false, { emitEvent: false });
          }
          this.select.emit(idsSelected);
        });

      this.allSelectForm.valueChanges
        .pipe(takeUntil(this.changes$))
        .subscribe((isSelected) => {
          this.selectionCheckboxes.forEach(checkbox => {
            checkbox.form.setValue(isSelected, { emitEvent: false });
          });
          this.select.emit(isSelected ? this.items.map(item => item[this.uniqueKey]) : []);

        });
    }
  }

  ngOnDestroy() {
    this.changes$.next();
    this.destroy$.next();
  }

  goToRow(row: Entity) {
    this.goTo.emit(row[this.uniqueKey]);
  }

  getColumnName(): (keyof Entity & string)[] {
    return Object.keys(this.tableConfig) as (keyof Entity & string)[];
  }

  @HostListener('keydown.shift')
  shiftKeyDown() {
    this.shiftKeyIsDown = true;
  }

  @HostListener('keyup.shift')
  shiftKeyUp() {
    this.shiftKeyIsDown = false;
  }

  sortColumn(column: keyof Entity & string) {
    if (!this.tableConfig[column]?.sortable) {
      return;
    }

    const isSameKey = this.lastSortOption$.value?.sort === column;
    if (!isSameKey) {
      this.lastSortOption$.next({
        sort: column,
        order: 'ASC'
      });
      return;
    }
    if (this.lastSortOption$.value?.order === 'ASC') {
      this.lastSortOption$.next({
        sort: column,
        order: 'DESC'
      });
      return;
    }

    this.lastSortOption$.next(undefined);
  }

  getColumnConfig<T extends keyof Entity>(column: T): ColumnConfig<Entity[T]> {
    return this.tableConfig[column] as ColumnConfig<Entity[T]>;
  }

  getItemValue<T extends keyof Entity>(item: Entity, property: T): Entity[T] {
    return item[property];
  }

}
