import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-editable-list',
  templateUrl: './editable-list.component.html'
})
export class EditableListComponent<T> {

  @Input() items: T[] = [];

  @ContentChild('listItem', { static: false }) contentTemplateRef!: TemplateRef<any>;

  @Output() remove = new EventEmitter<number>();

  @Output() add = new EventEmitter<void>();

  addItem(): void {
    this.add.emit();
  }

  removeItem(index: number): void {
    this.remove.emit(index);
  }
}
