<div class="trains-by-thematics column no-gap">
  <div class="header row">
    <div class="image-slot">
      <app-image  width="248" height="192" [src]="trainsByThematics.thematic.imageUrl" [alt]="trainsByThematics.thematic.slug" />
    </div>
    <div class="column grow">
      <div class="space-between row">
        <p class="thematic-name" [ngStyle]="{'background-color': colorMedium }"><strong>{{ trainsByThematics.thematic.name }}</strong></p>
        <div class="base-padding">
          <app-favorite-thematic [thematic]="trainsByThematics.thematic"></app-favorite-thematic>
        </div>
      </div>
      <div class="header-description column no-gap">
        <h3 class="cooper margin-bottom">{{trainsByThematics.thematic.title}}</h3>
        <div class="text-ellipsis row gap mobile-hidden">
          <div class="grow">
            <p class="thematic-resume text-ellipsis overflow-hidden">{{trainsByThematics.thematic.resume}}</p>
          </div>
          <div class="shrink discover-button">
            <app-button [outline]="true" icon="arrow-right" (click)="goToThematic()">Découvrir</app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let train of trainsByThematics.trains" class="row gap train center-children-vertically text-small">
    <div class="grow-desktop row gap center-children-vertically half-width-mobile">
      <div class="mobile-column space-between grow center-children">
        <strong class="train-flex">Lancement le {{dateFormat(train.getFirstSession().date, { full: true })}} à {{timeFormat(train.timeSlot)}}</strong>
        <div class="center-children train-flex">
          <span >{{train.dynamic.dynamicType}}</span>
        </div>
        <div class="train-flex mobile-hidden">
          <div class="sherpa row pointer center-children-vertically" (click)="goToSherpa(train.sherpa)" *ngIf="train.sherpa">
            <app-profile-icon class="tiny-margin-right" [profile]="train.sherpa" [tiny]="true" [editable]="false"></app-profile-icon>
            <span>avec <u>{{train.sherpa.fullName}}</u></span>
          </div>
          <div *ngIf="!train.sherpa"> </div>
        </div>
      </div>
    </div>
    <app-button class="grow-mobile" style="justify-content: flex-end; white-space: nowrap" (click)="selectTrain(train)">S'inscrire</app-button>
  </div>
  <div class="space-between wrap center-children-vertically base-padding">
    <span>{{ trainsByThematics.trains.length > 1 ? 'Ces dates ne vous conviennent pas ?' : 'Cette date ne vous convient pas ?' }}</span>
    <app-button [outline]="true" icon="arrow-right" (click)="goToCreateTrain()">Lancer un nouveau parcours</app-button>
  </div>

</div>
