import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs";

import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { CreateTrainService } from "../create-train.service";
import { Thematic } from "../models/thematic.model";


@Component({
  selector: 'app-select-dynamic',
  templateUrl: './select-dynamic.component.html',
})
export class SelectDynamicComponent extends AbstractInFunnelComponent {

  thematic!: Thematic;

  constructor(
    protected override readonly funnelService: FunnelService,
    protected override readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly createTrainService: CreateTrainService) {
    super(funnelService, route);
  }

  override ngOnInit() {
    this.createTrainService.thematic$
      .pipe(takeUntil(this.destroy$))
      .subscribe(thematic => {
        if (!thematic) {
          this.router.navigate([ 'thematics' ]);
        } else {
          this.thematic = thematic;
          super.ngOnInit();

        }

      });
  }
}
