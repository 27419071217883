function GCD(numberA: number, numberB: number): number {
  if (numberA === 0)
    return numberB;
  return GCD(numberB % numberA, numberA);
}

export function findGCD(numbers: number[]): number {
  let result: number = numbers[0];
  numbers.forEach(number => {
    result = GCD(number, result);
  });

  return result;
}
