import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs";

import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { TrainByThematic } from "../../trains/available-trains/available-trains.component";
import { TrainRepository } from "../../trains/repositories/train.repository";
import { CreateTrainService } from "../create-train.service";
import { Dynamic } from "../models/dynamic.model";
import { Thematic } from "../models/thematic.model";

@Component({
  selector: 'app-select-train',
  templateUrl: './select-train.component.html',
  styleUrls: [ './select-train.component.scss' ]
})
export class SelectTrainComponent extends AbstractInFunnelComponent {
  thematic?: Thematic;

  dynamic?: Dynamic;

  trainsByThematic!: TrainByThematic;

  loading = true;

  constructor(protected override readonly funnelService: FunnelService,
              protected override readonly route: ActivatedRoute,
              private readonly createTrainService: CreateTrainService,
              private readonly trainService: TrainRepository) {
    super(funnelService, route);
  }


  override ngOnInit() {
    this.createTrainService.thematic$
      .pipe(takeUntil(this.destroy$))
      .subscribe(thematic => {
        if (thematic) {
          this.trainService.getAvailableTrains()
            .pipe(takeUntil(this.destroy$))
            .subscribe(trains => {
              this.trainsByThematic = {
                thematic,
                trains: trains.filter(t => t.dynamic.thematic.id === thematic.id)
              };
              this.loading = false;
            });
        }
      });


  }


}
