import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { takeUntil } from "rxjs";

import { OrganizationRepository } from "../../organizations/repositories/organization.repository";
import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { Talker } from "../models/talker.entity";
import { CreateUserOptions } from "../models/users.entity";
import { NewUserComponent } from "../new-user/new-user.component";


export type PilotAdd = {
  type: 'add';
  data: { id: string; }
}

export type PilotCreation = {
  type: 'creation';
  data: CreateUserOptions;
}

@Component({
  selector: 'app-new-pilot',
  templateUrl: './new-pilot.component.html',
  styleUrls: [ './new-pilot.component.scss' ]
})
export class NewPilotComponent extends DialogComponent<PilotAdd | PilotCreation, Talker[]>() implements OnInit, OnDestroy {

  newPilotForm!: EntityFormGroup<{ name: string }>;

  existingTalkers: Talker[] = [];

  existingTalkerNames: string[] = [];

  talkerChosen?: Talker;

  newPilot?: CreateUserOptions;


  constructor(
    @Inject(DIALOG_DATA)
    private readonly existingPilots: Talker[],
    private readonly ref: DialogRef<PilotAdd | PilotCreation>,
    private readonly formBuilder: FormBuilder,
    private readonly dialog: DialogService,
    private readonly organizationRepository: OrganizationRepository) {
    super(ref);
    this.newPilotForm = this.formBuilder.nonNullable.group({
      name: new FormControl('', { nonNullable: true })
    });
  }

  ngOnInit() {
    super.onInit();
    this.organizationRepository.findAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe(organizations => {
        this.existingTalkers = organizations
          .flatMap(organization => organization.members)
          .filter(talker => !!talker.personalInformation)
          .filter(member => !this.existingPilots.map(pilot => pilot.id).includes(member.id));
        this.existingTalkerNames = this.existingTalkers.map(talker => talker.fullName);
      });

    this.newPilotForm.controls.name.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(pilotName => {
          this.talkerChosen = this.existingTalkers.find(talker => talker.fullName === pilotName);
        }
      );
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get formIsValid(): boolean {
    return !!this.talkerChosen || !!this.newPilot;
  }

  createPilot(): void {
    this.dialog.open(NewUserComponent, 'pilot')
      .pipe(takeUntil(this.destroy$))
      .subscribe(newPilot => {
        if (newPilot) {
          this.newPilot = newPilot;
          this.newPilotForm.controls.name.setValue(`${ capitalize(newPilot.firstName) } ${ newPilot.lastName.toUpperCase() }`);
        }
      });
  }

  validate(validated = false): void {
    if (validated) {
      if (this.newPilot) {
        super.close({
          type: 'creation',
          data: this.newPilot
        });
      }
      if (this.talkerChosen) {
        super.close({
          type: 'add',
          data: { id: this.talkerChosen.id }
        });
      }
    } else {
      super.close();
    }
  }


}
