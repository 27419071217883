import { Component } from '@angular/core';

import { TestimonialQuoteType } from "../testimonial-quote.type";

@Component({
  selector: 'app-sherpa-testimonial',
  templateUrl: './sherpa-testimonial.component.html'
})
export class SherpaTestimonialComponent {

  testimonials: TestimonialQuoteType[] = [
    {
    content: '““Très enrichissant dans le contenu, simplicité d\'échange avec la sherpa, adaptation à mon contexte, aucun jugement.”',
    author: 'N',
    color: '#FF8B05'
  },  {
    content: '“J’ai mis des mots sur mes ressentis, les questions posées m’ont ouvert à plus de réflexions.”',
    author: 'S',
    color: '#8364ab'
  }, {
    content: '“C\'était une séance très enrichissante par le partage avec l\'autre personne qui a eu des expériences de vie similaires. Cela a permis de mettre le doigt sur des éléments de ma vie que j\'avais oubliés.”',
    author: 'M',
    color: '#bdabd4'
  },{
    content: '“J’ai mis des mots sur mes ressentis, les questions posées m’ont ouvert à plus de réflexions.”',
    author: 'V',
    color: '#ddc8d0'
  }
  ];

}
