import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { BehaviorSubject } from "rxjs";

import { TrainMembership } from "../../../trains/models/membership.entity";
import { Train } from "../../../trains/models/train.entity";
import { DateIntervalFilterOption, DEFAULT_FONT } from "../../utils/utils";
import { AbstractChartComponent } from "../abstract.chart.component";

@Component({
  selector: 'app-train-filling',
  templateUrl: './train-filling.component.html'
})
export class TrainFillingComponent extends AbstractChartComponent<'doughnut'> implements OnInit, AfterViewInit, OnChanges {

  type: 'doughnut' = 'doughnut';

  chartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            family: DEFAULT_FONT
          }
        }
      },
      title: {
        display: true,
        font: {
          family: DEFAULT_FONT
        },
        text: 'Remplissage des parcours'
      }
    }
  };

  @Input() dateIntervalSelection$!: BehaviorSubject<DateIntervalFilterOption>;

  @Input() trainMemberships!: TrainMembership[];

  @Input() trains!: Train[];


  ngOnInit(): void {
    this.onInit([ this.dateIntervalSelection$ ]);
  }

  ngAfterViewInit(): void {
    this.chartData.labels = [ '1 membre', '2 membres', '3 membres', '4 membres' ];
    this.chart?.update();
  }


  ngOnChanges() {
    super.onChanges();
  }

  updateData() {


    this.chartData.datasets = [
      {
        label: 'Parcours',
        data: [
          this.trains.filter(train => this.trainMemberships.filter(trainMembership => trainMembership.train.id === train.id).length === 1).length,
          this.trains.filter(train => this.trainMemberships.filter(trainMembership => trainMembership.train.id === train.id).length === 2).length,
          this.trains.filter(train => this.trainMemberships.filter(trainMembership => trainMembership.train.id === train.id).length === 3).length,
          this.trains.filter(train => this.trainMemberships.filter(trainMembership => trainMembership.train.id === train.id).length === 4).length
        ],
        backgroundColor: this.getDefaultColors().slice(0, 4)
      }

    ];
  }
}
