import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError, filter, finalize, Observable, of, switchMap, throwError } from "rxjs";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { StorageService } from "../../shared/storage/storage.service";

import { AuthenticationService } from "./authentication.service";


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  refreshSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly apiUrl: string;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly storageService: StorageService,
    private readonly router: Router,
    private readonly snackBarService: SnackbarService,
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  private addTokenToRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({ headers: req.headers.set('Authorization', `Bearer ${ this.storageService.getAccessToken() }`) });
  }

  private handleError(req: HttpRequest<any>, error: HttpErrorResponse, next: HttpHandler): Observable<any> {
    if (error.status === 0) {
      // Offline
      this.snackBarService.pushMessage('Vous êtes hors-ligne', 'error');
      return of(null);
    }

    if (error.status >= 500 || error.status === 403 || error.status === 400) {
      this.snackBarService.pushMessage("Une erreur s'est produite", 'error');
      return throwError(() => error);
    }

    if (error.status === 401) {
      if (!this.refreshSubject$.getValue()) {
        this.refreshSubject$.next(true);

        return this.authenticationService.refreshToken().pipe(
          switchMap(() => {
            if (this.storageService.getAccessToken() !== '') {
              return next.handle(this.addTokenToRequest(req));
            }
            this.logout();

            return of(null);

          }),
          catchError(err => {
            this.logout();

            return throwError(() => err);
          }),
          finalize(() => {
            this.refreshSubject$.next(false);
          })
        );
      }
      return this.refreshSubject$.pipe(filter(isRefreshing => !isRefreshing), switchMap(() => next.handle(this.addTokenToRequest(req))));
    }

    return throwError(() => error);
  }

  private logout(): void {
    try {
      this.authenticationService.logout();
    } catch {
      this.snackBarService.pushMessage("Vous n'êtes pas connecté", 'error');
    } finally {
      this.storageService.logout();
      this.router.navigate([ 'auth', 'login' ], { queryParamsHandling: 'preserve' });
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // You don't have to override if
    // It's not http request to our API
    if (!req.url.startsWith(this.apiUrl)) {
      return next.handle(req);
    }

    return next.handle(this.addTokenToRequest(req)).pipe(catchError(error => this.handleError(req, error, next)));
  }
}
