<div class="audio-player">
    <div class="progress-bar">
      <input type="range" min="0" [max]="audioElement?.nativeElement?.duration" [value]="currentTime" (input)="seekAudio($event)">
    </div>
    <audio #audio (timeupdate)="updateProgress()" (volumechange)="updateVolumeIcon()">
      <source [src]="audioSrc" type="audio/mpeg">
      Your browser does not support the audio element.
    </audio>
    <div class="audio-controls row space-between">
        <div class="control-group row gap">
            <div class="button-container-variant">
                <app-icon color="#FFFFFF" size="20" type="backward" (click)="skipBackwardQuarter()"></app-icon>
            </div>
            <div class="button-container-variant">
                <app-icon color="#FFFFFF" size="30" type="previous" (click)="skipBackward()"></app-icon>
            </div>
            <div class="button-container">
                <app-icon color="#000000" size="30" [type]="isPlaying ? 'pause': 'play-filled'" (click)="playAudio()"></app-icon>
            </div>
            <div class="button-container-variant">
                <app-icon color="#FFFFFF" size="30" type="next" (click)="skipForward()"></app-icon>
            </div>
            <div class="button-container-variant">
                <app-icon color="#FFFFFF" size="20" type="forward" (click)="skipForwardQuarter()"></app-icon>
            </div>
        </div>
        <div class="control-group row gap">
            <div>
                <select id="speed" class="dropdown" (change)="changePlaybackSpeed($event)">
                    <option value="0.5">x0.5</option>
                    <option value="1" selected>x1</option>
                    <option value="1.5">x1.5</option>
                    <option value="2">x2</option>
                </select>
            </div> 
            <app-icon color="#FFFFFF" size="30" [type]="isMuted ? 'speaker-muted' : 'speaker'" (click)="toggleMute()"></app-icon>
        </div>
    </div>
  </div>