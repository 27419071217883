import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, combineLatestWith, filter, Subject, take, takeUntil } from "rxjs";
import { map } from "rxjs/operators";

import { ConfigurationRepository } from "../../admin/repositories/configuration.repository";
import { DateDeltaConstantType } from "../../admin/types/constant";
import { MeetInstance } from "../../meet/meet.type";
import { OrganizationRepository } from '../../organizations/repositories/organization.repository';
import { ConfirmComponent } from "../../shared/components/confirm/confirm.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { FunnelTrainService } from '../../shared/services/funnel-train.storage.service';
import { FunnelService } from "../../shared/services/funnel.service";
import { addDelayToDate, formatDate, formatTime } from "../../shared/tool-functions/date-tools";
import { stringNumber } from "../../shared/tool-functions/string-number";
import { mediumCategoryColors } from "../../thematics/colors";
import { CreateTrainService } from '../../thematics/create-train.service';
import { ThematicCategorySlug } from "../../thematics/models/category.model";
import { Thematic } from "../../thematics/models/thematic.model";
import { Role } from "../../users/models/users.entity";
import { ProfileService } from "../../users/services/profile.service";
import { Train } from "../models/train.entity";
import { ModifySessionDateComponent } from "../modify-session-date/modify-session-date.component";
import { ModifySherpaComponent } from "../modify-sherpa/modify-sherpa.component";
import { TrainRepository } from "../repositories/train.repository";

@Component({
  selector: 'app-train-details',
  templateUrl: './train-details.component.html',
  styleUrls: [ './train-details.component.scss' ]
})
export class TrainDetailsComponent extends AbstractInFunnelComponent implements AfterViewInit, OnDestroy {

  public train!: Train;

  public thematic!: Thematic;

  workingOnBulletPoints: string[] = [];

  userId?: string;

  isSherpaOrAdminWatching = false;

  override isFunnelFirstStep = true;

  categorySlug?: ThematicCategorySlug;

  colorMedium!: string;

  public api?: MeetInstance;

  public canLeave!: boolean;

  public canSeeTalker = false;

  public isSherpaWatching = false;

  public isSherpa = false;

  public isAdmin = false;

  public talkerCanJoinTrain = false;

  private canSeePageSubject = new BehaviorSubject<boolean>(true);

  canSeePage$ = this.canSeePageSubject.asObservable();

  private afterViewInit$ = new Subject<void>();

  destroys$ = new Subject<void>();

  constructor(
    protected override readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly trainRepository: TrainRepository,
    private readonly configurationRepository: ConfigurationRepository,
    private readonly dialog: DialogService,
    private readonly snackBarService: SnackbarService,
    private readonly profileService: ProfileService,
    protected override readonly funnelService: FunnelService,
    private readonly funnelTrainService: FunnelTrainService,
    private readonly organizationRepository: OrganizationRepository,
    private readonly creatTrainService: CreateTrainService,
  ) {
    super(funnelService, route);
  }

  override ngOnInit(): void {
    this.canSeePage$.subscribe(value => {
      this.funnelService.canValidate$.next(value);
    });

    this.route.data
      .pipe(
        map(data => data.train),
        filter(train => train instanceof Train),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (train: Train) => {
          this.train = train;
          this.thematic = train.dynamic.thematic;
          this.thematic.dynamics = [ train.dynamic ];
          this.previousMessage = 'Retour';
          this.profileService.getMyProfile().subscribe(profile => {
            const canSeePage = profile.role === Role.Talker ? !!profile.organization.subscriptionPlan : true;
            this.canSeePageSubject.next(canSeePage);
            this.userId = profile.id;
            this.isSherpa = profile.role === Role.Sherpa;
            this.isAdmin = profile.role === Role.Admin;
            this.isSherpaOrAdminWatching = profile.role !== Role.Talker;
            this.funnelTrainService.state$.next({ train, user: profile, isTrainDetailsPage: true });
            const isMember = train.members.some(member => member.id === profile.id);
            if(!isMember){
              if(this.isSherpaOrAdminWatching){
                this.funnelService.validateMessage$.next("Accéder à la séance");
                this.funnelService.isPurple$.next(true);
              } else {
                this.trainRepository.getTrainJoiningPermission(this.train.id).subscribe(canJoin => {
                  if(canJoin) {
                    this.funnelService.validateMessage$.next("S’inscrire");
                    this.talkerCanJoinTrain = true;
                  } 
                });
              }
            } else {
              this.funnelService.validateMessage$.next("Accéder à la séance");
              this.funnelService.isPurple$.next(true);
              this.talkerCanJoinTrain = true;
            }
          });
          super.ngOnInit();

          setTimeout(() => {
            if (this.train.getNextSession()) {
              if(!this.train.sherpa || this.train.sherpa?.id === this.profileService.profile?.id || this.isAdmin || this.profileService.profile?.role === Role.Talker) {
                if(this.profileService.profile?.role === Role.Talker){
                  this.funnelService.canValidate$.next(this.talkerCanJoinTrain);
                } else {
                  this.funnelService.canValidate$.next(true);
                }
              } else if(this.isSherpa)
                this.funnelTrainService.showTakenTrainMessage$.next(true);
            }
          }, 1000);

          this.funnelService.validate$.pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              if(this.profileService.profile?.role === Role.Talker){
                if(this.train.members.some(member => member.id === this.profileService.profile?.id)){
                  this.joinTrainMeeting();
                } else {
                  this.organizationRepository.canConsumeUnit()
                  .pipe(takeUntil(this.destroys$))
                  .subscribe(can => {
                    if (can.unitAvailable && can.quota) {
                      this.creatTrainService.selectTrain(train);
                    } else {
                      this.snackBarService.pushMessage(!can.unitAvailable ? "Votre entreprise n'a plus d'unité disponible" : "Vous avez consommé le quota d'unités qui vous a été accordé par votre entreprise.", "error");
                    }
                  });
                }
              } else {
                this.joinTrainMeeting();
              }
            });

          this.categorySlug = this.thematic.category?.slug;
          this.colorMedium = this.categorySlug ? mediumCategoryColors[this.categorySlug] : '';
          this.workingOnBulletPoints = this.thematic.workingOn.split('\n');

          this.route.queryParams.pipe(combineLatestWith(this.afterViewInit$), take(1), takeUntil(this.destroy$)).subscribe(([ params, _ ]) => {
            if (params.join) {
              this.joinTrainMeeting();
            }
          });

          this.configurationRepository.getDateDeltaConstant(DateDeltaConstantType.SHERPA_MINIMUM_DELAY_TO_LEAVE_TRAIN).subscribe(
            ({ value }) => {
              this.canLeave = addDelayToDate(`-${ value }`, this.train.getFirstSession().date)?.getTime() > (new Date())?.getTime();
            }
          );

          const rolesAllowedToSeeTalkerDetails: Role[] = [ Role.Sherpa, Role.Admin ];

          if (this.profileService.profile) {
            this.canSeeTalker = rolesAllowedToSeeTalkerDetails.includes(this.profileService.profile?.role);
            this.isSherpaWatching = this.profileService.profile?.id === this.train.sherpa?.id;
          } else {
            this.profileService.getMyProfile().subscribe(state => {
              this.canSeeTalker = rolesAllowedToSeeTalkerDetails.includes(state.role);
            });
          }
        }
      });


  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.destroys$.next();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.afterViewInit$.next();
  }


  joinTrainMeeting() {
    this.router.navigate([ 'meet', this.train.id ]);

  }


  leaveTrain(): void {
    this.dialog.open(ConfirmComponent, { title: 'Voulez-vous vraiment quitter ce parcours ?' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(isValidated => {
        if (isValidated) {
          this.trainRepository.leaveTrain(this.train.id).subscribe(() => {
            this.router.navigate([ 'home' ]);
          });
        }
      });
  }

  goToSherpa() {
    if (this.train.sherpa && !this.isSherpaWatching && !this.train.sherpa.isDeleted) {
      this.router.navigate([ 'sherpas', this.train.sherpa.id ]);
    }
  }

  goToTalkerDetails(userId: string) {
    if (this.canSeeTalker && this.train.members.find(member => member.id === userId)?.isDeleted === false) {
      this.router.navigate([ 'talkers', userId ]);
    }
  }

  modifySherpa() {
    this.dialog.open(ModifySherpaComponent, this.train.sherpa)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.sherpa?.id) {
          this.trainRepository.modifySherpa(this.train.id, result.sherpa.id).subscribe(() => {
            this.snackBarService.pushMessage('Modification enregistrée', 'success');
            this.train.sherpa = result.sherpa ?? undefined;
          });
        }
        if (result?.sherpa === null) {
          this.trainRepository.removeSherpa(this.train.id).subscribe(() => {
            this.snackBarService.pushMessage('Modification enregistrée', 'success');
            this.train.sherpa = undefined;
          });
        }
      });
  }


  modifySessionDate(sessionIndex: number) {
    if (!this.isSherpaOrAdminWatching) {
      return;
    }
    const session = this.train.sessions[sessionIndex];
    this.dialog.open(ModifySessionDateComponent, {
      index: sessionIndex + 1,
      date: `${ stringNumber(session.date.getFullYear()) }-${ stringNumber(session.date.getMonth() + 1) }-${ stringNumber(session.date.getDate()) }`,
      time: `${ stringNumber(session.date.getHours()) }:${ stringNumber(session.date.getMinutes()) }`,
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.date) {
          const dateChosen = new Date(`${ result.date } ${ result.time }`);
          this.trainRepository.updateSessionDate(this.train.id, session.id, {
            date: dateChosen.getDate(),
            month: dateChosen.getMonth(),
            year: dateChosen.getFullYear(),
            hours: dateChosen.getHours(),
            minutes: dateChosen.getMinutes()
          }).subscribe(() => {
            this.snackBarService.pushMessage('Modification enregistrée', 'success');
            this.train.sessions[sessionIndex].dateString = dateChosen.toISOString();
          });
        }
      });
  }

  protected readonly formatDate = formatDate;

  protected readonly formatTime = formatTime;
}
