<div class="cards-list" #list>
  <div *ngIf="currentItems.length">
    <div
      class="card-item"
      [class.enter]="item.entering"
      [class.leave]="item.leaving"
      *ngFor="let item of currentItems; let index = index; trackBy: cardTrackBy"
      (scroll)="onScrollEvent()"
    >
      <ng-container
        *ngIf="contentTemplateRef"
        [ngTemplateOutlet]="contentTemplateRef"
        [ngTemplateOutletContext]="{$implicit:item.data}"
      >
      </ng-container>
    </div>
  </div>
  <div *ngIf="!currentItems.length && !isLoading">
    Aucun résultat
  </div>
  <div #bottomTag></div>
  <div class="loading-spinner" *ngIf="isLoading">
    <app-spinner></app-spinner>
  </div>
</div>
