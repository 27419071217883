import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { filter, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";

import { AuthenticationService } from "../../authentication/services/authentication.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Admin } from "../models/admin.entity";
import { GenderDescription, RoleDescription } from "../models/users.entity";

@Component({
  selector: 'app-user-details',
  templateUrl: './admin-details.component.html',
  styleUrls: [ './admin-details.component.scss' ]
})
export class AdminDetailsComponent implements OnInit, OnDestroy {

  user!: Admin;

  loading = true;

  private destroy$ = new Subject<void>();

  constructor(private readonly route: ActivatedRoute,
              private readonly authService: AuthenticationService,
              private readonly snackBarService: SnackbarService) {

  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        map(data => data.user),
        filter(user => user instanceof Admin),
        tap((user: Admin) => {
          this.user = user;
        }),
      ).subscribe(() => {
      this.loading = false;
    });
  }

  sendActivationMail() {
    this.authService.sendActivationEmail(this.user.email).subscribe(
      () => {
        this.snackBarService.pushMessage('Email d\'inscription envoyé', 'success');
      }
    );
  }


  get role(): string {
    return capitalize(RoleDescription[this.user.role]);
  }

  get gender(): string {
    return capitalize(GenderDescription[this.user.gender]);
  }
}
