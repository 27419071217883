<div class="thematics-wrapper " [class.hidden]="loading">
  <div [class.fading-section-list]="loading" [class.fade-left-reverse]="leavingFunnel"
       [class.fade-left]="enteringFunnel">

    <app-notification *ngIf="quotaExceeded" class="margin-bottom" icon="danger" color="#b65a44"
                      [buttonMessage]="alreadyAskForMoreQuota ? undefined : 'Demander plus de quota'"
                      (onButtonClick)="askForMoreQuota()"
                      [checkMessage]="alreadyAskForMoreQuota ? 'Vous avez fait une demande de quota' : undefined">
      Vous avez consommé le quota de crédits qui vous a été accordé par votre entreprise.
    </app-notification>
    <app-notification *ngIf="!quotaExceeded && noUnitAvailable" class="margin-bottom" icon="danger" color="#b65a44"
                      [buttonMessage]="alreadyAskForMoreUnits ? undefined : 'Notifier votre responsable'"
                      (onButtonClick)="askForMoreUnits()"
                      [checkMessage]="alreadyAskForMoreUnits ? 'Vous avez fait une demande de crédits' : undefined">
      Votre entreprise n'a plus de crédits disponibles, veuillez contacter vos administrateurs ou les équipes WeTalk pour
      en savoir plus.
    </app-notification>
    <div>
      <h1 class="margin-bottom">Les thématiques les plus suivies</h1>
      <div class="most-followed-thematics">
        <div class="thematic"
             *ngFor="let thematicOrdered of thematicsOrdered">
          <app-thematic-card  
            matomoCategory="thematicsTalker"
            matomoAction="Click"
            matomoName="mostpopularThematic" 
            matomoTracker="click"
            [tiny]="true" 
            [thematic]="thematicOrdered"
            (click)="clickOnThematic(thematicOrdered)">
          </app-thematic-card>
        </div>
      </div>
    </div>


    <div>
      <div class="margin-bottom margin-top center-children-vertically row gap"><h1>Découvrez nos thématiques</h1>
        <app-icon class="text-large" type="add" *ngxPermissionsOnly="'admin'" button
                  title="Ajouter une thématique" (click)="addThematic()"></app-icon>
      </div>

      <div class="wrap">
        <div
          (click)="onSelectCategoryClick()"
          class="bubble pointer margin-bottom row gap center-children" 
          [dropdownTriggerFor]="categoriesDropdown"
          dropdownPosition="start">
          Catégories
          <app-icon type="chevron-down"/>
        </div>
        <app-dropdown [closeOnClick]="false" #categoriesDropdown>
          <div class="wrap gap base-padding">
            <div class="bubble pointer" (click)="selectCategory(category.id)"
                 [style.border-color]="category.selected ? mediumCategoryColors[category.id]: 'grey'"
                 *ngFor="let category of categoryCheckboxes">{{ capitalize(category.key) }}
            </div>
          </div>
        </app-dropdown>
        <div 
          (click)="onSelectDynamicClick()"
          class="bubble pointer row gap center-children margin-bottom" 
          [dropdownTriggerFor]="dynamicsDropdown">
          Dynamique de groupe
          <app-icon type="chevron-down"/>
        </div>
        <app-dropdown [closeOnClick]="false" #dynamicsDropdown>
          <div class="wrap gap base-padding">
            <div class="bubble pointer" (click)="selectDynamic(dynamic.id)"
                 [style.border-color]="dynamic.selected ? dynamic.id === DynamicType.COACHING ? '#8C5FB0': '#FFA135': ''"
                 *ngFor="let dynamic of dynamicTypeCheckboxes">{{ capitalize(dynamic.key) }}
            </div>
          </div>
        </app-dropdown>
        <div 
          *ngxPermissionsOnly="'talker'" 
          class="bubble pointer margin-bottom row gap center-children" 
          (click)="selectFavorite()">
            Mes favoris
          <app-icon [type]="onlyFavorites ? 'favorite' : 'favorite-outline'"/>
        </div>
        <div class="bubble pointer row gap center-children margin-bottom" (click)="selectCategory(category.id)"
             [style.border-color]="category.selected ? mediumCategoryColors[category.id]: 'grey'"
             *ngFor="let category of categoryCheckboxesSelected">{{ capitalize(category.key) }}
          <app-icon type="close"/>
        </div>
        <div class="bubble pointer row gap center-children margin-bottom" (click)="selectDynamic(dynamic.id)"
             [style.border-color]="dynamic.selected ? dynamic.id === DynamicType.COACHING ? '#8C5FB0': '#FFA135': ''"
             *ngFor="let dynamic of dynamicTypeCheckboxesSelected">{{ capitalize(dynamic.key) }}
          <app-icon type="close"/>
        </div>

      </div>

      <div class="thematics-list">
        <div class="thematic"
             *ngFor="let thematic of thematicsShownPartOne; let index = index; trackBy cardTrackBy">
          <app-thematic-card 
            [thematic]="thematic" 
            (click)="clickOnThematic(thematic)" />
          <app-favorite-thematic [thematic]="thematic" />
          <p *ngxPermissionsOnly="['admin']" class="color-danger">{{ getThematicProblem(thematic) }}</p>
        </div>
        <div class="orientation-notification-container margin-top">
          <app-notification class="margin-bottom"
          buttonMessage="Prévoir un appel d'orientation" (onButtonClick)="goToOrientation()" color="#8C5FB0"
          [plainStyle]="true"
          icon="question" title="Quelle thématique pour ma situation ?"
          >
            <p>Vous n'êtes pas sûr.e de quelle thématique choisir ?</p>
            <p>
              Vous vous poser beaucoup de questions, avez-vous plusieurs sujets à 
              aborder et vous ne savez pas par lequel commencer ?
            </p>
          </app-notification>
        </div>
        <div class="thematic"
        *ngFor="let thematic of remainingThematics; let index = index; trackBy cardTrackBy">
          <app-thematic-card 
            [thematic]="thematic" 
            (click)="clickOnThematic(thematic)" />
          <app-favorite-thematic [thematic]="thematic" />
          <p *ngxPermissionsOnly="['admin']" class="color-danger">{{ getThematicProblem(thematic) }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="full-screen center-children" *ngIf="loading">
  <app-spinner></app-spinner>
</div>
