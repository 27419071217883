<app-input [title]="title ?? ''" class="pointer" [dropdownTriggerFor]="dropdown" type="dropdown" [readonly]="true"
           [inputField]="resumeFormControl" [isCheckmarks]="true"></app-input>
<app-dropdown [closeOnClick]="false" #dropdown class="dropdown-wrapper">
  <div class="checkbox">
    <app-checkbox (ngModelChange)="selectAll($event)"
                  [ngModel]="allSelected">{{ isFemaleWord ? 'Toutes' : 'Tous' }}</app-checkbox>
  </div>
  <hr/>
  <div class="checkbox" *ngFor="let option of checkboxesState; let index = index; trackBy: checkboxTrackBy">
    <app-checkbox (ngModelChange)="selectOption(option.id, $event)"
                  [ngModel]="option.selected">{{ option.key }}</app-checkbox>
  </div>
</app-dropdown>
