export type DateSuffix = "m" | "h" | "d";

export type DateDelta = `${number}${DateSuffix}`;

export enum DateDeltaConstantType {
  MINIMUM_DELAY_TO_CREATE_TRAIN = "minimumDelayToCreateTrain",
  MINIMUM_DELAY_TO_JOIN_TRAIN = "minimumDelayToJoinTrain",
  SHERPA_MINIMUM_DELAY_TO_LEAVE_TRAIN = "sherpaMinimumDelayToLeaveTrain",
}

export enum NumberConstantType {
  TRAIN_SESSIONS_COUNT = "trainSessionCount",
  TRAIN_TALKERS_MAX = "trainTalkersMax",
  SHERPA_MONTHLY_TRAINS_MAX = "sherpaMonthlyTrainsMax",
  COACHING_HOURLY_RATE = "coachingHourlyRate",
  SPEECH_HOURLY_RATE = "speechHourlyRate",
}

export const DateSuffixDescription: Record<DateSuffix, string> = {
  m: "minutes",
  h: "heures",
  d: "jours",
};

export const WeekDaysNames: string[] = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];

export const MonthNames: string[] = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

export type ConstantsConfigurationType = {
  [key in NumberConstantType]: number;
} & {
  [key in DateDeltaConstantType]: DateDelta;
};

export type ConstantsConfigurationForm = {
  minimumDelayToCreateTrainNumber: number;
  minimumDelayToCreateTrainUnit: string;
  minimumDelayToJoinTrainNumber: number;
  minimumDelayToJoinTrainUnit: string;
  sherpaMinimumDelayToLeaveTrainNumber: number;
  sherpaMinimumDelayToLeaveTrainUnit: string;
  trainSessionCount: number;
  trainTalkersMax: number;
  sherpaMonthlyTrainsMax: number;
  coachingHourlyRate: number;
  speechHourlyRate: number;
};

export interface IConstantsConfiguration extends ConstantsConfigurationType {
  minimumDelayToCreateTrain: DateDelta;
  minimumDelayToJoinTrain: DateDelta;
  sherpaMinimumDelayToLeaveTrain: DateDelta;
  trainSessionCount: number;
  trainTalkersMax: number;
  sherpaMonthlyTrainsMax: number;
  coachingHourlyRate: number;
  speechHourlyRate: number;
}

export class ConstantsConfiguration implements IConstantsConfiguration {
  minimumDelayToCreateTrain!: DateDelta;

  minimumDelayToJoinTrain!: DateDelta;

  sherpaMinimumDelayToLeaveTrain!: DateDelta;

  trainSessionCount!: number;

  trainTalkersMax!: number;

  sherpaMonthlyTrainsMax!: number;

  coachingHourlyRate!: number;

  speechHourlyRate!: number;
}

export interface ISendingEmailConfiguration {
  isSendingEmailActivated: number;
}
