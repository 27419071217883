import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
// import { MatomoTracker } from "ngx-matomo-client";
import { forkJoin, Subject, takeUntil } from "rxjs";

import { EditConstantsComponent } from "../../admin/edit-constants/edit-constants.component";
import { EditTimeslotsComponent } from "../../admin/edit-timeslots/edit-timeslots.component";
import { ConfigurationRepository } from "../../admin/repositories/configuration.repository";
import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { Organization } from "../../organizations/models/organizations.entity";
import { OrganizationRepository } from "../../organizations/repositories/organization.repository";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { ProfileStore } from "../../shared/services/profile.storage.service";
import { StorageService } from "../../shared/storage/storage.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Train } from "../../trains/models/train.entity";
import { TrainRepository } from "../../trains/repositories/train.repository";
import { Role } from "../../users/models/users.entity";
import { ProfileService } from "../../users/services/profile.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  firstName!: string;

  role!: Role;

  smsBalance?: number;

  destroy$ = new Subject<void>();

  checkForSherpaAlert$ = new Subject<void>();

  trains: Train[] = [];

  trainsCompleted: Train[] = [];

  availableTrains: Train[] = [];

  loading = true;

  resetDataLoading = false;

  quotaDemandsCount = 0;

  isPilot = false;

  isTalker = false;

  hasDoneInitialResponse = false;

  managedOrganizations: Organization[] = [];

  firstOrganizationWithQuotaDemand?: Organization;

  isSendingEmailActivated = true;

  canDeactivateSendingEmail = false;

  canResetData: boolean;

  // private readonly tracker = inject(MatomoTracker);

  constructor(
    private readonly profileService: ProfileService,
    private readonly router: Router,
    private readonly configurationRepository: ConfigurationRepository,
    private readonly trainRepository: TrainRepository,
    private readonly organizationRepository: OrganizationRepository,
    private readonly dialog: DialogService,
    private readonly snackbarService: SnackbarService,
    private readonly titleService: Title,
    private readonly storageService: StorageService,
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
    private profileStore: ProfileStore
  ) {
    this.canDeactivateSendingEmail = this.appConfig.canDeactivateSendingEmail === "true";
    this.canResetData = this.appConfig.canResetData === "true";
  }

  ngOnInit(): void {
    this.titleService.setTitle("We Talk");
    this.profileService
      .getObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile) => {
        this.firstName = capitalize(profile.firstName);
        this.isTalker = profile.role === Role.Talker;

        this.profileStore.saveProfile(profile);

        this.hasDoneInitialResponse =
          profile.role === Role.Talker && !!profile.initialFormResponse;
        this.role = profile.role;
        this.managedOrganizations =
          profile.role === Role.Talker
            ? profile.organizationAssociations
                ?.map((association) => association.organization)
                ?.filter((o): o is Organization => !!o)
            : [];
        this.isPilot = profile.role === Role.Talker && profile.isPilot;

        if (this.managedOrganizations.length) {
          forkJoin(
            this.managedOrganizations.map((organization) => this.organizationRepository.getQuotaDemands(organization.id)
            )
          )
            .pipe(takeUntil(this.destroy$))
            .subscribe((demandCounts) => {
              this.quotaDemandsCount = demandCounts.reduce(
                (acc, count, index) => {
                  if (count) {
                    this.firstOrganizationWithQuotaDemand =
                      this.managedOrganizations[index];
                  }
                  return acc + count;
                },
                0
              );
            });
        }

        this.trainRepository.getMyTrains().subscribe((trains) => {
          if (this.role === Role.Admin) {
            this.trains = trains.filter((_, index) => index < 2);
          } else {
            this.trains = trains.filter((train) => !train.isCompleted);
            this.trainsCompleted = trains.filter((train) => train.isCompleted);
          }
          this.checkForSherpaAlert$.next();
        });

        if (this.role === Role.Admin) {
          this.configurationRepository.getSmsBalance().subscribe((balance) => {
            this.smsBalance = balance;
          });
          this.trains = this.trains.filter((_, index) => index < 1);
          if (this.canDeactivateSendingEmail) {
            this.configurationRepository
              .getPreprodEmailSendingConfig()
              .subscribe(({ isSendingEmailActivated }) => {
                this.isSendingEmailActivated = isSendingEmailActivated === 1;
              });
          }
        }

        if (this.role === Role.Sherpa) {
          this.trainRepository
            .getSherpaAvailableTrains()
            .subscribe((trains) => {
              this.availableTrains = trains;
              this.loading = false;
              this.checkForSherpaAlert$.next();
            });
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  editPlatformConfiguration() {
    this.dialog
      .open(EditConstantsComponent)
      .pipe(takeUntil(this.destroy$))
      .subscribe((configuration) => {
        if (configuration) {
          this.configurationRepository
            .updateConstantsConfiguration(configuration)
            .subscribe({
              next: () => {
                this.snackbarService.pushMessage(
                  "Configuration modifiée avec succès",
                  "success"
                );
              },
            });
        }
      });
  }

  editWeeklyTimeSlots() {
    this.dialog
      .open(EditTimeslotsComponent)
      .pipe(takeUntil(this.destroy$))
      .subscribe((timeSlotsConfiguration) => {
        if (timeSlotsConfiguration) {
          this.configurationRepository
            .updateWeeklyTimeSlots(timeSlotsConfiguration)
            .subscribe({
              next: () => {
                this.snackbarService.pushMessage(
                  "Créneaux modifiés avec succès",
                  "success"
                );
              },
            });
        }
      });
  }

  protected readonly capitalize = capitalize;

  goToMySpace() {
    this.router.navigate(["my-space"]);
  }

  onSwitchChange(event: boolean) {
    const isChecked = event;
    this.configurationRepository
      .updatePreprodEmailSendingConfig({
        isSendingEmailActivated: isChecked ? 1 : 0,
      })
      .subscribe(() => {
        this.isSendingEmailActivated = isChecked;
      });
  }

  restTestData() {
    this.resetDataLoading = true;
    this.configurationRepository.resetTestData().subscribe(() => {
      this.resetDataLoading = false;
      this.snackbarService.pushMessage(
        "Données de test réinitialisées avec succès",
        "success"
      );
      this.storageService.logout();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    });
  }

  goToOrientation(){
    this.router.navigate(["home", "orientation"]);
  }
  
  protected readonly Role = Role;
}
