<div class="header row gap">
  <h1 class="title">Veuillez sélectionner les créneaux où vous êtes disponible</h1>
  <app-icon button type="close"
              (click)="close()"></app-icon>
</div>

<section>
  <div class="agenda">
    <p class="margin-bottom margin-top">Vous pouvez désactiver un créneau en cliquant dessus</p>
    <div class="center-children horizontally row gap margin-bottom">
      <div>Légende:</div>
      <div class="tag primary">activé</div>
      <div class="tag">désactivé</div>
    </div>
    <app-timezone-warning></app-timezone-warning>
    <app-weekly-calendar class="center-vertically-by-margin" [hideTimeSlots]="true" [weekDaysAvailable]="weekDayAvailable" [dayTimes]="dayTimes" (clickOnDay)="selectDayTime($event)"></app-weekly-calendar>
  </div>

  <div class="add-button relative">
    <app-button class="center-by-relative-parent" (onClick)="validate(true)" title="Sauvegarder">Sauvegarder
    </app-button>
  </div>
</section>
