import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Subject } from 'rxjs';

import { AbstractChartComponent } from '../abstract.chart.component';

@Component({
  selector: 'app-thematic-progression-distribution',
  templateUrl: './thematic-progression-distribution.component.html',
})
export class ThematicProgressionDistributionComponent extends AbstractChartComponent<'bar'> implements OnInit, AfterViewInit {
  resize$ = new Subject<void>();

  type: 'bar' = 'bar';
  
  width!: number;
  
  height!: number;

  @Input() data: any;

  chartOptions: ChartOptions<'bar'> = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false // Hide the legend
      },
      datalabels: {
        display: true, // Explicitly enable display
        anchor: 'end',
        align: 'top' as const, // Align the label at the top of the bar
        offset: 4, // Add some spacing from bar
        color: '#000',
        font: {
          size: 12,
          family: 'Arial'
        },
        formatter: (value: any) => value // Format the label value
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y}%`;
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#000',
          font: {
            size: 12,
            family: 'Arial'
          },
          minRotation: 0, // Force horizontal labels
          maxRotation: 0  // Force horizontal labels
        },
        grid: {
          display: false
        }
      },
      y: {
        display: false,
        beginAtZero: true,
        max: 100
      }
    },
    datasets: {
      bar: {
        maxBarThickness: 50, 
        barPercentage: 0.8,  
        categoryPercentage: 0.8
      }
    }
  };

  ngOnInit() {
    this.chartPlugins = [ChartDataLabels];
    this.onInit([ this.resize$, this.data ]);
    this.getWidth();
    this.getHeight();
  }

  ngAfterViewInit(): void {
    if (this.chart) {
      this.chart.update();
    }
  }

  updateData() {
    const data = this.data.map((resp: any)=> resp.percent);
    const labels = this.data.map((resp: any) => resp.responses); // Assuming labelData contains the labels

    this.chartData = {
      labels: ['pas du tout', '', '', '', '', '', '', '', '', 'énormément'],
      datasets: [
        {
          data,
          backgroundColor: ['#FF4F4E', '#FF4F4E', '#FC9418', '#FC9418', '#FCC417', '#FCC417', '#94D82C', '#94D82C', '#22C997', '#22C997'],
          datalabels: {
            labels: {
              title: {
                display: true,
                color: '#000',
                font: {
                  size: 12,
                  family: 'Arial'
                },
                formatter: (value: any, context: any) => labels[context.dataIndex] // Use labelData for the labels
              }
            }
          }
        }
      ]
    };

        // Configure datalabels options
        this.chartOptions.plugins = {
          ...this.chartOptions.plugins,
          datalabels: {
            display: true,
            anchor: 'end',
            align: 'top',
            offset: 4,
            color: '#000',
            font: {
              size: 12,
              family: 'Arial'
            },
            formatter: (_value: any, context: any) => labels[context.dataIndex]
          }
        };
  }

getWidth(): void {
  const BREAKPOINT = 741;
  if (window.innerWidth < BREAKPOINT) {
    this.width = Math.min(Math.floor(window.innerWidth - 40), 350); 
  } else {
    this.width = Math.min(Math.floor((window.innerWidth - 460)), 300);
  }
  this.resize$.next();
}

@HostListener('window:resize')
getHeight(): void {
  const BREAKPOINT = 741;
  if (window.innerWidth < BREAKPOINT) {
    this.height = Math.min(Math.floor(this.width * 0.8), 400); 
  } else {
    this.height = Math.min(Math.floor(this.width * 2 / 3), 200);
  }
  this.resize$.next();
}
}