import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import {
  FilterInterface,
  FindResult,
  IPaginationRepository,
} from "../../shared/repositories/pagination.interface";
import { CreateManyResult } from "../../shared/types/create-many-result";
import { TrainMembership } from "../../trains/models/membership.entity";
import {
  CanConsumeResult,
  CreateUser,
  User,
} from "../../users/models/users.entity";
import {
  CreateOrganization,
  IOrganizationTransfer,
  Organization,
  OrganizationDataResume,
  OrganizationToDeleteInfo,
} from "../models/organizations.entity";

export type OrganizationFilterForm = {
  startCreationDate: string;
  endCreationDate: string;
  trainsCountMinimum: string;
  trainsCountMaximum: string;
  membersCountMinimum: string;
  membersCountMaximum: string;
  unitsMinimum: string;
  unitsMaximum: string;
};

export type OrganizationPropertiesFilter = OrganizationFilterForm & { 
  subscriptionPlans: string[];
};

@Injectable()
export class OrganizationRepository
  extends AbstractRepository<Organization, CreateOrganization>
  implements IPaginationRepository<Organization, OrganizationPropertiesFilter> {
  constructor(
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
    protected override readonly http: HttpClient
  ) {
    super(http, Organization);
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return "organizations";
  }

  findAll(): Observable<Organization[]> {
    return this.http
      .get<Organization[]>([this.apiUrl, this.getEntityName()].join("/"))
      .pipe(
        map((result) =>
          result.map((organization) => this.instantiate(organization))
        )
      );
  }

  findAllTrees(): Observable<Organization[]> {
    return this.http
      .get<Organization[]>(
        [this.apiUrl, this.getEntityName(), "trees"].join("/")
      )
      .pipe(
        map((result) =>
          result.map((organization) => this.instantiate(organization))
        )
      );
  }

  paginate(
    filter: FilterInterface<OrganizationPropertiesFilter>
  ): Observable<FindResult<Organization>> {
    return this.http
      .post<FindResult<Organization>>(
        [this.apiUrl, this.getEntityName(), "paginate"].join("/"),
        filter
      )
      .pipe(
        map(({ items, ...rest }) => ({
          ...rest,
          items: items.map((item) => this.instantiate(item)),
        }))
      );
  }

  isNameTaken(name: string): Observable<boolean> {
    return this.http.get<boolean>(
      [this.apiUrl, this.getEntityName(), "all", "check-name"].join("/"),
      {
        params: { name },
      }
    );
  }

  createMember(member: CreateUser, organizationId: string): Observable<User> {
    return this.http
      .post<User>(
        [this.apiUrl, this.getEntityName(), organizationId, "members"].join(
          "/"
        ),
        member
      )
      .pipe(map((user) => plainToInstance(User, user)));
  }

  uploadTalkersFile(
    organizationId: string,
    file: File
  ): Observable<CreateManyResult<User>> {
    const formData = new FormData();

    formData.append("file", file);

    return this.http
      .post<CreateManyResult<User>>(
        [
          this.apiUrl,
          this.getEntityName(),
          organizationId,
          "members",
          "upload-csv",
        ].join("/"),
        formData
      )
      .pipe(
        map((result) => ({
          ...result,
          added: result.added.map((user) => plainToInstance(User, user)),
        }))
      );
  }

  getQuotaDemands(organizationId: string): Observable<number> {
    return this.http.get<number>(
      [this.apiUrl, this.getEntityName(), organizationId, "quota-demands"].join(
        "/"
      )
    );
  }

  canConsumeUnit(): Observable<CanConsumeResult> {
    return this.http.get<CanConsumeResult>(
      [this.apiUrl, this.getEntityName(), "units", "can-consume-unit"].join("/")
    );
  }

  getOrganizationTrainMemberShips(
    organizationId: string
  ): Observable<TrainMembership[]> {
    return this.http
      .get<TrainMembership[]>(
        [this.apiUrl, this.getEntityName(), organizationId, "trains"].join("/")
      )
      .pipe(
        map((result) => result.map((t) => plainToInstance(TrainMembership, t)))
      );
  }

  getOrganizationTalkerActivatedCount(
    organizationId: string
  ): Observable<number> {
    return this.http.get<number>(
      [
        this.apiUrl,
        this.getEntityName(),
        organizationId,
        "activated-count",
      ].join("/")
    );
  }

  getOrganizationDataResume(id: string): Observable<OrganizationDataResume> {
    return this.http.get<OrganizationDataResume>(
      [this.apiUrl, this.getEntityName(), id, "data-resume"].join("/")
    );
  }

  checkBeforeArchive(
    organizationId: string
  ): Observable<OrganizationToDeleteInfo> {
    return this.http.get<any>(
      [
        this.apiUrl,
        this.getEntityName(),
        organizationId,
        "check-before-archive",
      ].join("/")
    );
  }

  archiveOrganization(data: any): any {
    return this.http.post<any>(
      [this.apiUrl, this.getEntityName(), data.organizationId, "archive"].join(
        "/"
      ),
      data
    );
  }

  moveUsersToOrganization(
    transferOrganization: IOrganizationTransfer
  ): Observable<void> {
    return this.http.post<void>(
      [
        this.apiUrl,
        this.getEntityName(),
        transferOrganization.fromOrganizationId,
        "move-users-from",
      ].join("/"),
      transferOrganization
    );
  }

  moveUnitsToOrganization(
    transferOrganization: IOrganizationTransfer
  ): Observable<void> {
    return this.http.post<any>(
      [
        this.apiUrl,
        this.getEntityName(),
        transferOrganization.fromOrganizationId,
        "move-units-from",
      ].join("/"),
      transferOrganization
    );
  }
}
