import { addDelayToDate, createCurrentMonthFirstDate, dateDelta, months } from "../../shared/tool-functions/date-tools";
import { stringNumberDate } from "../../shared/tool-functions/string-number";

import { DateIntervalFilterOption } from "./utils";

export type DateInterval = {
  fromDate: Date;
  toDate: Date;
}

export type DateLabelledInterval = DateInterval & {
  label: string;
}

export function createMonthDateLabelledInterval(monthIndex: number, year?: number): DateLabelledInterval {
  const fromDate = createCurrentMonthFirstDate();
  fromDate.setMonth(monthIndex);
  if (year) {
    fromDate.setFullYear(year);
  }
  const toDate = addDelayToDate('31d', fromDate);
  toDate.setDate(1);

  return {
    fromDate,
    toDate,
    label: `${months[monthIndex]} ${year}`
  };
}

export function createTwoWeeksDateLabelledInterval(monthIndex: number, isFirstPartOfMonth: boolean, year?: number): DateLabelledInterval {
  const fromDate = createCurrentMonthFirstDate();
  fromDate.setMonth(monthIndex);
  fromDate.setDate(isFirstPartOfMonth ? 1 : 15);
  if (year) {
    fromDate.setFullYear(year);
  }
  const toDate = addDelayToDate('20d', fromDate);
  toDate.setDate(isFirstPartOfMonth ? 15 : 1);

  const lastDateOfMonth = new Date();
  lastDateOfMonth.setMonth(monthIndex + 1, 0);

  return {
    fromDate,
    toDate,
    label: `${isFirstPartOfMonth ? '01-14' : `15-${lastDateOfMonth.getDate().toString()}`} ${months[monthIndex]}`
  };
}

export function createWeekDateLabelledInterval(monthIndex: number, firstDateNumber: number, year?: number): DateLabelledInterval {
  const fromDate = createCurrentMonthFirstDate();
  fromDate.setMonth(monthIndex);
  fromDate.setDate(firstDateNumber);
  if (year) {
    fromDate.setFullYear(year);
  }
  const toDate = addDelayToDate('7d', fromDate);
  const toDateOneDayBefore = addDelayToDate(`${6 * 24 + 23}h`, fromDate);
  return {
    fromDate,
    toDate,
    label: toDateOneDayBefore.getMonth() === fromDate.getMonth()
      ? `${stringNumberDate(firstDateNumber)}-${stringNumberDate(toDateOneDayBefore.getDate())} ${months[monthIndex]}`
      : `${stringNumberDate(firstDateNumber)} ${months[monthIndex]} - ${stringNumberDate(toDateOneDayBefore.getDate())} ${months[(monthIndex + 1) % 12]}`
  };
}

export function createDayDateLabelledInterval(date: Date): DateLabelledInterval {
  return {
    fromDate: date,
    toDate: addDelayToDate('1d', date),
    label: `${stringNumberDate(date.getDate())} ${months[date.getMonth()].toLowerCase().slice(0, 4)}`
  };
}

export function getYearIntervals(): DateLabelledInterval[] {
  const now = new Date();
  const currentMonthIndex = now.getMonth();
  const currentYear = now.getFullYear();

  return Array.from(Array(12)).map((_, index) => {
    const monthOffset = currentMonthIndex + 1 + index;
    const targetYear = currentYear + Math.floor((monthOffset - 12) / 12);
    const targetMonth = monthOffset % 12;
    
    return createMonthDateLabelledInterval(targetMonth, targetYear);
  });
}

export function getQuarterIntervals(): DateLabelledInterval[] {
  const now = new Date();
  const currentMonthIndex = now.getMonth();
  const currentYear = now.getFullYear();

  return Array.from(Array(3)).map((_, index) => {
    const monthOffset = currentMonthIndex - 2 + index;
    const targetYear = currentYear + Math.floor(monthOffset / 12);
    const targetMonth = ((monthOffset % 12) + 12) % 12;
    
    return createMonthDateLabelledInterval(targetMonth, targetYear);
  });
}

export function getMonthIntervals(): DateLabelledInterval[] {
  return Array.from(Array(5)).map((_, index) => {
    const date = addDelayToDate(`${-7 * (4 - index)}d`);
    return createWeekDateLabelledInterval(date.getMonth(), date.getDate(), date.getFullYear());
  });
}

export function computeBarChartDateData(dates: Date[], intervals: DateLabelledInterval[]): number[] {
  return intervals.map(interval => 
    dates.filter(date => 
      date.getTime() >= interval.fromDate.getTime() && 
      date.getTime() < interval.toDate.getTime()
    ).length
  );
}

export function getIntervalsFromFilter(filter: DateIntervalFilterOption): DateLabelledInterval[] {
  switch (filter) {
    case DateIntervalFilterOption.THREE_YEARS:
    case DateIntervalFilterOption.YEAR:
      return getYearIntervals();
    case DateIntervalFilterOption.QUARTER:
      return getQuarterIntervals();
    case DateIntervalFilterOption.MONTH:
      return getMonthIntervals();
    default:
      return getYearIntervals();
  }
}

export function getLabelledIntervalsFromGlobalInterval(filter: DateInterval): DateLabelledInterval[] {
  const daysDelta = dateDelta(filter.toDate, filter.fromDate);

  if (daysDelta < 14) {
    return Array.from(Array(daysDelta + 1)).map((_, dayIndex) => {
      const date = addDelayToDate(`${dayIndex}d`, filter.fromDate);
      return createDayDateLabelledInterval(date);
    });
  }
  
  if (daysDelta < 45) {
    return Array.from(Array(Math.ceil(daysDelta / 7) + 1)).map((_, dayIndex) => {
      const date = addDelayToDate(`${dayIndex * 7}d`, filter.fromDate);
      return createWeekDateLabelledInterval(date.getMonth(), date.getDate(), date.getFullYear());
    });
  }
  
  return Array.from(Array(Math.ceil(daysDelta / 30) + 1)).map((_, dayIndex) => {
    const date = addDelayToDate(`${dayIndex * 30}d`, filter.fromDate);
    return createMonthDateLabelledInterval(date.getMonth(), date.getFullYear());
  });
}