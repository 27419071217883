import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { ProfileStore } from "../../shared/services/profile.storage.service";
import { ProfileService } from "../../users/services/profile.service";

@Injectable()
export class OrientationResolver {
  constructor(
    private readonly profileStore: ProfileStore,
    private readonly profileService: ProfileService,
    private readonly snackbarService: SnackbarService,
    private readonly router: Router) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(): Observable<any | undefined> {
    const profile = this.profileStore.getProfile();
    if (profile) {
        return profile;
    }
    return this.profileService.getObservable().pipe(
        tap((prof: any) => {
            if (prof) {
                this.profileStore.saveProfile(prof);
            }
        }),
        map((prof: any) => prof),
        catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse): Observable<any | undefined> {
    if (err.status === 404) {
      this.snackbarService.pushMessage('Un erreur est survenue', 'error');
    }
    this.router.navigate([ 'home' ]);

    return of(undefined);
  }
}
