import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap
} from "rxjs";

import { FeedbackStats } from '../feedbacks/models/feedbacks.entity';
import { FeedbacksRepository } from '../feedbacks/repositories/feedbacks.repository';
import { Organization, OrganizationTrainMembership } from "../organizations/models/organizations.entity";
import { MyOrganizationComponent } from '../organizations/my-organization.component';
import { OrganizationRepository } from "../organizations/repositories/organization.repository";
import { OrganizationStoreService } from '../organizations/services/organization.store.service';
import { flattenOrganizationTrees } from "../organizations/utils/utils";
import { BreadcrumbInput } from '../shared/components/breadcrumb/breadcrumb.component';
import { Checkbox } from "../shared/components/checkmarks/checkmarks.component";
import { IconType } from '../shared/components/icon/icon.component';
import { TreeCheckbox } from "../shared/components/tree-checkmarks/tree-checkmarks.component";
import { CsvService } from '../shared/services/csv.service';
import { capitalize } from "../shared/tool-functions/capitalize";
import { getEmojiColorByType } from '../shared/tool-functions/emojis';
import { EntityFormGroup } from "../shared/types/entity-form-group";
import { mediumCategoryColors } from "../thematics/colors";
import { DynamicType } from "../thematics/models/dynamic.model";
import { Thematic } from "../thematics/models/thematic.model";
import { ThematicRepository } from "../thematics/repositories/thematic.repository";
import { TrainMembership } from "../trains/models/membership.entity";
import { Train } from "../trains/models/train.entity";
import { ResponseResultType } from "../typeform/response/response.data";
import {
  TypeformFormType,
  TypeformResponseFromApi } from "../typeform/typeform.type";
import { Sherpa } from "../users/models/sherpa.entity";
import { Talker } from "../users/models/talker.entity";
import { Role } from "../users/models/users.entity";
import { Profile, ProfileService } from "../users/services/profile.service";


import { TypeformScoreBarChartData } from "./charts/typeform-score-bar-chart/typeform-score-bar-chart.component";
import { DashboardRepository } from "./dashboard.repository";
import { TalkersEmotionalStateComponent } from './talkers-emotional-state/talkers-emotional-state.component';
import { DateInterval } from "./utils/bar-chart-tools";
import { sherpaTabs, tabs } from './utils/constant';
import { formatToOneDecimal } from './utils/utils';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild('wrapper') wrapperContainer!: ElementRef<HTMLElement>;

  @ViewChild('talkerSection') talkerSection!: ElementRef<HTMLElement>;

  @ViewChild('commitmentSection') commitmentSection!: ElementRef<HTMLElement>;

  @ViewChild('satisfactionSection') satisfactionSection!: ElementRef<HTMLElement>;

  @ViewChild(TalkersEmotionalStateComponent) emotionalStateComponent!: TalkersEmotionalStateComponent;

  destroy$ = new Subject<void>();

  reload$ = new Subject<void>();

  loading = true;

  canSeeStatistics = false;

  organizations: Organization[] = [];

  selectedOrganizations$: BehaviorSubject<Organization[]> = new BehaviorSubject<Organization[]>([]);

  selectedInterval$: BehaviorSubject<DateInterval> = new BehaviorSubject<DateInterval>({
    fromDate: new Date(),
    toDate: new Date()
  });

  dafaultSelectedInterval$: BehaviorSubject<DateInterval> = new BehaviorSubject<DateInterval>({
    fromDate: new Date(),
    toDate: new Date()
  });

  organizationTrees!: TreeCheckbox[];

  thematics$: BehaviorSubject<Thematic[]> = new BehaviorSubject<Thematic[]>([]);


  thematics: Thematic[] = [];

  thematicCheckboxes: Checkbox[] = [];

  thematicCheckboxesSelected$: BehaviorSubject<Checkbox[]> = new BehaviorSubject<Checkbox[]>([]);

  dynamicTypeCheckboxes: Checkbox<DynamicType>[] = [
    {
      id: DynamicType.COACHING,
      key: "Coaching en groupe",
      selected: false,
    },
    {
      id: DynamicType.SPEECH,
      key: "Parole en groupe",
      selected: false,
    } ];

  dynamicTypeCheckboxesSelected$: BehaviorSubject<Checkbox<DynamicType>[]> = new BehaviorSubject<Checkbox<DynamicType>[]>([]);

  dateForm!: EntityFormGroup<{ fromDate: string, toDate: string }>;

  invitedTalkers!: Talker[];

  activatedTalkers!: Talker[];

  inactiveTalkers!: Talker[];

  talkersWithTrain!: Talker[];

  activatedInvitedPercent!: number;

  talkerInvitedPercent!: number;

  talkerActivatedPercent!: number;

  allTalkers: Talker[] = [];

  allTypeformResponses: TypeformResponseFromApi[] = [];

  talkers$: BehaviorSubject<Talker[]> = new BehaviorSubject<Talker[]>([]);

  allOrganizationTrainMemberships: OrganizationTrainMembership[] = [];

  allTrainMemberships: TrainMembership[] = [];

  trains: Train[] = [];

  firstTrainMemberships$ = new BehaviorSubject<TrainMembership[]>([]);

  trainMemberships$ = new BehaviorSubject<TrainMembership[]>([]);

  sherpas: Sherpa[] = [];

  showSelection = false;

  isAdminWatching = false;

  isSherpaWatching = false;

  sessionsDone: number = 0;

  trainsDone = 0;

  trainsInProgress = 0;

  hoursDone: number = 0;

  sherpasInvolved: number = 0;

  coachingSherpasInvolved: number = 0;

  speechSherpasInvolved: number = 0;

  canSeePostTrainData = false;

  canSeePostSessionAndTrainData = false;

  canSeePostSessionData = false;

  canSeePostTrainResponses = false;

  canSeeRecommendationData = false;

  canSeePostSessionAndTrainResponsesWithGoalAchievements = false;

  canSeePostSessionAndTrainThematicProgressResponses = false;

  canSeePostSessionAndTrainResponsesWithSherpaNote = false;

  canSeePostTrainNote = false;

  canSeePostSessionNote = false;

  goalsAchievements: { label: string, responses: number, percent: number, color: string, note: number, score: number }[] = [
    {
      label: "Je les ai totalement atteints voire dépassés",
      responses: 0,
      percent: 0,
      color: '#22C997',
      note: 0,
      score: 3
    },
    {
      label: "J’ai atteint la plupart de mes objectifs",
      responses: 0,
      percent: 0,
      color: '#94D82C',
      note: 0,
      score: 2
    }, {
      label: "Je les ai atteints en partie",
      responses: 0,
      percent: 0,
      color: '#FC9418',
      note: 0,
      score: 1
    }, {
      label: "Je n’ai pas du tout atteint mes objectifs",
      responses: 0,
      percent: 0,
      color: '#FF4F4E',
      note: 0,
      score: 0
    },
  ];

  thematicProgress: { responses: number, percent: number, marginTop: string }[] = [];

  typeformResultLabels = [ "de 0 à 25/100", "de 25 à 50/100", "de 50 à 75/100", "de 75 à 100/100" ];

  typeformResultDescriptions = [ "Avant le parcours", "Après le parcours", "6 mois après le parcours" ];

  canViewPage = false ;

  private diagnosticScoresSubject = new BehaviorSubject<TypeformScoreBarChartData[]>([]);

  public diagnosticScores$: Observable<TypeformScoreBarChartData[]> = this.diagnosticScoresSubject.asObservable();

  public selectedOrganizationIds$ = new BehaviorSubject<string[]>([]);

  diagnosticScoresData: Record<ResponseResultType, Omit<TypeformScoreBarChartData, 'steps'> & {
    colors: [ string, string, string ]
  }> = {
    omsMood: {
      title: 'Satisfaction de vie',
      colors: [ "#FFA135", "#FF8B05", "#CC6F04" ],
      color: '#FF8B05',
      icon: 'heart',

    },
    flourishing: {
      title: 'Affects',
      colors: [ "#BA9FD0", "#8C5FB0", "#704C8D" ],
      color: '#8C5FB0',
      icon: 'smile'
    },
    workSatisfaction: {
      title: 'Épanouissement au travail',
      colors: [ "#7CAAD5", "#2D7DC8", "#2464A0" ],
      color: '#2D7DC8',
      icon: 'suit-case'
    }
  };

  sherpaSatisfactionNote: number = 0;

  sessionSatisfactionNote: number = 0;

  trainSatisfactionNote: number = 0;

  wantToDoAnotherTrainData: number[] = [];

  recommendationCountsData: number[] = [];

  relativesRecommendationPercent: number = 0;

  wantToDoAnotherTrainPercent: number = 0;

  recommendationPercentages: { [key: string]: number } = {};

  wantToDoAnotherTrainPercentages: { [key: string]: number } = {};

  coveredOrganizationsForSherpa: Organization[] = [];

  userCreationDate!: Date;

  feedbackStats?: FeedbackStats;

  talkerOrganization?: Organization;

  profile?: Profile;

  canSeePage = true;

  invitedAndActivatedDataTransform!: (talkers: Talker[]) => number[];

  activatedAndTalkerDataTransform!: (talkers: Talker[]) => number[];

  noTrainAndTalkerDataTransform!: (talkers: Talker[]) => number[];

  activatedAgainstTalkersLabels = ["Inscrits n’ayant pas fait de parcours", "Talkers"];

  invitedAgainstActivatedLabels= ["Invités non inscrits", "Inscrits"];

  invitedAgainstTalkersLabels= ["Invités n’ayant pas fait de parcours", "Talkers"];

  private omsMoodDataSubject = new BehaviorSubject<Array<{count: number, percentage: number}>>([]);

  omsMoodData$ = this.omsMoodDataSubject.asObservable();

  averageOmsScore : number = 0;

  private flourishingDataSubject = new BehaviorSubject<Array<{count: number, percentage: number}>>([]);

  flourishingData$ = this.flourishingDataSubject.asObservable();

  averageFlourishingScore : number = 0;

  private affectAndRelationDataSubject = new BehaviorSubject<any>({});


  affectAndRelationData$ = this.affectAndRelationDataSubject.asObservable();

  private workSatisfactionDataSubject = new BehaviorSubject<any>({});


  workSatisfactionData$ = this.workSatisfactionDataSubject.asObservable();

  private lifeSatisfactionDataSubject = new BehaviorSubject<any>({});

  lifeSatisfactionData$ = this.lifeSatisfactionDataSubject.asObservable();

  private beforeTrainTypeformDataSubject = new BehaviorSubject<any>({});

  beforeTrainTypeformData$ = this.beforeTrainTypeformDataSubject.asObservable();

  private postTrainTypeformDataSubject = new BehaviorSubject<any>({});

  postTrainTypeformData$ = this.postTrainTypeformDataSubject.asObservable();

  private sixMonthAfterTrainTypeformDataSubject = new BehaviorSubject<any>({});

  sixMonthAfterTrainTypeformData$ = this.sixMonthAfterTrainTypeformDataSubject.asObservable();

  averageWorkSatisfactionScore : number = 0;

  filteredFeedbacks : any[] = [];

  sherpaFeedbacks : any[] = [];

  tabs: any;

  radarChartDataSubject = new BehaviorSubject<any>({
    dailyRoutine: {
      label: 'Quotidien, activités',
      value: 0
    },
    senseOfPurpose: {
      label: "Sentiment d'utilité, sens de la vie",
      value: 0
    },
    confidence: {
      label: 'Estime de soi, confiance en soi',
      value: 0
    },
    mood: {
      label: 'Humeur, énergie mentale',
      value: 0
    },
    interactions: {
      label: 'Interactions, relations',
      value: 0
    },
    workRecognition: {
      label: 'Reconnaissance professionnelle, capacités',
      value: 0
    },
    workMotivation: {
      label: 'Motivation au travail, intérêt',
      value: 0
    },
    workFocus: {
      label: 'Attention et concentration au travail',
      value: 0
    },
    health: {
      label: 'Santé, énergie physique',
      value: 0
    },
    socialEngagement: {
      label: "Impact de la société"
    }
  });

  constructor(private readonly organizationRepository: OrganizationRepository,
              private readonly dashboardRepository: DashboardRepository,
              private readonly profileService: ProfileService,
              private readonly formBuilder: FormBuilder,
              private readonly thematicRepository: ThematicRepository,
              private readonly feedbacksRepository: FeedbacksRepository,
              private readonly csvService: CsvService,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly route: ActivatedRoute,
              @Optional() @Inject(MyOrganizationComponent) private parent: any) {
    this.dateForm = this.formBuilder.group({
      fromDate: new FormControl('', { validators: Validators.required, nonNullable: true }),
      toDate: new FormControl('', { validators: Validators.required, nonNullable: true })
    });
  }

  ngOnInit() {
    combineLatest([
      this.dashboardRepository.getMembersData(),
      this.dashboardRepository.getTypeformData(),
      this.dashboardRepository.getTrainsData(),
      this.profileService.getMyProfile(),
      this.thematicRepository.getAll(),
      this.organizationStoreService.getObservable()
    ])
      .pipe(
        tap(([ members, typeformResponses, organizationTrainMemberships, profile, thematics, talkerOrganization ]) => {
          this.getOrganizationObservable()
          .subscribe({
            next: (organizations: Organization[]) => {
              this.organizations = this.talkerOrganization ? [this.talkerOrganization] : organizations;
              this.showSelection = this.organizations?.length > 1;
              this.organizationTrees = this.organizations.map(organization => this.createTree(organization));
              this.selectedOrganizations$.next(flattenOrganizationTrees(this.organizations));
            }
          });

          this.allTalkers = members;
          this.allTypeformResponses = typeformResponses;
          this.allOrganizationTrainMemberships = organizationTrainMemberships;
          this.allTrainMemberships = organizationTrainMemberships.flatMap(o => o.trainMemberships);
          this.isAdminWatching = profile.role === Role.Admin;
          this.isSherpaWatching = profile.role === Role.Sherpa;
          this.tabs = this.isSherpaWatching ? sherpaTabs: tabs;
          this.userCreationDate = profile.createdAt;
          this.profile = profile;
          this.thematics$.next(thematics);
          this.thematicCheckboxes = thematics.map(thematic => ({
            id: thematic.id,
            key: thematic.name,
            selected: false
          }));
          this.coveredOrganizationsForSherpa = members.reduce((acc, member) => {
            const currentIds = acc.map(o => o.id);
            if (!currentIds.includes(member.organization.id)) {
              acc.push(member.organization);
            }
            return acc;
          }, [] as Organization[]);
          this.talkerOrganization = talkerOrganization;
          this.canSeePage = !(profile.role === Role.Talker && 
            (!profile.organization.subscriptionPlan || 
              ['personalized-subscription-individual', 'personalized-subscription-professional'].includes(profile.organization.subscriptionPlan.slug)) && 
            (!talkerOrganization?.subscriptionPlan || 
              ['personalized-subscription-individual', 'personalized-subscription-professional'].includes(talkerOrganization?.subscriptionPlan?.slug)));      

        }),
        switchMap(() => this.selectedOrganizations$),
        combineLatestWith(this.dateForm.valueChanges.pipe(startWith(this.dateForm.value)), this.thematicCheckboxesSelected$.pipe(startWith([])), this.dynamicTypeCheckboxesSelected$.pipe(startWith([]))),
        takeUntil(this.destroy$),
      )
      .subscribe(([ organizationsFromObservable, intervalSelected, thematicCheckboxes, dynamicCheckboxes ]) => {
        const organizations = this.isSherpaWatching ? this.coveredOrganizationsForSherpa : organizationsFromObservable;
        const selectedDynamicTypes: DynamicType[] = dynamicCheckboxes.map(c => c.id);
        const selectedThematics: string[] = thematicCheckboxes.map(c => c.id);


        const selectedOrganizationIds = (this.isSherpaWatching ? this.coveredOrganizationsForSherpa : organizations).map(o => o.id)
          .filter(id => {
            if (!this.isAdminWatching) {
              return this.allTalkers.filter(t => t.organization.id === id && !!t.activatedAt).length >= 5 && this.allOrganizationTrainMemberships
                .filter(t => t.id === id)
                .reduce((acc, organizationTrainMembership) => acc + organizationTrainMembership.firstTrainMemberships.length, 0) >= 5;
            }
            return true;
          });
        
        this.selectedOrganizationIds$.next(selectedOrganizationIds);

        this.canSeeStatistics = selectedOrganizationIds.length > 0 || this.isSherpaWatching;
        const interval: DateInterval = this.isSherpaWatching ? {
          fromDate: this.userCreationDate,
          toDate: new Date()
        } : {
          fromDate: intervalSelected.fromDate ? new Date(intervalSelected.fromDate) : undefined
            ?? new Date('2020-01-01'),
          toDate: intervalSelected.toDate ? new Date(intervalSelected.toDate) : new Date()
        };

        this.selectedInterval$.next(interval);

        const today = new Date();
        const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

        const lastOneYear: DateInterval = this.isSherpaWatching ? {
          fromDate: this.userCreationDate,
          toDate: today
        } : {
          fromDate: intervalSelected.fromDate ? new Date(intervalSelected.fromDate) : oneYearAgo,
          toDate: intervalSelected.toDate ? new Date(intervalSelected.toDate) : today
        };

        this.dafaultSelectedInterval$.next(lastOneYear);

        this.talkers$.next(this.allTalkers.filter(talker =>
          (this.isSherpaWatching || (selectedOrganizationIds.includes(talker.organization.id))
            && talker.createdAt.getTime() >= interval.fromDate.getTime()
            && talker.createdAt.getTime() < interval.toDate.getTime())
        ));

        const selectedTalkerIds = this.talkers$.getValue().map(talker => talker.id);


        this.trains = this.allOrganizationTrainMemberships
          .filter(organizationTrainMemberships => this.isSherpaWatching || selectedOrganizationIds.includes(organizationTrainMemberships.id))
          .flatMap(organizationTrainMemberships => organizationTrainMemberships.trainMemberships.map(trainMembership => trainMembership.train))
          .filter(train => (selectedDynamicTypes.length === 0 || selectedDynamicTypes.includes(train.dynamic.type)) && (selectedThematics.length === 0 || selectedThematics.includes(train.dynamic.thematic.id)))
          .filter(train => train && train.getFirstSession().date.getTime() > interval.fromDate.getTime()
            || (train && train.getLastSession().date.getTime() > interval.fromDate.getTime()))
          .reduce((acc, train) => {
            if (!acc.map(t => t.id).includes(train.id)) {
              acc.push(train);
            }
            return acc;
          }, [] as Train[]);


        const selectedTrainIds = this.trains.map(train => train.id);

        this.trainsDone = this.trains.filter(t => t.isCompleted).length;
        this.trainsInProgress = this.trains.filter(t => !t.isCompleted).length;
        
        const filteredTrainMemberships = this.allOrganizationTrainMemberships
        .filter(organizationTrainMemberships => this.isSherpaWatching || selectedOrganizationIds.includes(organizationTrainMemberships.id))
        .flatMap(organizationTrainMemberships => organizationTrainMemberships.trainMemberships)
        .filter(trainMembership => trainMembership && trainMembership.createdAt.getTime() > interval.fromDate.getTime())
        .filter(trainMembership => (selectedDynamicTypes.length === 0 || selectedDynamicTypes.includes(trainMembership.train.dynamic.type)) && (selectedThematics.length === 0 || selectedThematics.includes(trainMembership.train.dynamic.thematic.id)))
        .filter(trainMembership => trainMembership.train && (trainMembership.train.getNextSession()?.date?.getTime() ?? 0) > interval.fromDate.getTime()
          || (trainMembership.train && trainMembership.train.getLastSession().date.getTime() > interval.fromDate.getTime()));

        const completedTrainMemberships = this.allOrganizationTrainMemberships
            .filter(organizationTrainMemberships => this.isSherpaWatching || selectedOrganizationIds.includes(organizationTrainMemberships.id))
            .flatMap(organizationTrainMemberships => organizationTrainMemberships.trainMemberships)
            .filter(trainMembership => (selectedDynamicTypes.length === 0 || selectedDynamicTypes.includes(trainMembership.train.dynamic.type)) && 
              (selectedThematics.length === 0 || selectedThematics.includes(trainMembership.train.dynamic.thematic.id)))
            .filter(trainMembership => trainMembership.train && trainMembership.train.isCompleted)
            .filter(trainMembership => {
              const lastSessionDate = trainMembership.train.getLastSession().date;
              return lastSessionDate.getTime() >= interval.fromDate.getTime() && 
                    lastSessionDate.getTime() <= interval.toDate.getTime();
            });
        
        this.canViewPage =completedTrainMemberships.length >= 5;
        
        this.trainMemberships$.next(filteredTrainMemberships);

        this.firstTrainMemberships$.next(this.allOrganizationTrainMemberships
          .filter(organizationTrainMemberships => selectedOrganizationIds.includes(organizationTrainMemberships.id))
          .flatMap(organizationTrainMemberships => organizationTrainMemberships.firstTrainMemberships)
          .filter(trainMembership => trainMembership && trainMembership.createdAt.getTime() > interval.fromDate.getTime()));

        const postSessionResponses = (this.allTypeformResponses as any)
        .map((r: any) => r.data)
        .filter((response: any) => response?.sessionSatisfaction !== null)
        .filter((response: any) => new Date(response.createdAt).getTime() >= interval.fromDate.getTime() && 
        new Date(response.createdAt).getTime() < interval.toDate.getTime())
        .filter((response: any) => selectedTalkerIds.includes(response.talkerId))
        .filter((response: any) => response.type === TypeformFormType.POST_SESSION);

        const postSessionAndTrainResponses = (this.allTypeformResponses as any)
        .map((r: any) => r.data)
        .filter((response: any) => response.sessionSatisfaction !== null || response.sherpaSatisfaction !== null)
        .filter((response: any) => new Date(response.createdAt).getTime() >= interval.fromDate.getTime() && 
        new Date(response.createdAt).getTime() < interval.toDate.getTime())
        .filter((response: any) => selectedTalkerIds.includes(response.talkerId))
        .filter((response: any) => response.type === TypeformFormType.POST_SESSION || (response.type === TypeformFormType.POST_TRAIN && selectedTrainIds.includes(response.trainId)));

        const postSessionAndTrainThematicProgressResponses = (this.allTypeformResponses as any)
        .map((r: any) => r.data)
        .filter((response: any) => response.thematicProgress !== null)
        .filter((response: any) => new Date(response.createdAt).getTime() >= interval.fromDate.getTime() && 
        new Date(response.createdAt).getTime() < interval.toDate.getTime())
        .filter((response: any) => selectedTalkerIds.includes(response.talkerId))
        .filter((response: any) => response.type === TypeformFormType.POST_SESSION || (response.type === TypeformFormType.POST_TRAIN && selectedTrainIds.includes(response.trainId)));

        const postTrainResponses = (this.allTypeformResponses as any)
        .map((r: any) => r.data)
        .filter((response: any) => response.trainSatisfaction !== null)
        .filter((response: any) => new Date(response.createdAt).getTime() >= interval.fromDate.getTime() && 
        new Date(response.createdAt).getTime() < interval.toDate.getTime())
        .filter((response: any) => selectedTalkerIds.includes(response.talkerId))
        .filter((response: any) => response.type === TypeformFormType.POST_SESSION || (response.type === TypeformFormType.POST_TRAIN && selectedTrainIds.includes(response.trainId)));
        
        const postSessionAndTrainResponsesWithGoalAchievements = (this.allTypeformResponses as any)
        .map((r: any) => r.data)
        .filter((response: any) => response.goalsAchievement !== null)
        .filter((response: any) => new Date(response.createdAt).getTime() >= interval.fromDate.getTime() && 
        new Date(response.createdAt).getTime() < interval.toDate.getTime())
        .filter((response: any) => selectedTalkerIds.includes(response.talkerId))
        .filter((response: any) => response.type === TypeformFormType.POST_SESSION || (response.type === TypeformFormType.POST_TRAIN && selectedTrainIds.includes(response.trainId)));
        
        // const typeformResponses = this.allTypeformResponses
        // .map(r => r.data)
        // .filter((response): response is any => !!response)
        // .filter((response) => new Date(response.createdAt).getTime() >= interval.fromDate.getTime() && 
        // new Date(response.createdAt).getTime() < interval.toDate.getTime())
        // .filter((response) => selectedTalkerIds.includes(response.talkerId))
        // .filter((response) => response.type === TypeformFormType.INITIAL || selectedTrainIds.includes(response?.trainId));

      this.canSeePostTrainData = !!postSessionAndTrainResponses.length;
        
      this.canSeePostSessionAndTrainData = !!postSessionAndTrainResponses.length;

      this.canSeePostSessionData = !!postSessionResponses.length;

      this.canSeePostTrainResponses = !!postTrainResponses.length;

      this.canSeePostSessionAndTrainResponsesWithGoalAchievements = !!postSessionAndTrainResponsesWithGoalAchievements.length;

      this.canSeePostSessionAndTrainThematicProgressResponses = !!postSessionAndTrainThematicProgressResponses.length;

        this.goalsAchievements.forEach((goalStep) => {
          const count = postSessionAndTrainResponsesWithGoalAchievements
            .filter((response: any) => response.goalsAchievement === goalStep.score)
            .length;
          goalStep.responses = count;
          goalStep.percent = Math.round((count / Math.max(postSessionAndTrainResponsesWithGoalAchievements.length, 1) * 100));
          goalStep.note = count / Math.max(postSessionAndTrainResponsesWithGoalAchievements.length, 1);
        });
        this.thematicProgress = Array.from(Array(11))
          .map((_, index) => {
              const responses = postSessionAndTrainThematicProgressResponses
                .filter((response: any) => response.thematicProgress === index)
                .length;
              const percent = Math.round(responses / Math.max(postSessionAndTrainThematicProgressResponses.length, 1) * 100);
              
              return {
                responses,
                percent,
                marginTop: `${ 100 - percent }px`
              };
            }
          );

        const recommendationTotalResponses = Math.max(postSessionAndTrainResponses.filter((r: any) => r.relativesRecommendation).length, 1);
        
        this.canSeeRecommendationData = !!recommendationTotalResponses;

        const totalResponses = Math.max(postSessionAndTrainResponses.length, 1);
        
        const recommendationCounts = postSessionAndTrainResponses.reduce((counts: any, response: any) => {
          if (response.relativesRecommendation >= 1 && response.relativesRecommendation <= 3) {
              counts.no++;
          } else if (response.relativesRecommendation > 3 && response.relativesRecommendation <= 6) {
              counts.maybe++;
          } else if (response.relativesRecommendation > 6) {
              counts.yes++;
          }
          return counts;
      }, { no: 0, maybe: 0, yes: 0 });

      this.recommendationCountsData = [recommendationCounts.yes, recommendationCounts.no, recommendationCounts.maybe];
      
      this.recommendationPercentages = {
          'Non' : (recommendationCounts.no / recommendationTotalResponses) * 100,
          'Peut-être': (recommendationCounts.maybe / recommendationTotalResponses) * 100,
          'Oui': (recommendationCounts.yes / recommendationTotalResponses) * 100
      };

        const postSessionAndTrainResponsesWithSherpaNote = postSessionAndTrainResponses.filter((response: any) => response.sherpaSatisfaction >= 0);
        this.canSeePostSessionAndTrainResponsesWithSherpaNote = !!postSessionAndTrainResponsesWithSherpaNote.length;
        
        const postTrainResponsesWithTrainNote = postTrainResponses.filter((response: any) => response.trainSatisfaction >= 0);
        this.canSeePostTrainNote = !!postTrainResponsesWithTrainNote.length;

        const postSessionResponsesWithSessionNote = postSessionResponses.filter((response: any) => response.sessionSatisfaction >= 0);
        this.canSeePostSessionNote = !!postSessionResponsesWithSessionNote.length;

        this.relativesRecommendationPercent = Math.round(postSessionAndTrainResponses.reduce((sum: any, response: any) => sum + response.relativesRecommendation, 0) / Math.max(postSessionAndTrainResponses.filter((p: any) => p.relativesRecommendation).length, 1));
        this.sherpaSatisfactionNote = formatToOneDecimal(postSessionAndTrainResponses.reduce((sum: any, response: any) => sum + response.sherpaSatisfaction, 0) / Math.max(postSessionAndTrainResponses.filter((p: any) => p.sherpaSatisfaction).length, 1));
        this.sessionSatisfactionNote = formatToOneDecimal(postSessionResponses.reduce((sum: any, response: any) => sum + response.sessionSatisfaction, 0) / Math.max(postSessionResponses.length, 1));
        this.trainSatisfactionNote = formatToOneDecimal(postTrainResponses.reduce((sum: any, response: any) => sum + response.trainSatisfaction, 0) / Math.max(postTrainResponses.length, 1));
        
        const trueCount = postSessionAndTrainResponses.filter((response: any) => response.wantToDoAnotherTrain).length;
        const falseCount = postSessionAndTrainResponses.filter((response: any) => !response.wantToDoAnotherTrain).length;
        
        this.wantToDoAnotherTrainData = [ trueCount, falseCount, 0 ];

        const truePercentage = (trueCount / totalResponses) * 100;
        const falsePercentage = (falseCount / totalResponses) * 100;

        this.wantToDoAnotherTrainPercentages = { 'Oui' : truePercentage, 'Non' : falsePercentage, 'Peut-être': 0 };

        if(selectedOrganizationIds.length || this.talkerOrganization?.id){
          const orgIds = this.isAdminWatching ? selectedOrganizationIds : (this.talkerOrganization?.id ? [this.talkerOrganization.id] : selectedOrganizationIds);
          if(orgIds.length){
            this.feedbacksRepository.getFeedBackMergedStats(orgIds).pipe(takeUntil(this.destroy$)).subscribe((feedbackStats) => {
              this.feedbackStats = feedbackStats;
            });
          }
          if(orgIds.length){
            this.dashboardRepository.getPostSessionFeedbacks(orgIds).pipe(takeUntil(this.destroy$)).subscribe((feedbacks) => {
              this.filteredFeedbacks = feedbacks;
            });
          }
        } else {
          this.filteredFeedbacks = [];
        }

        if(this.isSherpaWatching){
            this.dashboardRepository.getSherpaPostSessionFeedbacks().pipe(takeUntil(this.destroy$)).subscribe((feedbacks) => {
              this.sherpaFeedbacks = feedbacks;
            });
        }

        this.reload$.next();

        this.loading = false;
      });

    this.talkers$.pipe(takeUntil(this.destroy$))
      .subscribe(filteredTalkers => {
        this.invitedTalkers = filteredTalkers;
        this.activatedTalkers = filteredTalkers.filter(talker => !!talker.activatedAt);
        this.inactiveTalkers = filteredTalkers.filter(talker => !talker.activatedAt);
        this.talkersWithTrain = filteredTalkers.filter(talker => !!talker.activatedAt && talker.trainMemberships?.length);

        this.activatedInvitedPercent = Math.round(this.activatedTalkers.length / Math.max(this.invitedTalkers.length, 1) * 100);
        this.talkerActivatedPercent = Math.round(this.talkersWithTrain.length / Math.max(this.activatedTalkers.length, 1) * 100);
        this.talkerInvitedPercent = Math.round(this.talkersWithTrain.length / Math.max(this.invitedTalkers.length, 1) * 100);

        this.invitedAndActivatedDataTransform = (talkers: Talker[]) => [
          (filteredTalkers.filter(talker => !talker.activatedAt)).length,
          talkers.filter(t => !!t.activatedAt).length
        ];

        this.activatedAndTalkerDataTransform = (talkers: Talker[]) => [
          talkers.filter(t => !!t.activatedAt && !t.trainMemberships?.length).length,
          talkers.filter(t => !!t.activatedAt && !!t.trainMemberships?.length).length
        ];

        this.noTrainAndTalkerDataTransform = (talkers: Talker[]) => [
          talkers.filter(t => !t.trainMemberships?.length).length,
          talkers.filter(t => !!t.activatedAt && !!t.trainMemberships?.length).length
        ];

      });

    this.trainMemberships$
      .pipe(takeUntil(this.destroy$))
      .subscribe(memberships => {
        this.sessionsDone = memberships.reduce((sum, m) => sum + m.train.currentSessionIndex, 0);
        this.hoursDone = memberships.reduce((sum, m) => sum + Math.floor(m.train.currentSessionIndex * m.train.sessionDuration / 3600E3), 0);

        const sherpaIdList: string[] = [];
        const coachingSherpaIdList: string[] = [];
        const speechSherpaIdList: string[] = [];
        for (const memberShip of memberships) {
          const { sherpaId } = memberShip.train;
          const dynamicType = memberShip.train.dynamic.type;

          if (sherpaId && !sherpaIdList.includes(sherpaId)) {
            sherpaIdList.push(sherpaId);
          }

          if (sherpaId && !coachingSherpaIdList.includes(sherpaId) && dynamicType === DynamicType.COACHING) {
            coachingSherpaIdList.push(sherpaId);
          }

          if (sherpaId && !speechSherpaIdList.includes(sherpaId) && dynamicType === DynamicType.SPEECH) {
            speechSherpaIdList.push(sherpaId);
          }
        }

        this.sherpasInvolved = sherpaIdList.length;
        this.coachingSherpasInvolved = coachingSherpaIdList.length;
        this.speechSherpasInvolved = speechSherpaIdList.length;
      });
  }

  private createTree(organization: Organization): TreeCheckbox {
    return {
      id: organization.id,
      key: organization?.name?.toUpperCase(),
      selected: true,
      children: organization.children?.filter(o => o.id).map(child => this.createTree(child)),
      expanded: !!organization.children?.length
    };
  }

  private getOrganizationObservable(): Observable<Organization[]> {
    return this.profileService.getMyProfile().pipe(takeUntil(this.destroy$),
      switchMap(profile => (profile.role === Role.Admin
        ? this.organizationRepository.findAllTrees()
        : profile.role === Role.Talker ? this.organizationRepository.findAll() : of([]))));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  goTo(link: string) {
    let offset: number = 0;

    if (link === 'talkers') {
        offset = this.talkerSection.nativeElement.offsetTop;
    }
    if (link === 'commit') {
        offset = this.commitmentSection.nativeElement.offsetTop;
    }
    if (link === 'satisfaction') {
        offset = this.satisfactionSection.nativeElement.offsetTop;
    }
    if (link === 'impact' && this.emotionalStateComponent?.impactSection) {
      offset = this.emotionalStateComponent.impactSection.nativeElement.offsetTop;
    }
    
    if(this.talkerOrganization){
      if(this.parent?.parentWrapperContainer){
        this.parent.parentWrapperContainer?.nativeElement.scrollTo({
          top: offset - 200,
          behavior: 'smooth'
        });
      }
    } else {
      this.wrapperContainer.nativeElement?.scrollTo({
        top: offset - 200,
        behavior: 'smooth'
    });
    }
}

  protected readonly capitalize = capitalize;

  protected readonly mediumCategoryColors = mediumCategoryColors;

  protected readonly DynamicType = DynamicType;

  selectThematic(slug: string) {
    const thematic = this.thematicCheckboxes.find(c => c.id === slug);
    if (thematic) {
      thematic.selected = !thematic.selected;
    }
    this.thematicCheckboxesSelected$.next(this.thematicCheckboxes.filter(checkbox => checkbox.selected));
  }

  selectDynamic(type: string) {
    const dynamic = this.dynamicTypeCheckboxes.find(c => c.id === type);
    if (dynamic) {
      dynamic.selected = !dynamic.selected;
    }
    this.dynamicTypeCheckboxesSelected$.next(this.dynamicTypeCheckboxes.filter(checkbox => checkbox.selected));
  }

  updateTreeSelection(trees: TreeCheckbox[]): void {
    this.organizationTrees = trees;
  }

  updateOrganizationsSelected(selected: string[]): void {
    this.selectedOrganizations$.next(flattenOrganizationTrees(this.organizations).filter(o => selected.includes(o.id)));
  }

  onThematicSelect(){
    // this.tracker.trackEvent(TrackerCategory.STATS_PILOT, TrackerAction.CLICK, 'categoryFilter');
  }

  onDynamicSelect(){
    // this.tracker.trackEvent(TrackerCategory.STATS_PILOT, TrackerAction.CLICK, 'dynamicFilter');
  }

  getEmojiColorByType(type: IconType): string {
    return getEmojiColorByType(type);
  }

  getNoteColorByScore(score: number): string {
    if (score <= 2) return '#FF4F4E';
    if (score > 2 && score <= 4) return '#FC9418';
    if (score > 4 && score <= 6) return '#FCC418';
    if (score > 6 && score <= 8) return '#94D82C';
    if (score > 8 && score <= 10) return '#22C997';
    return '#22C997';
  }

  formatRating (rating: string) {
    const num = parseFloat(rating);
    const rounded = Number(num.toFixed(1));
    return rounded % 1 === 0 ? Math.floor(rounded).toString() : rounded.toString();
  }

  getLatestRating(rating: number): string {
    return this.feedbackStats?.latestRatings?.find(r => r.rating === rating)?.uniqueUserCount ?? '0';
  }

  getAverageRating(){
    return this.formatRating((this.feedbackStats?.averageRating?.toString()) ?? '0');
  }

  downloadCsv(){
    const organizationsIds = this.selectedOrganizations$.value.map(o => o.id);
    this.feedbacksRepository.findAll(organizationsIds).subscribe((feedbacks: any) => {
      this.csvService.downloadFeebacks(feedbacks);
    });
  }

  getOrganizationBreadcrumbs(organization: Organization): BreadcrumbInput[] {
    const result: BreadcrumbInput[] = [ { displayName: organization.name, url: organization.id } ];
    let currentOrganization = organization;
    let depth = 0;
    let gotRightsOnOrganization = true;
    while (currentOrganization.parent && depth <= 2) {
      if (this.profile?.role === Role.Talker && this.profile?.organizationAssociations?.map(o => o.organization?.id)?.includes(currentOrganization.id)) {
        gotRightsOnOrganization = false;
      }
      currentOrganization = currentOrganization.parent;
      result.push({
        displayName: depth < 2 ? currentOrganization.name : '...',
        url: gotRightsOnOrganization && organization?.id && depth < 2 ? currentOrganization.id : undefined
      });
      depth += 1;

    }
    return result;
  }

  getBarColor(value: number): string {
    if (value <= 2) return '#FF4F4E';  
    if (value <= 4) return '#FC9418';  
    if (value <= 6) return '#FFD700';  
    if (value <= 8) return '#94D82C';  
    return '#22C997';                  
  }

  getColorByScore(score: number): string {
    if (score <= 25) return '#FF4F4E';  
    if (score <= 50) return '#FC9418';  
    if (score <= 75) return '#94D82C';  
    if (score <= 100) return '#22C997';  
    return '#22C997';                  
  }

  get isPilot(): boolean {
    return this.profile?.role === Role.Talker && this.profile.isPilot;
  }
}
