import { Component, Input } from '@angular/core';

import { IconType } from "../../../shared/components/icon/icon.component";

export type TypeformScoreBarChartData =  {
  icon: IconType;
  color: string;
  steps: TypeformScoreBarChartStep[];
  title: string;
}

export type TypeformScoreBarChartStep = {
  responses: number,
  percent: number,
  marginTop: string,
  color: string,
  description: string
};

@Component({
  selector: 'app-typeform-score-bar-chart',
  templateUrl: './typeform-score-bar-chart.component.html',
  styleUrl: './typeform-score-bar-chart.component.scss'
})
export class TypeformScoreBarChartComponent {
  @Input() result!: TypeformScoreBarChartData;
}
