import { Thematic } from "./thematic.model";


export enum ThematicCategorySlug {
  PERSONAL_LIFE = 'personal-life',
  BETTER_LIFE = 'better-life',
  PROFESSIONAL_LIFE = 'professional-life',
  SELF_REFLECTION = 'self-reflection'
}

export class ThematicCategory {
  id!: string;

  thematics?: Thematic[];

  slug!: ThematicCategorySlug;

  name!: string;
}
