<div class="header row gap">
  <h1 class="title">Ajouter un nouveau pilote</h1>
  <app-icon button type="close"
            (click)="close()"></app-icon>
</div>

<section>
  <form [formGroup]="newPilotForm" (submit)="validate(true)">
    <app-input title="Ajouter un talker existant en tant que pilote"  [dropdownData]="existingTalkerNames"
               formControlName="name" type="user"></app-input>
    <app-separator/>
    <div class="margin-bottom center-children">

      <app-button type="button" [outline]="true" (click)="createPilot()">Créer un nouveau pilote</app-button>
    </div>
    <div class="add-button center-children">
      <app-button [disabled]="!formIsValid" type="submit">Ajouter
        ce pilote
      </app-button>
    </div>
  </form>
</section>
