import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, combineLatest, combineLatestWith, debounceTime, filter, of, Subject, switchMap, takeUntil, tap } from "rxjs";

import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { Talker } from "../../users/models/talker.entity";
import { CreateTalker, Gender, GenderDescription, Role } from "../../users/models/users.entity";
import { UsersRepository } from "../../users/repositories/users.repository";
import { ProfileService } from '../../users/services/profile.service';
import { Organization } from "../models/organizations.entity";
import { OrganizationMembersRepository } from "../repositories/organization.members.repository";
import { OrganizationStoreService } from "../services/organization.store.service";

@Component({
  selector: 'app-add-talker',
  templateUrl: './add-talker.component.html'
})
export class AddTalkerComponent implements OnInit, OnDestroy {

  role!: 'talker' | 'pilot';

  private destroy$ = new Subject<void>();

  newUserForm!: EntityFormGroup<CreateTalker>;

  futurePilotName = new FormControl<string>('', { nonNullable: true });

  existingTalkerNames: string[] = [];

  emailAlreadyTaken = false;

  genderPossibilities = Object.values(GenderDescription).map(gender => capitalize(gender));

  loading = false;

  organization!: Organization;

  existingTalkers: Talker[] = [];

  constructor(private readonly usersRepository: UsersRepository,
              private readonly router: Router,
              private readonly organizationMembersRepository: OrganizationMembersRepository,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly formBuilder: FormBuilder,
              private readonly snackBarService: SnackbarService,
              private readonly profileService: ProfileService,
              private readonly route: ActivatedRoute) {
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {


    this.newUserForm = this.formBuilder.group({
      email: new FormControl('', { validators: [ Validators.email, Validators.required ], nonNullable: true }),
      gender: new FormControl('' as Gender, { validators: Validators.required, nonNullable: true }),
      firstName: new FormControl('', { validators: Validators.min(2), nonNullable: true }),
      lastName: new FormControl('', { validators: Validators.min(2), nonNullable: true })
    });
    this.newUserForm.controls.email.valueChanges.pipe(
      tap(() => {
        this.loading = true;
        this.emailAlreadyTaken = false;
      }),
      debounceTime(500),
      filter<string>(() => this.newUserForm.controls.email.valid),
      switchMap(email => this.usersRepository.isEmailTaken(email)),
      catchError(() => of(false)),
      takeUntil(this.destroy$)
    ).subscribe({
      next: isTaken => {
        this.loading = false;
        this.emailAlreadyTaken = isTaken;
      }
    });


    this.route.data
      .pipe(
        combineLatestWith(this.organizationStoreService.getObservable()
          .pipe(
            filter((organization): organization is Organization => !!organization),
            switchMap(organization => {
              this.organization = organization;
              this.organizationMembersRepository.setOrganizationId(organization.id);
              return this.organizationMembersRepository.getAll();
            }))),
        takeUntil(this.destroy$))
      .subscribe(([ data, members ]) => {
        this.existingTalkers = members
          .filter(member => !this.organization.pilots.map(p => p.id).includes(member.id) && !!member.personalInformation);

        this.existingTalkerNames = this.existingTalkers.map(member => member.fullName);
        this.role = data.role;
      });
  }

  get title(): string {
    return this.role === 'talker' ? 'Inviter un nouveau collaborateur' : 'Ajouter un pilote';
  }

  validate() {
    if (this.newUserForm.valid) {
      const body = {
        email: this.newUserForm.controls.email.value.trim(),
        firstName: this.newUserForm.controls.firstName.value.toLowerCase(),
        lastName: this.newUserForm.controls.lastName.value.toLowerCase(),
        gender: (Object.values(Gender) as Gender[]).find(gender => GenderDescription[gender] === this.newUserForm.controls.gender.value) ?? Gender.OTHER,
      };

      const observable = this.role === 'pilot'
        ? this.organizationMembersRepository.createPilot(body)
        : this.organizationMembersRepository.createMember(body);

      observable
        .pipe(
          switchMap(data =>
            combineLatest([
              of(data), 
              this.organizationStoreService.reloadOrganization()
            ])
          ))
        .subscribe(([user, organization]) => {
          this.snackBarService.pushMessage(`${ this.role === 'pilot' ? 'Pilote' : 'Collaborateur' } ajouté avec succès`, 'success');
          this.usersRepository.createMailchimpContact(user.id).subscribe({
            next: () => {
              if(this.profileService?.profile?.role === Role.Admin){
                this.snackBarService.pushMessage('Contact créé dans Mailchimp', 'success');
              }
              this.router.navigate(this.role === 'pilot' ? [ 'organizations', organization.id, 'edit' ] : [ 'organizations', organization.id, 'talkers' ]);
            },
            error: () => {
              if(this.profileService?.profile?.role === Role.Admin){
                this.snackBarService.pushMessage('Contact n’a pas pu être créé dans Mailchimp', 'error');
              }
              this.router.navigate(this.role === 'pilot' ? [ 'organizations', organization.id, 'edit' ] : [ 'organizations', organization.id, 'talkers' ]);
            }
          });
        });
    }
  }

  promoteTalkerToPilot() {
    if (this.futurePilotName.value.length) {
      const talker = this.existingTalkers.find(t => t.fullName === this.futurePilotName.value);
      if (talker) {
        this.organizationMembersRepository.addExistingTalkerAsPilot(talker.id)
          .pipe(
            switchMap(() => this.organizationStoreService.reloadOrganization()),
            takeUntil(this.destroy$))
          .subscribe((organization) => {
            this.snackBarService.pushMessage(`${ this.role === 'pilot' ? 'Pilote' : 'Collaborateur' } ajouté avec succès`, 'success');
            this.router.navigate(this.role === 'pilot' ? [ 'organizations', organization.id, 'edit' ] : [ 'organizations', organization.id, 'talkers' ]);

          });
      }
    }
  }
}
