import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { startWith, Subject, takeUntil, tap } from "rxjs";

import { DateDelta, WeekDaysNames } from "../../../../admin/types/constant";
import { TimeSlot } from "../../../../trains/models/time-slot.entity";
import {
  addDelayToDate,
  createCurrentMonthFirstDate,
  createCurrentMonthLastDate,
  months
} from "../../../tool-functions/date-tools";


type Day = {
  title: string;
  weekDay: number;
  date: Date;
  availableTimes: TimeSlot[];
}

export type TimeSlotsPerDay = [ TimeSlot[], TimeSlot[], TimeSlot[], TimeSlot[], TimeSlot[], TimeSlot[], TimeSlot[] ];

@Component({
  selector: 'app-monthly-calendar',
  templateUrl: './monthly-calendar.component.html',
  styleUrls: [ './monthly-calendar.component.scss' ]
})
export class MonthlyCalendarComponent implements OnInit, OnDestroy {

  @Input() weekDaysAvailable!: number[];

  @Input() delayToCreate: DateDelta = '7d';

  @Input() timeSlotsPerDay: TimeSlotsPerDay = [ [], [], [], [], [], [], [] ];

  @Output() clickOnDay = new EventEmitter<Date>();

  globalWeekDaysAvailable = [ 1, 2, 3, 4, 5, 6, 0 ];

  weekDaysNames = WeekDaysNames;

  deltaMonthIndexSelected = 0;

  days!: Day[];

  @Input() selectedDate?: Date;

  selectedDeltaMonthIndex$: Subject<number> = new Subject<number>();

  firstAvailableDate: Date = new Date();

  months: string[] = months;

  destroy$ = new Subject<void>();

  ngOnInit() {
    this.firstAvailableDate = addDelayToDate(this.delayToCreate);
    while (this.weekDaysAvailable.length && !this.weekDaysAvailable.includes(this.firstAvailableDate.getDay())) {
      this.firstAvailableDate = addDelayToDate('1d', this.firstAvailableDate);
    }
    this.firstAvailableDate.setHours(0, 0, 0, 0);
    const firstDayOfSelectedMonth = createCurrentMonthFirstDate({
      monthIndex: this.firstAvailableDate.getMonth(),
      year: this.firstAvailableDate.getFullYear()
    });
    this.selectedDeltaMonthIndex$
      .pipe(
        startWith(0),

        tap((monthIndex) => {
          this.deltaMonthIndexSelected = monthIndex;
          this.computeMonthShow(firstDayOfSelectedMonth.getMonth() + monthIndex);
        }),
        takeUntil(this.destroy$),)
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  get weekDaysClass(): string {
    return `days-wrapper days-wrapper-${ this.weekDaysAvailable.length }`;
  }

  get year(): number {
    return (this.firstAvailableDate).getFullYear() + Math.floor((this.firstAvailableDate.getMonth() + this.deltaMonthIndexSelected) / 12);
  }

  get monthIndex(): number {
    return (this.deltaMonthIndexSelected + this.firstAvailableDate.getMonth()) % 12;
  }

  computeMonthShow(monthIndex: number): void {
    const firstDayOfSelectedMonth = createCurrentMonthFirstDate({
      monthIndex,
      allowedWeekDays: this.weekDaysAvailable ?? this.globalWeekDaysAvailable,
      year: this.firstAvailableDate.getFullYear()
    });
    const lastDayOfSelectedMonth = createCurrentMonthLastDate(monthIndex, this.weekDaysAvailable ?? this.globalWeekDaysAvailable);

    const weekNumber = Math.ceil((lastDayOfSelectedMonth.getDate() - firstDayOfSelectedMonth.getDate() + firstDayOfSelectedMonth.getDay()) / 7);

    this.days = Array.from(Array(7 * weekNumber)).map((_, index) => {

      const date = addDelayToDate(`${ index - firstDayOfSelectedMonth.getDay() }d`, firstDayOfSelectedMonth);

      return {
        title: `${ date.getDate() }`,
        weekDay: date.getDay(),
        date,
        availableTimes: this.timeSlotsPerDay[date.getDay()]
      };
    });
  }

  onClickOnDay(dayIndex: number) {
    if (this.days[dayIndex].date.getTime() >= this.firstAvailableDate.getTime()) {
      this.clickOnDay.emit(this.days[dayIndex].date);
    }
  }

  goToPreviousMonth(): void {
    if (this.deltaMonthIndexSelected === 0) {
      return;
    }
    this.selectedDeltaMonthIndex$.next(this.deltaMonthIndexSelected - 1);
  }

  goToNextMonth(): void {
    this.selectedDeltaMonthIndex$.next(this.deltaMonthIndexSelected + 1);
  }
}
