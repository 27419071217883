import { booleanAttribute, Component, Input, numberAttribute } from '@angular/core';

@Component({
  selector: 'app-train-image',
  templateUrl: './train-image.component.html',
  styleUrl: './train-image.component.scss'
})
export class TrainImageComponent {

  public imageIsLoaded = false;

  private _src!: string;

  @Input() overlayText?: string;

  @Input() 
  set src(value: string) {
    this._src = this.noCache ? this.addNoCacheParam(value) : value;
  }

  get src(): string {
    return this._src;
  }

  @Input() alt!: string;

  @Input({ transform: booleanAttribute }) rounded = false;

  @Input({ transform: numberAttribute }) width? : number;

  @Input({ transform: numberAttribute }) height? : number;

  @Input({ transform: booleanAttribute }) grey = false;

  @Input({ transform: booleanAttribute }) noCache = false;


  imageLoaded() {
    this.imageIsLoaded = true;
  }

  private addNoCacheParam(url: string): string {
    const noCacheParam = `noCache=${new Date().getTime()}`;
    return url.includes('?') ? `${url}&${noCacheParam}` : `${url}?${noCacheParam}`;
  }

}
