<div class="header row gap">
  <h1 class="title">Configuration de la plateforme</h1>
  <app-icon button type="close"
              (click)="validate()"></app-icon>
</div>

<section>
  <form [formGroup]="configurationForm" (submit)="validate(true)">
    <p>Délai minimum pour ouvrir un train avant son départ</p>
    <div class="input-with-unit">
      <app-input class="value-input" formControlName="minimumDelayToCreateTrainNumber" type="number"></app-input>
      <app-input class="unit" formControlName="minimumDelayToCreateTrainUnit" type="default"
                 placeholder="unité"
                 [dropdownData]="delayUnits"
      ></app-input>
    </div>

    <p>Délai minimum pour rejoindre un train avant le départ</p>
    <div class="input-with-unit">
      <app-input class="value-input" formControlName="minimumDelayToJoinTrainNumber" type="number"></app-input>
      <app-input class="unit" formControlName="minimumDelayToJoinTrainUnit" type="default"
                 placeholder="unité"
                 [dropdownData]="delayUnits"
      ></app-input>
    </div>

    <p>Délai entre la fermeture d'un train et le départ</p>
    <div class="input-with-unit">
      <app-input class="value-input" formControlName="sherpaMinimumDelayToLeaveTrainNumber" type="number"></app-input>
      <app-input class="unit" formControlName="sherpaMinimumDelayToLeaveTrainUnit" type="default"
                 placeholder="unité"
                 [dropdownData]="delayUnits"
      ></app-input>
    </div>

    <p>Nombre de séances par parcours</p>
    <div class="input-with-unit">
      <app-input class="value-input" formControlName="trainSessionCount" type="number"></app-input>
      <div class="unit">séances</div>
    </div>

    <p>Nombre max de talkers par parcours</p>
    <div class="input-with-unit">
      <app-input class="value-input" formControlName="trainTalkersMax" type="number"></app-input>
      <div class="unit">talkers</div>
    </div>

    <p>Nombre max de parcours mensuels pour un sherpa</p>
    <div class="input-with-unit">
      <app-input class="value-input" formControlName="sherpaMonthlyTrainsMax" type="number"></app-input>
      <div class="unit">parcours</div>
    </div>

    <p>Tarif horaire Coach</p>
    <div class="input-with-unit">
      <app-input class="value-input" formControlName="coachingHourlyRate" type="number"></app-input>
      <div class="unit">€</div>
    </div>


    <p>Tarif horaire Thérapeute</p>
    <div class="input-with-unit">
      <app-input class="value-input" formControlName="speechHourlyRate" type="number"></app-input>
      <div class="unit">€</div>
    </div>

    <div class="add-button relative">
      <app-button class="center-by-relative-parent" [disabled]="!formIsValid" type="submit" title="Confirmer">
        Confirmer
      </app-button>
    </div>
  </form>
</section>
