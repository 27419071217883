import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { BehaviorSubject, debounceTime, Subject, switchMap, takeUntil, tap } from "rxjs";

import { InPageTab } from "../../../shared/components/in-page-tabs/in-page-tabs/in-page-tabs.component";
import { IPaginationRepository } from "../../../shared/repositories/pagination.interface";
import { Role } from "../../../users/models/users.entity";

@Component({
    selector: "app-sherpas-card-pagination",
    templateUrl: "./sherpas-card-pagination.component.html",
    styleUrls: ["./sherpas-card-pagination.component.scss"],
  })

  export class SherpasCardPaginationComponent<
  Entity extends { id: string },
  EntityFilterForm extends {
    [key: string]: string | boolean;
  },
> implements OnInit, OnDestroy {
    @Input() mainRepository!: IPaginationRepository<Entity, EntityFilterForm>;


    scroll$ = new Subject<void>();

    pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(10);

    page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

    items$ = new Subject<Entity[]>();

    loading$ = new Subject<void>();

    protected destroy$ = new Subject<void>();

    firstLoaded = false;

    @ContentChild("card", { static: false }) cardTemplateRef!: TemplateRef<any>;

    @Input("unique-key") uniqueKey: keyof Entity = "id";

    @Input() newItems$: Subject<Entity[]> = new Subject<Entity[]>();

    nextPageLoading = false;

    totalCount: number = 0;

    ngOnInit(): void {
        this.page$
      .pipe(
        tap(() => {
          this.loading$.next();
        }),
        debounceTime(300),
        switchMap(() =>
          this.mainRepository.paginate({
            page: this.page$.getValue(),
            pageSize: this.pageSize$.getValue(),
            properties: {
              role: [Role.Sherpa], 
              pilot: false,
              withDescription: true
            } as any,
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(({ items, totalCount }) => {
        this.firstLoaded = true;
        this.items$.next(items);
        this.totalCount = totalCount;
        this.nextPageLoading = false;
      });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    onScrollEvent() {
        this.scroll$.next();
    }

    public loadNextPage(): void {
        if (!this.nextPageLoading) {
          this.nextPageLoading = true;
          this.page$.next(this.page$.getValue() + 1);
        }
      }

      tabs: InPageTab[] = [
        {
          icon: "award-prize",
          color: "#ff8b05",
          title: "Coachs et thérapeutes certifiés",
          description: "",
          link: "#"
        },
        {
          icon: "suit-case",
          color: "#239692",
          title: "10 ans d'expérience en entreprise",
          description: "",
          link: "#"
        },
        {
          icon: "hands-heart",
          color: "#8c5fb0",
          title: "3 ans d'expérience en accompagnement",
          description: "",
          link: "#"
        },
      ];

    certifications = [
        {
            image: "icf"
        },
        {
            image: "sfcoach"
        },
        {
            image: "rncp"
        },
        {
            image: "emcc"
        }
    ]; 
  }