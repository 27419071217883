import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgxPermissionsModule } from "ngx-permissions";

import { FunnelComponent } from "../shared/components/funnel/funnel.component";
import { SharedModule } from "../shared/shared.module";
import { SherpaResolver } from "../users/resolvers/sherpa.resolver";
import { SherpaDetailsComponent } from "../users/sherpa-details/sherpa-details.component";

import { HomeComponent } from "./home/home.component";
import { OrientationComponent } from "./orientation/orientation.component";
import { PilotTestimonialComponent } from "./pilot-testimonial/pilot-testimonial.component";
import { HomeMtmResolver } from "./resolvers/home-mtm.resolver";
import { OrientationResolver } from "./resolvers/orientation.resolver";
import { SherpaTestimonialComponent } from "./sherpa-testimonial/sherpa-testimonial.component";
import { SherpaCardComponent } from "./sherpas/sherpa-card/sherpa-card.component";
import { SherpasCardPaginationComponent } from "./sherpas/sherpas-card-pagination/sherpas-card-pagination.component";
import { SherpasComponent } from "./sherpas/sherpas.component";
import { TalkerTestimonialComponent } from "./talker-testimonial/talker-testimonial.component";
import { TalkersComponent } from "./talkers/talkers.component";
import { WelcomeComponent } from "./welcome/welcome.component";

@NgModule({
  declarations: [
    HomeComponent,
    TalkerTestimonialComponent,
    SherpaTestimonialComponent,
    PilotTestimonialComponent,
    WelcomeComponent,
    TalkersComponent,
    SherpasComponent,
    SherpaCardComponent,
    SherpasCardPaginationComponent,
    OrientationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxPermissionsModule.forChild(),
    RouterModule.forChild([
      {
        path: "",
        pathMatch: "full",
        resolve: {
          mtm: HomeMtmResolver
        },
        component: HomeComponent,
      },
      {
        path: "",
        pathMatch: "prefix",
        component: FunnelComponent,
        children: [
          {
            path: "orientation",
            pathMatch: "full",
            resolve: {
              profile: OrientationResolver
            },
            component: OrientationComponent,
          },
          {
            path: "talker-testimonial",
            pathMatch: "full",
            component: TalkerTestimonialComponent,
          },
          {
            path: "sherpa-testimonial",
            pathMatch: "full",
            component: SherpaTestimonialComponent,
          },
          {
            path: "pilot-testimonial",
            pathMatch: "full",
            component: PilotTestimonialComponent,
          },
          {
            path: "welcome",
            pathMatch: "full",
            component: WelcomeComponent,
          },
          {
            path: "sherpas",
            pathMatch: "prefix",
            children: [
              {
                path: "",
                pathMatch: "full",
                component: SherpasComponent,
              },
              {
                path: ":id",
                pathMatch: "full",
                resolve: {
                  user: SherpaResolver
                },
                runGuardsAndResolvers: 'always',
                component: SherpaDetailsComponent,
              }
            ]
          },
          {
            path: "talkers",
            pathMatch: "full",
            component: TalkersComponent,
          },
        ],
      },
    ]),
  ],
  providers: [HomeMtmResolver, OrientationResolver, HomeMtmResolver]
})
export class HomeModule {}
