import { Component } from "@angular/core";

import { TestimonialQuoteType } from "../testimonial-quote.type";

@Component({
    selector: 'app-talkers',
    templateUrl: './talkers.component.html',
    styleUrls: ['./talkers.component.scss']
})

export class TalkersComponent {
    testimonials: TestimonialQuoteType[] = [
        {
          content: '“Très enrichissant dans le contenu, simplicité d\'échange avec la sherpa, adaptation à mon contexte, aucun jugement.”',
          author: 'N',
          color: '#FF8B05'
        }, {
          content: '“J’ai appris beaucoup de choses durant ces sessions qui m’ont été utiles dans mon quotidien aussi bien pro que perso ! Je  recommande volontiers We Talk auprès des collègues !”',
          author: 'S',
          color: '#8364ab'
        }, {
          content: '“Plateforme géniale, travail bénéfique pour ma part. À communiquer plus intensément afin que cela soit connu et proposé pour bon nombre d\'entre nous.”',
          author: 'M',
          color: '#bdabd4'
        }, {
          content: '“C\'était très pro, intéressant, constructif. J\'ai été impressionné d\'avoir autant appris juste en découvrant les autres situations. Cette posture de "non-co-développement" a été pour moi très riche. Très belle expérience, merci. “',
          author: 'H',
          color: '#FF8B05'
        }, {
          content: '“C’est hallucinant en 4 séances où j’en suis ! J’ai changé de perception me concernant, sur ma vie et la situation dans laquelle j’étais. “',
          author: 'N',
          color: '#8364ab'
        },
      ];
}