import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Organization } from "src/app/organizations/models/organizations.entity";
import { OrganizationRepository } from "src/app/organizations/repositories/organization.repository";
import { ConfirmData } from "src/app/shared/components/confirm/confirm.component";
import { DialogComponent } from "src/app/shared/components/dialog/dialog.component";
import { DialogRef } from "src/app/shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "src/app/shared/components/dialog/dialog.token";

export interface OrganizationData extends ConfirmData {
  organization: Organization;
  step: number;
  organizations?: Organization[];
}

@Component({
  selector: "archive-organization-dialog",
  templateUrl: "./archive-organization-dialog.html",
  styleUrls: ["./archive-organization-dialog.scss"],
})
export class ArchiveOrganizationDialog
  extends DialogComponent<boolean, OrganizationData>()
  implements OnInit, OnDestroy {
  organizationDestination = new FormControl("");

  selectedOrganization: any = null;

  apiCalling = false;

  constructor(
    @Inject(DIALOG_DATA)
    public override data: OrganizationData,
    private readonly ref: DialogRef<boolean>,
    private readonly organizationRepository: OrganizationRepository
  ) {
    super(ref);
  }

  static override isPopIn = true;

  ngOnInit() {
    super.onInit();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  private canArchiveOrganization() {
    this.apiCalling = true;
    this.organizationRepository
      .checkBeforeArchive(this.data.organization.id)
      .subscribe((data) => {
        const hasSiblings = data.parent || data.siblings?.length;
        if (data.members.length > 0) {
          this.apiCalling = false;
          if (hasSiblings) {
            this.data = {
              ...this.data,
              organizations: data.parent
                ? [data.parent, ...data.siblings]
                : data.siblings,
              step: 1,
              title:
                "Des utilisateurs sont présents dans cette organisation. Voulez-vous déplacer tous ces utilisateurs vers une organisation liée ?",
              confirmMessage: "Déplacer les utilisateurs",
            };
          } else {
            this.data = {
              ...this.data,
              step: 2,
              title:
                "Des utilisateurs sont présents dans cette organisation. Veuillez les supprimer ou les déplacer dans une autre organisation.",
              confirmMessage: "OK",
            };
          }
        } else if (data.units > 0) {
          this.apiCalling = false;
          if (hasSiblings) {
            this.data = {
              ...this.data,
              organizations: data.parent
                ? [data.parent, ...data.siblings]
                : data.siblings,
              step: 3,
              title:
                "Cette organisation possède encore des crédits. Voulez-vous transférer les crédits restants vers une organisation liée ?",
              confirmMessage: "Transférer les crédits",
            };
          } else {
            this.apiCalling = false;
            this.data = {
              ...this.data,
              step: 4,
              title:
                "Cette organisation possède encore des crédits. Si vous continuez, ces crédits seront perdus. Êtes-vous sûr de vouloir continuer ?",
              confirmMessage: "Supprimer",
            };
          }
        } else {
          this.apiCalling = true;
          this.organizationRepository
            .archiveOrganization({ organizationId: this.data.organization.id })
            .subscribe(() => {
              this.apiCalling = false;
              this.close(true);
            });
        }
      });
  }

  validate() {
    if (this.data.step === 1) {
      this.apiCalling = true;
      // move users
      this.organizationRepository
        .moveUsersToOrganization({
          fromOrganizationId: this.data.organization.id,
          destinationOrganizationId: this.selectedOrganization.id,
        })
        .subscribe(() => {
          this.canArchiveOrganization();
        });
    }

    if (this.data.step === 2) {
      this.close(false);
    }

    if (this.data.step === 3) {
      this.apiCalling = true;
      // move units
      this.organizationRepository
        .moveUnitsToOrganization({
          fromOrganizationId: this.data.organization.id,
          destinationOrganizationId: this.selectedOrganization.id,
        })
        .subscribe(() => {
          this.canArchiveOrganization();
        });
    }

    if (this.data.step === 4) {
      this.apiCalling = true;
      this.organizationRepository
        .archiveOrganization({ organizationId: this.data.organization.id })
        .subscribe(() => {
          this.apiCalling = false;
          this.close(true);
        });
    }
    this.canArchiveOrganization();
  }

  parseMessageToHtml(message?: string): string {
    const sentences = message?.split("\n") ?? [];
    return sentences.reduce((htmlResult, sentence) => {
      htmlResult += `<p class="margin-bottom">${sentence}</p>`;

      return htmlResult;
    }, "");
  }

  handleOptionSelected(option: any) {
    this.selectedOrganization = option;
  }

  cancel() {
    this.close(false);
  }
}
