<div class="padding-wrapper">
  <div class="wrapper">
    <h1 class="margin-bottom">Mon compte</h1>

    <div class="header" *ngIf="profile">
      <div class="column gap center-children-vertically">
        <app-profile-icon [color]="getProfileInitialColorByRole()" [reload]="reload$" [editable]="true"
                          title="Modifier votre photo de profil" [big]="true" [profile]="profile"></app-profile-icon>
        <app-upload-file image link (updateFile)="updateImage($event)" >Changer de photo de profil</app-upload-file>
      </div>
      <div class="user-description">
        <div class="row gap">

          <h2 class="cooper">{{ capitalize(profile.firstName) }} {{ capitalize(profile.lastName) }}</h2>
          <app-icon button (click)="modifyNames()"
                    type="edit"></app-icon>
        </div>
        <p class="row gap center-children-vertically  text-bold">
          <app-icon type="email"/>
          {{profile.email}}</p>
        <p class="row gap center-children-vertically text-bold">
          <app-icon type="phone"/>
          {{profile.phoneNumber ?? 'Pas de numéro renseigné'}}
          <app-icon button (click)="modifyPhone()"
                    type="edit"></app-icon>
        </p>
        <p class="row gap center-children-vertically">Mot de passe : <span class="text-bold">********</span>
          <app-icon button (click)="modifyPassword()"
                    type="edit"></app-icon>
        </p>
        <p class="row gap center-children-vertically">Genre : <span class=" text-bold">{{gender}}</span> <app-icon button (click)="modifyGender()"
                                        type="edit"></app-icon></p>
        <p *ngIf="organization">Organisation
          : <span class=" text-bold">{{organization}}</span></p>
        <p>Rôle : <span class=" text-bold">{{role}}</span></p>
        <app-button [outline]="true" *ngxPermissionsOnly="'pilot'" (click)="switchProfile()">
          Changer de compte
        </app-button>
      </div>
      <div>
        <div class="center-children" *ngIf="isSherpa">
          <app-button [outline]="true" (click)="modifyTimeSlotPreferences()" type="button" title="Mes dispos">Modifier
            mes
            disponibilités
          </app-button>
        </div>
      </div>
    </div>
    <div class="margin-top-xl" *ngIf="isSherpa">
      <h2 class="margin-bottom">Ma présentation</h2>
      <ng-template #noVideoLink>
        <div class="presenting-wrapper">
          <div class="video-thumbnail-container margin-bottom" *ngIf="!!profile.personalInformation.presentingVideoLink; else noLink">
            <app-image [src]="videoThumbnail" alt="Sherpa video thumbnail"></app-image>
            <app-button [icon]="'trash'" class="margin-top center-children-horizontally" [outline]="true" (click)="deletePresentingVideo()" type="button" title="Mes dispos">
              Supprimer
            </app-button>
          </div>
        </div>
        <ng-template #noLink>
          <app-button [outline]="true" (click)="showAddVideo()" type="button" title="Ajout d'une vidéo"> Ajouter une vidéo </app-button>
          <br/>
        </ng-template>
      </ng-template>
      <ng-container *ngIf="showAddVideoLink; else noVideoLink">
        <form [formGroup]="videoLinkForm" (submit)="validate()">
          <h6>Lien :</h6>
          <div class="row gap margin-top-sm full-width">
            <app-input class="input-video-link" [error]="formError" [error-message]="errorMessage"  formControlName="link" type="link" placeholder="https://youtu.be/Ouf9bjNTD"/>
            <app-button class="confirm-button" type="submit" title="Saisie de ma présentation"> Confirmation </app-button>
          </div>
        </form>
      </ng-container>
      
      <ng-container *ngIf="!!profile.personalInformation.description && !editingDescription; else noPresentingText">
        <div class="presenting-text-container">
          <div class="rich-content-container" [innerHTML]="sanitizeHtml(profile.personalInformation.description)"></div>
          <div class="row modify-icon">
            <app-icon button title="Modifier" (click)="modifyDescription()" type="edit"></app-icon>
            <app-icon button title="Supprimer" (click)="deleteDescription()" type="trash"></app-icon>
          </div>
        </div>
      </ng-container>
      <br/>
      <div class="margin-top-xl">
          <ng-template #noPresentingText>
            <app-button *ngIf="!editingDescription && !addingDescription" (click)="addDescription()" type="button" title="Saisie de ma présentation"> Saisir ma présentation </app-button>
            <div class="margin-top" *ngIf="addingDescription">
              <div class="NgxEditor__Wrapper">
                <ngx-editor-menu [toolbar]="toolbar" [editor]="editor"> </ngx-editor-menu>
                <ngx-editor [editor]="editor" [ngModel]="description" (ngModelChange)="onDescriptionChange($event)" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
              </div>
              <div class="row gap presenting-text-action-button margin-top">
                <app-button title="Enregistrer"
                          (click)="updateDescription()"
                >Enregistrer</app-button> 
                <app-button [outline]="true" title="Enregistrer"
                          (click)="cancelDescriptionEdit()"
                >Annuler</app-button>  
            </div>    
            </div>
          </ng-template>
      
      <div class="margin-top" *ngIf="editingDescription">
        <div class="NgxEditor__Wrapper">
          <ngx-editor-menu [toolbar]="toolbar" [editor]="editor"> </ngx-editor-menu>
          <ngx-editor [editor]="editor" [ngModel]="description" (ngModelChange)="onDescriptionChange($event)" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
        </div>
        <div class="row gap presenting-text-action-button margin-top">
          <app-button title="Enregistrer"
                    (click)="updateDescription()"
          >Enregistrer</app-button> 
          <app-button [outline]="true" title="Enregistrer"
                    (click)="cancelDescriptionEdit()"
          >Annuler</app-button>  
        </div>    
      </div>
       
    </div>

    <div class="row center-children-horizontally">
      <a class="margin-top-xl" (click)="deleteAccount()" type="button" title="Supprimer mon compte">Supprimer mon compte</a>
    </div>


  </div>

  <div class="full-screen mask center-children" *ngIf="loading">
    <app-spinner></app-spinner>
  </div>
</div>
