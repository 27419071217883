import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from "@angular/forms";
import { BehaviorSubject, debounceTime, merge, Observable, Subject, switchMap, takeUntil, tap } from "rxjs";
import { map } from "rxjs/operators";

import { IPaginationRepository } from "../../../repositories/pagination.interface";
import { EntityFormGroup } from "../../../types/entity-form-group";
import { SnackbarService } from "../../snackbar/snackbar.service";
import { TableConfig, SortOrder } from "../../table/table.component";


@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html'
})
export class TablePaginationComponent<Entity extends { id: string }, EntityFilterForm extends {
  [key: string]: string | boolean
}, UniqueKey extends keyof Entity, EntityPropertiesFilter extends EntityFilterForm = EntityFilterForm> implements OnInit, OnDestroy {
  @Input() mainRepository!: IPaginationRepository<Entity, EntityFilterForm>;

  @Input() name!: string;

  @Input('unique-key') uniqueKey: UniqueKey = 'id' as UniqueKey;

  @Input() newItems$: Subject<Entity[]> = new Subject<Entity[]>();

  @Input() isFemaleName = false;

  @Input() isWrittenInPlural = false;

  @Input() filterForm?: EntityFormGroup<EntityFilterForm>;

  @Input() propertiesFilters$?: BehaviorSubject<Partial<EntityPropertiesFilter>>;

  @Input() otherFilterOptionCount = 0;

  @Input() tableConfig!: TableConfig<Entity>;

  @Output() add = new EventEmitter<void>();

  @Output() download = new EventEmitter<void>();

  @Output() clear = new EventEmitter<void>();

  @Output() select = new EventEmitter<Entity[UniqueKey][]>();

  @Output() goTo = new EventEmitter<Entity[UniqueKey]>();

  @Output('totalCount') totalCountEmitter = new EventEmitter<number>();

  @Input() searchForm!: FormControl<string | null>;

  @ViewChild('bottomTag') lastItem!: ElementRef<HTMLElement>;

  orderFilter$: BehaviorSubject<SortOrder<Entity> | undefined> = new BehaviorSubject<SortOrder<Entity> | undefined>(undefined);

  items$ = new BehaviorSubject<Entity[]>([]);

  loading$ = new BehaviorSubject<boolean>(true);

  nextPageLoading = false;

  firstLoaded = false;

  pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(25);

  page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);


  totalCount?: number;

  protected destroy$ = new Subject<void>();

  constructor(private readonly snackBarService: SnackbarService
  ) {
  }

  ngOnInit(): void {
    this.page$
      .pipe(
        tap(() => {
          this.loading$.next(true);
        }),
        debounceTime(300),
        switchMap(() =>
          this.mainRepository.paginate({
            page: this.page$.getValue(),
            pageSize: this.pageSize$.getValue(),
            search: this.searchForm.value ?? '',
            properties: this.propertiesFilters$?.getValue(),
            sortBy: this.orderFilter$?.getValue()
          })),
        takeUntil(this.destroy$)
      )
      .subscribe(({ items, totalCount }) => {
        this.firstLoaded = true;
        this.items$.next(this.page$.getValue() === 1 ? items : [ ...this.items$.getValue(), ...items ]);
        this.totalCountEmitter.emit(totalCount);
        this.totalCount = totalCount;
        this.nextPageLoading = false;
        this.loading$.next(false);
        this.onScrollEvent();
      });

    merge(this.searchForm.valueChanges, this.propertiesFilters$ ?? new Observable(), this.pageSize$, this.mainRepository.reload$, this.orderFilter$)
      .subscribe({
        next: () => {
          this.page$.next(1);
        }
      });

    this.filterForm?.valueChanges
      .pipe(
        map(changes => Object.keys(changes).reduce((result: Partial<EntityFilterForm>, key) => ({
          ...result,
          [key]: changes[key] === '' ? undefined : changes[key]
        }), {} as Partial<EntityFilterForm>)),
        takeUntil(this.destroy$))
      .subscribe({
        next: (changes) => {
          this.propertiesFilters$?.next({
            ...this.propertiesFilters$.value,
            ...changes
          });
        },
        error: () => this.snackBarService.pushMessage('Une erreur s\'est produite', 'error')
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  public loadNextPage(): void {
    if (!this.nextPageLoading) {
      this.nextPageLoading = true;
      this.page$.next(this.page$.getValue() + 1);
    }
  }

  selectRows(id: Entity[UniqueKey][]) {
    this.select.emit(id);
  }

  goToRow(id: Entity[UniqueKey]) {
    this.goTo.emit(id);
  }

  onScrollEvent() {
    if (window.innerHeight > this.lastItem.nativeElement.getBoundingClientRect().top
      && (this.totalCount || 0) > this.items$.getValue().length) {
      this.loadNextPage();
    }
  }

  sortData(option: SortOrder<Entity> | undefined) {
    this.orderFilter$.next(option);
  }
}
