import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class MySpaceTabStore {
  private readonly selectedTab$ = new BehaviorSubject<number>(1);

  setSelectedTab(tab: number) {
    this.selectedTab$.next(tab);
  }

  getSelectedTab() {
    return this.selectedTab$.getValue();
  }

  getObservable() {
    return this.selectedTab$.asObservable();
  }
}