import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { debounceTime, filter, Observable, takeUntil, tap } from "rxjs";

import { ModifyPasswordPayload } from "../../authentication/authentication.type";
import { AuthenticationService } from "../../authentication/services/authentication.service";
import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { EntityFormGroup } from "../../shared/types/entity-form-group";


@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: [ './edit-password.component.scss' ]
})
export class EditPasswordComponent extends DialogComponent<ModifyPasswordPayload>() implements OnInit {
  oldPasswordFalse = false;

  oldPasswordCorrect = false;

  oldPasswordForm!: EntityFormGroup<{ password: string }>;

  newPasswordForm!: EntityFormGroup<{ password: string, confirmation: string }>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly ref: DialogRef<ModifyPasswordPayload>,
    private readonly authenticationService: AuthenticationService
  ) {
    super(ref);
    this.oldPasswordForm = this.formBuilder.group({
      password: new FormControl('', { validators: Validators.required, nonNullable: true }),
    });

    this.newPasswordForm = this.formBuilder.group({
      password: new FormControl('', { validators: Validators.required, nonNullable: true }),
      confirmation: new FormControl('', { validators: Validators.required, nonNullable: true }),
    });
  }

  ngOnInit(): void {
    super.onInit();
    this.oldPasswordForm.valueChanges
      .pipe(
        tap(() => {
          this.oldPasswordFalse = false;
          this.oldPasswordCorrect = false;
        }),
        filter(changes => !!changes.password && changes.password.length >= 8),
        debounceTime(500),
        takeUntil(this.destroy$))
      .subscribe(changes => {
        if (changes.password) {
          this.checkOldPassword(changes.password).subscribe();
        }

      });
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  checkOldPassword(password: string): Observable<boolean> {
    return this.authenticationService.checkPassword(password).pipe(tap(result => {
      this.oldPasswordFalse = !result;
      this.oldPasswordCorrect = result;
    }));
  }

  validate(isValidated: boolean): void {
    if (isValidated) {
      super.close({
        oldPassword: this.oldPasswordForm.controls.password.value,
        newPassword: this.newPasswordForm.controls.password.value
      });
    } else {
      super.close();
    }
  }
}
