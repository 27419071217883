import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { I18nAbstractRepository } from "../../shared/repositories/i18n-abstract.repository";
import { ProfileService } from "../../users/services/profile.service";
import { CreateThematicTag, ThematicTag } from "../models/tag.model";

@Injectable()
export class TagRepository extends I18nAbstractRepository<ThematicTag, CreateThematicTag> {
  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              protected override readonly http: HttpClient,
              private readonly profileState: ProfileService) {
    super(http, ThematicTag, profileState);
    this.apiUrl = this.appConfig.apiUrl;
  }


  getEntityName(): string {
    return "tags";
  }
}
