import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

import { InPageTab } from "../../shared/components/in-page-tabs/in-page-tabs/in-page-tabs.component";
import { computeUserProfileRouterUrl } from "../../shared/tool-functions/user-details-url";
import { User } from "../../users/models/users.entity";
import { UsersRepository } from "../../users/repositories/users.repository";

@Component({
    selector: 'app-sherpas',
    templateUrl: './sherpas.component.html',
    styleUrl: './sherpas.component.scss',
    encapsulation: ViewEncapsulation.None
})

export class SherpasComponent implements OnInit {

    newItems$ = new Subject<User[]>();

    scroll$ = new Subject<void>();

    constructor(public mainRepository: UsersRepository, private readonly router: Router) {}

    ngOnInit(){
    }

    tabs: InPageTab[] = [
        {
          icon: "award-prize",
          color: "#ff8b05",
          title: "Coachs et thérapeutes certifiés",
          description: "",
          link: "#"
        },
        {
          icon: "suit-case",
          color: "#239692",
          title: "10 ans d'expérience en entreprise",
          description: "",
          link: "#"
        },
        {
          icon: "hands-heart",
          color: "#8c5fb0",
          title: "3 ans d'expérience en accompagnement",
          description: "",
          link: "#"
        },
      ];

    certifications = [
        {
            image: "icf"
        },
        {
            image: "sfcoach"
        },
        {
            image: "rncp"
        },
        {
            image: "emcc"
        }
    ]; 
    
    public goToUserDetails(user: User) {
      this.router.navigate(['home', ...computeUserProfileRouterUrl(user)]);
    }
    
    onScrollEvent() {
      this.scroll$.next();
    }
}
