<div class="wrapper">
    <h2 class="title">Activer mon compte</h2>
    <div class="margin-top-xl">
        Votre organisation vous a envoyé une invitation mais vous n'avez pas encore créé votre compte ?
        Pas de souci, entrez votre email pour recevoir à nouveau le mail d'inscription et accéder à la plateforme.
    </div>
    <div class="margin-top">
        <i>
            Si vous êtes particuliers et avez acheté un abonnement ou un parcours en ligne, la création de votre compte peut prendre 
            jusqu'à 48H. Passé ce délai, si vous ne recevez pas le mail d'inscription, n'hésitez pas à nous contacter.
        </i>
    </div>

    <form class="margin-top" [formGroup]="emailForm" (submit)="sendActivationEmail()">
        <app-input placeholder="Email" class="form-input margin-top margin-bottom" formControlName="email" type="email"></app-input>
        <div class="button-wrapper">
            <app-button class="margin-top" type="submit">{{mailButton}}</app-button>
        </div>
    </form>
    <div class="margin-top">
        <a href="auth/login">Revenir à la page de connexion</a>
    </div>
</div>