import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";

import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Gender, GenderDescription } from "../models/users.entity";

@Component({
  selector: 'app-edit-gender',
  templateUrl: './edit-gender.component.html',
  styleUrls: [ './edit-gender.component.scss' ]
})
export class EditGenderComponent extends DialogComponent<Gender, { gender:Gender }>() implements OnInit {

  genderForm!: FormControl<Gender>;

  genderPossibilities = Object.values(GenderDescription).map(gender => capitalize(gender));

  constructor(
    @Inject(DIALOG_DATA)
    public override readonly data: { gender: Gender },
    private readonly ref: DialogRef<string>,
  ) {

    super(ref);

    this.genderForm = new FormControl<Gender>(data.gender ?? Gender.OTHER, { nonNullable: true });
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  validate(isValidated: boolean): void {
    if (isValidated) {
      super.close((Object.values(Gender) as Gender[]).find(gender => GenderDescription[gender] === this.genderForm.value) ?? Gender.OTHER);
    } else {
      super.close();
    }
  }

}
