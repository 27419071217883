<div class="header row gap">
  <h1 class="title">Changement de mot de passe</h1>
  <app-icon button type="close"
              (click)="close()"></app-icon>
</div>

<section>

  <h3 class="margin-bottom">Ancien mot de passe</h3>
  <div [formGroup]="oldPasswordForm">
    <app-input [correct]="oldPasswordCorrect" [error]="oldPasswordFalse" error-message="Mot de passe incorrect" class="form-input" id="old-password" formControlName="password" type="password"
               placeholder="Veuillez entrer votre ancien mot de passe"
               [login-password]="true"></app-input>
  </div>
  <h3 class="margin-bottom">Nouveau mot de passe</h3>
  <app-password-setter [externalCondition]="oldPasswordCorrect" class="step" [passwordForm]="newPasswordForm"
                       (setPassword)="validate(true)"></app-password-setter>
</section>
