<div class="column no-gap">
  <div class="row gap" *ngFor="let item of items; let index = index;">
    <div class="grow">
      <ng-container
        *ngIf="contentTemplateRef"
        [ngTemplateOutlet]="contentTemplateRef"
        [ngTemplateOutletContext]="{$implicit:item}"
      >
      </ng-container>
    </div>
    <app-icon class="pointer tiny-margin-top" type="trash" (click)="removeItem(index)"></app-icon>
  </div>
  <app-button class="center-horizontally-by-margin" icon="add" (click)="addItem()">Ajouter</app-button>
</div>
