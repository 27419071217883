import { BrowserCacheLocation } from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser/dist/config/Configuration";

import { SignInMethod } from "../shared/storage/storage.service";

import { AppConfig, OauthConfig } from "./config.type";

export function appConfigFactory(): AppConfig {
  // @ts-ignore
  const config = window.WETALK_CONFIG;
  return {
    apiUrl: config.apiUrl,
    sentryDsn: config.sentryDsn,
    sentryEnv: config.sentryEnv,
    jitsiAppId: config.jitsiAppId,
    jitsiDomain: config.jitsiDomain,
    canDeactivateSendingEmail: config.canDeactivateSendingEmail,
    canResetData: config.canResetData,
    matomoSiteId: config.matomoSiteId,
  };
}

export function googleConfigFactory(): OauthConfig {
  // @ts-ignore
  const config = window.WETALK_CONFIG;
  return {
    apiUrl: config.apiUrl,
    clientId: config.googleClientId,
    authorityUrl: config.googleAuthorityUrl,
    type: SignInMethod.GOOGLE,
  };
}

export function azureConfigFactory(): Configuration {
  // @ts-ignore
  const config = window.WETALK_CONFIG;
  const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;
  return {
    auth: {
      clientId: config.azureClientId,
      authority: config.azureAuthorityUrl,
      postLogoutRedirectUri: config.frontUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE,
    },
  };
}
