<div class="train-card-wrapper wrap" *ngIf="nextSession && currentSessionIndex !== undefined"
     (click)="detailsOnMobile()">
  <div class="row grow-mobile">
    <div class="column grow-mobile gap meta-data-part">
      <div class="column">
        <p><strong>{{ formatDate(nextSession.date) }}</strong></p>
        <p >{{ formatTime(nextSession.date) }}</p>
      </div>

      <span >Durée : {{train.sessionDuration / (60 * 1E3)}} min</span>
      <p class="text-bold text-primary" >{{ getTrainTimeDelta(train) }}</p>
      <p class="color-danger">{{alertMessage}}</p>
    </div>
    <div class="image-slot">
      <app-image [width]="200" [height]="150" [src]="train.dynamic.thematic.imageUrl" />
    </div>
  </div>
  <div class="column title-part grow center-children-horizontally">
    <div class="cooper text-large text-black tiny-margin-bottom">{{train.dynamic.thematic.title}}</div>
    <div class="mobile-column tiny-margin-bottom " >

      <div class="row gap center-children-vertically width-300 train-card-border pointer" (click)="train.sherpa ? goToSherpa(train.sherpa): {}">
        <app-profile-icon *ngIf="train.sherpa" [profile]="train.sherpa" [tiny]="true" [editable]="false"></app-profile-icon>
        <div class="column no-gap">
          <span>{{ train.dynamic.dynamicType}}</span>
          <span *ngIf="train.sherpa">avec <u>{{train.sherpa.fullName}}</u></span>
        </div>
      </div>
      <div class="row gap center-children-vertically train-card-border">
        <div class="user-bubble center-children"><app-icon type="users"/></div>
        <span>{{train.memberShips.length}} membre{{train.memberShips.length > 1 ? 's' : '' }}</span>
      </div>

    </div>
    <div class="row gap center-children-vertically tiny-margin-top">
      <p>Progression</p>
      <div class="steps row">
        <div *ngFor="let session of train.sessions; let index = index;" class="step"
             [class.validated]="index < currentSessionIndex" [class.current]="index === currentSessionIndex"></div>
      </div>
    </div>
  </div>
  <div class="button-part mobile-row center tiny-gap">
    <app-button *ngIf="train.sherpa || !isSherpa" [primary]="true" title="Accès séance" icon="play"
                (click)="joinMeeting()">Accès séance
    </app-button>
    <app-button [disabled]="apiCalling" title="J'accompagne ce groupe" (click)="clickOnManage()"
                *ngIf="!train.sherpa && isSherpa">J'accompagne ce groupe
    </app-button>
    <app-button title="Détails du parcours" (click)="clickOnDetails()"
                *ngIf="train.sherpa || !isSherpa" [outline]="true">Détails
    </app-button>
  </div>
</div>
<div class="train-card-wrapper mobile-column" *ngIf="!nextSession">
  <div class="row grow gap margin-bottom-mobile">
    <div class="image-slot">
      <app-image [width]="200" [height]="150" [src]="train.dynamic.thematic.imageUrl" [alt]="train.dynamic.thematic.slug" />
    </div>
    <div class="column gap grow center-children-horizontally title-part">
      <div class="cooper text-large text-black">{{train.dynamic.thematic.title}}</div>
      <div class="row mobile-hidden">
        <span><strong>{{ train.dynamic.dynamicType}}</strong> avec <u>{{ train.sherpa ? train.sherpa.fullName : '' }}</u></span>
      </div>
      <div class="mobile-hidden">Parcours réalisé entre le {{train.getFirstSession().date.toLocaleDateString()}} et
        le {{train.getLastSession().date.toLocaleDateString()}}</div>
    </div>
  </div>
  <div class="desktop-hidden column tiny-gap">
    <div class="row" *ngIf="train.sherpa">
      <span><strong>{{ train.dynamic.dynamicType}}</strong> avec <u>{{train.sherpa.fullName}}</u></span>
    </div>
    <div>Parcours réalisé entre le {{train.getFirstSession().date.toLocaleDateString()}} et
      le {{train.getLastSession().date.toLocaleDateString()}}</div>
  </div>
  <div class="button-part mobile-row center tiny-gap">

    <app-button *ngIf="needToEvaluate || needToEvaluateSixMonths" title="Evaluer" (click)="evaluateTrain()">
      {{ needToEvaluateSixMonths ? "Faire le bilan de 6 mois" : "Faire le bilan" }}
    </app-button>
    <div class="row gap center-children-vertically" *ngIf="evaluationDate">
      <app-icon type="check-dot" />
      Bilan fait le {{ evaluationDate }}
    </div>
    <div class="row gap center-children-vertically" *ngIf="sixMonthsEvaluationDate">
      <app-icon type="check-dot" />
      Bilan à 6 mois fait le {{ sixMonthsEvaluationDate }}
    </div>
    <app-button title="Détails du parcours" (click)="clickOnDetails()"
                [outline]="true">Détails
    </app-button>

  </div>
</div>

