import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Subject } from "rxjs";


export type Tag = {
  id: string;
  name: string;
  highlighted?: boolean;
  selected?: boolean,

}

export type SelectionItem = {
  id: string,
  selected: boolean,
  name: string,
  highlighted?: boolean,
}

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: [ './tags.component.scss' ]
})
export class TagsComponent implements  OnChanges {

  public tagsSelected: SelectionItem[] = [];

  public destroy$ = new Subject<void>();

  public readonly cardTrackBy = (index: number, item: SelectionItem) => item.id;

  @Input() tags: Tag[] | null = [];

  @Input() firstIndexSelected = false;

  @Input() disabled = false;

  @Input() addEnabled = false;

  @Input() selectOnlyOne = false;

  @Input() allSelectEnabled = false;

  @Input() highlightEnabled = false;

  @Output() selectTags = new EventEmitter<{ id: string, selected: boolean, name: string }[]>();

  @Output() clickOnTag = new EventEmitter<string>();

  @Output() addTag = new EventEmitter<void>();


  ngOnChanges(changes: SimpleChanges) {
    const isFirstChange = changes.tags && !changes.tags.previousValue;
    this.tagsSelected = (this.tags ?? []).map((tag, index) => ({
      id: tag.id,
      selected:  tag?.selected || this.selectOnlyOne && index === 0 && isFirstChange && this.firstIndexSelected,
      name: tag.name,
      highlighted: !!tag.highlighted
    }));
  }

  onTagSelect(tagName: string) {
    this.tagsSelected.forEach(tag => {
      tag.selected = this.selectOnlyOne
        ? tag.name === tagName
        : tag.name === tagName ? !tag.selected : tag.selected;
    });
    this.selectTags.emit(this.tagsSelected);
    this.clickOnTag.emit(this.tagsSelected.find(tag => tag.name === tagName)?.id ?? '');
  }

  onAddTag() {
    this.addTag.emit();
  }

  selectAll() {
    const isAllSelected = this.allSelected;
    this.tagsSelected.forEach(tag => {
      tag.selected = !isAllSelected;
    });
    this.selectTags.emit(this.tagsSelected);
  }

  get allSelected(): boolean {
    return this.tagsSelected.every(tag => tag.selected);
  }

}
