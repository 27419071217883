import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, RouterOutlet } from "@angular/router";
import { NgxPermissionsModule } from "ngx-permissions";

import { OrientationResolver } from '../home/resolvers/orientation.resolver';
import { FunnelComponent } from "../shared/components/funnel/funnel.component";
import { SharedModule } from "../shared/shared.module";

import { CreateTrainComponent } from './create-train/create-train.component';
import { DynamicsDistinctionComponent } from './dynamics-distinction/dynamics-distinction.component';
import { EditThematicComponent } from './edit-thematic/edit-thematic.component';
import { NewTagComponent } from './new-tag/new-tag.component';
import { TagRepository } from "./repositories/tag.repository";
import { ThematicRepository } from "./repositories/thematic.repository";
import { ThematicResolver } from "./resolvers/thematic.resolver";
import { ThematicsMtmResolver } from './resolvers/thematics-mtm.resolver';
import { TimeslotResolver } from "./resolvers/timeslot.resolver";
import { SelectDateComponent } from './select-date/select-date.component';
import { SelectDynamicComponent } from './select-dynamic/select-dynamic.component';
import { SelectTimeComponent } from './select-time/select-time.component';
import { SelectTrainComponent } from './select-train/select-train.component';
import { SherpaThematicsComponent } from "./sherpa-thematics/sherpa-thematics.component";
import { ThematicDetailsComponent } from './thematic-details/thematic-details.component';
import { ThematicsListComponent } from './thematics-list/thematics-list.component';
import { TrainCongratsComponent } from './train-congrats/train-congrats.component';
import { TrainResumeComponent } from './train-resume/train-resume.component';


@NgModule({
  declarations: [
    ThematicsListComponent,
    NewTagComponent,
    EditThematicComponent,
    SelectTrainComponent,
    ThematicDetailsComponent,
    CreateTrainComponent,
    SelectDateComponent,
    SelectTimeComponent,
    TrainResumeComponent,
    TrainCongratsComponent,
    SelectDynamicComponent,
    DynamicsDistinctionComponent,
    SherpaThematicsComponent

  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxPermissionsModule.forChild(),
    RouterOutlet,
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        component: ThematicsListComponent,
      },
      {
        path: 'my-thematics',
        pathMatch: 'full',
        component: SherpaThematicsComponent,
        data: {
          permissions: {
            only: 'sherpa',
            redirectTo: '/'
          }
        }
      },
      {
        path: '',
        pathMatch: 'prefix',
        component: FunnelComponent,
        children: [
          {
          path: ':slug',
          pathMatch: 'prefix',
          resolve: {
            thematic: ThematicResolver
          },
          runGuardsAndResolvers: 'always',
          children: [
            {
              path: '',
              pathMatch: 'full',
              component: ThematicDetailsComponent,
            },
            {
              path: 'edit',
              pathMatch: 'full',
              component: EditThematicComponent,
              data: {
                permissions: {
                  only: 'admin',
                  redirectTo: '/'
                }
              }
            },
            {
              path: 'chose-train',
              pathMatch: 'full',
              component: SelectTrainComponent,
            },
            {
              path: 'create-train',
              pathMatch: 'prefix',
              resolve: {
                profile: OrientationResolver
              },
              component: CreateTrainComponent,
              children: [
                {
                  path: 'chose-dynamic',
                  pathMatch: 'full',
                  component: SelectDynamicComponent
                },
                {
                  path: ':dynamic',
                  pathMatch: 'prefix',
                  children: [
                    {
                      path: 'date',
                      pathMatch: 'full',
                      component: SelectDateComponent
                    },
                    {
                      path: 'time',
                      pathMatch: 'full',
                      component: SelectTimeComponent
                    },
                    {
                      path: 'resume',
                      pathMatch: 'full',
                      component: TrainResumeComponent
                    },
                    {
                      path: 'available-trains',
                      pathMatch: 'full',
                      component: SelectTrainComponent
                    }
                  ]
                },
              ]
            }
          ]
        } ]
      } ]),
  ],
  providers: [ ThematicRepository, TagRepository, ThematicResolver, TimeslotResolver, ThematicsMtmResolver ]
})
export class ThematicsModule {
}
