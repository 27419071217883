<div class="header row gap">
  <h1 class="title">Configuration des créneaux</h1>
  <app-icon button type="close"
              (click)="close()"></app-icon>
</div>

<div class="calendar-wrapper">
  <div class="agenda">
    <p class="margin-bottom margin-top">Vous pouvez désactiver un créneau en cliquant dessus.</p>
    <div class="center-children horizontally row gap margin-bottom">
      <div>Légende:</div>
      <div class="tag primary">activé</div>
      <div class="tag">désactivé</div>
    </div>
    <app-timezone-warning></app-timezone-warning>
    <app-weekly-calendar class="center-vertically-by-margin" [weekDaysAvailable]="weekDayAvailable"
                         [dayTimes]="dayTimes" (clickOnDay)="selectDayTime($event)"></app-weekly-calendar>
  </div>
  <div class="description margin-top">
    <h3 class="margin-bottom">Configuration</h3>

    <form [formGroup]="dayTimeForm">
      <p>Séance disponible toutes les :</p>
      <div class="input-with-unit">
        <app-input class="value-input" formControlName="delayValue" type="number"></app-input>
        <app-input class="unit" formControlName="delayUnit" type="default"
                   placeholder="durée"
                   [dropdownData]="delayUnits"
        ></app-input>
      </div>

      <p>Jours :</p>
      <app-checkmarks [checkboxes]="daysCheckboxes" item-name="Jour"
                      (changeSelection)="updateWeekDayCheckBoxes($event)"></app-checkmarks>

      <div class="times">
        <p>De :</p>
        <app-input type="time" formControlName="fromTime"></app-input>

        <p>À :</p>
        <app-input type="time" formControlName="toTime"></app-input>
      </div>
    </form>

  </div>
</div>
<div class="add-button margin-top center-children">
  <app-button type="button" (click)="save()" title="Valider">
    Valider
  </app-button>
</div>
