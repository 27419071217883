<div class="tabs-wrapper no-select" [class.entering]="entering" [class.hidden]="isMeeting || isRespondingTypeform" *ngIf="!!profile">
  <div class="head center-children-vertically">
    <app-image class="logo" [width]="150" [height]="30" src="assets/logos/logo_wetalk.png" alt="logo"/>
  </div>
  <div class="mobile-hidden tabs-list">
    <div class="tab" [class]="{'selected': isTabSelected(tab)}" *ngFor="let tab of tabs" (click)="clickOnLink(tab.id)">
      <div class="tab-content">
        <div class="center-children">
          <app-icon class="icon" [class.icon-selected]="isTabSelected(tab)" [type]="tab.icon"></app-icon>
        </div>
        <div class="center-children tab-description"><div>{{ tab.name[profile.role] ?? tab.name.all  }}</div></div>
      </div>
    </div>
  </div>
  <div class="bottom-part">
    <div *ngxPermissionsExcept="'admin'" (click)="openFeedBackDialog()" class="feedback-container column gap">
      <div *ngIf="!rating" class="row gap center-children">
        <app-icon size="20" type="sad-emoji" color="#ff4f4f"/>
        <app-icon size="20" type="neutral-emoji" color="#FCC418"/>
        <app-icon size="20" type="laughy-emoji" color="#22C997"/>
      </div>
      <div *ngIf="rating" class="row gap center-children">
        <app-icon size="20" [color]="mapRatingValueToIconType(rating).color" [type]="mapRatingValueToIconType(rating).type"/>
        </div>

      <div class="center-horizontally-by-margin mobile-hidden">
        <span *ngIf="!rating; else elseBlock">Votre satisfaction</span>
        <ng-template #elseBlock>
          <span>Envoyé le {{ feedbackDate }}</span>
        </ng-template>
      </div>
    </div>
    <div class="profile" *ngIf="!organizationSelected" (click)="goToProfile()">
      <div class="profile-icon" [style.color]="getColorByRole(profile.role)">
        <app-profile-icon [color]="getColorByRole(profile.role)" [isOnTabs]="true" [reload]="reloadImage$" [profile]="profile"></app-profile-icon>
      </div>
      <div class="mobile-hidden" >{{ capitalize(role) }}</div>
    </div>
    <div 
        class="profile" 
        *ngIf="organizationSelected" 
        matomoClickCategory="menuTalker"
        matomoClickAction="Click"
        matomoClickName="profileButton" 
        (click)="switchProfile()">
          <div class="profile-icon center-children">
            <div class="organization-icon center-children">
              <app-icon type="building" class="mobile-hidden"/>
              <app-icon type="building" class="desktop-hidden" size="10"/>
            </div>
          </div>
      <div class="mobile-hidden"> Pilote </div>
    </div>


    <div class="profile center-children mobile-hidden" *ngxPermissionsOnly="'pilot'" (click)="switchProfile()">
      Changer de compte
    </div>
    <div class="power mobile-hidden" (click)="logout()">
      <div class="center-children">
        <app-icon type="power" />
      </div>
      <div class="tab-description">{{isImpersonating ? 'Repasser admin' : 'Déconnexion'}}</div>
    </div>
    <div class="menu desktop-hidden center-children" [dropdownTriggerFor]="mobileTabs">
      <app-icon type="menu"/>
    </div>
  </div>
</div>
<div class="space"></div>

<app-dropdown #mobileTabs [isFilter]="true">
  <div class="tabs-list">
    <div class="tab" [class]="{'selected': isTabSelected(tab)}" *ngFor="let tab of tabs" (click)="clickOnLink(tab.id)">
      <div class="tab-content">
        <div class="center-children">
          <app-icon class="icon" [class.icon-selected]="isTabSelected(tab)" [type]="tab.icon"/>
        </div>
        <div class="tab-description">{{ tab.name[profile.role] ?? tab.name.all  }}</div>
      </div>
    </div>
    <hr />
    <div class="power" (click)="logout()">
      <div class="center-children">
        <app-icon type="power" />
      </div>
      <div class="tab-description">{{isImpersonating ? 'Repasser admin' : 'Déconnexion'}}</div>
    </div>
  </div>
</app-dropdown>
