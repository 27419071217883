import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, filter, map, Observable, switchMap, combineLatest, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { DashboardRepository } from "../dashboard.repository";
import { DateInterval } from "../utils/bar-chart-tools";

interface MetricRange {
  key: 'veryLow' | 'low' | 'high' | 'veryHigh';
  count: number;
  percentage: number;
}

interface MetricStats {
  distribution: MetricRange[];
  average: number;
}

interface EmotionalStateStats {
  lifeSatisfaction: MetricStats;
  workSatisfaction: MetricStats;
  affectAndRelation: MetricStats;
}

@Component({
  selector: 'app-talkers-emotional-state',
  templateUrl: './talkers-emotional-state.component.html',
  styleUrls: ['./talkers-emotional-state.component.scss']
})
export class TalkersEmotionalStateComponent implements OnInit {
  @Input() organizationIds$!: Observable<string[]>;

  @Input() dateInterval$ = new BehaviorSubject<DateInterval>({
    fromDate: new Date(),
    toDate: new Date()
  });

  @ViewChild('impactSection') impactSection!: ElementRef<HTMLElement>;


  private emotionalState$ = new BehaviorSubject<EmotionalStateStats | null>(null);

  private emotionalStateProgression$ = new BehaviorSubject<any>(null);

  private loading$ = new BehaviorSubject<boolean>(false);

  private error$ = new BehaviorSubject<string | null>(null);

  readonly lifeSatisfactionData$ = this.emotionalState$.pipe(
    map(state => state?.lifeSatisfaction ?? null)
  );

  readonly workSatisfactionData$ = this.emotionalState$.pipe(
    map(state => state?.workSatisfaction ?? null)
  );

  readonly affectAndRelationData$ = this.emotionalState$.pipe(
    map(state => state?.affectAndRelation ?? null)
  );

  typeformVariables = ["lifeSatisfaction", "affectAndRelation", "workSatisfaction"];

  progressionsDistributions = ["before", "after", "sixMonthsAfter"];

  constructor(private readonly dashboardRepository: DashboardRepository) {}

  ngOnInit() {
    combineLatest([
        this.organizationIds$,
        this.dateInterval$
      ]).pipe(
        tap(([orgIds, _]) => {
          if (!orgIds?.length) {
            this.emotionalState$.next(null);
            this.emotionalStateProgression$.next(null);
            return;
          }
          this.loading$.next(true);
          this.error$.next(null);
        }),
        filter(([orgIds, _]) => !!orgIds?.length),
        switchMap(([orgIds, interval]) =>
          combineLatest([
            this.dashboardRepository.getTalkersEmotionalState({ 
              organizationIds: orgIds,
              startDate: interval.fromDate.toISOString(),
              endDate: interval.toDate.toISOString() 
            }),
            this.dashboardRepository.getTalkersEmotionalStateProgression({ 
              organizationIds: orgIds,
              startDate: interval.fromDate.toISOString(),
              endDate: interval.toDate.toISOString()
            })
          ]).pipe(
            catchError(error => {
              this.error$.next('Failed to load emotional state data');
              this.loading$.next(false);
              return of([null, null]);
            })
          )
        )
      ).subscribe(([state, progression]) => {
        if (state) {
          this.emotionalState$.next(state);
        }
        if (progression) {
          this.emotionalStateProgression$.next(progression);
        }
        this.loading$.next(false);
      });
  }

  get emotionalState() {
    return this.emotionalState$.asObservable();
  }

  get emotionalStateProgression() {
    return this.emotionalStateProgression$.asObservable();
  }

  get loading() {
    return this.loading$.asObservable();
  }

  get error() {
    return this.error$.asObservable();
  }

  getColorByScore(score: number): string {
    if (score <= 25) return '#FF4F4E';
    if (score <= 50) return '#FC9418';
    if (score <= 75) return '#94D82C';
    return '#22C997';
  }

  getMetricData$(metric: string): Observable<MetricStats | null> {
    return this.emotionalState$.pipe(
      map(state => {
        if (!state) return null;
        switch (metric) {
          case 'lifeSatisfaction': return state.lifeSatisfaction;
          case 'workSatisfaction': return state.workSatisfaction;
          case 'affectAndRelation': return state.affectAndRelation;
          default: return null;
        }
      })
    );
  }

  getMetricLabel(metric: string): string {
    switch (metric) {
      case 'lifeSatisfaction': return 'Satisfaction de vie';
      case 'workSatisfaction': return 'Satisfaction au travail';
      case 'affectAndRelation': return 'Affect et relation';
      default: return '';
    }
  }

  getIconType(metric: string) {
    switch (metric) {
      case 'lifeSatisfaction': return 'smile';
      case 'workSatisfaction': return 'suit-case';
      case 'affectAndRelation': return 'heart';
      default: return 'smile';
    }
  }

  getProgressionData$(metric: string) {
    return this.emotionalStateProgression$.pipe(
        map(data => {
          if(!data) return [0, 0, 0];
            const metricData = data[metric];
            return [
                metricData?.before?.averageScore ?? 0,
                metricData?.after?.averageScore ?? 0,
                metricData?.sixMonths?.averageScore ?? 0
            ];
        })
    );
}

getProgressionCounts$(metric: string) {
    return this.emotionalStateProgression$.pipe(

        map(data => {
            if(!data) return [0, 0, 0];
            const metricData = data[metric];
            return [
                metricData?.before?.count ?? 0,
                metricData?.after?.count ?? 0,
                metricData?.sixMonths?.count ?? 0
            ];
        })
    );
}

hasNonZeroValues(data: MetricRange[]): boolean {
  return data.some(range => range.count > 0);
}

hasProgressionData(progressionData: number[]): boolean {
  return progressionData.some(value => value !== 0);
}
}