import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
// import { MatomoTracker } from 'ngx-matomo-client';
import { BehaviorSubject, combineLatestWith, filter, Subject, switchMap, takeUntil, tap } from "rxjs";

import { DashboardRepository } from '../dashboard/dashboard.repository';
import { capitalize } from "../shared/tool-functions/capitalize";
import { mediumCategoryColors } from "../thematics/colors";
import { ThematicCategory } from "../thematics/models/category.model";
import { Thematic } from "../thematics/models/thematic.model";
import { ThematicRepository } from "../thematics/repositories/thematic.repository";
import { Train } from "../trains/models/train.entity";
import { TrainRepository } from "../trains/repositories/train.repository";
import { Talker } from "../users/models/talker.entity";
import { Role } from "../users/models/users.entity";
import { ProfileService } from "../users/services/profile.service";

import { MySpaceTabStore } from './services/my-space.store';


@Component({
  selector: 'app-my-space',
  templateUrl: './my-space.component.html',
  styleUrls: [ './my-space.component.scss' ]
})
export class MySpaceComponent implements OnInit, OnDestroy {

  loading = true;

  destroy$ = new Subject<void>();

  currentTrains: Train[] = [];

  overTrains: Train[] = [];

  sessionsDone!: number;

  hoursDone!: number;

  favoriteThematics: Thematic[] = [];

  favoriteCategories: ThematicCategory[] = [];

  selectedTab: number = 1;

  selectedTabIndex$ = new BehaviorSubject<number>(1);

  emotionalResponseData: any;

  radarChartDataSubject$ = new BehaviorSubject<any>(null);

  // private readonly tracker = inject(MatomoTracker);

  constructor(private readonly router: Router,
              private readonly route: ActivatedRoute,
              private readonly profileService: ProfileService,
              private readonly thematicRepository: ThematicRepository,
              private readonly trainRepository: TrainRepository,
              private readonly tabStore: MySpaceTabStore,
              private dashboardRepository: DashboardRepository) {
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {

    this.route.queryParams.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      const tab = +params.tab || 1;
      this.selectTab(tab);
    });
    
    this.selectedTabIndex$.pipe(
      filter(index => index === 1),
      switchMap(() => this.profileService.getMyProfile()),
      filter((profile): profile is Talker => profile.role === Role.Talker),
      combineLatestWith(
        this.trainRepository.getMyTrains(), 
        this.thematicRepository.getAvailableOnes(), 
        this.thematicRepository.getCategories()
      ),
      takeUntil(this.destroy$)
    ).subscribe(([profile, trains, thematics, categories]) => {
      this.currentTrains = trains.filter(train => !train.isCompleted);
      this.overTrains = trains.filter(train => train.isCompleted);
  
      this.favoriteThematics = thematics
        .filter(thematic => (profile.favoriteThematics?.map(ft => ft.id) ?? [])
        .includes(thematic.id));
        
      this.favoriteCategories = categories
        .filter(category => this.favoriteThematics
        .map(t => t.category?.id)
        .includes(category.id));
  
      this.sessionsDone = trains.reduce((sum, train) => sum + train.currentSessionIndex, 0);
      this.hoursDone = Math.floor(trains.reduce((sum, train) => 
        sum + (train.currentSessionIndex * train.sessionDuration), 0) / (1E3 * 3600));
  
      this.loading = false;
    });

    this.selectedTabIndex$.pipe(
      filter(index => index === 2),
      tap(() => { this.loading = true; }),
      switchMap(() => this.dashboardRepository.getTalkerLatestEmotionnalResponse()),
      takeUntil(this.destroy$)
    ).subscribe(data => {
      if (data) {
        this.emotionalResponseData = data;
        const filteredData: Record<string, number> = Object.fromEntries(
          Object.entries(data)
            .filter(([key]) => [
              'dailyRoutine',
              'senseOfPurpose',
              'confidence',
              'mood',
              'interactions',
              'workRecognition',
              'workMotivation', 
              'workFocus',
              'health',
              'socialEngagement'
            ].includes(key))
            .map(([key, value]) => [key, value as number]));
  
        type LabelKeys = 'dailyRoutine' | 'senseOfPurpose' | 'confidence' | 'mood' | 'interactions' | 
          'workRecognition' | 'workMotivation' | 'workFocus' | 'health' | 'socialEngagement';
        
        const labels: Record<LabelKeys, { label: string }> = {
          dailyRoutine: { label: 'Quotidien, activités' },
          senseOfPurpose: { label: "Sentiment d'utilité, sens de la vie" },
          confidence: { label: 'Estime de soi, confiance en soi' },
          mood: { label: 'Humeur, énergie mentale' },
          interactions: { label: 'Interactions, relations' },
          workRecognition: { label: 'Reconnaissance professionnelle, capacités' },
          workMotivation: { label: 'Motivation au travail, intérêt' },
          workFocus: { label: 'Attention et concentration au travail' },
          health: { label: 'Santé, énergie physique' },
          socialEngagement: { label: "Impact de la société" }
        };
        
        const orderedKeys = [
          'dailyRoutine',
          'senseOfPurpose', 
          'confidence',
          'mood',
          'interactions',
          'workRecognition',
          'workMotivation',
          'workFocus', 
          'health',
          'socialEngagement'
        ];
        
        // Create ordered data
        const formattedData = orderedKeys.reduce((acc, key) => {
          if (filteredData[key] !== undefined) {
            acc[key] = {
              label: labels[key as LabelKeys].label,
              value: filteredData[key] ?? 0
            };
          }
          return acc;
        }, {} as Record<string, { label: string, value: number }>);
        
        this.radarChartDataSubject$.next(formattedData);
        this.loading = false;
      } else {
        this.radarChartDataSubject$.next({});
        this.loading = false;
      }
    });

    this.selectedTabIndex$.pipe(
      filter(index => index === 2),
      takeUntil(this.destroy$)
    ).subscribe(data => {
      // console.log('Here ');
    });
  }

  goToThematics() {
    // this.tracker.trackEvent(TrackerCategory.MYSPACE_TALKER, TrackerAction.CLICK, 'thematicsButton');
    this.router.navigate([ 'thematics' ]);
  }

  goToAvailableTrains() {
    // this.tracker.trackEvent(TrackerCategory.MYSPACE_TALKER, TrackerAction.CLICK, 'trainsButton');
    this.router.navigate([ 'available-trains' ]);
  }

  clickOnThematic(slug: string) {
    this.router.navigate([ 'thematics', slug ]);
  }

  goToTrain(train: Train) {
    this.router.navigate([ 'trains', train.id ]);
  }

  goToMeeting(trainId: string): void {
    this.router.navigate([ 'meet', trainId ]);
  }

  selectTab(tabIndex: number) {
    this.selectedTab = tabIndex;
    this.selectedTabIndex$.next(tabIndex);
    
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tabIndex },
      queryParamsHandling: 'merge'
    });
  }

  protected readonly mediumCategoryColors = mediumCategoryColors;

  protected readonly capitalize = capitalize;
}
