import { OauthConfig } from "../../config/config.type";


export abstract class AbstractOauthService {
  protected readonly apiUrl: string;

  protected readonly clientId: string;

  protected readonly authorityUrl: string;

  protected redirectUrl: string = '';

  protected constructor(protected readonly config: OauthConfig) {
    this.apiUrl = config.apiUrl;
    this.clientId = config.clientId;
    this.authorityUrl = config.authorityUrl;
    this.redirectUrl = `${ config.apiUrl }/auth/${ config.type }/callback`;
  }

  abstract logout(): void;
}
