import { IconType } from "../components/icon/icon.component";

export function getEmojiColorByType(type: IconType): string {
    switch (type) {
      case 'sad-emoji':
        return '#ff4f4f';
      case 'woozy-emoji':
        return '#FC9418';
      case 'neutral-emoji':
        return '#FCC418';
      case 'happy-emoji':
        return '#94D82C';
      case 'laughy-emoji':
        return '#22C997';
      default:
        return '';
    }
  }