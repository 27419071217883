<div class="tabs column margin-bottom" *ngIf="tabs.length" >
  <div class="mobile-column">
    <div [ngClass]="{'no-pointer': !withClick}" class="mobile-row gap tab" *ngFor="let tab of firstPartTabs" (click)="withClick ? goTo(tab.link) : undefined">
      <app-icon class="tab-icon" [size]="tab.description ? 32 : 60" [style.color]="tab.color" [type]="tab.icon" />
      <div class="column gap">
        <div class="text-large cooper text-black">{{tab.title}}</div>
        <div *ngIf="tab.description" class="text-black tab-description">{{tab.description}}</div>
      </div>
      <app-icon *ngIf="showArrowIcons" [type]="arrowDown ? 'arrow-down' : 'arrow-right'" class="rounded-icon"></app-icon>
    </div>
  </div>
  <hr class="grow" *ngIf="secondPartTabs.length"/>
  <div class="mobile-column" *ngIf="secondPartTabs.length">
    <div class="mobile-row gap tab" *ngFor="let tab of secondPartTabs" (click)="withClick ? goTo(tab.link) : undefined">
      <app-icon class="tab-icon" [size]="tab.description ? 32 : 60" [style.color]="tab.color" [type]="tab.icon" />
      <div class="column gap">
        <div class="text-large cooper text-black">{{tab.title}}</div>
        <div *ngIf="tab.description" class="text-black tab-description">{{tab.description}}</div>
      </div>
      <app-icon *ngIf="showArrowIcons" [type]="arrowDown ? 'arrow-down' : 'arrow-right'" class="rounded-icon"></app-icon>
    </div>
  </div>
</div>
