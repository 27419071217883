<div>
  <div class="wrapper">
    <ng-container *ngIf="!alreadyActiveAccount && !expiredLink">
      <div class="introducing">
        <h2 class="title">Bienvenue {{ firstName }} !</h2>
        <div>Quelques étapes nécessaires pour activer votre compte...</div>
      </div>
      <app-progress-bar *ngIf="!expiredLink" class="progress-bar" (onStepClick)="goToSpecificStep($event)"
                        [stepsDone]="stepsDone"
                        [maxSteps]="maxSteps"></app-progress-bar>
      <app-stepper *ngIf="!expiredLink" [previous]="true" (selectStep)="changeStep($event)" [maxSteps]="maxSteps +1"
                  [stepsDone]="stepsDone">
        <form class="step" [formGroup]="emailForm" (submit)="goToNextStep()">
          <div class="margin-bottom">Votre adresse mail de votre compte WeTalk</div>
          <app-input class="form-input margin-top email-input" [readonly]="true" formControlName="email"
                     type="email"
          ></app-input>
          <div class="center-children">
            <app-button title="Ok" class="margin-top" type="submit">Ok</app-button>
          </div>
        </form>
        <app-password-setter class="step" [passwordForm]="passwordForm"
                             (setPassword)="goToNextStep()"></app-password-setter>
        <form class="step space-between" [formGroup]="phoneForm" (submit)="sendFirstPhoneVerifyCode()">
          <div class="margin-bottom">Dans le cadre de votre accompagnement We Talk,
            nous serons amenés à vous envoyer des notifications suivant les activités.
            Votre numéro reste confidentiel. Consultez les <a (click)="openCGU()">CGU</a>.
          </div>
          <app-input [correct]="phoneWork" class="form-input"
                     [phoneCode]="phoneRegionCode"
                     (selectPhoneCode)="selectPhoneRegionCode($event)"
                     type="phone"
                     formControlName="phoneNumber"
                     placeholder="0612345678"></app-input>

          <div class="center-children">
            <app-button title="Envoyer un sms" [disabled]="!phoneWork" type="submit">Envoyer un sms</app-button>
          </div>
          <div class="margin-top">
            <a class="margin-top" (click)="goToLastStep()">Passer cette étape</a>
          </div>
        </form>
        <form class="step" [formGroup]="confirmationPhoneForm" (submit)="replaySendPhoneVerifyCode()">
          <div class="margin-bottom">Veuillez rentrer le code reçu par sms.
          </div>
          <code-input code="number" [codeLength]="6" (codeCompleted)="validatePhoneCode()"
                      (codeChanged)="changePhoneCode($event)"></code-input>

          <div class="margin-top center-children column gap">
            <app-button title="Renvoyer un sms" type="submit">{{codeButton}}</app-button>
            <div class="margin-top">
              <a (click)="goToLastStep()">Passer cette étape</a>
            </div>
          </div>
        </form>
        <div class="step center-children column gap">
          <h3>Votre compte est prêt !</h3>
          <div class="margin-bottom margin-top">La création de votre compte We Talk est terminée.</div>
          <app-button title="Terminer" class="margin-top" (onClick)="validate()">Terminer</app-button>
        </div>
      </app-stepper>
    </ng-container>
    <ng-container *ngIf="expiredLink && !alreadyActiveAccount">
      <form [formGroup]="emailForm" (submit)="sendActivationEmail()">
        <div class="introducing expired">
          <h2 class="title">Bienvenue {{ firstName }} !</h2>
          <div class="margin-bottom">Il semblerait que le lien soit expiré. Vous pouvez réenvoyer un lien d'activation
            en
            cliquant sur le lien ci-dessous.
          </div>
        </div>
        <div class="email-introduction">Votre adresse mail de votre compte WeTalk</div>
        <app-input class="form-input margin-top margin-bottom" [readonly]="true"
                   formControlName="email"
                   type="email"
        ></app-input>
        <app-button class="margin-top" type="submit">{{mailButton}}</app-button>
      </form>
    </ng-container>
    <ng-container *ngIf="alreadyActiveAccount">
      <form [formGroup]="emailForm">
        <div class="introducing expired">
          <h2 class="title">Bienvenue {{ firstName }} !</h2>
          <div class="margin-bottom">Il semblerait que ce compte soit déjà activé. Veuillez vous rendre sur la page de
            connexion pour vous connecter ou cliquer sur <a href="auth/forgotten-password">Mot de passe oublié</a> pour
            récupérer vos identifiants.
          </div>
        </div>
        <a href="auth/login">Page de connexion</a>
      </form>
    </ng-container>


  </div>
</div>


