import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { DialogComponent } from "../dialog/dialog.component";
import { DialogRef } from "../dialog/dialog.ref";
import { DIALOG_DATA } from "../dialog/dialog.token";

export type ConfirmData = {
  title: string;
  message?: string;
  confirmMessage?: string;
  cancelMessage?: string;
  reverseColor?: boolean;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: [ './confirm.component.scss' ]
})
export class ConfirmComponent extends DialogComponent<boolean, ConfirmData>() implements OnInit, OnDestroy {

  constructor(@Inject(DIALOG_DATA)
              public override readonly data: ConfirmData,
              private readonly ref: DialogRef<boolean>
  ) {
    super(ref);
  }

  static override isPopIn = true;

  ngOnInit() {
    super.onInit();
  }

  ngOnDestroy() {
    super.onDestroy();
  }


  validate(isValidated: boolean) {
    super.close(isValidated);
  }

  parseMessageToHtml(message?: string): string {
    const sentences = message?.split('\n') ?? [];
    return sentences.reduce((htmlResult, sentence) => {
      htmlResult += `<p class="margin-bottom">${sentence}</p>`;

      return htmlResult;
    }, '');
  }
}
