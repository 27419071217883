import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";

import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { EntityFormGroup } from "../../shared/types/entity-form-group";

export type SessionUpdatePayload = {
  index: number;
  date: string;
  time: string;
}

export type SelectDate = {
  date: string;
  time: string;
}

@Component({
  selector: 'app-modify-session-date',
  templateUrl: './modify-session-date.component.html',
  styleUrls: [ './modify-session-date.component.scss' ]
})
export class ModifySessionDateComponent extends DialogComponent<SelectDate, SessionUpdatePayload>() implements OnInit {

  sessionIndex!: number;

  dateForm!: EntityFormGroup<SelectDate>;

  constructor(
    @Inject(DIALOG_DATA)
    public override readonly data: SessionUpdatePayload,
    private readonly formBuilder: FormBuilder,
    private readonly ref: DialogRef<SelectDate>,
  ) {
    super(ref);
    this.sessionIndex = data.index;
    this.dateForm = this.formBuilder.group({
      date: new FormControl(data.date, { nonNullable: true }),
      time: new FormControl(data.time, { nonNullable: true }),
    });


  }

  ngOnInit(): void {
    super.onInit();
  }


  validate(validated: boolean = false) {
    if (validated) {
      super.close({
        date: this.dateForm.controls.date.value,
        time: this.dateForm.controls.time.value,
      });
    } else {
      super.close();
    }
  }


  ngOnDestroy() {
    super.onDestroy();
  }
}
