import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { startWith, Subject, takeUntil } from "rxjs";

import { plural } from "../../tool-functions/plural";

export type Checkbox<T = string> = {
  id: T,
  key: string,
  selected: boolean
}

@Component({
  selector: 'app-checkmarks',
  templateUrl: './checkmarks.component.html',
  styleUrl: './checkmarks.component.scss',
})
export class CheckmarksComponent implements OnInit, OnChanges, OnDestroy {

  private destroy$ = new Subject<void>();

  private isInitialized = false;

  public readonly checkboxTrackBy = (index: number, checkbox: Checkbox) => checkbox.key;

  public resumeFormControl = new FormControl('');

  public checkboxesState: Checkbox[] = [];

  public allSelected = false;

  @Input() emitOnFirstLoad = false;

  @Input() update$ = new Subject<void>();

  @Input() checkboxes: Checkbox[] = [];

  @Input('item-name') itemName: string = '';

  @Input() isFemaleWord = false;

  @Output() changeSelection = new EventEmitter<Checkbox[]>();

  @Input() title?: string;

  ngOnInit(): void {
    this.update$.pipe(
      startWith(undefined),
      takeUntil(this.destroy$)).subscribe(() => {
        const itemSelectedCount = this.checkboxesState.filter(checkbox => checkbox.selected).length;
        this.resumeFormControl.setValue(`${itemSelectedCount} ${plural(this.itemName, itemSelectedCount > 1)}`);
        this.allSelected = this.checkboxesState.every(checkbox => checkbox.selected);
        
        // Emit based on configuration or if we've initialized already
        if (this.emitOnFirstLoad || this.isInitialized) {
          this.changeSelection.emit(this.checkboxesState);
        }
        
        // Mark as initialized after first update
        this.isInitialized = true;
    });
  }

  ngOnChanges(): void {
    this.checkboxesState = this.checkboxes;
    this.update$.next();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  selectAll(value: boolean): void {
    this.checkboxesState.forEach(checkbox => {
      checkbox.selected = value;
    });
    this.update$.next();
  }

  selectOption(key: string, value: boolean): void {
    this.checkboxesState.forEach(checkbox => {
      checkbox.selected = checkbox.id === key ? value : checkbox.selected;
    });
    
    this.update$.next();
  }

}
