import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

import { Organization } from "../../../../organizations/models/organizations.entity";

@Component({
  selector: "app-organization-card",
  templateUrl: "./organization-card.component.html",
  styleUrls: ["./organization-card.component.scss"],
})
export class OrganizationCardComponent {

  public readonly cardTrackBy = (index: number, organization: Organization) => organization.id;
  
  @Input() organization!: Organization;

  @Input() loading = false;

  @Input() children: any[] = [];

  constructor(private readonly router: Router) {

  }

  public goToDetails(event: Event, id: string) {
    event.stopPropagation();
    this.router.navigate(["organizations", id]);
  }

  get parentDescription(): string {
    return this.organization.parent
      ? ` - ${this.organization.parent.name}`
      : "";
  }

  get organizationInfos(): string {
    return `${this.organization.members?.filter(o => !!o.personalInformation)?.length ?? 0} collaborateurs 
    | ${this.organization.units} unités restantes 
    | ${
      this.organization.members?.reduce(
        (sum, user) => sum + (user?.trainMemberships?.length ?? 0),
        0
      ) ?? 0
    } parcours${this.organization.subscriptionPlan?.name ? ` | ${this.organization.subscriptionPlan.name}` : ""}`;
  }
}
