import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { FilterInterface, FindResult, IPaginationRepository } from "../../shared/repositories/pagination.interface";
import { Talker } from "../../users/models/talker.entity";
import { CreateUser } from "../../users/models/users.entity";

export type OrganizationMembersFilterForm = {
  startCreationDate: string;
  endCreationDate: string;
  startActivationDate: string;
  endActivationDate: string;
  isActivated: boolean;
}

export type OrganizationMembersPropertiesFilter = OrganizationMembersFilterForm;

@Injectable()
export class OrganizationMembersRepository extends AbstractRepository<Talker>
  implements IPaginationRepository<Talker, OrganizationMembersPropertiesFilter> {
  private organisationId: string = '';

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              protected override readonly http: HttpClient) {
    super(http, Talker);
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return `organizations/${ this.organisationId }`;
  }

  setOrganizationId(organizationId: string): void {
    this.organisationId = organizationId;
    this.reload$.next();
  }

  override findById(id: string): Observable<Talker> {
    return this.http.get<Talker>([ this.apiUrl, this.getEntityName(), 'members', id ].join('/')).pipe(map(user => this.instantiate(user)));
  }

  getAll(): Observable<Talker[]> {
    return this.http.get<Talker[]>([ this.apiUrl, this.getEntityName(), 'members', 'all' ].join('/')).pipe(map(list => list.map(user => this.instantiate(user))));
  }

  createMember(member: CreateUser): Observable<Talker> {
    return this.http.post<Talker>([ this.apiUrl, this.getEntityName(), 'members' ].join('/'), member).pipe(map(user => this.instantiate(user)));
  }

  createPilot(member: CreateUser): Observable<Talker> {
    return this.http.post<Talker>([ this.apiUrl, this.getEntityName(), 'pilot' ].join('/'), member).pipe(map(user => this.instantiate(user)));
  }

  addExistingTalkerAsPilot(id: string): Observable<Talker> {
    return this.http.post<Talker>([ this.apiUrl, this.getEntityName(), 'pilot', id ].join('/'), {}).pipe(map(user => this.instantiate(user)));
  }

  addUnits(units: number): Observable<number> {
    return this.http.post<number>([ this.apiUrl, this.getEntityName(), 'add-units' ].join('/'), { units });
  }

  addUnitsFrom(units: number, organizationId: string): Observable<number> {
    return this.http.post<number>([ this.apiUrl, this.getEntityName(), 'add-units-from' ].join('/'), {
      units,
      organizationId
    });
  }

  override deleteById(id: string): Observable<void> {
    return this.http.delete<void>([ this.apiUrl, this.getEntityName(), 'members', id ].join('/'));
  }

  deleteByIds(ids: string[]): Observable<number> {
    return this.http.delete<number>([ this.apiUrl, this.getEntityName(), 'members' ].join('/'), { body: { ids } });
  }

  paginate(filter: FilterInterface<OrganizationMembersPropertiesFilter, 'createdAt' | 'activatedAt'>): Observable<FindResult<Talker>> {

    return this.http.post<FindResult<Talker>>([ this.apiUrl, this.getEntityName(), 'members', 'paginate' ].join('/'), filter).pipe(
      map(({ items, ...rest }) => ({
        ...rest,
        items: items.map(item => this.instantiate(item))
      }))
    );
  }

}
