import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { ContentReaction, IContentReaction, IUpdateContentReactionsPayload } from "../models/content-reactions.entity";

@Injectable()
export class ContentReactionsRepository extends AbstractRepository<IContentReaction> {
    constructor(
        @Inject(APP_CONFIG)
        private readonly appConfig: AppConfig,
        protected override readonly http: HttpClient
      ) {
        super(http, ContentReaction);
        this.apiUrl = this.appConfig.apiUrl;
      }
    
    getEntityName(): string {
        return "content-reactions";
    }

    getContentReactionByContentIdAndUserId(contentId: string): Observable<IContentReaction> {
        return this.http.post<IContentReaction>([ this.apiUrl, this.getEntityName(), 'get-content-reaction'].join('/'), { contentId });
    }

    createReaction(contentReactionToCreate: IUpdateContentReactionsPayload): Observable<IContentReaction> {
        return this.http.post<IContentReaction>([ this.apiUrl, this.getEntityName() ].join('/'), contentReactionToCreate);
    }

    updateReaction({ contentId, liked }: IUpdateContentReactionsPayload): Observable<IContentReaction> {
        return this.http.post<IContentReaction>([ this.apiUrl, this.getEntityName(), 'update-content-reaction' ].join('/'), { contentId, liked });
    }

    deleteContentReaction(contentId: string): Observable<void> {
        return this.http.post<void>([ this.apiUrl, this.getEntityName(), 'delete-content-reaction' ].join('/'), { contentId });
    }
}