import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { fromEvent, map, startWith, Subscription } from "rxjs";

import { allThematics } from "../../../../contents/models/contents.entity";
import { contentThematicCategoryTranslation } from "../../../../contents/translate";
import { mediumCategoryColors } from "../../../../thematics/colors";
import { ThematicCategorySlug } from "../../../../thematics/models/category.model";
import { capitalize } from "../../../tool-functions/capitalize";
import { extractVideoIdFromYoutubeUrl } from "../../../tool-functions/video";
import { IconType } from "../../icon/icon.component";


@Component({
    selector: 'app-media-card',
    templateUrl: './media-card.component.html',
    styleUrls: [ './media-card.component.scss' ]
})

export class MediaCardComponent implements OnInit, OnDestroy {
    @Input() content: any;

    selectedCategory: any;

    isSmallScreen: boolean = false;

    resizeSubscription: Subscription = new Subscription();
    
    constructor(private sanitizer: DomSanitizer, private readonly router: Router ) { }

    ngOnInit(): void {
        this.resizeSubscription = fromEvent(window, 'resize')
        .pipe(
          startWith(this.getScreenWidth),
          map(() => window.innerWidth < 801)
        )
        .subscribe(isSmall => {
            this.isSmallScreen = isSmall;
        });
    }

    ngOnDestroy(): void {
        this.resizeSubscription.unsubscribe();
    }

    get associatedThematics() {
        const thematicsTitles = this.content.thematics.map((thematic: any) => allThematics.find((t: any) => t.key === thematic.slug)?.label ?? '');
        return thematicsTitles.join(', ');
    }

    get remainingThematicslength() {
        return this.content.thematics.length - 2;
    }

    public youtubeUrl(url: string) {
        const link = url.includes('s3') ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${extractVideoIdFromYoutubeUrl(url)}`);
        return link;
    }

    get isVideo() {
        return ['video', 'podcast'].includes(this.content.format);
    }

    public getIcon(): IconType {
        if(this.content.format === 'pratical_sheet'){
            return 'pdf';
        }

        if(this.content.format === 'test'){
            return 'file';
        }

        return 'file';
    }

    getPillarIcon(pillar: any): IconType {
        if(pillar.name === 'individual'){
            return 'candle';
        }

        if(pillar.name === 'environment'){
            return 'house-rustic';
        }

        if(pillar.name === 'event'){
            return 'storm';
        }

        return 'candle';
    }

    handleNoVideoClick(event: any) {
        event.stopPropagation();
        window.open(this.content.resource, '_blank');
    }

    goToContent() {
        window._mtm.push({ 'event': 'clic-ressource', 'nom-ressource': this.content.title });
        this.router.navigate([`/resources/all/${this.content.id}`]);
    }

    getCategoryNameBySlug(slug: ThematicCategorySlug): string {
        return capitalize(contentThematicCategoryTranslation[slug]) || '';
    }

    getColorBySlug(slug: ThematicCategorySlug): any {
        return mediumCategoryColors[slug] || '';
    }

    onSelectCategory(event: any, category: any): void {
        if(this.isSmallScreen) {
            event.stopPropagation();
            this.selectedCategory = category;
        }
    }

    capitalizeName(name: string): string {
        return capitalize(name);
    }

    get getScreenWidth() {
        return window.innerWidth;
    }
}