import { User } from "../../users/models/users.entity";

import { Session } from "./session.entity";

export class TimeSlot {
  id!: string;

  hours!: number;

  minutes!: number;

  weekDay!: number;

  sherpas?: User[];

  sessions?: Session[];
}
