<app-card>
    <ng-container left-part>
      <app-profile-icon [profile]="user" [primary]="!!user.activatedAt"></app-profile-icon>
    </ng-container>
    <ng-container>
      <div class="center-vertically-by-margin">
        <p><strong>{{ user.civility}} {{ user.fullName}}</strong></p>
        <div class="truncate-text" [innerHTML]="infos"></div>
        <app-button (click)="goToDetails()" class="center-children-horizontally margin-top" title="Voir le profil" icon="arrow-right">Voir le profil</app-button>
      </div>
    </ng-container>
  </app-card>
  
  