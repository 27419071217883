import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { combineLatestWith, Subject, takeUntil } from "rxjs";

import { Profile, ProfileService } from "../../users/services/profile.service";
import { Organization } from "../models/organizations.entity";
import { OrganizationRepository } from "../repositories/organization.repository";
import { OrganizationStoreService } from "../services/organization.store.service";

@Component({
  selector: 'app-switch-profile',
  templateUrl: './switch-profile.component.html',
  styleUrls: [ './switch-profile.component.scss' ]
})
export class SwitchProfileComponent implements OnInit, OnDestroy {
  public profile!: Profile;

  public organizations!: Organization[];

  public organizationsFiltered: Organization[] = [];

  public organizationSelected?: Organization;

  public searchForm = new FormControl<string>('');

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly profileService: ProfileService,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly organizationRepository: OrganizationRepository,
              private readonly router: Router) {
  }

  ngOnInit() {
    this.organizationStoreService.getObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(organization => {
        this.organizationSelected = organization;
      });

    this.searchForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          this.filterOrganizations(value);
        } else {
          this.organizationsFiltered = this.organizations;
        }
      });

    this.profileService.getMyProfile()
      .pipe(combineLatestWith(this.organizationRepository.findAll(), this.organizationStoreService.getObservable()),
        takeUntil(this.destroy$))
      .subscribe(([ profile, organizations, organizationSelected ]) => {
        this.profile = profile;
        this.organizations = organizations;
        this.organizationsFiltered = organizations;
        this.organizationSelected = organizationSelected;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  goToTalkerProfile() {
    this.organizationStoreService.removeOrganization();
    this.router.navigate([ 'home' ]);
  }

  clickOnOrganization(organization: Organization) {
    this.router.navigate([ 'organizations', organization.id ]);

  }

  filterOrganizations(searchString: string): void {
    const searchStringSplit = searchString.toLowerCase().split(' ').filter(s => s.length >= 1);
    this.organizationsFiltered = this.organizations.filter((organization) => {
      const organizationNameSplit = organization.name.toLowerCase().split(' ');
      return searchStringSplit.every(stringPart => organizationNameSplit.some(organizationPartName => organizationPartName.includes(stringPart)));
    });
  }
}
