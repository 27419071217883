<div class="pagination-full-screen">
<app-card-pagination [mainRepository]="mainRepository" [propertiesFilters$]="propertiesFilters$"
                [otherFilterOptionCount]="otherFilterOptionCount"
                [filterForm]="filterForm" name="utilisateur"
                [newItems$]="newItems$"
                (add)="addUser()" (download)="downloadUsersCsv()" 
                (clear)="clearFilter()"
                [hasFilters]="hasFilters">
  <ng-container filter [formGroup]="filterForm">
    <div class="filter">
      <p class="center-children horizontally">Rôle</p>
      <app-tags [tags]="roles$ | async" (selectTags)="selectRoles($event)"></app-tags>
    </div>

    <div class="filter">
      <p>Organisations</p>
      <app-checkmarks [update$]="updateOrganizationsSelected$" [checkboxes]="organizationCheckboxes"
                      item-name="Organisation selectionnée"
                      [isFemaleWord]="true"
                      (changeSelection)="updateOrganizationsSelected($event)"></app-checkmarks>
    </div>

    <div class="filter">
      <p>Date d'invitation</p>
      <app-input title="À partir du" formControlName="startCreationDate" type="date"></app-input>
      <app-input title="Jusqu'au" formControlName="endCreationDate" type="date"></app-input>
    </div>
    <div class="filter">
      <p>Date d'inscription</p>
      <app-input title="À partir du" formControlName="startActivationDate" type="date"></app-input>
      <app-input title="Jusqu'au" formControlName="endActivationDate" type="date"></app-input>
    </div>
    <div class="filter">
      <p>Nombre de parcours</p>
      <app-input formControlName="trainsCountMinimum" type="number" placeholder="Minimum"></app-input>
      <app-input formControlName="trainsCountMaximum" type="number" placeholder="Maximum"></app-input>
    </div>
  </ng-container>
  <ng-template #card let-item>
    <app-user-card (clickOnDetails)="goToUserDetails($event)" [user]="item"></app-user-card>
  </ng-template>
  <ng-container options>
    <div class="dropdown-item row gap" (click)="sendNewReminders()">
      <span class="grow">Réenvoyer des invitations</span>
      <app-icon size="16" type="email"/></div>
  </ng-container>
</app-card-pagination>
</div>
