import { ThematicCategorySlug } from "../models/category.model";

const strongCategoryColors: Record<ThematicCategorySlug, string> = {
  [ThematicCategorySlug.BETTER_LIFE]: '#E8D414',
  [ThematicCategorySlug.PERSONAL_LIFE]: '#2464A0',
  [ThematicCategorySlug.PROFESSIONAL_LIFE]: '#B65A44',
  [ThematicCategorySlug.SELF_REFLECTION]: '#239692'
};

const lightCategoryColors: Record<ThematicCategorySlug, string> = {
  [ThematicCategorySlug.BETTER_LIFE]: '#F5F0B7',
  [ThematicCategorySlug.PERSONAL_LIFE]: '#B4CBE0',
  [ThematicCategorySlug.PROFESSIONAL_LIFE]: '#E8C7C0',
  [ThematicCategorySlug.SELF_REFLECTION]: '#B4DDDB'
};

const mediumCategoryColors: Record<ThematicCategorySlug, string> = {
  [ThematicCategorySlug.BETTER_LIFE]: '#F2E14D',
  [ThematicCategorySlug.PERSONAL_LIFE]: '#5491CB',
  [ThematicCategorySlug.PROFESSIONAL_LIFE]: '#E28873',
  [ThematicCategorySlug.SELF_REFLECTION]: '#53C1BD'
};


export { lightCategoryColors, mediumCategoryColors, strongCategoryColors };
