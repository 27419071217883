import { Injectable } from "@angular/core";

import { Feedback } from "../../feedbacks/models/feedbacks.entity";
import { Organization, OrganizationTransactionParsed } from "../../organizations/models/organizations.entity";
import { Train } from "../../trains/models/train.entity";
import { User } from "../../users/models/users.entity";
import {
  CsvMaker,
  FeedbackCsvMaker,
  OrganizationCsvMaker,
  TrainCsvMaker,
  TransactionHistoryCsvMaker,
  UserCsvMaker
} from "../tool-functions/csv-maker";

@Injectable({ providedIn: 'root' })
export class CsvService {

  private createCsv<Entity>(csvContent: CsvMaker<Entity>, fileName: string) {
    const blob = new Blob([ csvContent.getCsvString() ], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    a.click();
  }

  downloadUsersCsv(users: User[], fileName?: string): void {
    const csvMaker = new UserCsvMaker(users);
    return this.createCsv(csvMaker, fileName ?? `Utilisateurs ${ (new Date()).toLocaleDateString() }`);
  }

  downloadTrainCsv(trains: Train[], fileName?: string): void {
    const csvMaker = new TrainCsvMaker(trains);
    return this.createCsv(csvMaker, fileName ?? `Parcours ${ (new Date()).toLocaleDateString() }`);
  }

  downloadOrganizationsCsv(organizations: Organization[], fileName?: string): void {
    const csvMaker = new OrganizationCsvMaker(organizations);
    return this.createCsv(csvMaker, fileName ?? `Organisations ${ (new Date()).toLocaleDateString() }`);
  }

  downloadOrganizationsTransactionsParsed(organizationTransactionsParsed: OrganizationTransactionParsed[], fileName?: string): void {
    const csvMaker = new TransactionHistoryCsvMaker(organizationTransactionsParsed);
    return this.createCsv(csvMaker, fileName ?? `Historique des crédits au ${ (new Date()).toLocaleDateString() }`);
  }

  downloadFeebacks(feedbacks: Feedback[]){
    const csvMaker = new FeedbackCsvMaker(feedbacks);
    return this.createCsv(csvMaker, `Export satisfaction plateforme au ${ (new Date()).toLocaleDateString() }`);

  }
}
