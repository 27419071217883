import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { ContentsRepository } from "../repositories/contents.repository";
import { TabStoreService } from "../services/content-tab.store";

@Component({
    selector: "app-contents-list",
    templateUrl: "./contents-list.component.html",
    styleUrls: ["./contents-list.component.scss"],
})

export class ContentsListComponent {

    constructor(private router: Router, private route: ActivatedRoute, public readonly contentsRepository: ContentsRepository, private fb: FormBuilder, private cdr: ChangeDetectorRef, private tabStoreService: TabStoreService) { }

}