<div *ngIf="!loading">
  <div [class.fade-left]="fadeLeft$ | async" [class.fade-left-reverse]="fadeLeftReverse$ | async" [class.fade-right]="fadeRight$ | async"
       [class.fade-right-reverse]="fadeRightReverse$ | async">

    <app-notification class="sticky-notification" title="Enregistrement impossible" color="#e37055" *ngIf="errorMessages.length">
      <ul>
        <li *ngFor="let error of errorMessages">{{error}}</li>
      </ul>
    </app-notification>

    <div class="header">
      <h1 class="title">{{title()}}</h1>
    </div>

    <section>
      <h2 class="margin-top margin-bottom">Informations générales</h2>
      <form [formGroup]="thematicForm">
        <app-input title="Label (il doit être en anglais espacé par des tirets car sera l'url de la thématique, exemple: self-confidence)" formControlName="slug"></app-input>
        <app-radio-buttons formControlName="category" title="Catégorie" [options]="categoriesNames"></app-radio-buttons>
        <app-input title="Nom affiché" formControlName="name"></app-input>
        <app-input title="Titre/Question" formControlName="title"></app-input>
        <p>Résumé</p>
        <textarea formControlName="resume" placeholder="Résumé de la thématique"></textarea>

        <h2 class="margin-bottom margin-top">Dynamiques proposées</h2>
        <h3 class="margin-top">Coaching en groupe</h3>
        <app-radio-buttons formControlName="coaching"></app-radio-buttons>
        <div *ngIf="gotCoaching">
          <p>Description de la dynamique</p>
          <app-editable-list  [items]="coachingDescriptionForms" (add)="addCoachingDescriptionForm()" (remove)="removeCoachingDescriptionForm($event)">
            <ng-template #listItem let-item>
              <app-input class="full-width" [formControl]="item"></app-input>
            </ng-template>
          </app-editable-list>
          <app-checkmarks title="Sherpas associés" [checkboxes]="coachingSherpas" item-name="Sherpa associé"
          (changeSelection)="updateCoachingSherpasAssociated($event)"></app-checkmarks>
        </div>
        <h3 class="margin-top">Parole en groupe</h3>
        <app-radio-buttons formControlName="speech"></app-radio-buttons>
        <div *ngIf="gotSpeech">

          <p>Description de la dynamique</p>
          <app-editable-list  [items]="speechDescriptionsForms" (add)="addSpeechDescriptionForm()" (remove)="removeSpeechDescriptionForm($event)">
            <ng-template #listItem let-item>
              <app-input class="full-width" [formControl]="item"></app-input>
            </ng-template>
          </app-editable-list>
          <app-checkmarks title="Sherpas associés" [checkboxes]="speechSherpas" item-name="Sherpa associé"
                          (changeSelection)="updateSpeechSherpasAssociated($event)"></app-checkmarks>
        </div>

      </form>

      <h2 class="margin-bottom margin-top">Sur quoi les talkers vont travailler et avancer ?</h2>
      <app-editable-list  [items]="workingOnForms" (add)="addWorkingOnForm()" (remove)="removeWorkingOnForm($event)">
        <ng-template #listItem let-item>
          <app-input class="full-width" [formControl]="item"></app-input>
        </ng-template>
      </app-editable-list>

      <h2 class="margin-bottom margin-top">Témoignages</h2>
      <app-editable-list  [items]="testimonialForms" (add)="addTestimonialForm()" (remove)="removeTestimonialForm($event)">
        <ng-template #listItem let-item>
          <form class="column gap full-width" [formGroup]="item">
            <div class="column gap">
              <p>Contenu</p>
              <textarea formControlName="description"></textarea>
            </div>
            <app-input title="Auteur" formControlName="author"></app-input>
            <br/>
          </form>

        </ng-template>
      </app-editable-list>

      <h2 class="margin-bottom margin-top">Chiffres clés</h2>
      <app-editable-list  [items]="keyNumberForms" (add)="addKeyNumberForm()" (remove)="removeKeyNumberForm($event)">
        <ng-template #listItem let-item>
          <form class="column gap full-width margin-top" [formGroup]="item">
            <app-input title="Chiffre" formControlName="stringNumber"></app-input>
            <div class="column no-gap margin-bottom">
              <p>Description</p>
              <textarea class="full-width" formControlName="description"></textarea>
            </div>
            <br/>
          </form>

        </ng-template>
      </app-editable-list>

      <h2 class="margin-top margin-bottom">Image</h2>
      <app-upload-file (updateFile)="updateFile($event)" [update]="!!thematic" image></app-upload-file>

      <div class=" margin-top-xl">
        <a *ngIf="thematic && !thematic.isArchived" (click)="archiveThematic()">Archiver cette thématique</a>
      </div>
    </section>
  </div>
</div>
