import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, RouterOutlet } from "@angular/router";
import { CodeInputModule } from "angular-code-input";
import { NgxPermissionsModule } from "ngx-permissions";

import { SharedModule } from "../shared/shared.module";
import { UsersModule } from "../users/users.module";

import { ActivationComponent } from "./activation/activation.component";
import { AuthenticationComponent } from "./authentication.component";
import { ForgottenPasswordComponent } from "./forgotten-password/forgotten-password.component";
import { LoginComponent } from "./login/login.component";
import { NewSherpaComponent } from "./new-sherpa/new-sherpa.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthenticationGuardService } from "./services/authentication.guard.service";
import { AuthenticationService } from "./services/authentication.service";
import { DemandsService } from "./services/demands.service";
import { GoogleService } from "./services/google.service";
import { LoginGuardService } from "./services/login.guard.service";


@NgModule({
  declarations: [ LoginComponent, ActivationComponent, ForgottenPasswordComponent, ResetPasswordComponent, AuthenticationComponent, NewSherpaComponent ],
  providers: [ AuthenticationService,
    AuthenticationGuardService,
    LoginGuardService,
    GoogleService,
    DemandsService
  ],
  imports: [
    SharedModule,
    CommonModule,
    RouterOutlet,
    CodeInputModule,
    NgxPermissionsModule.forChild(),
    RouterModule.forChild([
        {
          path: '',
          component: AuthenticationComponent,
          children: [
            {
              path: 'activation',
              component: ActivationComponent,
            },
            {
              path: 'forgotten-password',
              component: ForgottenPasswordComponent,
            },
            {
              path: 'reset-password',
              component: ResetPasswordComponent,
            },
            {
              path: 'new-sherpa',
              component: NewSherpaComponent,
            },
          ]
        },
        {
          path: 'login',
          component: LoginComponent,
          canActivate: [ LoginGuardService ],
        },
      ]
    ),
    UsersModule
  ]
})
export class AuthenticationModule {
}
