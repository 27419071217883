import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { FilterInterface, FindResult, IPaginationRepository } from "../../shared/repositories/pagination.interface";
import { Transaction } from "../models/organizations.entity";

export type OrganizationTransactionFilterForm = {
  startCreationDate: string;
  endCreationDate: string;
  organizationId: string;
}

export type OrganizationTransactionsPropertiesFilter = OrganizationTransactionFilterForm;

@Injectable()
export class TransactionRepository
  implements IPaginationRepository<Transaction, OrganizationTransactionsPropertiesFilter> {
  private organisationId: string = '';

  public reload$ = new Subject<void>();

  private apiUrl !: string;

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private readonly http: HttpClient) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return `organizations/${ this.organisationId }`;
  }

  setOrganizationId(organizationId: string): void {
    this.organisationId = organizationId;
    this.reload$.next();
  }

  paginate(filter: FilterInterface<OrganizationTransactionFilterForm>): Observable<FindResult<Transaction>> {

    return this.http.post<FindResult<Transaction>>([ this.apiUrl, this.getEntityName(), 'transactions' ].join('/'), filter).pipe(
      map(({ items, ...rest }) => ({
        ...rest,
        items: items.map(item => plainToInstance(Transaction, item))
      }))
    );
  }
}
