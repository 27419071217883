<div class="card-wrapper">
  <div class="left-part center-children">
    <ng-content select="[left-part]"></ng-content>
  </div>
  <div class="main-part">
    <ng-content></ng-content>
  </div>
  <div class="right-part">
    <ng-content select="[right-part]"></ng-content>
  </div>
</div>

