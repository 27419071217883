import { Feedback } from "../../feedbacks/models/feedbacks.entity";
import { Organization, OrganizationTransactionParsed } from "../../organizations/models/organizations.entity";
import { DynamicType } from "../../thematics/models/dynamic.model";
import { Train } from "../../trains/models/train.entity";
import { Talker } from "../../users/models/talker.entity";
import { RoleDescription, User } from "../../users/models/users.entity";

import { capitalize } from "./capitalize";

export type CsvColumn<Entity> = {
  header: string;
  get(entity: Entity): string | undefined;
}

interface ICsvMaker<Entity> {
  columns: CsvColumn<Entity>[];
}

export abstract class CsvMaker<Entity> implements ICsvMaker<Entity> {
  protected readonly data: Entity[] = [];

  abstract readonly columns: CsvColumn<Entity>[];

  getCsvString(): string {
    let csvResult = `${ this.columns.map(column => column.header).join(',') }\n`;
    this.data.forEach(item => {
      csvResult += `${ this.columns.map(column => column.get(item) ?? '').join(',') }\n`;
    });

    return csvResult;
  }
}

export class UserCsvMaker extends CsvMaker<User> {
  constructor(protected override readonly data: User[]) {
    super();
  }

  columns = [
    {
      header: 'Id',
      get: (user: User) => user.id
    },
    {
      header: 'Nom',
      get: (user: User) => user.lastName.trim().toUpperCase()
    },
    {
      header: 'Prénom',
      get: (user: User) => capitalize(user.firstName)
    },
    {
      header: 'Email',
      get: (user: User) => user.email
    },
    {
      header: 'Rôle',
      get: (user: User) => capitalize(RoleDescription[user.role])
    },
    {
      header: 'Tel',
      get: (user: User) => user.phoneNumber ?? ''
    },
    {
      header: 'Invité le',
      get: (user: User) => user.createdAt.toLocaleDateString()
    },
    {
      header: 'Inscrit le',
      get: (user: User) => user.activatedAt?.toLocaleDateString() ?? ''
    },
    {
      header: 'Id orga',
      get: (user: Talker) => user.organization?.id ?? ''
    },
    {
      header: 'Nom orga',
      get: (user: Talker) => user.organization?.name?.toUpperCase() ?? ''
    },
    {
      header: 'Nb parcours',
       get: (user: Talker) =>  (user?.trainMemberships?.length ? `${user?.trainMemberships?.length}` : '0')
    },
    {
      header: 'Parcours',
      get: (user: Talker) => user?.trainMemberships?.map(tm => tm?.train?.id).join(' | ') ?? ''
    }
  ];
}


export class OrganizationCsvMaker extends CsvMaker<Organization> {
  constructor(protected override readonly data: Organization[]) {
    super();
  }

  columns = [
    {
      header: 'Id',
      get: (organization: Organization) => organization.id
    },
    {
      header: 'Nom',
      get: (organization: Organization) => organization.name.trim().toUpperCase()
    },
    {
      header: 'Crée le',
      get: (organization: Organization) => organization.createdAt.toLocaleDateString()
    },
    {
      header: 'Nombre de membres',
      get: (organization: Organization) => organization.members.length.toString()
    },
    {
      header: 'Unités restantes',
      get: (organization: Organization) => organization.units.toString()
    },
  ];
}


export class TrainCsvMaker extends CsvMaker<Train> {
  constructor(protected override readonly data: Train[]) {
    super();
  }

  columns = [
    {
      header: 'Id',
      get: (train: Train) => train.id
    },
    {
      header: 'Thématique',
      get: (train: Train) => train.dynamic.thematic.name
    },
    {
      header: 'Dynamique',
      get: (train: Train) => (train.dynamic.type === DynamicType.COACHING ? 'Coaching' : 'Parole')
    },
    {
      header: 'Crée le',
      get: (train: Train) => train.createdAt.toLocaleDateString()
    },
    {
      header: 'Nombre de membres',
      get: (train: Train) => train.members.length.toString()
    },
    {
      header: 'Séance 1 Date',
      get: (train: Train) => train.sessions[0]?.date?.toLocaleDateString()
    },
    {
      header: 'Séance 1 Heure',
      get: (train: Train) => train.sessions[0]?.date?.toLocaleTimeString()
    },
    {
      header: 'Séance 2 Date',
      get: (train: Train) => train.sessions[1]?.date?.toLocaleDateString()
    },
    {
      header: 'Séance 2 Heure',
      get: (train: Train) => train.sessions[1]?.date?.toLocaleTimeString()
    },
    {
      header: 'Séance 3 Date',
      get: (train: Train) => train.sessions[2]?.date?.toLocaleDateString()
    },
    {
      header: 'Séance 3 Heure',
      get: (train: Train) => train.sessions[2]?.date?.toLocaleTimeString()
    },
    {
      header: 'Séance 4 Date',
      get: (train: Train) => train.sessions[3]?.date?.toLocaleDateString()
    },
    {
      header: 'Séance 4 Heure',
      get: (train: Train) => train.sessions[3]?.date?.toLocaleTimeString()
    },
    {
      header: 'Sherpa id',
      get: (train: Train) => train.sherpa?.id
    },
    {
      header: 'Talker 1 id',
      get: (train: Train) => train.members[0]?.id
    },
    {
      header: 'Talker 2 id',
      get: (train: Train) => train.members[1]?.id
    },
    {
      header: 'Talker 3 id',
      get: (train: Train) => train.members[2]?.id
    },
    {
      header: 'Talker 4 id',
      get: (train: Train) => train.members[3]?.id
    },
  ];
}

export class TransactionHistoryCsvMaker extends CsvMaker<OrganizationTransactionParsed> {
  constructor(protected override readonly data: OrganizationTransactionParsed[]) {
    super();
  }

  columns = [
    {
      header: 'Date',
      get: (transaction: OrganizationTransactionParsed) => transaction.date.toLocaleDateString()
    },
    {
      header: 'Évolution',
      get: (transaction: OrganizationTransactionParsed) => transaction.units
    },
    {
      header: 'Solde',
      get: (transaction: OrganizationTransactionParsed) => transaction.restAmount.toString()
    },
    {
      header: "Nature de l'action",
      get: (transaction: OrganizationTransactionParsed) => transaction.description
    }
  ];
}

export class FeedbackCsvMaker extends CsvMaker<Feedback> {
  constructor(protected override readonly data: Feedback[]) {
    super();
  }

  columns = [
    {
      header: 'Nom',
      get: (feedback: Feedback) => capitalize(`${feedback.user.personalInformation.lastName}`)
    },
    {
      header: 'Prénom',
      get: (feedback: Feedback) => capitalize(`${feedback.user.personalInformation.firstName}`)
    },
    {
      header: 'Organisation',
      get: (feedback: Feedback) => `${feedback.user.organization.name}`
    },
    {
      header: 'Date du retour',
      get: (feedback: Feedback) => (feedback.createdAt ? new Date(feedback.createdAt).toLocaleString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false }) : '')
    },
    {
      header: 'Note',
      get: (feedback: Feedback) => feedback.rating.toString()
    },
    {
      header: 'Commentaire',
      get: (feedback: Feedback) => (feedback.comment ? `"${feedback.comment}"` : '')
    },
  ];
}

