<div *ngIf="canSeeStatistics" class="feedback-container margin-top">
    <div class="row start gap center-children-vertically margin-left">
      <app-icon type="info"></app-icon>
      <div>Cette section n'est pas visible par les Pilotes.</div>
    </div>
    <div class="emoji-section">
      <div class="left-part">
        <div>
          <div class="text-xl text-primary cooper"> /5</div>
          <div class="text-bold">Satisfaction</div>
          <div>vis à vis de la plateforme</div>
        </div>
      </div>
      <div class="center-part row space-between">
        <div class="emoji-item-container">
          <app-icon size="50" [type]="'sad-emoji'" [color]="'#fff'"></app-icon>
          <div class="rating">3</div>
        </div>
        <div class="emoji-item-container">
          <app-icon size="50" [type]="'woozy-emoji'" [color]="'#fff'"></app-icon>
          <div class="rating">4</div>
        </div>
        <div class="emoji-item-container">
          <app-icon size="50" [type]="'neutral-emoji'" [color]="'#fff'"></app-icon>
          <div class="rating">4</div>
        </div>
        <div class="emoji-item-container">
          <app-icon size="50" [type]="'happy-emoji'" [color]="'#fff'"></app-icon>
          <div class="rating">5</div>
        </div>
        <div class="emoji-item-container">
          <app-icon size="50" [type]="'laughy-emoji'" [color]="'#fff'"></app-icon>
          <div class="rating">5</div>
        </div>
      </div>
      <div class="right-part">
        <app-button icon="upload" [outline]="true" (click)="downloadCsv()">Exporter</app-button>
      </div>
    </div>
</div>