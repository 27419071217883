import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { filter, Subject, takeUntil } from "rxjs";

import { capitalize } from "../../shared/tool-functions/capitalize";
import { Organization } from "../models/organizations.entity";
import { OrganizationStoreService } from "../services/organization.store.service";

@Component({
  selector: 'app-organization-edit',
  templateUrl: './organization-edit.component.html',
  styleUrls: [ './organization-edit.component.scss' ]
})
export class OrganizationEditComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  organization!: Organization;

  loading = true;

  organizationFormControl?: FormControl<Organization>;

  constructor(
    private readonly organizationStoreService: OrganizationStoreService,) {
  }

  ngOnInit() {
    this.organizationStoreService.getObservable()
      .pipe(
        filter((organization): organization is Organization => !!organization),
        takeUntil(this.destroy$))
      .subscribe(organization => {
        this.organization = organization;
        this.organizationFormControl = new FormControl<Organization>(organization, { nonNullable: true });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly capitalize = capitalize;
}
