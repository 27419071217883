import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { ConfirmData } from "../confirm/confirm.component";
import { DialogComponent } from "../dialog/dialog.component";
import { DialogRef } from "../dialog/dialog.ref";
import { DIALOG_DATA } from "../dialog/dialog.token";

export enum CongratsType {
  POST_TRAIN_CONFIRM = 'post-train-confirm',
  POST_NEW_SHERPA = 'post-new-sherpa',
  MISSED_TRAIN = 'missed-train'
}


export type CongratsData = ConfirmData & {
  type: CongratsType;
}

@Component({
  selector: 'app-information',
  templateUrl: './congrats.component.html',
  styleUrls: ['./congrats.component.scss']
})
export class CongratsComponent extends DialogComponent<undefined, CongratsData>() implements OnInit, OnDestroy {

  public confirmMessage = 'Ok';

  constructor(@Inject(DIALOG_DATA)
              public override readonly data: CongratsData,
              private readonly ref: DialogRef<undefined>
  ) {
    super(ref);
    this.confirmMessage = data.confirmMessage ?? 'Ok';
  }

  static override isPopIn = true;

  get image(): string {
    const imageRecord: Record<CongratsType, string> = {
      [CongratsType.POST_TRAIN_CONFIRM] : '/assets/persona/congrats.png',
      [CongratsType.POST_NEW_SHERPA] : '/assets/persona/thumb.jpg',
      [CongratsType.MISSED_TRAIN]: '/assets/persona/too-bad.png'
    };

    return imageRecord[this.data.type];
  }

  ngOnInit() {
    super.onInit();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  parseMessageToHtml(message?: string): string {
    const sentences = message?.split('\n') ?? [];
    return sentences.reduce((htmlResult, sentence) => {
      htmlResult += `<p class="margin-bottom">${sentence}</p>`;

      return htmlResult;
    }, '');
  }
}
