import { Component, OnDestroy } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { catchError, delay, of, Subject, takeUntil, tap } from "rxjs";

import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { IsEmailValidator } from "../../shared/validators/email.validator";
import { AuthenticationService } from "../services/authentication.service";

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: [ './activate-account.component.scss' ]
})

export class ActivateAccountComponent implements OnDestroy {

    private destroy$ = new Subject<void>();
    
    sent = false;

    loading = false;

    emailForm: EntityFormGroup<{ email: string }>;

    formError = false;

    errorMessage = '';
    
    constructor(
        private readonly formBuilder: FormBuilder,
         private readonly authenticationService: AuthenticationService
        ) { 
            this.emailForm = this.formBuilder.group({
            email: new FormControl('', { validators: IsEmailValidator(), nonNullable: true }),
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
      }
    

    get mailButton(): string {
        if (this.loading) {
          return 'Envoi en cours...';
        }
        if (this.sent) {
          return 'Envoyé !';
        }
        return "Me renvoyer le mail d'inscription";
    }

      sendActivationEmail() {
        if (this.emailForm.valid && !this.sent) {
            this.loading = true;
            this.sent = true;
            this.authenticationService.sendAccountRecoveringEmail(this.emailForm.value.email!)
                        .pipe(
                          catchError(() => of(null)),
                          tap(() => {
                            this.loading = false;
                            this.sent = true;
                          }),
                          delay(5000),
                          tap(() => {
                            this.sent = false;
                          }),
                          takeUntil(this.destroy$))
                        .subscribe();
        } else {
            this.formError = true;
            this.errorMessage = 'Veuillez rentrer un email valide';
        }
      }
}