import { AfterViewInit, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { IconType } from '../icon/icon.component';

@Component({
  selector: 'app-emoji-selector',
  templateUrl: './emoji-selector.component.html',
  styleUrls: ['./emoji-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmojiSelectorComponent),
      multi: true
    }
  ]
})
export class EmojiSelectorComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {

  emojiTypes: IconType [] = ['sad-emoji', 'woozy-emoji', 'neutral-emoji', 'happy-emoji', 'laughy-emoji']; 

  private destroy$ = new Subject<void>();

  @Input() emojiSelected: FormControl = new FormControl<number | undefined>(undefined);

  onChange: (value: number) => void = () => {};

  onTouched: () => void = () => {};

  ngAfterViewInit(): void {
    this.emojiSelected.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.onChange(value);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  writeValue(value: number): void {
    this.onChange(value);
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  selectEmoji(value: number): void {
    this.emojiSelected.setValue(value);
    this.onChange(value);
  }

  getEmojiColorByType(type: IconType): string {
    switch (type) {
      case 'sad-emoji':
        return '#ff4f4f';
      case 'woozy-emoji':
        return '#FC9418';
      case 'neutral-emoji':
        return '#FCC418';
      case 'happy-emoji':
        return '#94D82C';
      case 'laughy-emoji':
        return '#22C997';
      default:
        return '';
    }
  }
}