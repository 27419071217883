import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init } from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  init({
// @ts-ignore
    dsn: window["WETALK_CONFIG"].sentryDsn,
// @ts-ignore
    environment: window["WETALK_CONFIG"].sentryEnv,
  });
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => err);
