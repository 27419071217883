<div class="question-container sized-wrapper">

  <div class="wrapper column gap full-width" [formGroup]="questionForm">

    <h1>Une question ?</h1>

    <p>Quel est le sujet de votre question ?</p>
    <app-input formControlName="subject"
               [dropdownData]="subjects"
               placeholder="Sujet"></app-input>
    <textarea class="margin-bottom" formControlName="content" placeholder="Veuillez inscrire ici le contenu de votre question"></textarea>
    <div class="center-children">
      <app-button [disabled]="!questionForm.valid || apiCalling" (click)="sendEmail()" title="Envoyer">Envoyer</app-button>
    </div>
  </div>


</div>
