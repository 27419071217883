<div class="padding-wrapper column">
  <div *ngIf="loading" class="full-screen">
    <app-spinner/>
  </div>
  <div class="wrapper" *ngIf="!loading">
    <h1 class="margin-bottom">Bien-être</h1>
    <app-typeform-responses-panel />

    <h1 class="margin-top-xl margin-bottom">Parcours</h1>
    <app-notification *ngIf="!(currentTrains.length + overTrains.length)" class="margin-bottom"
               buttonMessage="Inscrivez-vous à un parcours" (onButtonClick)="goToAvailableTrains()" color="#8C5FB0"
               icon="calendar" title="Suivez l'historique de vos parcours"
               >
      Parcourez en détail l’historique de vos parcours et de vos sessions. Préparez les parcours à venir.
    </app-notification>
    <div *ngIf="currentTrains.length + overTrains.length" class="margin-bottom">
      <div class="resume row">
        <div class="tile" *ngIf="currentTrains.length">
          <div class="text-xl text-primary cooper">{{currentTrains.length}}</div>
          <div class="cooper text-black">parcours en cours</div>
        </div>
        <div class="tile" *ngIf="overTrains.length">
          <div class="text-xl text-primary cooper">{{overTrains.length}}</div>
          <div class="cooper text-black">parcours réalisé{{overTrains.length > 1 ? 's' : ''}}</div>
        </div>
        <div class="tile" *ngIf="sessionsDone">
          <div class="text-xl text-primary cooper">{{sessionsDone}}</div>
          <div class="cooper text-black">séance{{sessionsDone > 1 ? 's' : ''}}
            réalisée{{sessionsDone > 1 ? 's' : ''}}</div>
        </div>
        <div class="tile" *ngIf="hoursDone">
          <div class="text-xl text-primary cooper">{{hoursDone}}h</div>
          <div class="cooper text-black">de temps pour moi</div>
        </div>

      </div>

    </div>
    <h2 *ngIf="currentTrains.length" class="margin-bottom margin-top">Sessions à venir</h2>
    <div *ngIf="currentTrains.length" class="column gap">
      <app-train-card class="margin-bottom" *ngFor="let train of currentTrains" [train]="train"
                      (goToMeeting)="goToMeeting($event)" (goToDetails)="goToTrain(train)"></app-train-card>
    </div>
    <div *ngIf="overTrains.length" class="column gap">
      <h2 class="margin-bottom">Parcours finalisé{{overTrains.length > 1 ? 's' : ''}}</h2>
      <app-train-card class="margin-bottom" *ngFor="let train of overTrains" [train]="train"
                      (goToDetails)="goToTrain(train)"></app-train-card>
    </div>

    <h1 class="margin-top-xl margin-bottom">Thématiques favorites</h1>
    <app-notification *ngIf="!favoriteThematics.length" class="margin-bottom" buttonMessage="Découvrez les thématiques"
               (onButtonClick)="goToThematics()" color="#2CBBB6" icon="favorite-outline"
               title="Renseignez vos thématiques favorites"
               >
      Identifiez les thèmes qui vous correspondent.
      Recevez des notifications quand un parcours est lancé.
    </app-notification>

    <div *ngIf="favoriteThematics.length">
      <div class="resume row margin-bottom">
        <div class="tile">
          <div class="text-xl text-primary cooper">{{favoriteThematics.length}}</div>
          <div class="cooper text-black">thématique{{ favoriteThematics.length > 1 ? 's' : '' }}
            favorite{{ favoriteThematics.length > 1 ? 's' : '' }}</div>
        </div>
        <div class="tile" *ngIf="favoriteCategories.length">
          <div class="text-xl text-primary cooper">{{favoriteCategories.length}}</div>
          <div class="cooper text-black">famille{{favoriteCategories.length > 1 ? 's' : ''}} de thématiques</div>
        </div>
        <div class="tile-bubble">
          <div class="bubble" [style.border-color]="mediumCategoryColors[category.slug]"
               *ngFor="let category of favoriteCategories">{{capitalize(category.name)}}</div>
        </div>

      </div>

      <div class="wrap start">
        <div
          class="thematic" *ngFor="let thematic of favoriteThematics">
          <app-thematic-card [thematic]="thematic"
                             (click)="clickOnThematic(thematic.slug)"></app-thematic-card>
        </div>
      </div>
    </div>

  </div>


</div>
