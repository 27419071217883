import { Type } from "class-transformer";

import { Dynamic } from "../../thematics/models/dynamic.model";
import { TimeSlot } from "../../trains/models/time-slot.entity";
import { Train } from "../../trains/models/train.entity";

import { CreateUser, Role, User } from "./users.entity";

export class Sherpa extends User {

  override role!: Role.Sherpa;

  @Type(() => Train)
  trainsManaged?: Train[];

  @Type(() => TimeSlot)
  timeSlotPreferences?: TimeSlot[];

  @Type(() => Dynamic)
  dynamicsAuthorized!: Dynamic[];

}

export interface CreateSherpa extends CreateUser {
  dynamicsAuthorized?: string[];
}
