import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'app-subscribe-page',
    templateUrl: './subscribe-page.component.html',
    styleUrls: [ './subscribe-page.component.scss' ]
})

export class SubscribePageComponent {
    @Input() backgroundColor: string = '#dad3ea';

    @Input() height: string = '100dvh';

    constructor(private readonly router: Router){}

    goToContact(){
        this.router.navigate(['questions']);
    }
}