import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, catchError, combineLatest, filter, Observable, of, switchMap, tap } from "rxjs";

import { DashboardRepository } from "../dashboard.repository";
import { DateInterval } from "../utils/bar-chart-tools";

@Component({
    selector: 'app-strength-weakness',
    templateUrl: './strength-weakness.component.html',
})

export class StrengthWeaknessComponent implements OnInit  {
  
  @Input() organizationIds$!: Observable<string[]>;

  @Input() dateInterval$ = new BehaviorSubject<DateInterval>({
    fromDate: new Date(),
    toDate: new Date()
  });

  hasResponse = true;

  private loading$ = new BehaviorSubject<boolean>(false);

  private error$ = new BehaviorSubject<string | null>(null);

  radarChartDataSubject$ = new BehaviorSubject<any>(null);
  

  constructor(private readonly dashboardRepository: DashboardRepository) {}

  ngOnInit() {
        combineLatest([
            this.organizationIds$,
            this.dateInterval$
          ]).pipe(
            tap(([orgIds, _]) => {
              if (!orgIds?.length) {
                this.radarChartDataSubject$.next(null);
                return;
              }
              this.loading$.next(true);
              this.error$.next(null);
            }),
            filter(([orgIds, _]) => !!orgIds?.length),
            switchMap(([orgIds, interval]) =>
              combineLatest([
                this.dashboardRepository.getTalkersStrengthsAndWeakness({ 
                  organizationIds: orgIds,
                  startDate: interval.fromDate.toISOString(),
                  endDate: interval.toDate.toISOString() 
                }),
              ]).pipe(
                catchError(error => {
                  this.error$.next('Failed to load emotional state data');
                  this.loading$.next(false);
                  return of([null, null]);
                })
              )
            )
          ).subscribe(([state, progression]) => {
            this.hasResponse = Object.values(state).every((obj: any) => obj.value !== 0);
            this.radarChartDataSubject$.next(state);
            this.loading$.next(false);
          });
  }

}