import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TimeSlotPossibility } from "../../../../admin/edit-timeslots/edit-timeslots.component";
import { WeekDaysNames } from "../../../../admin/types/constant";
import { stringNumber } from "../../../tool-functions/string-number";

@Component({
  selector: 'app-weekly-calendar',
  templateUrl: './weekly-calendar.component.html',
  styleUrls: [ './weekly-calendar.component.scss' ]
})
export class WeeklyCalendarComponent {

  @Input() weekDaysAvailable: number[] = [ 1, 2, 3, 4, 5, 6, 0 ];

  @Input() dayTimes: TimeSlotPossibility[] = [];

  @Input() hideTimeSlots = false;

  @Output() clickOnDay = new EventEmitter<number>();

  globalWeekDaysAvailable = [ 1, 2, 3, 4, 5, 6, 0 ];

  weekDaysNames = WeekDaysNames;


  isLastDayOfWeek(day: number): boolean {
    return this.weekDaysAvailable[this.weekDaysAvailable.length - 1] === day;
  }

  get weekDaysClass(): string {
    return `days-wrapper days-wrapper-${ this.weekDaysAvailable.length }`;
  }

  getDayTimeDescription(dayTime: TimeSlotPossibility): string {
    return `${ stringNumber(dayTime.hours) }:${ stringNumber(dayTime.minutes) }`;
  }

  selectDayTime(index: number) {
    this.clickOnDay.emit(index);
  }
}
