import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function IsEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Skip validation if empty
    if (!control.value) {
      return null;
    }
    
    // Updated regex that allows + in local part and longer TLDs
    const isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(control.value);
    return isEmail ? null : { email: { value: control.value } };
  };
}