<div class="wrapper">
  <div class="header row gap">
    <h1 class="title">Sélectionner un sherpa</h1>
    <app-icon button type="close"
              (click)="validate()"></app-icon>
  </div>


  <section>
    <form [formGroup]="sherpaForm" (submit)="validate(true)">
      <app-input [dropdownData]="existingSherpaNames"  formControlName="name"></app-input>
      <div class="add-button relative">
        <app-button class="center-by-relative-parent" [disabled]="!sherpaForm.valid" type="submit">Sauvegarder
        </app-button>
      </div>

    </form>
  </section>


</div>
