<div class="thematic-wrapper" [class.gray-filter]="thematic.isEnabled !== undefined && !thematic.isEnabled" [class.big]="big" (mouseenter)="onMouseOver($event)"
     (mouseleave)="onMouseLeave($event)" (click)="clickOnThematic(thematic.slug)">
    <div class="header">
        <div class="thematic-image" [class.big]="big">
          <app-image [height]="imageHeight || (big ? 600 : 200)" 
                     [width]="imageWidth || (big ? 900 : 345)" 
                     [src]="thematic.imageUrl"
                     [alt]="thematic.slug" />
        </div>
        <div class="thematic-name-wrapper">
          <p class="thematic-name" [ngStyle]="{'background-color': colorMedium }">{{ thematic.name }}</p>
        </div>
    </div>
    <h4 class="thematic-title cooper"
      [ngStyle]="{'background-color': hovered ? colorLight : '' }">{{ thematic.title }}</h4>
</div>