<div class="subscribe-container" [style.backgroundColor]="backgroundColor" [style.height]="height">
    <div class="text-container margin-top-xl">
        <p>Cette fonctionnalité est réservée à nos clients</p>
        <p class="subscribe-purple-text">possédant un abonnement</p>
    </div>
    <div class="margin-top-xl">
        Vous souhaitez découvrir nos tarifs ? <a (click)="goToContact()" class="contact-link">Contactez-nous !</a>
    </div>
    <div>
        <img class="subscribe-image" src="assets/images/transaction.png" alt="Transaction image">
    </div>
</div>