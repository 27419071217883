
<div *ngIf="showBanner" class="banner-wrapper">
    <div class="banner-content">
        <p>
            Votre test gratuit prendra fin dans {{ remainingDays }}  jours. <span (click)="goToContact()">Contactez-nous</span> pour nous donner 
            vos impressions ou transformer votre test en abonnement.
        </p>
    </div>
    <div class="icon-wrapper">
        <app-icon size="20" color="white" type="close"
        (click)="close()"></app-icon>
    </div>
</div>