<div class="cards-list" #list>
    <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
    <div *ngIf="currentItems.length">
      <div
        class="card-item"
        [class.enter]="item.entering"
        [class.leave]="item.leaving"
        *ngFor="let item of currentItems; let index = index; trackBy: cardTrackBy"
        (scroll)="onScrollEvent()"
      >
      <app-media-card [content]="item.data"></app-media-card>
      </div>
    </div>
    <div *ngIf="!currentItems.length && !isLoading && showNoData">
      Aucun résultat
    </div>
    <div #bottomTag></div>
    <ng-container *ngTemplateOutlet="noItemsTemplate"></ng-container>
    <div class="loading-spinner" *ngIf="isLoading">
      <app-spinner></app-spinner>
    </div>
  </div>
  