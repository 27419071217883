import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: [ './stepper.component.scss' ]
})
export class StepperComponent implements OnChanges {

  @Input() stepsDone!: number;

  @Input() maxSteps!: number;

  @Input('previous') previousButtonEnabled = false;

  @Input('next') nextButtonEnabled = false;

  @ViewChild('content') content!: ElementRef<HTMLElement>;

  @Output() selectStep: EventEmitter<number> = new EventEmitter<number>();

  get slideStep(): string {
    return `size-${ this.maxSteps } slide-${ Math.floor(this.stepsDone / this.maxSteps * 100) }`;
  }

  ngOnChanges() {
    this.content?.nativeElement?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onSelectStep(step: number) {
    this.selectStep.emit(step);
  }
}
