import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";

import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { Sherpa } from "../../users/models/sherpa.entity";
import { SherpaRepository } from "../../users/repositories/sherpa.repository";

export type SelectSherpa = {
  sherpa: Sherpa | null;
}

@Component({
  selector: 'app-modify-sherpa',
  templateUrl: './modify-sherpa.component.html',
  styleUrls: [ './modify-sherpa.component.scss' ]
})
export class ModifySherpaComponent extends DialogComponent<SelectSherpa, Sherpa | null>() implements OnInit, OnDestroy {

  existingSherpas!: Sherpa[];

  existingSherpaNames!: string[];

  sherpaForm!: EntityFormGroup<{ name: string | null }>;

  constructor(
    @Inject(DIALOG_DATA)
    public override readonly data: Sherpa | null,
    private readonly formBuilder: FormBuilder,
    private readonly ref: DialogRef<SelectSherpa>,
    private readonly sherpaRepository: SherpaRepository,
  ) {
    super(ref);
    this.sherpaForm = this.formBuilder.group({
      name: new FormControl(data ? data.fullName : '')
    });
    this.sherpaRepository.getAll().subscribe(sherpas => {
      this.existingSherpas = sherpas;
      this.existingSherpaNames = sherpas.map(sherpa => sherpa.fullName);
    });

  }

  ngOnInit(): void {
    super.onInit();
  }


  validate(validated: boolean = false) {
    if (validated) {
      super.close({
        sherpa: this.existingSherpas.find(sherpa => sherpa.fullName === this.sherpaForm.controls.name.value) ?? null
      });
    } else {
      super.close();
    }
  }


  ngOnDestroy() {
    super.onDestroy();
  }

}
