import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from "rxjs";

import { Profile } from "../../../users/services/profile.service";

@Component({
  selector: 'app-profile-icon',
  templateUrl: './profile-icon.component.html',
  styleUrls: [ './profile-icon.component.scss' ]
})
export class ProfileIconComponent implements OnInit, OnDestroy {
  @Input() profile!: Profile;

  @Input() reload!: Subject<void>;

  @Input() primary = true;

  @Input() tiny = false;

  @Input() big = false;

  @Input() title = '';

  @Input() editable = false;

  @Input() isOnTabs = false;

  @Input() color? : string;

  private destroy$ = new Subject<void>();


  ngOnInit() {

    this.reload?.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.profile.hasImage = true;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  get firstName(): string {
    return `${ (this.profile.firstName?.toUpperCase()?.charAt(0) ?? '') }${ this.profile.firstName?.toLowerCase()?.substring(1) }`;
  }

  get lastName(): string {
    return this.profile.lastName?.toUpperCase() ?? '';
  }
}
