<div *ngIf="selectedDate && selectedTimeSlot" class="column gap" [class.fade-left]="fadeLeft$ | async "
     [class.fade-left-reverse]="fadeLeftReverse$ | async ">
  <strong class="margin-top">Voici le résumé de votre réservation</strong>
  <div class="center-children-horizontally column gap">
    <div class="tile-primary center-children column gap">
      <h3>1re séance</h3>
      <div>{{dynamic.dynamicType}}</div>
      <div class="note-wrapper">
        <div>
          <app-icon type="edit" color="#ff8b05"></app-icon>
        </div>
        <div class="note-text">
          <span class="text-bold">Merci de noter ces dates dans votre agenda.</span>
          <span>Vous vous engagez à être présent.e. Toute demande de modification d'horaire doit être 
          signalée au plus tard 48h ouvrées avant la séance.
          </span>
        </div>
      </div>
      <p><strong>Le {{dateFormat(selectedDate)}} à {{timeFormat(selectedTimeSlot)}}</strong></p>
    </div>
    <p>Les {{nextDates.length}} séances suivantes se dérouleront aux dates suivantes :</p>
    <div *ngFor="let date of nextDates; let index = index" class="tile-secondary column">
      <p><strong>{{index + 2}}e séance</strong></p>
      <span>{{dateFormat(date)}} à {{timeFormat(selectedTimeSlot, 'h')}}</span>
    </div>
  </div>
</div>
