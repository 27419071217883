

<div *ngIf="isBannerVisible; else withBannerTemplate">
  <div class="alt-main-wrapper">
    <app-tabs></app-tabs>
    <div class="alt-main-page" >
      <app-test-end-banner (bannerVisibility)="onBannerVisibility($event)"></app-test-end-banner>
      <router-outlet></router-outlet>
    </div>
    <app-snackbar></app-snackbar>
  </div>
</div>

<ng-template #withBannerTemplate>
  <div class="alt-main-wrapper">
      <app-tabs></app-tabs>
      <div class="alt-main-page" >
        <router-outlet></router-outlet>
      </div>
      <app-snackbar></app-snackbar>
  </div>
</ng-template>



