import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatestWith, takeUntil } from "rxjs";

import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { formatTimeSlot } from "../../shared/tool-functions/date-tools";
import { TimeSlot } from "../../trains/models/time-slot.entity";
import { CreateTrainService } from "../create-train.service";
import { Dynamic } from "../models/dynamic.model";
import { Thematic } from "../models/thematic.model";

@Component({
  selector: 'app-select-time',
  templateUrl: './select-time.component.html',
  styleUrls: [ './select-time.component.scss' ]
})
export class SelectTimeComponent extends AbstractInFunnelComponent {

  thematic!: Thematic;

  dynamic!: Dynamic;

  availableTimes: TimeSlot[] = [];

  selectedDate!: Date;

  timeSlotSelected?: TimeSlot;

  constructor(
    protected override readonly funnelService: FunnelService,
    protected override readonly route: ActivatedRoute,
              private readonly router: Router,
              public readonly createTrainService: CreateTrainService) {
    super(funnelService, route);
  }

  override ngOnInit() {
    this.createTrainService.thematic$
      .pipe(
        combineLatestWith(this.createTrainService.dynamic$, this.createTrainService.dateSelected$, this.createTrainService.timeslots$),
        takeUntil(this.destroy$)
      )
      .subscribe(([ thematic, dynamic, date, timeslots ]) => {
        if (!thematic) {
          this.router.navigate([ 'thematics' ]);
        } else if (!dynamic) {
          this.router.navigate([ 'thematics', thematic.slug ]);
        } else if (!date || timeslots.length === 0) {
          this.router.navigate([ 'thematics', thematic.slug, 'create-train', dynamic.type, 'date' ]);
        } else {
          this.thematic = thematic;
          this.dynamic = dynamic;
          this.selectedDate = date;
          this.availableTimes = timeslots.filter(timeslot => timeslot.weekDay === date.getDay())
            .sort((timeA, timeB) =>
              (timeA.hours - timeB.hours) * 60 + (timeA.minutes - timeB.minutes));
          super.ngOnInit();
        }
      });

    this.createTrainService.timeSlotSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe(timeslot => {
        this.timeSlotSelected = timeslot || undefined;
        this.funnelService.canValidate$.next(!!timeslot);
      });

    this.funnelService.validate$.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.router.navigate([ 'thematics', this.thematic.slug, 'create-train', this.dynamic.type, 'resume' ]));

  }

  formatTime = formatTimeSlot;

  selectTime(id: string): void {
    this.createTrainService.timeSlotSelected$.next(this.availableTimes.find(t => t.id === id) || null);
  }
}
