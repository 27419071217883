import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, Subject, takeUntil } from "rxjs";

import { capitalize } from "../../shared/tool-functions/capitalize";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { AuthenticationService } from "../services/authentication.service";

export type PasswordForm = {
  password: string;
  confirmation: string;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [ './reset-password.component.scss' ]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  firstName!: string;

  email!: string;

  passwordForm: EntityFormGroup<PasswordForm>;

  expiredLink = false;

  isReactivation = false;

  token!: string;

  private destroy$ = new Subject<void>();

  constructor(private readonly formBuilder: FormBuilder,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly titleService: Title,
              private readonly authenticationService: AuthenticationService) {
    this.passwordForm = this.formBuilder.group({
      password: new FormControl('', { validators: Validators.required, nonNullable: true }),
      confirmation: new FormControl('', { validators: Validators.required, nonNullable: true }),
    });
  }

  ngOnInit() {
    this.route.queryParamMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(queryParams => {
        if (queryParams.has('token')) {
          this.token = queryParams.get('token') ?? '';

          this.authenticationService.verifyResetToken(this.token)
            .pipe(catchError(() => {
              this.isReactivation = true;
              return this.authenticationService.verifyNewPlatformToken(this.token);
            }))
            .subscribe({
              next: (user) => {
                this.email = user.email;
                this.firstName = capitalize(user.firstName);
              },
              error: () => {
                this.expiredLink = true;
                this.isReactivation = false;
              }
            });


        } else {
          this.expiredLink = true;
        }
      });

    this.titleService.setTitle(`Réinitialisation de mot de passe`);

  }

  ngOnDestroy() {
    this.destroy$.next();
  }


  validate() {
    if (this.passwordForm.valid) {
      if (this.isReactivation) {
        this.authenticationService.setNewPlatformPassword({
          email: this.email,
          password: this.passwordForm.controls.password.value,
          token: this.token
        }).subscribe({
          next: () => {
            this.router.navigate([ 'home' ]);
          },
          error: () => {
            this.expiredLink = true;
          }
        });
      } else {
        this.authenticationService.resetPassword({
          email: this.email,
          password: this.passwordForm.controls.password.value,
          token: this.token
        }).subscribe({
          next: () => {
            this.router.navigate([ 'home' ]);
          },
          error: () => {
            this.expiredLink = true;
          }
        });
      }
    }
  }

  goToLogin() {
    this.router.navigate([ 'auth', 'login' ]);
  }
}
