import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
import { combineLatest } from "rxjs";

import { APP_CONFIG } from "./config/config";
import { AppConfig } from "./config/config.type";
import { OrganizationStoreService } from "./organizations/services/organization.store.service";
import { FunnelService } from "./shared/services/funnel.service";
import { NavigationService } from "./shared/services/navigation.service";
import { Role } from "./users/models/users.entity";
import { ProfileService } from "./users/services/profile.service";

declare global {
  interface Window {
    _mtm: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit {

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private readonly render: Renderer2,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly router: Router,
              private readonly profileService: ProfileService,
              private readonly permissionService: NgxPermissionsService,
              private readonly navigationService: NavigationService,
              private readonly funnelService: FunnelService) {

  }

  ngOnInit() {
    combineLatest([this.profileService.getObservable(), this.organizationStoreService.getObservable()])
      .subscribe(([ state, organization ]) => {
          this.permissionService.flushPermissions();
          this.permissionService.addPermission([ state.role ?? Role.Talker ]);
          if (state.role === Role.Talker && state.isPilot) {

            this.permissionService.addPermission('pilot');
            if (state?.organizationAssociations?.length > 1 || state?.organizationAssociations.some(managedOrganization => managedOrganization?.organization?.children?.length)) {
              this.permissionService.addPermission('pilot-with-multiple-organizations');
            }
          }

          if (organization) {

            this.permissionService.addPermission('organization');
          }
        }
      );
  }

}
