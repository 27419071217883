export interface SubscriptionPlan {
    primary?: boolean;
    secondary?: boolean;
    warning?: boolean;
    info?: boolean;
    outline?: boolean;
    textColor: string;
    name: string;
}

export const SubscriptionPlanMetadata: { [key: string]: SubscriptionPlan }  = {
    "personalized-subscription-individual": {
        outline: true,
        primary: true,
        textColor: "white",
        name: "À la carte - Particulier",
    },
    "personalized-subscription-professional": {
        outline: true,
        primary: true,
        textColor: "white",
        name: "À la carte - Professionnel",
    },
    "basic-subscription": {
        info: true,
        textColor: "white",
        name: "Abonnement Basique"
    },
    "classic-subscription": {
        primary: true,
        textColor: "white",
        name: "Abonnement Classique"
    },
    "premium-subscription": {
        secondary: true,
        textColor: "white",
        name: "Abonnement Premium"
    },
    "elite-subscription": {
        warning: true,
        textColor: "white",
        name: "Abonnement Elite"
    }
};