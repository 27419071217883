import { Component, EventEmitter, Input, Output } from "@angular/core";

import { Admin } from "../../../users/models/admin.entity";
import { Sherpa } from "../../../users/models/sherpa.entity";
import { Talker } from "../../../users/models/talker.entity";
import { User } from "../../../users/models/users.entity";

@Component({
    selector: 'app-sherpa-card',
    templateUrl: './sherpa-card.component.html',
    styleUrls: [ './sherpa-card.component.scss' ]
})

export class SherpaCardComponent {

    @Input() user!: Talker | Sherpa | Admin;

    @Output() clickOnDetails = new EventEmitter<User>();

    constructor() {}

    get infos(): string {
        return this.user.personalInformation.description ?? '';
    }

    goToDetails(): void {        
        this.clickOnDetails.emit(this.user);
    }
}



