import {
  AfterViewInit,
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  numberAttribute,
  OnDestroy,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { debounceTime, distinctUntilChanged, Subject, Subscription, takeUntil } from "rxjs";

import { getPhoneCodes, PhoneCode } from "../../tool-functions/phone.number";
import { DropdownComponent } from '../dropdown/dropdown.component';
import { IconType } from "../icon/icon.component";


type InputType =
  'email'
  | 'password'
  | 'default'
  | 'phone'
  | 'user'
  | 'gender'
  | 'search'
  | 'organization'
  | 'number'
  | 'time'
  | 'date'
  | 'dropdown'
  | 'link';

const IconRegister: Record<InputType, IconType | undefined> = {
  email: 'email',
  password: 'lock',
  phone: 'phone',
  user: 'user',
  gender: undefined,
  search: 'search',
  organization: 'building',
  number: undefined,
  default: undefined,
  time: undefined,
  date: undefined,
  dropdown: undefined,
  link: undefined
};

@Component({
  selector: 'app-input',
  templateUrl: './text-input.component.html',
  styleUrls: [ './text-input.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => TextInputComponent)
  } ],
  encapsulation: ViewEncapsulation.None
})
export class TextInputComponent implements AfterViewInit, OnDestroy, ControlValueAccessor, OnDestroy {
  private onChange: (value: string | null) => void = (_: string | null) => {
  };

  private onTouched: () => void = () => {
  };

  passwordShown = false;

  isDisabled = false;

  isEditing = false;

  searchForm: FormControl = new FormControl('');

  get leftIcon() {
    return IconRegister[this.type] ?? null;
  }

  get isPassword() {
    return this.type === 'password';
  }

  get isSearch() {
    return this.type === 'search';
  }

  phoneCodes: PhoneCode[] = getPhoneCodes();

  private destroy$ = new Subject<void>();

  @Input() withLeftIcon: boolean = true;

  @Input() type: InputType = 'default';

  @Input() placeholder: string = '';

  @Input() id?: string;

  @Input() title: string = '';

  @Input() editable: boolean = false;

  @Input('login-password') passwordVisibleEnabled: boolean = false;

  @Input('error-message') errorMessage: string = 'disabled';

  @Input('margin-bottom') marginBottom: boolean = true;

  @Input() error: boolean = false;

  @Input() correct: boolean = false;

  @Input() readonly: boolean = false;

  @Input() inputField: FormControl = new FormControl<string>('');

  @Input() dropdownData: string[] | undefined;

  @Input() addOptionEnable = false;

  @Input() searchOptionEnable = false;

  @Input() phoneCode?: PhoneCode;

  @Input() gotSubMenu = false;

  @Input() isCheckmarks = false;

  @Input() filterOptionCount: number | undefined;

  @Input({ transform: numberAttribute }) min: number = 0;

  @Input({ transform: booleanAttribute }) negativeInput = false;

  @Input() max?: number;

  @Input() hasFilters?: boolean;

  @Output() selectPhoneCode = new EventEmitter<PhoneCode>();

  @Output() addOption = new EventEmitter<void>();

  @Output() searchPilot = new EventEmitter<string>();

  @Output() enterPressed = new EventEmitter<string>();

  @Output() deleteClicked = new EventEmitter<void>();

  @ViewChild('subMenuContainer') subMenuContainer!: ElementRef<HTMLElement>;

  @ViewChild('simpleDropdownContainer') simpleDropdownContainer!: ElementRef<HTMLElement>;

  @ViewChild('dropdown') dropdownContainer!: DropdownComponent;

  private subscription: Subscription = new Subscription();

  constructor(
    @Optional() private dropdown: DropdownComponent
  ) {}

  getPhoneIconFromRegion(region: string): string {
    return `fi fi-${ region.toLowerCase() }`;
  }

  ngAfterViewInit(): void {
    this.inputField.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.onChange(value);
        if (this.dropdown) {
          // this.dropdown.close();
        }
      });

      this.subscription.add(
        this.searchForm.valueChanges
          .pipe(
            debounceTime(300),
            distinctUntilChanged()
          )
          .subscribe(value => {
            this.searchPilot.emit(value);
          })
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.subscription.unsubscribe();
  }


  registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.inputField.setValue(value);
  }

  setDisabledState(state: boolean) {
    this.isDisabled = state;
  }

  showPassword() {
    this.passwordShown = !this.passwordShown;
  }

  selectOption(option: string) {
    this.inputField.setValue(option);
  }

  onSelectPhoneCode(phoneCode: PhoneCode) {
    this.selectPhoneCode.emit(phoneCode);
  }

  onAddOption() {
    this.addOption.emit();
  }

  focus() {
    this.isEditing = true;
  }

  showSimpleDropdown() {
    this.simpleDropdownContainer.nativeElement.click();
  }

  editFilter() {
    this.subMenuContainer.nativeElement.click();
  }

  onRemoveSelection() {
    this.inputField.setValue('');
    // emit event to be captured by parent component
    this.deleteClicked.emit();
  }

  get noValue(): boolean {
    return !this.inputField.value;
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onEnter();
    }
  }

  onEnter() {
    const inputValue = this.inputField.value;
    this.enterPressed.emit(inputValue);
  }

  public get dropdownElement(): DropdownComponent {
    return this.dropdownContainer;
  }
}
