import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPermissionsModule } from "ngx-permissions";

import { SharedModule } from "../shared/shared.module";

import { AvailableTrainsComponent } from './available-trains/available-trains.component';
import { LeaveTrainComponent } from './leave-train/leave-train.component';
import { ModifySessionDateComponent } from './modify-session-date/modify-session-date.component';
import { ModifySherpaComponent } from './modify-sherpa/modify-sherpa.component';
import { MyTrainsComponent } from "./my-trains/my-trains.component";
import { TrainRepository } from "./repositories/train.repository";
import { AvailableTrainsResolver } from './resolvers/available-trains.resolver';
import { MyTrainsMtmResolver } from './resolvers/my-trains-mtm.resolver';
import { TrainResolver } from "./resolvers/train.resolver";
import { TrainsMtmResolver } from './resolvers/trains-mtm.resolver';
import { TrainDetailsComponent } from './train-details/train-details.component';
import { TrainsListComponent } from './trains-list/trains-list.component';


@NgModule({
  declarations: [
    TrainsListComponent,
    TrainDetailsComponent,
    ModifySherpaComponent,
    ModifySessionDateComponent,
    LeaveTrainComponent,
    AvailableTrainsComponent,
    MyTrainsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxPermissionsModule.forChild()
  ],
  providers: [ TrainRepository, TrainResolver, AvailableTrainsResolver, MyTrainsMtmResolver, TrainsMtmResolver ],
})
export class TrainsModule {
}
