import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { ThematicRepository } from "../../thematics/repositories/thematic.repository";

@Component({
    selector: 'app-thematics-recommendation',
    templateUrl: './thematics-recommendation.component.html',
    styleUrls: ['./thematics-recommendation.component.scss'],
})
export class ThematicsRecommendationComponent implements OnInit, OnDestroy {
    recommendedThematics: any[] = [];

    loading = false;

    error: string | null = null;

    private destroy$ = new Subject<void>();

    constructor(private readonly thematicRepository: ThematicRepository) {}

    ngOnInit(): void {
        this.fetchRecommendedThematics();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private fetchRecommendedThematics(): void {
        this.loading = true;
        this.error = null;
        
        this.thematicRepository.getTalkerRecommendedThematics()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (thematics) => {
                    this.recommendedThematics = thematics;
                    this.loading = false;
                },
                error: (err) => {
                    console.error('Error fetching recommended thematics:', err);
                    this.error = 'Failed to load recommendations';
                    this.loading = false;
                }
            });
    }
}