import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

import { SortOrder, TableConfig } from "../../shared/components/table/table.component";
import { ContentsRepository } from "../repositories/contents.repository";
import { getFormatNameBySlug, getObjectiveNameBySlug } from "../utils/utils";

@Component({
    selector: "app-admin-contents-list",
    templateUrl: "./admin-contents-list.component.html",
    styleUrls: ["./admin-contents-list.component.scss"],
})

export class AdminContentsListComponent {
  headerColumns: TableConfig<any> = {
    title: {
      displayName: 'Titre',
      transformFunction: (title: string) => title,
      style: 'font-weight: 500',
      sortable: true
    },
    format: {
      displayName: 'Format',
      transformFunction: (format: string) => getFormatNameBySlug(format),
      style: 'font-weight: 500',
      sortable: true
    },
    objective: {
      displayName: 'Objectif',
      transformFunction: (objective: string) => getObjectiveNameBySlug(objective),
      style: 'font-weight: 500',
      sortable: true
    },
    createdAt: {
      displayName: "Date d'ajout",
      transformFunction: (date: Date) => (date ? date.toLocaleDateString() : '-'),
      sortable: true
    }
  };

  isActivatedFilter$: BehaviorSubject<{ isActivated?: boolean }> = new BehaviorSubject<{ isActivated?: boolean }>({});

  searchForm = new FormControl<string>('');

  selectedTalkerIds: string[] = [];

  sortBy$ = new BehaviorSubject<SortOrder<any> | undefined>({ sort: 'updatedAt', order: 'DESC' });

  constructor(
    public readonly mainRepository: ContentsRepository,
    private readonly router: Router) { }

  goToContent(contentId: string) {
    this.router.navigate([ 'resources', 'admin-resources', contentId, 'edit' ]);
  }

  selectTalkers(talkerIds: string[]) {
    this.selectedTalkerIds = talkerIds;
  }

  addNewContent() {
    this.router.navigate([ 'resources', 'admin-resources', 'add', 'new-content']);
  }

  handleSort(sortOrder: SortOrder<any> | undefined) {
    this.sortBy$.next(sortOrder);
  }
}