import { Component, Input } from '@angular/core';

import { capitalize } from "../../tool-functions/capitalize";

@Component({
  selector: 'app-talker-testimonial-quote',
  templateUrl: './talker-testimonial-quote.component.html',
  styleUrl: './talker-testimonial-quote.component.scss'
})
export class TalkerTestimonialQuoteComponent {

  @Input() author!: string;

  @Input() content!: string;

  @Input() color!: string;

  @Input() bubblePosition: 'left' | 'right' = 'left';

  protected readonly capitalize = capitalize;
}
