import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-pagination-footer",
  templateUrl: "./pagination-footer.component.html",
  styleUrls: ["./pagination-footer.component.scss"],
})
export class PaginationFooterComponent {
  @Input() currentPage: number = 0;

  @Input() totalPages: number = 0;

  @Output() pageChange = new EventEmitter<{ size: number; page: number }>();

  @Output() rowsPerPageChange = new EventEmitter();

  rowsPerPage = 50;

  onRowsPerPageChange(): void {
    this.rowsPerPageChange.emit(this.rowsPerPage);
  }

  changePage(page: number): void {
    this.currentPage = page;
    this.pageChange.emit({ size: this.rowsPerPage, page: this.currentPage });
  }
}
