import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { TreeCheckbox } from "../tree-checkmarks/tree-checkmarks.component";

@Component({
  selector: 'app-tree-checkbox',
  templateUrl: './tree-checkbox.component.html',
  styleUrls: [ './tree-checkbox.component.scss' ]
})
export class TreeCheckboxComponent {
  @Input() tree: TreeCheckbox = { id: 'id', selected: true, expanded: false, key: 'missing' };

  @Output() update = new EventEmitter<TreeCheckbox>();

  shiftKeyIsDown = false;

  onCheckboxUpdate(event: boolean) {
    this.update.emit({ ...this.tree, selected: event });
  }

  onTreeUpdate(event: TreeCheckbox) {
    const childIndex = this.tree.children?.findIndex(child => child.id === event.id);
    const treeCopy = { ...this.tree };
    if (treeCopy.children?.length && childIndex !== undefined && childIndex >= 0) {
      treeCopy.children[childIndex] = event;
    }
    this.update.emit(treeCopy);
  }

  @HostListener('keydown.shift')
  shiftKeyDown() {
    this.shiftKeyIsDown = true;
  }

  @HostListener('keyup.shift')
  shiftKeyUp() {
    this.shiftKeyIsDown = false;
  }

}
