<div class="wrapper">
  <div class="header row gap">
    <h2 class="confirm-title cooper" *ngIf="data.title">{{data.title}}</h2>
  </div>
  <section>
    <div class="confirm-message" [innerHTML]="parseMessageToHtml(data.message)"></div>
    <div class="confirm-buttons">
      <app-button [outline]="!data.reverseColor" type="button" (click)="validate(true)"
                  title="Confirmer">{{data.confirmMessage ?? 'Confimer' }}
      </app-button>

      <app-button [outline]="!!data.reverseColor" type="button" (click)="validate(false)"
                  title="Annuler">{{data.cancelMessage ?? 'Annuler' }}
      </app-button>
    </div>
  </section>
</div>


