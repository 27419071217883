import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { sortTrains, Train } from "../../trains/models/train.entity";
import { CreateSherpa } from "../models/sherpa.entity";
import { Talker } from "../models/talker.entity";

@Injectable()
export class TalkerRepository extends AbstractRepository<Talker, CreateSherpa> {
  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              protected override readonly http: HttpClient) {
    super(http, Talker);
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return "talkers";
  }

  getTalkerTrains(talkerId: string): Observable<Train[]> {
    return this.http.get<Train[]>([ this.apiUrl, this.getEntityName(), talkerId, 'trains' ].join('/'))
      .pipe(map(trains => trains.map(train => plainToInstance(Train, train)).sort(sortTrains)));
  }

  modifyManagedOrganizations(talkerId: string, organizationIds: string[]): Observable<Talker> {
    return this.http.patch<Talker>([ this.apiUrl, this.getEntityName(), talkerId, 'organizations' ].join('/'), { organizationIds })
      .pipe(map(talker => this.instantiate(talker)));
  }
}
