import { Type } from "class-transformer";

import { dateDelta } from "../../shared/tool-functions/date-tools";
import { Dynamic } from "../../thematics/models/dynamic.model";
import { Sherpa } from "../../users/models/sherpa.entity";
import { Talker } from "../../users/models/talker.entity";

import { TrainMembership } from "./membership.entity";
import { Session } from "./session.entity";
import { TimeSlot } from "./time-slot.entity";


export class Train {

  id!: string;

  @Type(() => Session)
  sessions!: Session[];

  @Type(() => Dynamic)
  dynamic!: Dynamic;

  @Type(() => TrainMembership)
  memberShips!: TrainMembership[];

  @Type(() => Sherpa)
  sherpa?: Sherpa;

  sherpaId?: string;

  @Type(() => TimeSlot)
  timeSlot!: TimeSlot;

  @Type(() => Date)
  createdAt!: Date;

  getFirstSession(): Session {
    return this.sessions
      .sort((sessionA, sessionB) => sessionA.date.getTime() - sessionB.date.getTime())
      ?.[0];
  }

  getNextSession(): Session | undefined {
    return this.sessions
      .filter(session => session.date.getTime() > ((new Date()).getTime() - this.sessionDuration))
      .sort((sessionA, sessionB) => sessionA.date.getTime() - sessionB.date.getTime())
      ?.[0];
  }

  getNearestSession(): Session | undefined {
    return this.sessions
      .sort((sessionA, sessionB) => (sessionA.date.getTime() - (new Date()).getTime()) - (sessionB.date.getTime() - (new Date()).getTime()))
      .filter(session => dateDelta(session.date) <= 1)
      ?.[0];

  }

  getLastSession(): Session {
    return this.sessions
      .sort((sessionA, sessionB) => sessionA.date.getTime() - sessionB.date.getTime())
      ?.[this.sessions.length - 1];
  }

  get currentSessionIndex(): number {
    return this.sessions.filter(session => session.date.getTime() + this.sessionDuration < (new Date()).getTime()).length;
  }



  get isCompleted(): boolean {
    return this.sessions.every(session => session.date.getTime() + this.sessionDuration < (new Date()).getTime());
  }

  get members(): Talker[] {
    return this.memberShips?.map(memberShip => memberShip.user);
  }

  get timeDelta(): number | undefined {
    const nextSession = this.getNextSession();
    if (!nextSession) {
      return undefined;
    }
    return nextSession.date.getTime() - (new Date()).getTime();
  }

  /**
   * returns session duration in milliseconds
   */
  get sessionDuration(): number {
    return (this.memberShips?.length ? 10 + this.memberShips.length * 20 : 30) * 1E3 * 60;
  }

  get isStartingSoon(): boolean {
    return !!(this.timeDelta && this.timeDelta < (15 * 60 * 1E3) && this.timeDelta >= -this.sessionDuration);
  }

  get isPlaying(): boolean {
    return this.sessions.some(session => (session.date.getTime() + this.sessionDuration) > (new Date()).getTime() && session.date.getTime() < ((new Date()).getTime() + (15 * 60 * 1E3)));
  }
}

export interface CreateTrainPayload {
  dynamicId: string;
  timeSlot: TimeSlot;
  date: number;
  month: number;
  year: number;
}

export interface CreateOrJoinTrain extends CreateTrainPayload {
  id?: string;
}

export interface JoinTrainPayload {
  id: string;
}


export function sortTrains(trainA: Train, trainB: Train): number {
  if (trainA.timeDelta || trainB.timeDelta) {
    return (trainA.timeDelta ?? Number.MAX_SAFE_INTEGER) - (trainB.timeDelta ?? Number.MAX_SAFE_INTEGER);
  }
  return trainB.getLastSession().date.getTime() - trainA.getLastSession().date.getTime();
}
