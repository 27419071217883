import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject, filter, Subject, takeUntil } from "rxjs";

import { AuthenticationService } from "../../authentication/services/authentication.service";
import { ConfirmComponent } from "../../shared/components/confirm/confirm.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { TableConfig } from "../../shared/components/table/table.component";
import { IPaginationComponent } from "../../shared/repositories/pagination.interface";
import { CsvService } from "../../shared/services/csv.service";
import { ProfileStore } from '../../shared/services/profile.storage.service';
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Talker } from "../../users/models/talker.entity";
import { User } from "../../users/models/users.entity";
import { ProfileService } from '../../users/services/profile.service';
import { Organization } from "../models/organizations.entity";
import {
  OrganizationMembersPropertiesFilter,
  OrganizationMembersRepository
} from "../repositories/organization.members.repository";
import { OrganizationStoreService } from "../services/organization.store.service";

type TalkerFilterSelection = 'all' | 'created' | 'activated';

@Component({
  selector: 'app-organization-talkers',
  templateUrl: './organization-talkers.component.html',
  styleUrls: [ './organization-talkers.component.scss' ]
})
export class OrganizationTalkersComponent implements IPaginationComponent<User, OrganizationMembersPropertiesFilter>, OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  searchForm = new FormControl<string>('');

  selectedTalkerIds: string[] = [];

  filterSelected$: BehaviorSubject<TalkerFilterSelection> = new BehaviorSubject<TalkerFilterSelection>('all');

  isActivatedFilter$: BehaviorSubject<{ isActivated?: boolean }> = new BehaviorSubject<{ isActivated?: boolean }>({});

  totalCount?: number;

  organization!: Organization;

  headerColumns: TableConfig<Talker> = {
    lastName: {
      displayName: 'Nom',
      transformFunction: (lastName: string) => capitalize(lastName),
      style: 'font-weight: 500',
      sortable: true
    },
    firstName: {
      displayName: 'Prénom',
      transformFunction: (firstName: string) => capitalize(firstName),
      style: 'font-weight: 500',
      sortable: true
    },
    email: {
      displayName: 'Email',
    },
    role: {
      displayName: 'Rôle',
      transformFunction: (role: string, item?: any) => {

        const isPilotOnly = item.organizationAssociations.length &&
        item.organizationId !== this.organization.id &&
        item.organizationAssociations.some((oA: any) => oA.pilotId === item.id);

      if (isPilotOnly) {
        return "Pilote";
      }
        
        if (item.organizationAssociations.length && item.organizationAssociations.map((oA: any) => oA.organizationId).includes(item.organizationId)) {
          return "Pilote et Talker";
        }

        return capitalize(role);
      }
    },
    createdAt: {
      displayName: 'Invité.e le',
      transformFunction: (date: Date) => (date ? date.toLocaleDateString() : '-'),
      sortable: true
    },
    activatedAt: {
      displayName: 'Inscrit.e le',
      transformFunction: (date: Date) => (date ? date.toLocaleDateString() : '-'),
      sortable: true
    }
  };

  constructor(private readonly organizationStoreService: OrganizationStoreService,
              public readonly mainRepository: OrganizationMembersRepository,
              private readonly router: Router,
              private readonly authenticationService: AuthenticationService,
              private readonly dialogService: DialogService,
              private readonly snackBarService: SnackbarService,
              private readonly csvService: CsvService,
              private profileStore: ProfileStore,
              private readonly profileService: ProfileService
  ) {
  
  }

  ngOnInit() {
    this.organizationStoreService.getObservable()
      .pipe(
        filter((organization): organization is Organization => !!organization),
        takeUntil(this.destroy$))
      .subscribe(organization => {
        this.mainRepository.setOrganizationId(organization.id);
        this.organization = organization;
      });

    this.filterSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe(selection => {
        switch (selection) {
          case "activated":
            this.isActivatedFilter$.next({ isActivated: true });
            break;
          case "created":
            this.isActivatedFilter$.next({ isActivated: false });
            break;
          default:
            this.isActivatedFilter$.next({});
            break;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  selectTalkers(talkerIds: string[]) {
    this.selectedTalkerIds = talkerIds;

  }

  goToTalker(talkerId: string) {
    this.router.navigate([ 'organizations', this.organization.id, 'talkers', talkerId ]);
  }

  selectFilter(selection: 'all' | 'created' | 'activated') {
    this.filterSelected$.next(selection);
  }

  addTalker() {
    this.router.navigate([ 'organizations', this.organization.id, 'add', 'talker' ]);
  }

  addTalkers() {
    this.router.navigate([ 'organizations', this.organization.id, 'add', 'talkers' ]);
  }

  // TODO: Ajouter la sélection de tout + le récap du nombre
  downloadCsv() {
    this.mainRepository.getAll()
      .subscribe(users => {
        this.csvService.downloadUsersCsv(users);

      });
  }

  getTotalCount(count: number) {
    this.totalCount = count;
  }

  changeOrganization() {
    // this.dialogService.open(ConfirmComponent, {
    //   title: 'Voulez '
    // })
  }

  sendOnboarding() {
    this.dialogService.open(ConfirmComponent, {
      title: `Voulez vraiment renvoyez un mail d'inscription à ces ${ this.selectedTalkerIds.length } collaborateurs ?`
    }).pipe(takeUntil(this.destroy$))
      .subscribe((isValidated) => {
        if (isValidated) {
          this.authenticationService.sendNewReminders(this.selectedTalkerIds)
            .subscribe(() => {
              this.snackBarService.pushMessage(`${this.selectedTalkerIds.length} emails envoyés`, 'success');
            });
        }
      });
  }

  removeTalkers() {
    this.dialogService.open(ConfirmComponent, {
      title: `Voulez vraiment supprimer ces ${ this.selectedTalkerIds.length } collaborateurs ?`
    }).pipe(takeUntil(this.destroy$))
      .subscribe((isValidated) => {
        if (isValidated) {
          this.mainRepository.deleteByIds(this.selectedTalkerIds)
            .subscribe((result) => {
              this.snackBarService.pushMessage(`${ result } collaborateurs supprimés`, 'success');
              this.isActivatedFilter$.next(this.isActivatedFilter$.value);
            });
        }
      });
  }


  protected readonly capitalize = capitalize;
}
