import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Admin } from "../../../../users/models/admin.entity";
import { Sherpa } from "../../../../users/models/sherpa.entity";
import { Talker } from "../../../../users/models/talker.entity";
import { Gender, Role, RoleDescription, User } from "../../../../users/models/users.entity";
import { capitalize } from "../../../tool-functions/capitalize";

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
})
export class UserCardComponent {

  @Input() user!: Talker | Sherpa | Admin;

  @Input() showOrganization = true;

  @Output() clickOnDetails = new EventEmitter<User>();

  get activationDateMessage(): string {
    return this.user.activatedAt ? ` | inscrit${ this.user.gender === Gender.WOMAN ? 'e' : '' } le ${ this.user.activatedAt.toLocaleDateString() }` : '';
  }

  get createdDateMessage(): string {
    return this.user.createdAt ? ` | invité${ this.user.gender === Gender.WOMAN ? 'e' : '' } le ${ this.user.createdAt.toLocaleDateString() }` : '';
  }

  get organizationAndRole(): string {
    return `${ this.user.role === Role.Talker && this.showOrganization ? `${ this.user.organization?.name?.toUpperCase() } - ` : '' }${ capitalize(RoleDescription[this.user.role ===Role.Talker && this.user.organizationAssociations?.length ? 'pilot' : this.user.role]) }` ;
  }


  get infos(): string {
    return `${ this.user.email }${ this.createdDateMessage }${ this.activationDateMessage }`;
  }

  goToDetails(): void {
    this.clickOnDetails.emit(this.user);
  }

}
