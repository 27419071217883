import { Component, Input } from "@angular/core";

import { TypeformScoreBarChartData } from "../typeform-score-bar-chart/typeform-score-bar-chart.component";

@Component({
    selector: 'app-typeform-score-distribution',
    templateUrl: './typeform-score-distribution.component.html',
    styleUrl: './typeform-score-bar-chart.component.scss'
})

export class TypeformScoreDistributionComponent {
    @Input() result!: TypeformScoreBarChartData;
}