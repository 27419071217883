export class Feedback {
    id!: string;

    rating!: number;

    comment?: string;

    createdAt!: Date;

    user: any;
}

export interface CreateFeedbackPayload {
    rating: number;
    comment?: string;
}

export interface FeedbackStats {
    latestRatings: {
        rating: number;
        uniqueUserCount: string;
    }[];
    averageRating: {
        averageRating: string;
    };
    feedbacks: Feedback []
}
