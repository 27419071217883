<app-table
  [items]="items$.getValue()"
  (sort)="sortData($event)"
  [tableConfig]="tableConfig"
  (select)="selectRows($event)"
  (goTo)="goToRow($event)"
  [showCheckbox]="showCheckbox"
/>

<app-pagination-footer
  [currentPage]="currentPage"
  [totalPages]="totalPages"
  (pageChange)="changePage($event)"
  (rowsPerPageChange)="changeRowsPerPage($event)"
></app-pagination-footer>

<div class="spinner">
  <app-spinner *ngIf="loading$ | async"></app-spinner>
</div>
