import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgxPermissionsGuard, NgxPermissionsModule } from "ngx-permissions";

import { DashboardComponent } from "../dashboard/dashboard.component";
import { DashboardModule } from "../dashboard/dashboard.module";
import { FunnelComponent } from "../shared/components/funnel/funnel.component";
import { SharedModule } from "../shared/shared.module";
import { TalkerDetailsComponent } from "../users/talker-details/talker-details.component";

import { AddTalkerComponent } from "./add-talker/add-talker.component";
import { AddUnitsComponent } from "./add-units/add-units.component";
import { EditOrganizationNameComponent } from "./edit-organization-name/edit-organization-name.component";
import { MyOrganizationHomeComponent } from "./my-organization-home/my-organization-home.component";
import { MyOrganizationComponent } from "./my-organization.component";
import { NewOrganizationComponent } from "./new-organization/new-organization.component";
import { OrganizationEditComponent } from "./organization-edit/organization-edit.component";
import { OrganizationEditThematicsComponent } from "./organization-edit-thematics/organization-edit-thematics.component";
import { OrganizationEditionFormComponent } from "./organization-edition-form/organization-edition-form.component";
import { OrganizationTalkersComponent } from "./organization-talkers/organization-talkers.component";
import { OrganizationUnitsHistoryComponent } from "./organization-units-history/organization-units-history.component";
import { OrganizationsListComponent } from "./organizations-list/organizations-list.component";
import { OrganizationMembersRepository } from "./repositories/organization.members.repository";
import { OrganizationRepository } from "./repositories/organization.repository";
import { TransactionRepository } from "./repositories/organization.transaction.repository";
import { OrganizationEditMtmResolver } from "./resolvers/organization-edit-mtm-resolver";
import { OrganizationHomeMtmResolver } from "./resolvers/organization-home-mtm.resolver";
import { OrganizationMtmResolver } from "./resolvers/organization-mtm.resolver";
import { OrganizationStatisticsMtmResolver } from "./resolvers/organization-statistics-mtm.resolver";
import { OrganizationUsersMtmResolver } from "./resolvers/organization-users-mtm.resolver";
import { OrganizationMemberResolver } from "./resolvers/organization.member.resolver";
import { OrganizationResolver } from "./resolvers/organization.resolver";
import { UnitsMtmResolver } from "./services/units-mtm.resolver";
import { SwitchProfileComponent } from "./switch-profile/switch-profile.component";
import { UploadTalkersComponent } from "./upload-talkers/upload-talkers.component";

const organizationEditionRoutes: Routes = [
  {
    path: "thematics",
    pathMatch: "full",
    component: OrganizationEditThematicsComponent,
  },
  {
    path: "add-pilot",
    pathMatch: "full",
    component: AddTalkerComponent,
    data: {
      role: "pilot",
    },
  },
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DashboardModule,
    NgxPermissionsModule.forChild(),
    RouterModule.forChild([
      {
        path: "",
        pathMatch: "full",
        canActivate: [NgxPermissionsGuard],
        component: OrganizationsListComponent,
        resolve: {
          mtm: OrganizationMtmResolver
        },
        data: {
          permissions: {
            only: "admin",
            redirectTo: "home",
          },
        },
      },
      {
        path: "create",
        pathMatch: "full",
        canActivate: [NgxPermissionsGuard],
        component: OrganizationEditComponent,
        data: {
          isCreation: true,
          permissions: {
            only: "admin",
            redirectTo: "home",
          },
        },
      },
      {
        path: "create",
        pathMatch: "prefix",
        children: organizationEditionRoutes,
      },

      {
        path: ":id",
        pathMatch: "prefix",
        canActivate: [NgxPermissionsGuard],
        runGuardsAndResolvers: "always",
        resolve: {
          organization: OrganizationResolver,
          mtm: OrganizationHomeMtmResolver
        },
        component: MyOrganizationComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            component: MyOrganizationHomeComponent,
          },
          {
            path: "edit",
            pathMatch: "full",
            resolve: {
              mtm: OrganizationEditMtmResolver
            },
            component: OrganizationEditComponent,
          },

          {
            path: "edit",
            pathMatch: "prefix",
            component: FunnelComponent,
            data: {
              isFullPage: true,
            },
            children: organizationEditionRoutes,
          },
          {
            path: "talkers",
            resolve: {
              mtm: OrganizationUsersMtmResolver
            },
            pathMatch: "full",
            component: OrganizationTalkersComponent,
          },
          {
            path: "statistics",
            pathMatch: "full",
            canActivate: [NgxPermissionsGuard],
            runGuardsAndResolvers: "always",
            resolve: {
              // organization: OrganizationResolver,
              mtm: OrganizationStatisticsMtmResolver
            },
            component: DashboardComponent,
            data: {
              permissions: {
                only: [ 'admin', 'sherpa' ],
                redirectTo: 'home'
              }
            }
          },
          {
            path: "add",
            pathMatch: "prefix",
            component: FunnelComponent,
            data: {
              isFullPage: true,
            },
            children: [
              {
                path: "talker",
                pathMatch: "full",
                component: AddTalkerComponent,
                data: {
                  role: "talker",
                },
              },
              {
                path: "talkers",
                pathMatch: "full",
                component: UploadTalkersComponent,
              },
            ],
          },
          {
            path: "talkers/:id",
            pathMatch: "prefix",
            component: FunnelComponent,
            runGuardsAndResolvers: "always",
            resolve: {
              user: OrganizationMemberResolver,
            },
            data: {
              isFullPage: false,
            },
            children: [
              {
                path: "",
                pathMatch: "full",
                component: TalkerDetailsComponent,
              },
            ],
          },
          {
            path: "units",
            pathMatch: "full",
            resolve: {
              mtm: UnitsMtmResolver
            },
            component: OrganizationUnitsHistoryComponent,
          },
        ],
      },
    ]),
    CdkTableModule,
  ],
  declarations: [
    MyOrganizationComponent,
    SwitchProfileComponent,
    OrganizationTalkersComponent,
    OrganizationUnitsHistoryComponent,
    MyOrganizationHomeComponent,
    OrganizationEditComponent,
    OrganizationEditThematicsComponent,
    AddTalkerComponent,
    UploadTalkersComponent,
    OrganizationEditionFormComponent,
    NewOrganizationComponent,
    EditOrganizationNameComponent,
    AddUnitsComponent,
    OrganizationsListComponent,
  ],
  providers: [
    OrganizationRepository,
    OrganizationMembersRepository,
    OrganizationResolver,
    OrganizationMemberResolver,
    TransactionRepository,
    OrganizationMtmResolver,
    OrganizationUsersMtmResolver,
    UnitsMtmResolver,
    OrganizationEditMtmResolver,
    OrganizationStatisticsMtmResolver,
    OrganizationHomeMtmResolver
  ],
})
export class OrganizationsModule {}
