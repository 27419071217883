<p class="margin-top text-black">Bilan réalisé le {{emotionalResponse?.createdAt | date:'dd/MM/yyyy'}}</p>
<p class="text-black">Les scores obtenus sont purement indicatifs et ne constituent en aucun cas un diagnostic médical. Nous vous
    encourageons à vous rapprocher d'un professionnel de santé dès lors que vous en ressentez le besoin.</p>

    <div class="margin-top">
        <div class="tile row gap" *ngIf="emotionalResponse.lifeSatisfaction >= 0">
          <div class="result center-children column">
            <div class="row gap center-children-vertically">
                <app-icon color="#8C5FB0" type="smile" />
                <p class="text-bold">Satisfaction de vie</p>
            </div>
            <div class="margin-top text-center row">
              <div class="text-large cooper" [style.color]="getColorByScore(emotionalResponse.lifeSatisfaction)">{{round(emotionalResponse.lifeSatisfaction)}}</div>
              <div class="text-large cooper">/{{flourishingResult.maximum}}</div>
            </div>
          </div>
          <div class="column gap center-children-horizontally">
            <h3 class="cooper">{{ getLifeSatisfactionRange(round(emotionalResponse.lifeSatisfaction)).title }}</h3>
            <div [innerHTML]="getLifeSatisfactionRange(round(emotionalResponse.lifeSatisfaction)).description"></div>         
          </div>
        </div>

    <div class="margin-top" *ngIf="emotionalResponse.affectAndRelation >= 0">
        <div class="tile row gap">
            <div class="result center-children column">
                <div class="row gap center-children-vertically">
                    <app-icon color="#8C5FB0" type="heart" />
                    <p class="text-bold">Affect et relations</p>
                </div>
                <div class="margin-top text-center row">
                    <div class="text-large cooper" [style.color]="getColorByScore(emotionalResponse.affectAndRelation)">{{round(emotionalResponse.affectAndRelation)}}</div>
                    <div class="text-large cooper">/{{omsMoodResult.maximum}}</div>
                </div>
            </div>
            <div class="column gap center-children-horizontally">
                <h3 class="cooper">{{ getAffectRange(round(emotionalResponse.affectAndRelation)).title }}</h3>
                <div [innerHTML]="getAffectRange(round(emotionalResponse.affectAndRelation)).description"></div>
            </div>
        </div>
    </div>

    <div class="margin-top" *ngIf="emotionalResponse.workSatisfaction >= 0">
        <div class="tile row gap">
          <div class="result center-children column">
            <div class="row gap center-children-vertically">
                <app-icon color="#8C5FB0" type="suit-case" />
                <p class="text-bold">Vie professionnelle</p>
            </div>
            <div class="margin-top text-center row">
              <div class="text-large cooper" [style.color]="getColorByScore(emotionalResponse.workSatisfaction)">{{round(emotionalResponse.workSatisfaction)}}</div>
              <div class="text-large cooper">/{{workSatisfactionResult.maximum}}</div>
            </div>
          </div>
          <div class="column gap center-children-horizontally">
            <h3 class="cooper">{{ getWorkSatisfactionRange(round(emotionalResponse.workSatisfaction)).title }}</h3>
            <div [innerHTML]="getWorkSatisfactionRange(round(emotionalResponse.workSatisfaction)).description"></div>
          </div>
        </div>
    </div>
 </div>