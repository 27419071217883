import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';

import { Talker } from '../../../users/models/talker.entity';
import { Gender } from '../../../users/models/users.entity';
import { DEFAULT_FONT } from '../../utils/utils';
import { AbstractChartComponent } from '../abstract.chart.component';


@Component({
    selector: 'app-satisfaction-distribution',
    templateUrl: './satisfaction-distribution.component.html'
})

export class SatisfactionDistributionComponent extends AbstractChartComponent<'doughnut'> implements AfterViewInit, OnChanges {
    @Input() talkers!: Talker[];

    @Input() labels: string[] = [];

    @Input() data: number[] = [];

    @Input() backgroundColors!: string[];

    @Input() percentages: any = {};

    type: "doughnut" = 'doughnut';

    chartOptions = {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              font: {
                family: DEFAULT_FONT
              },
            },
            position: 'right' as const
          },
          tooltip: {
            callbacks: {
                label: (tooltipItem: any) => `${tooltipItem.label}: ${this.percentages[tooltipItem.label]}%`
            }
        }
        }
    };

    ngAfterViewInit(): void {
        this.chartData.labels = this.labels;
        this.chart?.update();
    }

    ngOnChanges() {
        super.onChanges();
    }

    updateData() {
        this.chartData.datasets = [
          {
            data: this.data,
            backgroundColor: this.backgroundColors,
          }
    
        ];
    }    
}