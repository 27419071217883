
  <div class="wrapper">

    <div class="header">
      <div class="center-children">
        <app-profile-icon [big]="true" [profile]="talker"></app-profile-icon>
      </div>
      <div class="user-description">
        <div class="row gap center-children-vertically">
          <h2>{{talker.fullName}}          </h2>
          <app-icon *ngxPermissionsOnly="'admin'" button type="user-profile-right" title="Prendre la place"
                    (click)="impersonateUser()"></app-icon>
        </div>
        <p>Email : {{talker.email}}</p>
        <p>Genre : {{gender}}</p>
        <p>Numéro : {{talker.phoneNumber ?? 'Pas de numéro renseigné'}}</p>
        <a (click)="goToOrganisation()" *ngIf="talker.organization">Organisation
          : {{talker.organization.name.toUpperCase()}}</a>
        <div class="row gap center-children-vertically">
          <p>Rôle : {{role}}</p>
          <app-icon *ngxPermissionsOnly="'admin'" button type="edit" title="Modifier"
                    (click)="modifyManagedOrganizations()"></app-icon>
        </div>

        <p>Invité{{ talker.gender === Gender.WOMAN ? 'e' : '' }} le : {{talker.createdAt.toLocaleDateString()}}</p>
        <div>
          <p *ngIf="talker.activatedAt">Inscrit{{ talker.gender === Gender.WOMAN ? 'e' : '' }} le : {{talker.activatedAt.toLocaleDateString()}}</p>
          <p *ngIf="!talker.activatedAt" class="margin-bottom">Pas encore inscrit{{ talker.gender === Gender.WOMAN ? 'e' : '' }}</p>
        </div>

      </div>
    </div>
    <div *ngxPermissionsOnly="['admin']">
      <div class="center-children">
        <div *ngIf="!loading" class="trains">
          <h3 *ngIf="userTrains$.getValue().length">Ses parcours</h3>
          <app-train-card (goToDetails)="goToTrain($event)" *ngFor="let train of userTrains$.getValue()"
                          [train]="train"></app-train-card>
          <p *ngIf="!userTrains$.getValue().length && talker.activatedAt">Aucun parcours en cours</p>
        </div>
        <div *ngIf="loading" class="center-children">
          <app-spinner></app-spinner>
        </div>
      </div>
    </div>

    <div *ngxPermissionsOnly="['admin', 'talker']" class="center-children column">
      <app-button class="margin-bottom" *ngIf="!talker.activatedAt" [outline]="true" (click)="sendActivationMail()"
                  title="Renvoyer un email d'inscription">Renvoyer un email d'inscription
      </app-button>

      <a *ngIf="canDeleteUser" (click)="deleteUser()">Retirer cet
        utilisateur
      </a>
    </div>
  </div>
