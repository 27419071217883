import { HttpClient } from "@angular/common/http";
import { ClassConstructor } from "class-transformer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ProfileService } from "../../users/services/profile.service";
import { Lang } from "../types/lang";

import { AbstractRepository } from "./abstract.repository";

export type CreateI18n<Type> = {
  entity: Type,
  lang: Lang
}

export abstract class I18nAbstractRepository<Entity, CreateEntity> extends AbstractRepository<Entity, CreateI18n<CreateEntity>> {

  userLang!: Lang;

  protected constructor(
    protected readonly httpClient: HttpClient,
    protected readonly createClassConstructor: ClassConstructor<Entity>,
    protected readonly profileService: ProfileService) {
    super(httpClient, createClassConstructor);
    this.profileService
      .getObservable()
      .subscribe(state => {
        this.userLang = state.lang;
      });
  }

  getAll(): Observable<Entity[]> {
    return this.http.get<Entity[]>([ this.apiUrl, this.getEntityName() ].join('/')).pipe(map(result => result.map(item => this.instantiate(item))));
  }

  addLangToInput(input: CreateEntity): CreateI18n<CreateEntity> {
    return {
      entity: input,
      lang: this.userLang
    };
  }

}
