import { PhoneNumberUtil } from "google-libphonenumber";

export type PhoneCode = {
  region: string;
  code: number;
}
const phoneNumberUtil = PhoneNumberUtil.getInstance();

/**
 * Return a set of country codes
 */
export function getPhoneCodes(): PhoneCode[] {
  return phoneNumberUtil.getSupportedRegions()
    .map(region => ({
        region,
        code: phoneNumberUtil.getCountryCodeForRegion(region)
      }))
    .sort((codeA, codeB) => codeA.code - codeB.code);
}
