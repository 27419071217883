import { ChangeDetectorRef, Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, merge, Subject, switchMap, takeUntil, tap } from "rxjs";

import { Role } from "../../../users/models/users.entity";
import { allAdditionnalThematics, allMainThematics, contentDurations, contentFormats, pillars } from "../../models/contents.entity";
import { ContentsRepository } from "../../repositories/contents.repository";
import { TabStoreService } from "../../services/content-tab.store";

@Component({
    selector: 'app-contents-card-pagination',
    templateUrl: './contents-card-pagination.component.html',
    styleUrls: ['./contents-card-pagination.component.scss']
})

export class ContentsCardPaginationComponent <
  Entity extends { id: string }
> implements OnInit, OnDestroy {
    
    scroll$ = new Subject<void>();

    pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(5);

    page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

    items$ = new BehaviorSubject<Entity[]>([]);

    newItems$ = new Subject<Entity[]>();

    loading$ = new Subject<void>();

    protected destroy$ = new Subject<void>();

    @ContentChild("card", { static: false }) cardTemplateRef!: TemplateRef<any>;

    @Input("unique-key") uniqueKey: keyof Entity = "id";

    nextPageLoading = false;

    totalCount: number = 0;

    loading: boolean = false;

    private _apiLoading = new BehaviorSubject<boolean>(false);
  
    apiLoading$ = this._apiLoading.asObservable();

    selectedTab: number = 2;

    objectives: string[] = ['inspire', 'prepare'];

    contentsForm!: FormGroup;
    
    searchForm: FormControl<string | null> = new FormControl<string>("");

    isRestricted!: boolean;

    canSeePage = true;

    userProfile: any;

    isDropdownVisible = false;

    contentsFilterForm!: FormGroup;

    page = 1;

    limit = 5;

    private currentItemsLength = 0;

    initialLoad = true;

    constructor(private router: Router, private route: ActivatedRoute, public readonly contentsRepository: ContentsRepository, private fb: FormBuilder, private cdr: ChangeDetectorRef, private tabStoreService: TabStoreService) { }
    
    fetchTabContent(tabIndex: number) {
        this.selectedTab = tabIndex;
        this.totalCount = 0;
        this.page = 1;

        this.tabStoreService.setSelectedTab(tabIndex);

        if(tabIndex === 1){
          this.objectives = ['inspire'];
        }
  
        if(tabIndex === 2){
          this.objectives = ['act'];
        }

        if(tabIndex === 3){
          this.objectives = ['transform', 'prepare'];
        }

        const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
        const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
        const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
        const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
        this._apiLoading.next(true);
        this.contentsRepository.talkerPaginate({ page: this.page, pageSize: this.limit, search: this.searchForm.value?.trim(), properties: { objectives : this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe({
          next: (contents: any) => {
              this.items$.next(contents.items);
              this.currentItemsLength = contents.items.length;
              this.isRestricted = contents.isRestricted;
              this.totalCount = contents.totalCount;
              this.nextPageLoading = false;
              this.loading = false;
              this._apiLoading.next(false);
          },
          error: () => {
          }
        });
      }

    selectTab(tabIndex: number) {
        this.loading = true;
        this.loading$.next();
        this.totalCount = 0;
        this.page = 1;
        this.selectedTab = tabIndex;
        this.tabStoreService.setSelectedTab(tabIndex);
        
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { tab: tabIndex },
            queryParamsHandling: 'merge'
        });

        if(tabIndex === 1){
            this.objectives = ['inspire'];
        }

        if(tabIndex === 2){
            this.objectives = ['act'];
        }

        if(tabIndex === 3){
            this.objectives = ['transform', 'prepare'];
        }

        const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
        const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
        const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
        const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
        this._apiLoading.next(true);
        this.contentsRepository.talkerPaginate({ page: this.page, pageSize: this.limit, search: this.searchForm.value?.trim(), properties: { objectives : this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe({
          next: (contents: any) => {
            this.page$.next(1);
            this.items$.next(contents.items);
            this.currentItemsLength = contents.items.length;
            this.isRestricted = contents.isRestricted;
            this.totalCount = contents.totalCount;
            this.nextPageLoading = false;
            this.loading = false;
            this._apiLoading.next(false);
          },
          error: () => {
            this.loading = false;
          }
        });
    }

    ngOnInit(): void {
      this.initializeForms();
        this.route.data
        .pipe(
            map(data => data.profile),
            takeUntil(this.destroy$)

        ).subscribe((profile: any) => {
          this.userProfile = profile;
          this.canSeePage = profile.role === Role.Sherpa ? true : !!profile?.organization?.subscriptionPlan;
          this.route?.queryParams?.subscribe(params => {
              const tab = +params.tab || this.tabStoreService.getSelectedTab() || 2; 
              this.fetchTabContent(tab);
          });

          this.subscribeToChanges();
      });
    }

    private initializeForms() {
      this.contentsForm = this.fb.group({
        allMainThematics: this.fb.array(this.createItems(allMainThematics)),
        allAdditionnalThematics: this.fb.array(this.createItems(allAdditionnalThematics)),
        formats: this.fb.array(this.createItems(contentFormats)),
        durations: this.fb.array(this.createItems(contentDurations)),
        pillars: this.fb.array(this.createItems(pillars)),
      });
          
      this.contentsFilterForm = this.fb.group({
        durationMinimum: new FormControl('', { nonNullable: true }),
        durationMaximum: new FormControl('', { nonNullable: true }),
      });
    }
    
    ngOnDestroy() {
        this.destroy$.next();
    }
    
    onScrollEvent() {
        this.scroll$.next();
    }
    
    public loadNextPage(): void {
      if (!this.nextPageLoading && this.currentItemsLength < this.totalCount) {
        if(this.page$.getValue() === 1 && this.currentItemsLength === 0) {
          return;
        }
        this.nextPageLoading = true; 
        this.page$.next(this.page$.getValue() + 1);
      }
    }

    private subscribeToChanges() {
      merge(
        this.page$.pipe(
          distinctUntilChanged()
        ),
        this.contentsForm.valueChanges.pipe(
          tap(() => {
            if (this.initialLoad) return;
            this.items$.next([]);
            this.page$.next(1);
            this.currentItemsLength = 0;
          }),
          debounceTime(300),
          distinctUntilChanged()
        ),
        this.contentsFilterForm.valueChanges.pipe(
          tap(() => {
            if (this.initialLoad) return;
            this.items$.next([]);
            this.page$.next(1);
            this.currentItemsLength = 0;
          }),
          debounceTime(300),
          distinctUntilChanged()
        )      
      ).pipe(
        tap(() => {
          if (this.initialLoad) {
            this.initialLoad = false;
            return;
          }
          this.loading$.next();
        }),
        debounceTime(300),
        switchMap(() => {
          const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
          const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
          const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
          const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
          this._apiLoading.next(true);
          return this.contentsRepository.talkerPaginate({ page: this.page$.getValue(), pageSize: this.limit, properties: { objectives: this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } });
        }),
        takeUntil(this.destroy$)
      ).subscribe({
        next: (contents: any) => {
          if (this.page$.getValue() === 1 || !this.items$.getValue()?.length) {
            this.items$.next(contents.items);
            this.currentItemsLength = contents.items.length;
          } else {
            this.newItems$.next(contents.items);
            this.currentItemsLength += contents.items.length;
          }
          this.isRestricted = contents.isRestricted;
          this.totalCount = contents.totalCount;
          this.nextPageLoading = false;
          this.loading = false;
          this._apiLoading.next(false);
        },
        error: () => {
          this.loading = false;
          this.nextPageLoading = false;
        }
      });
    }
    
    toggleSelection(item: any): void {
        const pillar: any = this.pillars.controls.find((control: any) => control.value.key === item.key);
        if (pillar) {
          pillar.patchValue({ value: !pillar.value.value });
        } 
    }
    
    createItems(thematicsPart: any[]): FormGroup[] {
        return thematicsPart.map(item => this.fb.group({
        name: [item.label],
        value: [item.selected],
        key: [item.key],
        icon: [item.icon]
        }));
    }
    
    openMoreThematic(event: Event){
        event.preventDefault();
        this.isDropdownVisible = !this.isDropdownVisible;
    }
    
    handleEnterPressed(value: string) {
        (window._mtm || []).push({ 'event' : 'recherche-ressource', 'motcle' : `${value.trim()}` });
        const themes = [...this.contentsForm.value.allMainThematics, ...this.contentsForm.value.allAdditionnalThematics].filter((theme: any) => theme.value).map((theme: any) => theme.key);
        const formatsFiltered = this.contentsForm.value.formats.filter((format: any) => format.value).map((format: any) => format.key);
        const durationsFiltered = this.contentsForm.value.durations.filter((duration: any) => duration.value).map((duration: any) => duration.key);
        const pillarsFiltered = this.contentsForm.value.pillars.filter((pillar: any) => pillar.value).map((pillar: any) => pillar.key);
        this._apiLoading.next(true);
        this.contentsRepository.talkerPaginate({ page: this.page, pageSize: this.limit, search: value.trim(),properties: { objectives: this.objectives, pillars: pillarsFiltered, durations: durationsFiltered, formats: formatsFiltered, themes } }).subscribe({
          next: (contents: any) => {
            this.items$.next(contents.items);
            this.currentItemsLength = contents.items.length;
            this.isRestricted = contents.isRestricted;
            this.totalCount = contents.totalCount;
            this.nextPageLoading = false;
            this.loading = false;
            this._apiLoading.next(false);
          },
          error: () => {
          }
        });
    }

    onCurrentItemsLengthChange(length: number) {
      this.currentItemsLength = length;
    }
    
    get pillars() {
        return this.contentsForm.get('pillars') as FormArray;
    }


    get formats() {
        return this.contentsForm.get('formats') as FormArray;
    }

    get durations() {
        return this.contentsForm.get('durations') as FormArray;
    }

    get thematics(){
        return this.contentsForm.get('allMainThematics') as FormArray;
    }

    get additionnalThematics(){
        return this.contentsForm.get('allAdditionnalThematics') as FormArray;
    }
}