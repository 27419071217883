import { HttpClient } from "@angular/common/http";
import { ClassConstructor, plainToInstance } from "class-transformer";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

export abstract class AbstractRepository<Entity, UpsertEntity = Entity> {
  protected apiUrl!: string;

  public reload$ = new Subject<void>();

  protected constructor(
    protected readonly http: HttpClient,
    protected readonly classConstructor: ClassConstructor<Entity>
  ) {
  }

  protected abstract getEntityName(): string;

  protected instantiate(dto: any): Entity {
    return plainToInstance(this.classConstructor, dto);
  }

  deleteById(id: string): Observable<void> {
    return this.http.delete<void>([ this.apiUrl, this.getEntityName(), id ].join('/'));
  }

  findById(id: string): Observable<Entity> {
    return this.http.get<Entity>([ this.apiUrl, this.getEntityName(), id ].join('/')).pipe(map(result => this.instantiate(result)));
  }

  create(data: UpsertEntity): Observable<Entity> {
    return this.http.post<Entity>([ this.apiUrl, this.getEntityName() ].join('/'), data).pipe(map(result => this.instantiate(result)));
  }

  patch(id: string, data: Partial<UpsertEntity>): Observable<Entity> {
    return this.http.patch<Entity>([ this.apiUrl, this.getEntityName(), id ].join('/'), data).pipe(map(result => this.instantiate(result)));
  }
}
