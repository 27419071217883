import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { catchError, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { StorageService } from "../../shared/storage/storage.service";
import { ProfileService } from "../../users/services/profile.service";

@Injectable()
export class AuthenticationGuardService  {
  constructor(private readonly storageService: StorageService,
              private readonly router: Router,
              private readonly profileService: ProfileService) {
  }

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree |  Observable<boolean | UrlTree> {
    if (this.storageService.getAccessToken() === '') {
      this.storageService.setReturnUrl(state.url);
      return this.router.createUrlTree([ 'auth', 'login' ], {
        queryParams: { returnUrl: state.url }
      });
    }

    return this.profileService.getMyProfile()
      .pipe(
        catchError(() => of(null)),
        map(() => {
        if (this.storageService.getAccessToken() === '') {
          return this.router.createUrlTree([ 'auth', 'login' ], {
            queryParams: { returnUrl: state.url }
          });
        }
        return true;
      }));
  }
}
