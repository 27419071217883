import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventPayload, EventType } from "@azure/msal-browser";
// import { MatomoTracker } from 'ngx-matomo-client';
import { filter, Subject, switchMap, takeUntil, tap } from "rxjs";
import { map } from "rxjs/operators";

import { SignInMethod, StorageService } from "../../shared/storage/storage.service";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { UserCredentials } from "../authentication.type";
import { AuthenticationService } from '../services/authentication.service';
import { GoogleService } from "../services/google.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ]
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  formError = false;

  loginForm: EntityFormGroup<UserCredentials & { rememberMe: boolean }>;

  errorMessage: string = '';

  returnUrl!: string;

  loading = false;

  apiCalling = false;

  private destroy$ = new Subject<void>();

  @ViewChild('google') googleContainer!: ElementRef<HTMLElement>;

  // private readonly tracker = inject(MatomoTracker);

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly titleService: Title,
    private readonly googleService: GoogleService,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly storageService: StorageService
  ) {
    this.titleService.setTitle('Connection');
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', { nonNullable: true, validators: Validators.required }),
      password: new FormControl('', { nonNullable: true, validators: Validators.required }),
      rememberMe: new FormControl(false, { nonNullable: true })
    });
  }

  ngOnInit() {
    const { queryParams } = this.route.snapshot;
    this.storageService.setMode('session');
    this.returnUrl = queryParams?.returnUrl ?? this.storageService.getReturnUrl() ?? '/';

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((message: EventMessage) => message.eventType === EventType.LOGIN_SUCCESS || message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        map(message => message.payload),
        filter((payload: EventPayload): payload is AuthenticationResult => !!payload && payload.hasOwnProperty('accessToken')),
        tap(() => {
          this.loading = true;
        }),
        switchMap(result => this.authenticationService.loginByAzure(result.accessToken)),
        takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.formError = false;
          this.storageService.setSignInMethod(SignInMethod.AZURE);
          this.router.navigateByUrl(this.returnUrl);
          this.loading = false;
        },
        error: () => {
          this.setErrorMessage(SignInMethod.AZURE);
          this.loginForm.controls.password.reset();
          this.formError = true;
          this.loading = false;
        }
      });

    if (queryParams?.accessToken && queryParams.refreshToken && Object.values(SignInMethod).includes(queryParams?.oauth)) {
      this.storageService.setAccessToken(queryParams.accessToken);
      this.authenticationService.updateDeviceId(queryParams.accessToken, { deviceId: this.storageService.getDeviceId(), refreshToken: queryParams.refreshToken })
        .subscribe({
          next: () => {
            this.storageService.setRefreshToken(queryParams.refreshToken);
            this.storageService.setSignInMethod(queryParams.oauth);
            this.router.navigateByUrl(this.returnUrl);
          },
          error: () => {
            this.storageService.logout();
            this.setErrorMessage(queryParams?.oauth);
            this.formError = true;
          }
        });
    }

    if (Object.values(SignInMethod).includes(queryParams?.oauth)) {
      this.formError = true;
      this.setErrorMessage(queryParams?.oauth);
    }

    this.loginForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((valueChanges) => {
        if (this.formError) {
          this.formError = false;
          this.setErrorMessage(SignInMethod.SIMPLE);
        }
        if (valueChanges.rememberMe) {
          this.storageService.setMode('local');
        }
        if (valueChanges.rememberMe === false) {
          this.storageService.setMode('session');
        }
      });

  }

  async ngAfterViewInit() {
    await this.googleService.createLoginButton(this.googleContainer);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  login(): void {
    // this.tracker.trackEvent(TrackerCategory.LOGIN_PAGE, TrackerAction.CLICK, 'loginButton');
    window._mtm.push(
      { 
        'event': 'validation-connexion', 
        'type-connexion': 'Standard',
      }
    );
    if (this.loginForm.invalid) {
      this.formError = true;
      this.setErrorMessage(SignInMethod.SIMPLE);
      return;
    }
    // this.loading = true;
    this.apiCalling = true;
    this.authenticationService.login({
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value
    })
      .pipe(
        tap(() => {
        this.loading = true;
      }), takeUntil(this.destroy$))
      .subscribe({
          next: async () => {
            this.formError = false;
            this.loading = false;
            this.apiCalling = false;
            this.router.navigateByUrl(this.returnUrl ?? '');
          },
          error: () => {
            this.setErrorMessage(SignInMethod.SIMPLE);
            this.loginForm.controls.password.reset();
            this.formError = true;
            this.loading = false;
            this.apiCalling = false;
          }
        }
      );

  }

  setErrorMessage(type: SignInMethod): void {
    if (type === SignInMethod.GOOGLE) {
      this.errorMessage = 'Le compte Google choisi n\'est pas reconnu';
      return;
    }
    if (type === SignInMethod.AZURE) {
      this.errorMessage = 'Le compte Microsoft choisi n\'est pas reconnu';
      return;
    }
    if (type === SignInMethod.SIMPLE) {
      if (this.loginForm.controls.email.invalid) {
        this.errorMessage = 'Veuillez rentrer un email valide';
        return;
      }

      if (this.loginForm.controls.password.invalid) {
        this.errorMessage = 'Veuillez rentrer un mot de passe';
        return;
      }
      this.errorMessage = 'Email ou mot de passe incorrects';
    }
  }

  async loginByAzure() {
    window._mtm.push(
      { 
        'event': 'validation-connexion', 
        'type-connexion': 'Microsoft',
      }
    );
    // this.tracker.trackEvent(TrackerCategory.LOGIN_PAGE, TrackerAction.CLICK, 'MicrosoftSSOButton');
    this.msalService.loginRedirect();
  }

  handleForgotPasswordLinkClick(){
    // this.tracker.trackEvent(TrackerCategory.LOGIN_PAGE, TrackerAction.CLICK, 'forgotPasswordLink');
  }

  googleLoginClick(){
    window._mtm.push(
      { 
        'event': 'validation-connexion', 
        'type-connexion': 'Google',
      }
    );
    // this.tracker.trackEvent(TrackerCategory.LOGIN_PAGE, TrackerAction.CLICK, 'GoogleSSOButton');
  }

  handleForgotPasswordClick(){
    window._mtm.push(
      { 
        'event': 'clic-mot-passe'
      }
    );
  }

}
