import { Injectable } from '@angular/core';
import { Observable, of, Subject, takeUntil } from 'rxjs';

import { OrganizationStoreService } from '../../organizations/services/organization.store.service';
import { ProfileStore } from "../../shared/services/profile.storage.service";
import { getUserRole } from "../../shared/tool-functions/user";
import { ProfileService } from "../../users/services/profile.service";

@Injectable()
export class AvailableTrainsResolver  {
  private destroy$ = new Subject<void>();

  constructor(private readonly profileService: ProfileService,
              private readonly profileStore: ProfileStore,
              private readonly organizationStoreService: OrganizationStoreService
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(): void {
    const profile = this.profileStore.getProfile();
    if (profile) {
      this.setTrackerVariables(profile);
    } else {
      this.profileService
        .getObservable()
        .pipe(takeUntil(this.destroy$))
        .subscribe((prof: any) => {
          if (prof) {
            this.setTrackerVariables(prof);
          }
        });
    }
  }

  private setTrackerVariables(profile: any): void {
    this.organizationStoreService.getObservable().subscribe(value => {
        // MTM START
        (window._mtm || []).push(
          { 
            'PageName': 'AvailableTrains', 
            'PageCategory': 'Parcours',
            'UserProfile':getUserRole(profile, value), 
            'UserID': profile.id
          }
        );
      // MTM END
    });
  }
  
  private handleError(): Observable<undefined> {
    return of(undefined);
  }
}
