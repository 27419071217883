import { Component } from "@angular/core";

@Component({
    selector: 'app-subscribe-more',
    templateUrl: './subscribe-more.component.html',
    styleUrls: [ './subscribe-more.component.scss' ]
})

export class SubscribeMoreComponent { 

}