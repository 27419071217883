import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
// import { MatomoTracker } from "ngx-matomo-client";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { IPaginationComponent } from "../../shared/repositories/pagination.interface";
import { CsvService } from "../../shared/services/csv.service";
import { FilterStateService } from "../../shared/services/filter-state.service";
import { ProfileStore } from "../../shared/services/profile.storage.service";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { ProfileService } from "../../users/services/profile.service";
import { Organization } from "../models/organizations.entity";
import { NewOrganizationComponent } from "../new-organization/new-organization.component";
import {
  OrganizationFilterForm,
  OrganizationPropertiesFilter,
  OrganizationRepository,
} from "../repositories/organization.repository";

@Component({
  selector: "app-organizations-list",
  templateUrl: "./organizations-list.component.html",
  styleUrls: ["./organizations-list.component.scss"],
})
export class OrganizationsListComponent
  implements
    IPaginationComponent<Organization, OrganizationFilterForm, OrganizationPropertiesFilter>,
    OnInit, AfterViewInit, OnDestroy {
  mainRepository: OrganizationRepository;

  filterForm!: EntityFormGroup<OrganizationFilterForm>;

  extractLoading = false;

  newItems$ = new Subject<Organization[]>();

  sortBy$ = new BehaviorSubject<{ sort: string; order: "ASC" | "DESC" }>({ sort: 'name', order: 'ASC' });

  propertiesFilters$ = new BehaviorSubject<
    Partial<OrganizationPropertiesFilter>
  >({});

  hasFilters = false;

  private destroy$ = new Subject<void>();

  updateSubscriptionPlanSelected$ = new Subject<void>();

  subscriptionPlanCheckboxes: Checkbox[] = [
    {
      id: "no-subscription",
      key: "Sans abonnement",
      selected: false,
    }, 
    {
      id: "tester-subscription",
      key: "Testeur",
      selected: false,
    }, 
    {
      id: "personalized-subscription-individual",
      key: "À la carte - Particulier",
      selected: false,
    }, 
    {
      id: "personalized-subscription",
      key: "Abonnement Particulier",
      selected: false,
    },
    {
      id: "personalized-subscription-professional",
      key: "À la carte",
      selected: false,
    },
    {
      id: "basic-subscription",
      key: "Abonnement Basique",
      selected: false,
    },
    {
      id: "classic-subscription",
      key: "Abonnement Classique",
      selected: false,
    },
    {
      id: "premium-subscription",
      key: "Abonnement Premium",
      selected: false
    },
    {
      id: "elite-subscription",
      key: "Abonnement Elite",
      selected: false
    }
    ];

  // private readonly tracker = inject(MatomoTracker);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly organizationsRepository: OrganizationRepository,
    private readonly dialog: DialogService,
    private readonly formBuilder: FormBuilder,
    private readonly csvService: CsvService,
    private readonly snackBarService: SnackbarService,
    private filterStateService: FilterStateService,
  ) {
    this.mainRepository = this.organizationsRepository;
    const prevUrl = this?.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
    const pattern = /^\/organizations\/([^\/]+)$/;
    const isMatch = !!prevUrl?.match(pattern);

    if(!isMatch){
      this.filterStateService.state$.next(null);
    }

    this.filterForm = this.formBuilder.group({
      startCreationDate: new FormControl("", { nonNullable: true }),
      endCreationDate: new FormControl("", { nonNullable: true }),
      trainsCountMinimum: new FormControl("", { nonNullable: true }),
      trainsCountMaximum: new FormControl("", { nonNullable: true }),
      membersCountMinimum: new FormControl("", { nonNullable: true }),
      membersCountMaximum: new FormControl("", { nonNullable: true }),
      unitsMinimum: new FormControl("", { nonNullable: true }),
      unitsMaximum: new FormControl("", { nonNullable: true }),
    });
    
    this.route.queryParamMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryParams) => {
        if (queryParams.has("create")) {
          this.addOrganization();
        }
      });
  }

  clearFilter?: (() => void) | undefined;

  otherFilterOptionCount?: number | undefined;

  savePreferences?: ((filter: OrganizationFilterForm) => void) | undefined;

  getFilter?: (() => OrganizationFilterForm) | undefined;

  ngOnInit() {
    this.propertiesFilters$.subscribe((propertiesFilters) => {
      const hasValue = Object.values(propertiesFilters).some(value => !!value);
      this.hasFilters = hasValue;
    });

    this.subscriptionPlanCheckboxes = this.subscriptionPlanCheckboxes.map(checkbox => ({
      ...checkbox,
      selected: this.filterStateService.state$.getValue()?.subscriptionPlans?.includes(checkbox.id) || false
    }));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngAfterViewInit() {
    const savedState = this.filterStateService.state$.getValue();
    if (savedState) {
      const { subscriptionPlans, ...formValues } = savedState;
      
      this.filterForm.setValue(formValues);
      
      if (subscriptionPlans && subscriptionPlans.length > 0) {
        this.subscriptionPlanCheckboxes = this.subscriptionPlanCheckboxes.map(checkbox => ({
          ...checkbox,
          selected: subscriptionPlans.includes(checkbox.id)
        }));
        
        this.propertiesFilters$.next({
          ...this.propertiesFilters$.getValue(),
          subscriptionPlans
        });
        
        this.updateSubscriptionPlanSelected$.next();
      }
    }
  }

  public addOrganization(): void {
    this.dialog
      .open(NewOrganizationComponent)
      .pipe(takeUntil(this.destroy$))
      .subscribe((newOrganization) => {
        if (newOrganization?.name) {
          this.organizationsRepository.create(newOrganization).subscribe({
            next: (organisationCreated) => {
              this.snackBarService.pushMessage(
                "Organisation créée avec succès",
                "success"
              );
              this.newItems$.next([organisationCreated]);
            },
          });
        }
      });
  }

  public goToDetails(id: string) {
    if (this.filterForm.dirty || this.hasFilters) {
      const formValues = this.filterForm.value;

      const currentFilters = this.propertiesFilters$.getValue();
      const subscriptionPlans = currentFilters.subscriptionPlans || [];

      this.filterStateService.state$.next({
        ...formValues,
        subscriptionPlans
      });
    }
    this.router.navigate(["organizations", id]);
  }

  downloadOrganizationsCsv(): void {
    this.extractLoading = true;
    this.organizationsRepository.paginate(
      { 
        page: 1, 
        pageSize: 100000, 
        properties: this.propertiesFilters$.getValue(), 
        sortBy: this.sortBy$.getValue(),
      }).subscribe((data) => {
      this.extractLoading = false;
      this.csvService.downloadOrganizationsCsv(data.items);
    });
  }

updateSubscriptionPlanSelected(checkboxes: any): void {
  const selectedPlans = checkboxes
    .filter((checkbox: any) => checkbox.selected)
    .map((checkbox: any) => checkbox.id);
  
  const currentState = this.propertiesFilters$.getValue();
  const currentPlans = currentState.subscriptionPlans || [];
  
  const hasChanged = JSON.stringify(currentPlans) !== JSON.stringify(selectedPlans);
  
  if (!hasChanged) {
    return;
  }

  if (selectedPlans.length > 0) {
    this.propertiesFilters$.next({
      ...currentState,
      subscriptionPlans: selectedPlans
    });
    
    const formValues = this.filterForm.value;
    this.filterStateService.state$.next({
      ...formValues,
      subscriptionPlans: selectedPlans
    });
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { subscriptionPlans, ...rest } = currentState;
    this.propertiesFilters$.next(rest);
    
    const formValues = this.filterForm.value;
    this.filterStateService.state$.next({
      ...formValues,
      subscriptionPlans: []
    });
  }
  
  setTimeout(() => {
    this.updateSubscriptionPlanSelected$.next();
  });
}
}
