<div class="wrapper">

  <div class="introducing">
    <h2 class="title">Devenir Sherpa</h2>
    <br>

  </div>

  <form class="content margin-top" [formGroup]="newSherpaForm" (submit)="sendEmail()">
    <app-input class="form-input" [error]="formError" error-message="Veuillez entrer un email valide" formControlName="email"
               type="email" placeholder="Veuillez entrer votre email"></app-input>
    <textarea class="margin-bottom" formControlName="content" placeholder="Veuillez vous présenter en quelques lignes"></textarea>
    <app-button [disabled]="!newSherpaForm.valid" class="margin-top" type="submit">Envoyer</app-button>
    <div>
      <a href="auth/login">Revenir à la page de connexion</a>
    </div>
  </form>
</div>
