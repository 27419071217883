import { Component } from '@angular/core';

import { DialogComponent } from "../dialog/dialog.component";
import { DialogRef } from "../dialog/dialog.ref";

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: [ './cgu.component.scss' ]
})
export class CGUComponent extends DialogComponent() {
  constructor(private readonly dialogRef: DialogRef<undefined>) {
    super(dialogRef);
  }
}
