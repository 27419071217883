import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable, Injector } from "@angular/core";
import { delay, Subject, tap } from "rxjs";

import { TDialogComponent } from "./dialog.component";
import { DialogRef } from "./dialog.ref";
import { DIALOG_DATA } from "./dialog.token";

@Injectable({ providedIn: "root" })
export class DialogService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  /**
   * Open a custom component in an overlay
   */
  open<Result, Config>(
    component: TDialogComponent<Result, Config>,
    config?: Config
  ): Subject<Result | undefined> {
    // Globally centered position strategy
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    // Create the overlay with customizable options
    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: "dialog-backdrop",
      panelClass: "dialog-panel",
    });

    overlayRef.addPanelClass(component.isPopIn ? "pop-in" : "");

    // Create dialogRef to return
    const dialogRef = new DialogRef<Result>(overlayRef);

    // Create injector to be able to reference the DialogRef from within the component
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: config },
      ],
    });

    // Attach component portal to the overlay
    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);

    overlayRef
      .backdropClick()
      .pipe(
        tap(() => overlayRef.addPanelClass("closing")),
        delay(500)
      )
      .subscribe(() => overlayRef.dispose());

    return dialogRef.afterClose$;
  }
}
