<div
  class="bubble-override pointer margin-bottom row gap center-children"
  [dropdownTriggerFor]="optionsDropdown"
  dropdownPosition="center"
>
  {{ selectedOption?.name ?? placeholder }}
  <app-icon
    type="close"
    (click)="deleteSelectedOption()"
    *ngIf="selectedOption"
  />
  <app-icon *ngIf="!selectedOption" type="chevron-down" />
</div>
<app-dropdown [closeOnClick]="true" #optionsDropdown>
  <div
    class="dropdown-item row gap"
    (click)="selectOption(option)"
    *ngFor="let option of options"
  >
    {{ option.name }}
  </div>
</app-dropdown>
