import { Injectable } from "@angular/core";
import * as uuid from "uuid";

export type StorageMode = 'session' | 'local';
export enum SignInMethod {
  SIMPLE = 'simple',
  GOOGLE = 'google',
  AZURE = 'azure'
}

export interface Storage {
  getItem(itemKey: StorageKey) : string | null;
  removeItem(itemKey: StorageKey): void;
  setItem(itemKey: StorageKey, itemValue: string) : void;
  clear(): void;
}

export enum StorageKey {
  DEVICE_ID = 'x-device-id',
  REFRESH_TOKEN = 'x-refresh-token',
  ACCESS_TOKEN = 'access-token',
  ADMIN_TOKEN = 'admin-token',
  SIGNIN_METHOD = 'oauth',
  RETURN_URL = 'return-url',
  PAGE_HISTORY = 'page-history',
  ORGANIZATION_SELECTED_ID = 'organization-selected-id'
}

@Injectable({ providedIn: 'root' })
export class StorageService {

  private storage: Storage = localStorage;

  private mode!: StorageMode;

  constructor() {
    if (!localStorage.getItem(StorageKey.REFRESH_TOKEN)) {
      this.mode= 'session';
      this.storage = sessionStorage;
    }
  }

  setMode(mode: StorageMode) {
    this.mode = mode;
    if (mode === 'local') {
      this.storage = localStorage;
    } else {
      this.storage = sessionStorage;
    }
  }

   getDeviceId(): string {
    const deviceId = this.storage.getItem(StorageKey.DEVICE_ID) ?? uuid.v4();
    this.setDeviceId(deviceId);

    return deviceId;
  }

  private setDeviceId(deviceId: string): void {
    this.storage.setItem(StorageKey.DEVICE_ID, deviceId);
  }

  getRefreshToken = () => this.storage.getItem(StorageKey.REFRESH_TOKEN) ?? "";

  setRefreshToken = (refreshToken: string) =>
    this.storage.setItem(StorageKey.REFRESH_TOKEN, refreshToken);

  getAdminRefreshToken = () => this.storage.getItem(StorageKey.ADMIN_TOKEN) ?? "";

  setAdminRefreshToken = (adminToken: string) =>
    this.storage.setItem(StorageKey.ADMIN_TOKEN, adminToken);

  removeAdminRefreshToken = () => this.storage.removeItem(StorageKey.ADMIN_TOKEN);

  getAccessToken = () => this.storage.getItem(StorageKey.ACCESS_TOKEN) ?? "";

  setAccessToken = (accessToken: string) =>
    this.storage.setItem(StorageKey.ACCESS_TOKEN, accessToken);

  getSignInMethod = () => this.storage.getItem(StorageKey.SIGNIN_METHOD) ?? "";

  setSignInMethod = (method: SignInMethod) => this.storage.setItem(StorageKey.SIGNIN_METHOD, method);

  logout = () => {
    this.storage.clear();
  };

  setReturnUrl = (returnUrl: string) => this.storage.setItem(StorageKey.RETURN_URL, returnUrl);

  getReturnUrl = () => this.storage.getItem(StorageKey.RETURN_URL);

  setPageHistory = (pageHistory: string[][]) => this.storage.setItem(StorageKey.PAGE_HISTORY, JSON.stringify(pageHistory));

  getPageHistory: () => string[][] = () => JSON.parse(this.storage.getItem(StorageKey.PAGE_HISTORY) ?? '[]') as string[][];

  getOrganizationSelectedId: () => string = () => this.storage.getItem(StorageKey.ORGANIZATION_SELECTED_ID) ?? '';

  setOrganizationSelectedId = (id: string | undefined) => this.storage.setItem(StorageKey.ORGANIZATION_SELECTED_ID, id ?? '');
}
