import { Component, Inject } from '@angular/core';
import { takeUntil } from "rxjs";

import { OrganizationRepository } from "../../organizations/repositories/organization.repository";
import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";

@Component({
  selector: 'app-edit-managed-organizations',
  templateUrl: './edit-managed-organizations.component.html',
  styleUrls: [ './edit-managed-organizations.component.scss' ]
})
export class EditManagedOrganizationsComponent extends DialogComponent<string[], {
  selectedOrganizationsIds: string[]
}>() {

  loading = false;

  selectedOrganizationIds: string[] = [];

  organizationCheckboxes: Checkbox[] = [];

  constructor(
    @Inject(DIALOG_DATA)
    public override readonly data: { selectedOrganizationsIds: string[] },
    private readonly organizationRepository: OrganizationRepository,
    private readonly ref: DialogRef<string[]>,
  ) {
    super(ref);
  }

  ngOnInit(): void {
    super.onInit();
    this.organizationRepository.findAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe(organizations => {
        this.organizationCheckboxes = organizations
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            return 1;
          })
          .map(organization => {
            if(organization.parent){
              return {
                id: organization.id,
                key: organization.name.toUpperCase(),
                selected: this.data.selectedOrganizationsIds.includes(organization.id) || this.data.selectedOrganizationsIds.includes(organization.parent.id)
              };
            }
            return {
            id: organization.id,
            key: organization.name.toUpperCase(),
            selected: this.data.selectedOrganizationsIds.includes(organization.id)
          };
        });
        this.selectedOrganizationIds = this.data.selectedOrganizationsIds;
      });

  }

  ngOnDestroy() {
    super.onDestroy();
  }

  validate(isValidated = false): void {
    if (isValidated) {
      super.close(this.selectedOrganizationIds);
    } else {
      super.close();
    }
  }

  updateSelectedOrganizations(checkboxes: Checkbox[]): void {
    this.selectedOrganizationIds = checkboxes.filter(checkbox => checkbox.selected).map(checkbox => checkbox.id);
  }
}
