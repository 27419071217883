<div class="cgu-wrapper">

  <div class="cgu-content"><h1>Conditions générales d'utilisation - Version du 26/04/2021</h1>

    <p>L'utilisation du site internet accessible sous le lien <a href="www.wetalk.life"
                                                                 target="_blank">www.wetalk.life</a> (ci-après « Site »)
      de We Talk Group est régie par les présentes Conditions Générales d'Utilisation (ci-après « CGU »).</p>
    <p>La société We Talk Group, société immatriculée auprès du Registre du Commerce et des Sociétés de Lille Métropole,
      sous
      le numéro 887 553 766 et dont le siège social se situe 23, Boulevard du Général Leclerc à Roubaix (59100)
      développe
      et
      exploite le Site à destination des entreprises et des particuliers qui propose la participation à des groupes de
      paroles et du coaching de groupe à distance sur des thèmes variés.</p>
    <p>Nom du Directeur ou co-directeur de la publication du Site : DUPREZ David.</p>
    <p>Dénomination sociale, adresse et numéro de téléphone de l’hébergeur :</p>
    <p>We Talk Group, 23, boulevard du Général Leclerc - 59 100 ROUBAIX - Tél : 03 74 09 85 89</p>
    <p>Il est recommandé de lire attentivement les présentes CGU avant toute utilisation du Site. L'utilisation du Site
      vaut
      acceptation des présentes CGU. En cas de désaccord avec celles-ci, l’Utilisateur est prié de ne pas utiliser le
      Site.</p>
    <p>We Talk Group peut modifier les présentes CGU à tout moment. Les CGU sont celles en vigueur lors de chaque
      utilisation
      du Site par l’Utilisateur. L’Utilisateur s’engage, préalablement à toute utilisation, à prendre connaissance des
      présentes CGU.</p>

    <p>Le fait pour We Talk Group de ne pas se prévaloir de l’une des dispositions des CGU à un instant et/ou une
      occasion
      donné(e)(s) ne saurait emporter renonciation de sa part à en revendiquer le bénéfice ultérieurement.</p>

    <h2>1. OBJET</h2>
    <p>Les présentes CGU ont pour objet de définir les conditions d’accès et d’utilisation du Site, ainsi que l’ensemble
      des
      services qui y sont associés.</p>
    <p>Les Utilisateurs du Site reconnaissent que la consultation et l'utilisation du Site sont régies exclusivement par
      les
      présentes CGU et qu'ils doivent s'y conformer.</p>
    <p>Par l’acceptation des CGU, l’Utilisateur s’engage à utiliser les Services conformément à leur finalité et dans
      les
      conditions décrites dans les présentes.</p>

    <h2>2. DÉFINITIONS</h2>
    <p>Les termes et expressions suivants ont, sauf précision contraire, le sens qui leur est donné ci-dessous s’ils
      apparaissent avec leur première lettre en majuscule, qu’ils soient au singulier ou au pluriel dans les présentes
      CGU
      :</p>
    <p><b>Utilisateur :</b>
      Désigne tout utilisateur du Site (consommateur, professionnel et non professionnel).</p>

    <p><b>Services :</b>
      Mise en relation des Utilisateurs avec des coachs et professionnels de la relation d’aide.</p>

    <p><b>Coachs :</b>
      Coachs et professionnels de la relation d’aide, indépendants de la société We Talk.</p>


    <h2>3. CONDITIONS D'ACCÈS – MINEURS</h2>
    <p>L'accès au Site est gratuit.</p>
    <p>Sa consultation par des personnes mineures (moins de 18 ans) se fait sous le contrôle et la seule responsabilité
      de
      leurs parents et/ou de leur représentant légal.</p>

    <h2>4. AFFICHAGE DES PRIX SUR LE SITE</h2>
    <p>Les prix affichés sur le Site sont mentionnés en Euros, Hors-Taxes (H.DialogType.) et Toutes Taxes Comprises (DialogType.DialogType.C.).</p>
    <p>Le Site est un site « vitrine » qui ne permet pas l’achat en ligne des services. L’Utilisateur peut se rapprocher
      de
      We Talk pour souscrire aux Services.</p>

    <h2>5. INSCRIPTION / CRÉATION D’UN ESPACE PERSONNEL</h2>
    <p>L’Utilisateur reçoit un mail avec un lien qui lui permet la création de son compte et d’un mot de passe unique.
      Un
      code lui sera envoyé via son téléphone portable afin d'authentifier l’Utilisateur.</p>

    <h2>6. OBLIGATIONS DES UTILISATEURS</h2>
    <p>Les Utilisateurs s'engagent à accepter les présentes CGU et à garantir leur respect dans leur intégralité dans le
      cadre de leur utilisation du Site, ainsi que les droits des tiers.</p>
    <p>Les Utilisateurs sont seuls responsables des informations fournies par leurs soins.</p>
    <p>La délivrance de fausses informations est susceptible d'engager la responsabilité de l’Utilisateur, conformément
      aux
      lois et règlements édictés en France.</p>
    <p>Les Utilisateurs sont responsables de la confidentialité de leur identifiant et mot de passe pour accéder à leur
      espace personnel et s’interdisent donc de communiquer ces informations à quelque tiers que ce soit n’ayant pas un
      accès autorisé et légitime à cet espace.</p>
    <p>We Talk Group se réserve le droit de prendre toute mesure permettant de remédier au non-respect des CGU par les
      Utilisateurs.</p>
    <p>WeTalk Group ne pourra être tenu responsable d'aucun manquement de l’Utilisateur aux présentes, notamment en cas
      d'atteinte aux droits des tiers de ce fait.</p>

    <h2>7. UTILISATION CONFORME DU SITE</h2>
    <p>L’Utilisateur s’engage à utiliser le Site dans le respect des législations et réglementations applicables et en
      prenant soin de ne pas porter atteinte aux droits de We Talk Group ou des tiers.</p>
    <p>Le Site est susceptible de modification et d’évolutions. L’Utilisateur reconnaît disposer de la compétence et des
      moyens nécessaires pour accéder et utiliser ce Site. Il reconnaît avoir vérifié que la configuration informatique
      qu’il utilise ne contient aucun virus et qu’elle est en parfait état de fonctionnement.</p>
    <p>Toute création de lien vers le Site est soumise à l’autorisation préalable et expresse de We Talk Group. Cette
      autorisation pourra à tout moment être retirée par We Talk Group, sans que ce dernier ait à justifier sa décision
      de
      retrait.</p>

    <h2>8. PROPRIÉTÉ INTELLECTUELLE</h2>
    <p>Le Site est la propriété exclusive de We Talk Group, qui est titulaire du nom de domaine www.wetalk.life
      correspondant
      au Site, des marques françaises « We Talk Group » et « Wetalk.life ». Pour plus d’information sur la société,
      l’Utilisateur peut consulter les Mentions légales également disponibles sur le Site.</p>
    <p>Le Site et son contenu sont protégés par le droit français et le Code de la Propriété Intellectuelle français. Le
      Site
      dans son ensemble ainsi que tous ses éléments, qu'ils soient visuels ou sonores, y compris la technologie qui y
      est
      sous-jacente, sont protégés (selon les cas) par les droits d'auteur, des marques ou des brevets ou encore par le
      droit
      sui generis des producteurs de bases de données (aucune extraction totale ni partielle n'est autorisée).</p>
    <p>We Talk Group est titulaire des droits susvisés ou est l’exploitant légitime de ces droits via le Site, aux fins
      du
      Contrat.</p>
    <p>Le droit d'utiliser le Site, accordé à l’Utilisateur, ne saurait en aucun cas s'analyser comme lui conférant un
      quelconque droit de propriété sur le Site ni sur aucun de ses éléments constitutifs. Toute utilisation de tout
      composant ou partie du Site, des marques ou logos, ou toute exploitation de ses droits de propriété
      intellectuelle,
      qui ne serait pas expressément autorisée aux présentes, est strictement interdite à l’Utilisateur.
      Notamment, l’Utilisateur n'est pas autorisé à modifier, copier, distribuer, encadrer, reproduire, republier,
      télécharger, afficher, mettre en ligne, transmettre ou créer des éléments contenus sur le Site ni de les diffuser
      ou
      communiquer à des tiers ou encore de les utiliser par tout autre moyen, sauf accord exprès, écrit et préalable de
      We
      Talk. Group.</p>
    <p>Tout lien hypertexte renvoyant au Site et utilisant la technique du "framing" ou du "in-line linking" ou toute
      autre
      technique de renvoi au Site est par principe formellement interdit.</p>
    <p>L’Utilisateur qui souhaiterait placer sur un Site internet tiers tout lien renvoyant à une page du Site, doit
      obligatoirement en demander l'autorisation expresse, écrite et préalable à We Talk.Group.</p>


    <h2>9. DONNÉES PERSONNELLES</h2>
    <p>We Talk Group, dans le cadre de la collecte des Données Personnelles renseignées par les Utilisateurs aux fins
      d’utilisation du Site et des services et prestations, intervient en qualité de responsable de traitement , et se
      conforme à la réglementation applicable en matière de Données Personnelles, émanant des dispositions de la Loi
      Informatique et Liberté n°78-17 du 6 janvier 1978 et du Règlement (UE) n°2016/679 du 27 avril 2016 relatif à la
      Protection des personnes physiques à l’égard du traitement des Données à caractère Personnel et à la libre
      circulation
      de ces Données Personnelles (RGPD).</p>
    <p>L’Utilisateur est informé que le traitement de ses Données Personnelles est nécessaire à la création d’un compte
      client et à la fourniture des services par WeTalk Group.</p>
    <p>La Politique de Confidentialité de We Talk Group, explicitant les Données Personnelles collectées par We Talk
      Group,
      la finalité de leur traitement, et le territoire sur lequel opèrent les destinataires des Données est consultable
      et
      téléchargeable sur support durable sur le Site WWW.WETALK.LIFE. We Talk Group collecte les Données Personnelles de
      l’Utilisateur pour l’exécution des présentes conditions générales, et dans le cadre des opérations suivantes :</p>
    <li>Lorsque We Talk Group adresse des mailings ou documentation de prospection à des prospects 
    </li>
    <li>lorsque l’Utilisateur souhaite créer un espace personnel et un compte client sur le Site 
    </li>
    <li>afin d’authentifier l’Utilisateur pour accéder à son Espace Personnel et utiliser le Site 
    </li>
    <li>lorsque l’Utilisateur souhaite s’inscrire à des séances et des prestations de We Talk Group via le Site 
    </li>
    <li>lorsque l’Utilisateur souhaite consulter son compte de séances disponibles et de paiement sur le Site 
    </li>
    <li>afin de transmettre à l’Utilisateur la lettre d’information de We Talk Group en cas d’abonnement 
    </li>
    <li>afin de gérer les commandes et abonnements de l’Utilisateurs sur le Site 
    </li>
    <li>Sélection et gestion des Coachs 
    </li>
    <li>Gestion des prestations des Coachs pour les clients 
    </li>
    <li>dans le cadre des études statistiques réalisées par We Talk Group, dans une optique d’amélioration constante et
      de
      développement de son offre 
    </li>
    <li>pour la gestion du risque, le contrôle et la surveillance liés au contrôle interne auquel est soumis We Talk
      Group
    </li>
    <li>pour la sécurité et la prévention des impayés et de la fraude, le recouvrement et le contentieux.
    </li>
    <p>L’Utilisateur est informé que, dans le cadre des opérations sus-décrites liées aux services de paiement, We
    TalkGroup
    confie le stockage de certaines Données Personnelles des Utilisateurs, ou leur accès, aux sous-traitants identifiés
    dans la Politique de Confidentialité, et potentiellement SELLSY, en sa qualité de sous-traitant du
      traitement[FIDAL-FBO5] .</p>
    <p>À titre exceptionnel, We Talk Group peut être amenée à retraiter et à stocker elle-même les Données collectées dans
    le
      cadre des services de paiement, dans les hypothèses suivantes :</p>
    <li>aux fins de réponse à d’éventuelles obligations légales, et notamment dans le cadre d’injonctions judiciaires ou
      administratives </li>
    <li>en cas de demande d’informations complémentaires par le responsable du traitement aux fins de vérification des
      Données renseignées par l’Utilisateur.</li>

    <h2>10. COOKIE</h2>
    <p>Un cookie est un fichier texte susceptible d’être enregistré, généralement sous réserve de l’accord ou du
    paramétrage
    de l'utilisateur, dans un espace dédié du disque dur du terminal, à l’occasion de la consultation du Site de We Talk
    Group.</p>
      
    <p>Un fichier Cookie permet notamment à son émetteur d’identifier le Terminal dans lequel il est enregistré, pendant la
    durée de validité ou d’enregistrement du Cookie.</p>
    <p>Pour toute information et paramétrage concernant les cookies, l’Utilisateur peut consulter la politique « cookies »
    de
    We Talk Group, accessible via le bandeau d’information du Site, ainsi que la Politique de confidentialité de We Talk
    Group sur le Site et les télécharger sur support durable.</p>

    <h2>11. RESPONSABILITÉ ET GARANTIE</h2>
    <p>We Talk Group fait ses meilleurs efforts pour :</p>
    <li>assurer le bon fonctionnement du Site et</li>
    <li>protéger les données personnelles des Utilisateurs qu'elle aurait collectées, contre tout dommage, perte,
    altération, destruction, détournement, intrusion dans les systèmes de données ou toute utilisation de ces données
    par
      des tiers non autorisés.</li>
    <p>Toutefois, We Talk Group ne peut garantir la maîtrise de tout risque inhérent au support internet et sa
    responsabilité
    ne saurait être retenue au titre de tout incident ou dommage inhérent à l'utilisation du réseau internet, notamment
      toute intrusion extérieure ou présence de virus informatiques.</p>
    <p>De même, la responsabilité de We Talk ne saurait être engagée du fait de l'utilisation du Site par les Utilisateurs,
    notamment du fait de la publication de tout contenu illicite sur le Site ou en cas d'atteinte aux droits des tiers
    du
      fait des Utilisateurs.</p>
    <p>Notamment, la responsabilité de We Talk ne saurait être engagée en cas :</p>
    <li>de publication de tout contenu illicite sur le Site, sous réserve des obligations de modération du Site de We Talk
    Grou</li>
    <li>d'atteinte aux droits des tiers du fait d’une faute des Utilisateurs</li>
    <li>de non- respect des présentes CGU</li>
    <li>d’accès par un tiers aux Données d’identification de l’Utilisateur, sur autorisation de celui-ci</li>
    <li>d’usage frauduleux ou abusif des Services par l’Utilisateur</li>
    <li>d’interruption ou défaillance des Services du fait ou suite à des dysfonctionnements du réseau Internet</li>
    <li>d’inexactitude des informations transmises par l’Utilisateur.
    <p>En particulier, We TalkGroup ne saurait voir sa responsabilité engagée à raison des conséquences, éventuellement
    dommageables pour l’Utilisateur, de l’usage qu’il fait des informations ou des outils informatiques qui sont mis à
    sa
      disposition sur le Site, dans la mesure où l’Utilisateur demeure seul responsable de ses décisions.</p>

      <h2>12. FORCE MAJEURE</h2>
    <p>La responsabilité de chacune des Parties ne pourra être recherchée qu'en cas d’inexécution de tout ou partie de ses
    obligations due à un événement de force majeure telle que définie à l’article 1218 du Code civil et par la
      jurisprudence française.</p>
    <p>De plus, de convention expresse entre les Parties, pourront être tenus pour des cas de force majeure, les événements
    d'interruption, panne, sabotage des moyens de télécommunication ou des logiciels et serveurs, incendie, inondation,
    tempêtes, foudre, intempéries exceptionnelles, émeutes, guerre ou guerre civile, grève ou lock out, soit chez l’une
    des Parties, soit chez ses fournisseurs ou prestataires, pandémie, épidémie, et maladies contagieuses, faits du
    prince
    et notamment les mesures nationales d’interdictions administratives de circulation, y compris celles découlant des
      épidémies, des pandémies ou des maladies contagieuses.</p>


      <h2>13. RÉCLAMATIONS</h2>
    <p>Pour toute question, demande ou réclamation relative au Site ou en cas de contestations liées à l’interprétation et
    à
      la validité des présentes CGU, l’Utilisateur peut contacter We Talk Groupaux coordonnées suivantes :</p>


    <li>Adresse postale : 23, Boulevard du Général Leclerc 59 100 Roubaix</li>
    <li>Adresse Mail : contact&#64;wetalkgroup.com</li>

    <h2>14. LANGUE</h2>
    <p>Les présentes CGU sont rédigées en langue française. En cas de traduction de celles-ci, seule la version française
      fera foi entre les Parties.</p>

    <h2>15. LOI APPLICABLE, MÉDIATION ET JURIDICTION COMPÉTENTE</h2>
    <p><b>15.1 Les présentes CGU sont soumises au droit français sous réserve pour l'utilisateur consommateur des règles
    impératives plus favorables du pays de sa résidence.</b></p>

    <p><b>15.2 Le tribunal compétent sera déterminé conformément aux règles de droit commun, sauf si l’Utilisateur a la
    qualité
    de commerçant.</b></p>

    <p>Pour les commerçants, tout litige entre les parties, relatif notamment à la conclusion, l’interprétation,
    l’exécution
    des présentes CGU sera soumis à la compétence exclusive des Tribunaux de Lille Métropole, même en cas de pluralité
    de
      défendeurs, d’appel en garantie, pour procédure d’urgence ou conservatoire, en référé ou par requête.</p>

    <p>A défaut d’une résolution du litige par la procédure de réclamation stipulée à l’article 13, l’Utilisateur
    consommateur est informé qu’il peut, dans la limite d’un an à compter de sa réclamation, recourir gratuitement à une
      médiation, notamment auprès de :</p>


    <p>la commission d'évaluation et de contrôle de la médiation de la consommation accessible à cette adresse :
      <a href="https://www.economie.gouv.fr/mediation-conso" target="_blank">https://www.economie.gouv.fr/mediation-conso</a></p>
    <p>la plateforme de règlement en ligne des litiges (RLL) :
      <a href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR." target="_blank">https://webgate.ec.europa.eu</a></p>


    <p>ou contacter We Talk via email à l’adresse : contact&#64;wetalkgroup.com</p>

  </div>
  <div class="cgu-close-button relative">
    <app-button class="center-by-relative-parent" (click)="close()">Fermer</app-button>
  </div>

</div>
