import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { catchError, of, Subject, switchMap, takeUntil } from "rxjs";
import { map } from "rxjs/operators";

import { TrainRepository } from "../repositories/train.repository";

@Component({
  selector: 'app-leave-train',
  templateUrl: './leave-train.component.html',
  styleUrls: [ './leave-train.component.scss' ]
})
export class LeaveTrainComponent implements OnDestroy {

  destroy$ = new Subject<void>();

  message = 'Voulez vous vraiment quitter votre parcours ?';

  needConfirmation = true;

  constructor(private readonly route: ActivatedRoute,
              private readonly trainRepository: TrainRepository
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  confirm(): void {
    this.route.queryParamMap
      .pipe(
        map(params => params.get('token')),
        switchMap(token => this.trainRepository.leaveTrainByMail(token ?? '')),
        catchError(() => of(null)),
        takeUntil(this.destroy$))
      .subscribe(() => {
        this.message = 'Vous avez bien été désinscrit de votre parcours';
        this.needConfirmation = false;
      });
  }
}
