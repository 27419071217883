<div class="header row gap">
  <h1 class="title">{{ data.isAdmin ? 'Veuillez insérer la quantité d\'unités' : 'Transférer des unités' }}</h1>
  <app-icon button type="close"
              (click)="validate()"></app-icon>
</div>

<section>
  <form [formGroup]="unitForm" (submit)="validate(true)">
    <app-input title="Quantité" negativeInput formControlName="units" type="number" placeholder="0"></app-input>
    <app-input title="Transférer depuis une autre organisation" formControlName="organizationName" type="organization" [dropdownData]="existingOrganizationNames"></app-input>
    <p *ngxPermissionsOnly="'admin'" class="margin-bottom">Si aucune organisation n'est précisée, la quantité d'unité est créée</p>

    <div class="add-button relative">
      <app-button class="center-by-relative-parent" [disabled]="!formValidated" type="submit" title="Ajouter">Ajouter</app-button>
    </div>
  </form>
</section>
