<div class="column" [class.fade-left]="fadeLeft$ | async" [class.fade-left-reverse]="fadeLeftReverse$ | async"
     [class.fade-right]="fadeRight$ | async" [class.fade-right-reverse]="fadeRightReverse$ | async">

  <strong class="margin-top">Choisissez une date.</strong>
  <div class="center-children-horizontally" *ngIf="dynamic">
    <app-monthly-calendar [weekDaysAvailable]="availableDays"
                          [delayToCreate]="minimumDelayToCreateTrain"
                          [timeSlotsPerDay]="availableTimes"
                          [selectedDate]="dateSelected"
                          (clickOnDay)="selectDate($event)"
    ></app-monthly-calendar>
  </div>
</div>
