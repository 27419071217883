<div class="column gap" *ngIf="thematic" #page [class.fade-left]="fadeLeft$ | async" [class.fade-left-reverse]="fadeLeftReverse$ | async">
  <app-button class="center-horizontally-by-margin" icon="edit" *ngxPermissionsOnly="'admin'" (click)="editThematic()">Editer</app-button>
  <app-thematic-card [big]="true" [withoutTitle]="true" [thematic]="thematic"></app-thematic-card>

  <div class="space-between row">
    <h1 class="cooper title">{{thematic.title}}</h1>
    <app-favorite-thematic [thematic]="thematic" />
  </div>
  <p><strong>{{thematic.resume}}</strong></p>
  <div class="info-tile row center-children-vertically">
    <div class="text-xl">
      <app-icon size="40" type="clock"/>
    </div>
    <p><strong>4 séances de 30 à 90 min</strong> selon le nombre de participants</p>
  </div>

  <h2>En fonction de vos besoins et de votre situation :</h2>
  <ul [class]=" categorySlug">
    <li *ngFor="let bulletPoint of workingOnBulletPoints">
      <span>{{bulletPoint}}</span>
    </li>
  </ul>

  <h2>Dans quelle dynamique ?</h2>
  <app-dynamics class="dynamics" [thematic]="thematic" [canValidate]="isTalker" [goToAvailableTrains]="true"/>

  <div *ngIf="thematic.testimonials?.length">
    <h2 class="margin-bottom-xl margin-top-xl">Témoignages</h2>
    <app-slider *ngIf="thematic.testimonials" [items]="thematic.testimonials">
      <ng-template #slide let-item>
        <div class="testimonial" [class]="categorySlug">
          <div class="testimonial-content">
            <p class="margin-bottom">{{item.description}}</p>
            <p><strong>{{item.author}}</strong></p>
          </div>
        </div>
      </ng-template>
    </app-slider>
  </div>


  <div *ngIf="thematic.keyNumbers?.length">
    <h2 class="margin-top-xl">Des chiffres qui parlent</h2>
    <div class="wrap gap">
      <div class="column margin-right" *ngFor="let keyNumber of thematic.keyNumbers">
        <p class="key-number cooper text-xl" [class]="categorySlug">{{keyNumber.stringNumber}}</p>
        <div class="key-number-description">{{keyNumber.description}}</div>
      </div>
    </div>
  </div>


  <div *ngIf="relatedThematics.length">
    <h2 class="margin-top margin-bottom">{{ relatedThematics.length > 1  ? 'Ces thématiques pourraient également vous intéresser' : 'Cette thématique pourrait également vous intéresser' }}</h2>
    <div class="wrap gap">
      <div class="column" *ngFor="let relatedThematic of relatedThematics">
        <app-thematic-card
          [thematic]="relatedThematic" (click)="goToThematic(relatedThematic.slug)"></app-thematic-card>
        <app-favorite-thematic [thematic]="relatedThematic"></app-favorite-thematic>
      </div>
    </div>
  </div>
</div>
