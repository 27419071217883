<h1 class="margin-bottom">WE talk</h1>
<app-notification class="margin-bottom" color="#FFA135"
                  title="Le développement personnel au service du bien-être professionnel. ">
  <i>« Notre plus grand désir est de vous aider à être plus en paix et en joie et que vous puissiez exprimer au mieux
    votre potentiel. »</i>
</app-notification>
<div class="video-wrapper margin-top">
  <iframe width="560" height="349" [src]="getVideoLink()" allowfullscreen></iframe>
</div>

<div class="margin-top-xl margin-bottom">
  <strong class="text-secondary cooper text-large">Bonjour, bienvenue dans votre espace {{ description }}.</strong>
</div>
<div class="margin-top-xl margin-bottom">
  <strong class="text-primary">Notre objectif chez We talk.</strong>
</div>
<div *ngIf="role === Role.Talker">
  <div>Peut être que certains d’entre vous traversent des difficultés et d’autres souhaitenta se développer, se
    challenger,
    vous êtes au bon endroit. En effet We talk est là pour vous accompagner, et vous offrir un espace confidentiel et
    sécurisé pour vous permettre à la fois de vous libérer et de progresser.
  </div>
  <div class="margin-top-xl margin-bottom">
    <strong class="text-primary">Notre solution : comment ça marche ?</strong>
  </div>
  <div>Nous vous proposons, en fonction de votre situation, 2 types d’accompagnement autour de thématiques pro ou perso
    :
    <ul class="margin-top margin-bottom">
      <li>La parole en groupe vous permettra de libérer vos émotions, retrouver un équilibre émotionnel et l’envie
        d’aller
        de l’avant.
      </li>
      <li>Le coaching en groupe vous permettra de vous dépasser, passer un cap et vous mettre en énergie pour atteindre
        vos objectifs.
      </li>
    </ul>
    Dans les deux cas, vous serez accompagnés par un coach spécialiste certifié.

  </div>


  <div class="margin-top-xl margin-bottom">
    <strong class="text-primary">Les petits plus de We talk.</strong>
  </div>
  <div>De plus nous vous invitons à poser un auto diagnostic qui vous permettra de voir l’évolution et l’apport du
    parcours sur votre santé mentale. Vous pourrez également évaluer chacune de vos séances et voir votre progression.
  </div>
</div>

<div *ngIf="role === 'pilot'" class="column gap">
  <div>Nous tenons tout d’abord à vous féliciter d’être ici. Cela prouve que vous vous préoccupez de la santé mentale de
    vos collaborateurs, que vous souhaitez prendre soin d’eux et faire évoluer les mentalités.
  </div>
  <div>Certains d’entre eux traversent peut-être des difficultés et d’autres souhaitent se développer, se challenger :
    sachez qu’ils sont au bon endroit avec We talk.
  </div>
  <div>En effet, Wetalk est là pour les accompagner, et leur offrir un espace confidentiel et sécurisé pour leur
    permettre à la fois de se libérer et de progresser.
  </div>

</div>
<div *ngIf="role === Role.Sherpa" class="column gap">
  <div>Nous tenons tout d’abord à vous remercier d’être ici. Merci d’avoir pris part à l’aventure We talk, de croire en
    notre projet et en notre vision.
  </div>
  <div>La santé mentale est un enjeu de plus en plus important pour les entreprises mais également pour la société en
    général.
  </div>
  <div>Certains des collaborateurs que vous allez accompagner traversent peut-être des difficultés et d’autres
    souhaitent se développer, se challenger : avec We talk et avec vous, ils sont au bon endroit pour évoluer.
  </div>
  <div>En effet, Wetalk est là pour les accompagner, et leur offrir un espace confidentiel et sécurisé pour leur
    permettre à la fois de se libérer et de progresser.
  </div>

</div>
<div class="margin-top-xl margin-bottom" *ngIf="role !== Role.Talker">
  <strong class="text-primary">La fierté de We talk</strong>
</div>
<div>Et ça fonctionne, nos Talkers nous font d’excellents retours : plus de 80 % atteignent leurs objectifs voire les
  dépassent en seulement 4 séances.
</div>



