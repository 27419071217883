import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// import { MatomoTracker } from 'ngx-matomo-client';
import { Subject, switchMap, takeUntil, tap } from "rxjs";

import { Thematic } from "../../../thematics/models/thematic.model";
import { ThematicRepository } from "../../../thematics/repositories/thematic.repository";
import { Role } from "../../../users/models/users.entity";
import { ProfileService } from "../../../users/services/profile.service";
import { SnackbarService } from "../snackbar/snackbar.service";

@Component({
  selector: 'app-favorite-thematic',
  templateUrl: './favorite-thematic.component.html',
  styleUrls: [ './favorite-thematic.component.scss' ]
})
export class FavoriteThematicComponent implements OnInit, OnDestroy {
  @Input() thematic!: Thematic;

  destroy$ = new Subject<void>();

  isVisible = false;

  isSelected = false;

  // private readonly tracker = inject(MatomoTracker);

  constructor(private readonly profileService: ProfileService,
              private readonly thematicRepository: ThematicRepository,
              private readonly snackBarService: SnackbarService) {

  }

  ngOnInit() {
    this.profileService.getObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        profile => {
          this.isVisible = profile.role === Role.Talker;
          if (profile.role === Role.Talker) {
            this.isSelected = !!profile.favoriteThematics?.find(favoriteThematic => favoriteThematic.id === this.thematic.id);
          }
        }
      );

  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateFavorite(): void {
    // this.tracker.trackEvent(TrackerCategory.THEMATIC_PAGE_TALKER, TrackerAction.CLICK, 'favoriteIcon');
    this.thematicRepository.updateFavoriteThematic(this.thematic.id)
      .pipe(takeUntil(this.destroy$),
        tap(isFavorite => {
          this.isSelected = isFavorite;
          this.snackBarService.pushMessage(isFavorite ? 'Thématique ajoutée aux favoris' : 'Thématique retirée des favoris', 'success');
        }),
        switchMap(() => this.profileService.loadProfile()))
      .subscribe();
  }
}
