<div class="wrap text-xl padding">
  <div class="center-children">
    <app-icon type="building" />
  </div>
  <div class="row gap center-vertically-by-margin" *ngFor="let part of parts; let index = index">
    <p class="padding center-children-vertically" [class]="{'text-bold': index ===( breadcrumbInputs.length - 1) }"  *ngIf="!part.url?.length">{{part.displayName.toUpperCase()}}</p>
    <p class="center-children-vertically link" [class]="{'text-bold': index ===( breadcrumbInputs.length - 1) }" *ngIf="part.url?.length" (click)="goToUrl(part.url)">{{part.displayName.toUpperCase()}}</p>
    <div class="padding text-primary" *ngIf="index < (breadcrumbInputs.length - 1)">
      <app-icon type="chevron-right" />
    </div>
  </div>
</div>
