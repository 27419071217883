<div class="stepper">
  <app-arrow-button *ngIf="previousButtonEnabled" class="arrow-button left" [class]="{'hidden': stepsDone === 0}"
                    (click)="onSelectStep(stepsDone - 1)"></app-arrow-button>
  <div class="stepper-wrapper" #content>
    <div class="stepper-content" [class]="slideStep">
      <ng-content></ng-content>
    </div>
  </div>
  <app-arrow-button *ngIf="nextButtonEnabled" [rotated]="true" class="arrow-button right" [class]="{'hidden': stepsDone === maxSteps - 1}"
                    (click)="onSelectStep(stepsDone + 1)"></app-arrow-button>
</div>
