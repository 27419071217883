<ng-container *ngIf="canSeePage; else subscribeBlock" >
    <div class="list-wrapper">
        <div class="list-header">
            <h1 class="margin-bottom">ICI, DÉVELOPPEZ-VOUS EN TOUTE AUTONOMIE</h1>
            <div>
                <div class="tabs">
                    <button class="tab-button row center-children-vertically gap" (click)="selectTab(1)" [class.active]="selectedTab === 1"><app-icon type="telescope" [color]="selectedTab === 1 ? '#fff' : '#000'" size="22"></app-icon><span>Inspiration & réflexion</span></button>
                    <button class="tab-button row center-children-vertically gap" (click)="selectTab(2)" [class.active]="selectedTab === 2"><app-icon type="manage" [color]="selectedTab === 2 ? '#fff' : '#000'" size="22"></app-icon><span>Expérimentation & bien être</span></button>
                    <button class="tab-button row center-children-vertically gap" (click)="selectTab(3)" [class.active]="selectedTab === 3"><app-icon type="trust" [color]="selectedTab === 3 ? '#fff' : '#000'" size="22"></app-icon><span>Se faire accompagner</span></button>
                </div>
            </div>
        </div>
        <ng-container>
            <ng-template #filtersTemplate>
                <div class="cards-list margin-top">
                    <div class="header-inputs row">
                        <app-input
                        [margin-bottom]="false"
                        class="input"
                        type="search"
                        [placeholder]="
                        'Rechercher une ressource'
                        "
                        [inputField]="searchForm"
                        [gotSubMenu]="true"
                        [hasFilters]="false"
                        (enterPressed)="handleEnterPressed($event)"
                    >
                        <div class="filter-menu padding-wrapper">
                            <div class="row">
                                <h2>Filtre ressource</h2>
                            </div>
                            <form class="column gap" [formGroup]="contentsForm">
                                <div class="margin-top">
                                    <p><strong>Format</strong></p>
                                    <div *ngIf="formats.controls" class="column margin-left" formArrayName="formats">
                                        <label *ngFor="let formats of formats.controls; let i = index" [formGroupName]="i">
                                            <input type="checkbox" formControlName="value">
                                            {{ formats.get('name')?.value }}
                                        </label>
                                    </div>
                                </div>
                                <div class="margin-top">
                                    <p><strong>Durée de lecture</strong></p>
                                    <div *ngIf="durations.controls" class="column margin-left" formArrayName="durations">
                                        <label *ngFor="let duration of durations.controls; let i = index" [formGroupName]="i">
                                            <input type="checkbox" formControlName="value">
                                            {{ duration.get('name')?.value }}
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </app-input>
                    </div>
                    <form class="margin-top margin-bottom-xl" [formGroup]="contentsForm">
                        <div  class="container">
                            <div class="item-1">
                                <p><strong>Pilier</strong></p>
                                <div *ngIf="pillars.controls" formArrayName="pillars" class="margin-top margin-left">
                                    <div *ngFor="let pillar of pillars.controls; let i = index" [formGroupName]="i" class="pillar-item" (click)="toggleSelection(pillar.value)" [class.selected]="pillar.value.value">
                                    <app-icon [type]="pillar.value.icon" size="25"></app-icon>
                                    <div class="pillar-text">{{ pillar?.get('name')?.value }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="item-2">
                                <p><strong>Thématique liée</strong></p>
                                <div class="linked-thematic">
                                    <div *ngIf="thematics.controls" class="themes-container" formArrayName="allMainThematics">
                                        <div *ngFor="let mainThematic of thematics.controls; let i = index" [formGroupName]="i">
                                            <label>
                                                <input type="checkbox" formControlName="value">
                                                {{ mainThematic?.get('name')?.value }}
                                            </label>
                                        </div>
                                    </div>
                                    <a href="#" class="plus-ten" (click)="openMoreThematic($event)" [dropdownTriggerFor]="filterDropdown">+10</a>
                                    <app-dropdown [closeOnClick]="false" [isFilter]="true" #filterDropdown class="dropdown-wrapper">
                                        <div #ref>
                                            <div class="padding-wrapper">
                                                <div class="row">
                                                    <p><strong>Thématique liée</strong></p>
                                                </div>
                                                <div class="additionnal-themes-container gap margin-left" formArrayName="allAdditionnalThematics">
                                                    <div class="additionnal-themes-content" *ngFor="let additionnalThematic of additionnalThematics.controls; let i = index" [formGroupName]="i">
                                                        <label>
                                                            <input type="checkbox" formControlName="value">
                                                            {{ additionnalThematic.get('name')?.value }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="!ref.hasChildNodes()">
                                        <div class="default-filter">
                                            Aucun filtre disponible
                                        </div>
                                        </ng-container>
                                    </app-dropdown>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-template>
            <ng-container *ngIf="!initialLoad; else loadingIndicatorTemplate">
                <ng-container>
                    <app-content-cards-list
                            [items]="items$"
                            [loading]="loading$"
                            [apiLoading$]="apiLoading$"
                            [scroll]="scroll$"
                            [unique-key]="uniqueKey"
                            [itemsCount]="totalCount"
                            [newItems$]="newItems$"
                            (loadNewPage)="loadNextPage()"
                            (scroll)="onScrollEvent()"
                            (currentItemsLength)="onCurrentItemsLengthChange($event)"
                            [filtersTemplate]="filtersTemplate"
                            [noItemsTemplate]="noItemsTemplate"
                            class="list"
                    >
                    </app-content-cards-list>
                </ng-container>
            </ng-container>
            <ng-template #loadingIndicatorTemplate>
                <div class="spinner-container">
                    <app-spinner></app-spinner>
                </div>
            </ng-template>
            <ng-template #noItemsTemplate>
                <div class="margin-top" *ngIf="isRestricted">
                    <app-subscribe-more></app-subscribe-more>
                </div>
            </ng-template>
        </ng-container>
    </div>
</ng-container>    
<ng-template #subscribeBlock>       
     <app-subscribe-page></app-subscribe-page>    
</ng-template>
