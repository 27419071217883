<app-card-pagination [mainRepository]="mainRepository" [propertiesFilters$]="propertiesFilters$"
                [otherFilterOptionCount]="otherFilterOptionCount"
                [filterForm]="filterForm" name="parcours"
                [isWrittenInPlural]="true"
                (download)="downloadTrainsCsv()" (clear)="clearFilter()"
>
  <ng-container filter [formGroup]="filterForm">
    <div class="filter margin-bottom">
      <p>Type de parcours</p>
      <app-checkbox formControlName="isSpeech">Parole en groupe</app-checkbox>
      <app-checkbox formControlName="isCoaching">Coaching en groupe</app-checkbox>
    </div>
    <div class="filter">
      <p>Sherpa encadrant</p>
      <app-checkmarks [update$]="updateSherpasSelected$" [checkboxes]="selectedSherpas" item-name="Sherpa selectionné"
                      (changeSelection)="updateSherpasSelected($event)"></app-checkmarks>
    </div>
    <div class="filter">
      <p>Date de création</p>
      <app-input title="À partir du" formControlName="startCreationDate" type="date"></app-input>
      <app-input title="Jusqu'au" formControlName="endCreationDate" type="date"></app-input>
    </div>
    <div class="filter">
      <p>Début du parcours</p>
      <app-input title="À partir du" formControlName="startFirstSessionDate" type="date"></app-input>
      <app-input title="Jusqu'au" formControlName="endFirstSessionDate" type="date"></app-input>
    </div>
    <div class="filter">
      <p>Nombre de membres</p>
      <app-input formControlName="membersCountMinimum" type="number" placeholder="Minimum"></app-input>
      <app-input formControlName="membersCountMaximum" type="number" placeholder="Maximum"></app-input>
    </div>
  </ng-container>
  <ng-template #card let-item>
    <app-train-card (goToDetails)="goToTrain($event)" [train]="item"></app-train-card>
  </ng-template>
</app-card-pagination>
