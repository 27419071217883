import { Overlay } from "@angular/cdk/overlay";
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { merge, Subject, takeUntil } from 'rxjs';

import { DropdownPanel } from '../components/dropdown/dropdown.panel';

@Directive({
  selector: '[dropdownTriggerFor]',
  host: {
    '(click)': 'toggleDropdown()'
  }
})
export class DropdownDirective implements OnDestroy {
  private isDropdownOpen = false;

  private destroy$ = new Subject<void>();

  @Input('dropdownTriggerFor') public dropdownPanel!: DropdownPanel;

  @Input('dropdownPosition') public dropdownPosition: 'start' | 'center' | 'end' = 'end';

  constructor(
    private readonly overlay: Overlay,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly viewContainerRef: ViewContainerRef
  ) {
  }

  toggleDropdown(): void {
    return this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private destroyDropdown(): void {
    this.destroy$.next();
  }

  private openDropdown(): void {

    this.isDropdownOpen = true;

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([ {
        originX: this.dropdownPosition,
        originY: 'bottom',
        overlayX: this.dropdownPosition,
        overlayY: 'top',
        offsetY: 0
      } ]);

    const overlayRef = this.overlay.create({
      positionStrategy,
      backdropClass: '',
      panelClass: 'overlay-panel',
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });


    const templatePortal = new TemplatePortal(
      this.dropdownPanel.templateRef,
      this.viewContainerRef
    );


    overlayRef.attach(templatePortal);
    const referenceSize = this.elementRef.nativeElement.getBoundingClientRect();
    overlayRef.updateSize({ width: referenceSize.width });

    merge(overlayRef.backdropClick(), overlayRef.detachments(), this.dropdownPanel.closed)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.isDropdownOpen = false;
          overlayRef.detach();
        }
      );
  }
}
