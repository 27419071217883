import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: [ './slider.component.scss' ]
})
export class SliderComponent<T extends { id: string } = { id: string }> implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  slideShownIndex$ = new BehaviorSubject<number>(0);

  slideClass = 'slide-0';

  touchStartX?: number;

  currentTouchX?: number;

  public readonly itemTrackBy = (_: number, item: T) => item.id;

  @Input() items: T[] = [];

  @Input() arrowInTopPosition = false;

  @ContentChild('slide', { static: false }) contentTemplateRef!: TemplateRef<any>;

  ngOnInit() {
    this.slideShownIndex$.pipe(takeUntil(this.destroy$))
      .subscribe(index => {
        this.slideClass = `slide-${ index }`;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  goNext() {
    this.slideShownIndex$.next((this.slideShownIndex$.getValue() + 1 + this.items.length) % this.items.length);
  }

  goPrevious() {
    this.slideShownIndex$.next((this.slideShownIndex$.getValue() - 1 + this.items.length) % this.items.length);
  }

  goSpecific(index: number) {
    this.slideShownIndex$.next(index % this.items.length);
  }

  touchStart(event: TouchEvent) {
    if (event.touches[0]?.clientX) {
      this.touchStartX = event.touches[0].clientX;
    }
  }


  touchMove(event: TouchEvent) {
    if (event.touches[0]?.clientX) {
      this.currentTouchX = event.touches[0].clientX;
    }
  }

  touchEnd() {
    if (this.touchStartX && this.currentTouchX) {
      if (this.touchStartX < this.currentTouchX) {
        this.goPrevious();
      } else {
        this.goNext();
      }
    }
    this.touchStartX = undefined;
    this.currentTouchX = undefined;
  }
}
