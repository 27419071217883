<label class="checkbox-container no-select"
       [class.switch]="type === 'switch'"
>
  <input
    [class.checkbox-input]="type === 'checkbox'"
    [class.round]="type === 'switch'"
    type="checkbox"
    [ngModel]="checked"
    (ngModelChange)="onModelChange($event)"
  />
  <div *ngIf="type === 'checkbox'" class="checkbox-border" [class.checked]="checked">
    <div class="checkbox-filling relative">
      <app-icon type="check" />
    </div>
  </div>
  <div class="slider round" *ngIf="type === 'switch'"></div>
  <div class="content">
    <ng-content> </ng-content>
  </div>
</label>
