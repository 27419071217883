import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

import { Talker } from "../../../users/models/talker.entity";
import {
  computeBarChartDateData,
  DateInterval,
  getLabelledIntervalsFromGlobalInterval
} from "../../utils/bar-chart-tools";
import { DEFAULT_FONT } from "../../utils/utils";
import { AbstractChartComponent } from "../abstract.chart.component";

@Component({
  selector: 'app-talkers-historical',
  templateUrl: './talkers-historical.component.html'
})
export class TalkersHistoricalComponent extends AbstractChartComponent<'bar'> implements OnInit, OnDestroy {

  @Input() talkers$!: BehaviorSubject<Talker[]>;

  @Input() dateIntervalSelection$!: BehaviorSubject<DateInterval>;

  resize$ = new Subject<void>();

  type: 'bar' = 'bar';

  width!: number;

  height!: number;

  chartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            family: DEFAULT_FONT
          }
        },
        position: 'bottom' as const
      }
    }
  };

  ngOnInit() {
    this.onInit([ this.dateIntervalSelection$, this.talkers$, this.resize$ ]);
    this.getWidth();
    this.getHeight();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  updateData() {
    const dateIntervals = getLabelledIntervalsFromGlobalInterval(this.dateIntervalSelection$.getValue());
    this.chartData.labels = dateIntervals.map(interval => interval.label);
    const creationDates = this.talkers$.getValue().map(talker => talker.createdAt);
    const activationDates = this.talkers$.getValue()
      .filter(member => !!member.activatedAt)
      .map(member => member.activatedAt);
    const sessionInscriptionDates = this.talkers$.getValue()
      .filter(talker => talker.trainMemberships?.length)
      .map(talker => talker.trainMemberships.sort((tA, tB) => tA.createdAt.getTime() - tB.createdAt.getTime())?.[0]?.createdAt);
    this.chartData.datasets = [
      {
        label: 'Invités',
        backgroundColor: "#707070",
        borderColor: "#707070",
        data: computeBarChartDateData(creationDates, dateIntervals)
      },
      {
        label: 'Inscrits',
        backgroundColor: "#8b5eb0",
        borderColor: "#8b5eb0",
        data: computeBarChartDateData(activationDates, dateIntervals)
      },
      {
        label: 'Talkers',
        backgroundColor: "#22c997",
        borderColor: "#22c997",
        data: computeBarChartDateData(sessionInscriptionDates, dateIntervals)
      }
    ];
  }

  @HostListener('window:resize')
  getWidth(): void {
    this.width = Math.min(Math.min(window.innerWidth - 400), 1000);
    this.resize$.next();
  }


  @HostListener('window:resize')
  getHeight(): void {
    this.height = Math.min(Math.floor(this.width * 2 / 3), 400);
    this.resize$.next();
  }
}
