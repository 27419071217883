<div class="sized-wrapper">
  <div class="wrapper">

    <div class="header">
      <div class="center-children">
        <app-profile-icon [big]="true" [profile]="user"></app-profile-icon>
      </div>
      <div class="user-description">
        <h2>{{user.fullName}}</h2>
        <p>Email : {{user.email}}</p>
        <p>Genre : {{gender}}</p>
        <p>Numéro : {{user.phoneNumber ?? 'Pas de numéro renseigné'}}</p>
        <p>Rôle : {{role}}</p>
        <p>Invité le : {{user.createdAt.toLocaleDateString()}}</p>
        <div>
          <p *ngIf="user.activatedAt">Inscrit le : {{user.activatedAt.toLocaleDateString()}}</p>
          <p *ngIf="!user.activatedAt" class="margin-bottom">Pas encore inscrit</p>
        </div>

      </div>
    </div>

    <div class="center-children column">
      <app-button class="margin-bottom" *ngIf="!user.activatedAt" [outline]="true" (click)="sendActivationMail()" title="Renvoyer un email d'inscription">Renvoyer un email d'inscription</app-button>
    </div>
  </div>
</div>
