<div *ngIf="role">
  <h1 class="cooper margin-bottom">{{ title }}</h1>

  <div *ngIf="role === 'pilot'" class="half-width-desktop">
    <p class="text-bold margin-bottom">Ajouter un talker existant en tant que pilote</p>
    <app-input [dropdownData]="existingTalkerNames" placeholder="Sélectionner un talker" [formControl]="futurePilotName" />

    <div class="center-children margin-bottom" *ngIf="futurePilotName.value.length">
      <app-button (click)="promoteTalkerToPilot()">Ajouter</app-button>
    </div>
    <app-separator class="margin-top" />
    <p class="text-bold margin-bottom">Créer un nouveau pilote</p>
  </div>

  <form class="width-300 column gap" *ngIf="newUserForm" [formGroup]="newUserForm" (ngSubmit)="validate()">
    <p class="text-bold">Genre</p>
    <app-radio-buttons [options]="genderPossibilities" formControlName="gender"></app-radio-buttons>
    <p class="text-bold">Coordonnées</p>
    <app-input [margin-bottom]="false" formControlName="firstName" type="user" placeholder="Prénom"></app-input>
    <app-input [margin-bottom]="false" formControlName="lastName" type="user" placeholder="Nom de famille"></app-input>
    <app-input [margin-bottom]="false" type="email"
               formControlName="email"
               [error]="emailAlreadyTaken"
               error-message="Email déjà utilisé"
               placeholder="Email"></app-input>

    <div class="center-children margin-bottom" *ngIf="newUserForm.valid && !emailAlreadyTaken">
      <app-button type="submit">Ajouter</app-button>
    </div>
  </form>
</div>
