<div class="header row gap">
  <h1 class="title">Création d'un nouveau {{ roleDescription }}</h1>
  <app-icon button type="close" (click)="close()"></app-icon>
</div>

<section>
  <form [formGroup]="newUserForm" (submit)="validate(true)">
    <app-radio-buttons
      title="Genre"
      [options]="genderPossibilities"
      formControlName="gender"
    ></app-radio-buttons>
    <app-input
      formControlName="firstName"
      type="user"
      placeholder="Prénom"
    ></app-input>
    <app-input
      formControlName="lastName"
      type="user"
      placeholder="Nom de famille"
    ></app-input>
    <app-radio-buttons
      *ngIf="!role"
      title="Rôle"
      [options]="rolePossibilities"
      formControlName="role"
    ></app-radio-buttons>
    <app-input
      [correct]="emailWorks"
      type="email"
      formControlName="email"
      [error]="emailAlreadyTaken"
      error-message="Email déjà utilisé"
      placeholder="Email"
    ></app-input>
    <app-input
      *ngIf="needToPreciseOrganization && !role"
      formControlName="organization"
      type="organization"
      [dropdownData]="organizationNames"
      [addOptionEnable]="true"
      (addOption)="createOrganization()"
      placeholder="Organisation"
    ></app-input>

    <div *ngIf="needToPreciseDynamics" class="center-children row gap wrap">
      <div>
        <p class="margin-bottom">Coaching en groupe</p>
        <app-checkmarks
          [checkboxes]="coachingDynamicAllowed"
          item-name="Thématique associée"
          [isFemaleWord]="true"
        ></app-checkmarks>
      </div>

      <div>
        <p class="margin-bottom">Parole en groupe</p>
        <app-checkmarks
          [checkboxes]="speechDynamicAllowed"
          item-name="Thématique associée"
          [isFemaleWord]="true"
        ></app-checkmarks>
      </div>
    </div>
    <div class="add-button center-children">
      <app-button [disabled]="!formIsValid" type="submit"
        >Ajouter ce{{ roleDescription === 'utilisateur' ? 't' : '' }}
        {{ roleDescription }}</app-button
      >
    </div>
  </form>
</section>
