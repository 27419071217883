import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { APP_CONFIG } from "../../../config/config";
import { AppConfig } from "../../../config/config.type";

@Injectable()
export class UploadFileService {

  private readonly apiUrl: string;

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private readonly http: HttpClient) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  uploadFile(file: File, entityName: 'my-profile' | 'thematics' | 'contents', id='', isCover=false): Observable<unknown> {
    const formData = new FormData();

    formData.append("file", file);

    if(isCover){
      formData.append("contentFileType", 'cover');
    }

    const urlParts = [this.apiUrl, entityName];
    if (id.trim().length > 0) {
        urlParts.push(id);
    }
    urlParts.push('image');

    return this.http.post(urlParts.join('/'), formData);
  }
}
