import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class AppSelectComponent {
  @Input() placeholder = "Choisir une option";

  @Input() options: any = [];

  @Output() optionSelected = new EventEmitter<any>();

  showDropdown = false;

  selectedOption: any = null;

  deleteSelectedOption() {
    this.selectedOption = null;
    this.optionSelected.emit(null);
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.optionSelected.emit(option);
  }
}
