import { Component, Input, OnInit } from "@angular/core";

import { affectRanges, lifeSatisfactionRanges, RESPONSE_DATA, ScoreRange, workSatisfactionRanges } from "../../typeform/response/response.data";

@Component({
    selector: 'app-emotional-response',
    templateUrl: './emotional-response.component.html',
    styleUrls: ['./emotional-response.component.scss']
})

export class EmotionalResponseComponent {
    
    @Input() emotionalResponse: any;

    omsMoodResult = RESPONSE_DATA.omsMoodData;

    flourishingResult = RESPONSE_DATA.flourishingData;

    workSatisfactionResult = RESPONSE_DATA.workSatisfactionData;

    constructor() {}

    round(value: number): number {
        return Math.round(value);
    }

    getColorByScore(score: number): string {
        if (score <= 25) return '#FF4F4E';
        if (score <= 50) return '#FC9418';
        if (score <= 75) return '#94D82C';
        if (score <= 100) return '#22C997';
        return '#22C997';
    }

    getAffectRange(score: number): ScoreRange {
        return affectRanges.find(range => score >= range.min && score <= range.max) || affectRanges[0];
    }
      
    getLifeSatisfactionRange(score: number): ScoreRange {
        return lifeSatisfactionRanges.find(range => score >= range.min && score <= range.max) || lifeSatisfactionRanges[0];
    }
    
    getWorkSatisfactionRange(score: number): ScoreRange {
        return workSatisfactionRanges.find(range => score >= range.min && score <= range.max) || workSatisfactionRanges[0];
    }


}