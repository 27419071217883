<div class="wrapper">
  <h1 class="margin-bottom text-primary">Bienvenue {{capitalize(firstName)}}</h1>
  <div class="notification-resume-wrapper column">
    <div class="resume-container">
     <app-notifications-resume />
    </div>

    <ng-container *ngxPermissionsExcept="'admin'">
      <div class="resume-wrapper" *ngIf="organizationDataResume && organizationDataResume.canSeeStats">
        <div class="resume row">
          <div class="tile">
            <div class="cooper text-large">Collaborateurs</div>
          </div>
          <div class="tile">
            <div class="text-xl text-primary cooper">{{organizationDataResume.members}}</div>
            <div class="cooper text-black">invité{{organizationDataResume.members > 1 ? 's' : ''}}</div>
          </div>
          <div class="tile">
            <div class="text-xl text-primary cooper">{{organizationDataResume.activatedMembers}}</div>
            <div class="cooper text-black">inscrit{{organizationDataResume.activatedMembers > 1 ? 's' : ''}}</div>
          </div>
          <div class="tile">
            <div class="text-xl text-primary cooper">{{organizationDataResume.talkers}}</div>
            <div class="cooper text-black">talker{{organizationDataResume.talkers > 1 ? 's' : ''}}</div>
          </div>
        </div>
      </div>      
    </ng-container>

    <ng-container *ngxPermissionsExcept="'admin'">
      <div class="resume row " *ngIf="organizationDataResume && organizationDataResume.lifeSatisfactionScore >= 0
      && organizationDataResume.affectAndRelationScore >= 0 && organizationDataResume.workSatisfactionScore >= 0 &&  organizationDataResume.canSeeStats">
            <div class="tile">
              <div class="cooper text-large">Baromètre</div>
            </div>
            <div class="tile">
              <div class="text-xl cooper row"> <span class="text-xl cooper tiny-margin-right" [style.color]="getColorByScore(organizationDataResume.lifeSatisfactionScore)">{{organizationDataResume.lifeSatisfactionScore}}</span> /100</div>
              <div class="cooper text-black">satisfaction de vie</div>
            </div>
            <div class="tile">
              <div class="text-xl cooper row"><span class="text-xl cooper tiny-margin-right" [style.color]="getColorByScore(organizationDataResume.affectAndRelationScore)">{{organizationDataResume.affectAndRelationScore}}</span> /100</div>
              <div class="cooper text-black">affect et relation</div>
            </div>
            <div class="tile">
              <div class="text-xl cooper row"><span class="text-xl cooper tiny-margin-right" [style.color]="getColorByScore(organizationDataResume.workSatisfactionScore)">{{organizationDataResume.workSatisfactionScore}}</span> /100</div>
              <div class="cooper text-black">épanouissement pro.</div>
            </div>
          </div>
    </ng-container>
  </div>

  <div class="margin-bottom">
    <app-home-page-tabs />
  </div>

  <div class="margin-top-xl">
    <h1 class="margin-bottom">En savoir plus sur We Talk</h1>
    <app-welcome-links [images$]="imagesLinks$" role="pilot" />
  </div>

</div>
