import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-train-congrats',
  templateUrl: './train-congrats.component.html',
  styleUrls: [ './train-congrats.component.scss' ]
})
export class TrainCongratsComponent {
  constructor(private readonly router: Router) {

  }

  goBackToHome() {
    this.router.navigate([ '' ]);
  }
}
