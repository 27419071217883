import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { merge, Subject, takeUntil } from "rxjs";
import { map } from "rxjs/operators";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { UploadFileService } from "../../shared/components/upload-file/upload-file.service";
import { FunnelService } from "../../shared/services/funnel.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { SherpaRepository } from "../../users/repositories/sherpa.repository";
import { ThematicCategory } from "../models/category.model";
import { CreateDynamic, DynamicType } from "../models/dynamic.model";
import {
  CreateKeyNumber,
  CreateTestimonial,
  CreateThematic,
  CreateThematicForm,
  Thematic
} from "../models/thematic.model";
import { ThematicRepository } from "../repositories/thematic.repository";


// TODO: add "archive" button and complete warnings and missed fields notification, add complete guide to create thematic
@Component({
  selector: 'app-edit-thematic',
  templateUrl: './edit-thematic.component.html',
  styleUrls: [ './edit-thematic.component.scss' ]
})
export class EditThematicComponent extends AbstractInFunnelComponent {

  thematicForm!: EntityFormGroup<CreateThematicForm>;

  workingOnForms: FormControl<string>[] = [];

  coachingDescriptionForms: FormControl<string>[] = [];

  speechDescriptionsForms: FormControl<string>[] = [];

  testimonialForms: EntityFormGroup<CreateTestimonial>[] = [];

  keyNumberForms: EntityFormGroup<CreateKeyNumber>[] = [];

  thematic?: Thematic;

  existingThematics: Thematic[] = [];

  coachingSherpas: Checkbox[] = [];

  coachingSherpasAssociated: string[] = [];

  speechSherpas: Checkbox[] = [];

  speechSherpasAssociated: string[] = [];

  nameTaken = false;

  loading = true;

  file?: File;

  categoriesNames: string[] = [];

  categories: ThematicCategory[] = [];

  modification$ = new Subject<void>();

  errorMessages: string[] = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly thematicRepository: ThematicRepository,
    private readonly sherpaRepository: SherpaRepository,
    protected override readonly route: ActivatedRoute,
    protected override readonly funnelService: FunnelService,
    private readonly snackBarService: SnackbarService,
    private readonly uploadService: UploadFileService,
    private readonly router: Router,
  ) {
    super(funnelService, route);
  }

  init(): void {
    this.loading = true;
    const oldSpeechDynamic = this.thematic?.dynamics?.find(dynamic => dynamic.type === DynamicType.SPEECH);
    const oldCoachingDynamic = this.thematic?.dynamics?.find(dynamic => dynamic.type === DynamicType.COACHING);

    this.sherpaRepository.getAll().subscribe(sherpas => {
      const oldCoachingSherpas = oldCoachingDynamic?.sherpasAllowed ?? [];

      this.coachingSherpas = sherpas.map(user => {
        const key = user.fullName;
        return {
          id: user.id,
          key,
          selected: oldCoachingSherpas.map(sherpa => sherpa.id).includes(user.id)
        };
      });

      const oldSpeechSherpas = oldSpeechDynamic?.sherpasAllowed ?? [];
      this.speechSherpas = sherpas.map(user => {
        const key = user.fullName;
        return {
          id: user.id,
          key,
          selected: oldSpeechSherpas.map(sherpa => sherpa.id).includes(user.id)
        };
      });
    });

    this.workingOnForms = this.thematic?.workingOn?.split('\n')?.length
      ? this.thematic.workingOn.split('\n').map(workingOn => new FormControl(workingOn, {
        validators: Validators.required,
        nonNullable: true
      }))
      : [  ];


    this.testimonialForms = this.thematic?.testimonials?.length
      ? this.thematic.testimonials.map(testimonial => this.formBuilder.group({
        author: new FormControl(testimonial.author, { validators: Validators.required, nonNullable: true }),
        description: new FormControl(testimonial.description, { validators: Validators.required, nonNullable: true }),
      }))
      : [ ];

    this.keyNumberForms = this.thematic?.keyNumbers?.length
      ? this.thematic.keyNumbers.map(keyNumber => this.formBuilder.group({
        stringNumber: new FormControl(keyNumber.stringNumber, { validators: Validators.required, nonNullable: true }),
        description: new FormControl(keyNumber.description, { validators: Validators.required, nonNullable: true }),
      }))
      : [];

    this.speechDescriptionsForms = oldSpeechDynamic
      ? oldSpeechDynamic.description.split('\n').map(description => new FormControl(description, {
        validators: Validators.required,
        nonNullable: true
      }))
      : [ "J'ai besoin de libérer mes émotions", "Je me sens moins seul.", "Je me sens moins seul.", "Je retrouve de l'énergie. " ]
        .map(string => new FormControl(string, {
          validators: Validators.required,
          nonNullable: true
        }));


    this.coachingDescriptionForms = oldCoachingDynamic
      ? oldCoachingDynamic.description.split('\n').map(description => new FormControl(description, {
        validators: Validators.required,
        nonNullable: true
      }))
      : [ "Je suis challengé.", "J'avance et je me transforme.", "Je développe mes soft skills.", "Je suis en démarche délibérée de progrès." ]
        .map(string => new FormControl(string, {
          validators: Validators.required,
          nonNullable: true
        }));

    this.thematicForm = this.formBuilder.group({
      slug: new FormControl(this.thematic?.slug ?? '', { validators: Validators.required, nonNullable: true }),
      name: new FormControl(this.thematic?.name ?? '', { validators: Validators.required, nonNullable: true }),
      title: new FormControl(this.thematic?.title ?? '', { validators: Validators.required, nonNullable: true }),
      resume: new FormControl(this.thematic?.resume ?? '', { validators: Validators.required, nonNullable: true }),
      category: new FormControl(capitalize(this.thematic?.category?.name ?? ''), {
        validators: Validators.required,
        nonNullable: true
      }),
      speech: new FormControl(oldSpeechDynamic ? !oldSpeechDynamic.isArchived : false, {
        validators: Validators.required,
        nonNullable: true
      }),
      coaching: new FormControl(oldCoachingDynamic ? !oldCoachingDynamic.isArchived : false, {
        validators: Validators.required,
        nonNullable: true
      }),
    });

    this.loading = false;

    this.funnelService.validate$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.save();
        this.funnelService.canValidate$.next(false);
      });

    this.modification$.next();

  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();

    setTimeout(() => {
      this.modification$
        .pipe(
          takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.isValid()) {
            this.funnelService.canValidate$.next(true);
            this.funnelService.validateMessage$.next(!this.thematic?.isArchived ? "Enregistrer" : "Réactiver");
          } else {
            this.funnelService.canValidate$.next(false);
          }
        });

      this.modification$.next();
    }, 1000);

  }

  override ngOnInit(): void {
    this.route.data
      .pipe(map(data => data.thematic))
      .subscribe((thematic) => {
        this.thematic = thematic;

        this.init();

        this.previousMessage = 'Retour à la thématique';
        super.ngOnInit();

        this.thematicRepository.getCategories().subscribe(categories => {
          this.categories = categories;
          this.categoriesNames = categories.map(category => capitalize(category.name));
        });


        this.thematicForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(
          value => {
            this.nameTaken = !!this.existingThematics.find(t => t.name.toLowerCase() === value.name?.toLowerCase());
            this.modification$.next();
          }
        );

        merge([
          this.thematicForm.valueChanges,
          this.keyNumberForms.map(f => f.valueChanges),
          this.workingOnForms.map(f => f.valueChanges),
          this.testimonialForms.map(f => f.valueChanges),
          this.speechDescriptionsForms.map(f => f.valueChanges),
          this.coachingDescriptionForms.map(f => f.valueChanges),
        ])
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.modification$.next();
          });

        this.loading = false;
      });
    this.thematicRepository.getAll().subscribe(thematics => {
      this.existingThematics = thematics.filter(thematic => thematic.name !== this.thematic?.name);
    });
  }

  addWorkingOnForm(): void {
    this.workingOnForms.push(new FormControl('', { validators: Validators.required, nonNullable: true }));
    this.modification$.next();
  }

  removeWorkingOnForm(index: number): void {
    this.workingOnForms.splice(index, 1);
    this.modification$.next();
  }

  addCoachingDescriptionForm(): void {
    this.coachingDescriptionForms.push(new FormControl('', { validators: Validators.required, nonNullable: true }));
    this.modification$.next();
  }

  removeCoachingDescriptionForm(index: number): void {
    this.coachingDescriptionForms.splice(index, 1);
    this.modification$.next();
  }

  addSpeechDescriptionForm(): void {
    this.speechDescriptionsForms.push(new FormControl('', { validators: Validators.required, nonNullable: true }));
    this.modification$.next();
  }

  removeSpeechDescriptionForm(index: number): void {
    this.speechDescriptionsForms.splice(index, 1);
    this.modification$.next();
  }

  addTestimonialForm(): void {
    this.testimonialForms.push(this.formBuilder.group({
      author: new FormControl('', { validators: Validators.required, nonNullable: true }),
      description: new FormControl('', { validators: Validators.required, nonNullable: true }),
    }));
    this.modification$.next();
  }

  removeTestimonialForm(index: number): void {
    this.testimonialForms.splice(index, 1);
    this.modification$.next();
  }

  addKeyNumberForm(): void {
    this.keyNumberForms.push(this.formBuilder.group({
      stringNumber: new FormControl('', { validators: Validators.required, nonNullable: true }),
      description: new FormControl('', { validators: Validators.required, nonNullable: true }),
    }));
    this.modification$.next();
  }

  removeKeyNumberForm(index: number): void {
    this.keyNumberForms.splice(index, 1);
    this.modification$.next();
  }

  get gotCoaching(): boolean {
    return this.thematicForm.controls.coaching.value;
  }

  get gotSpeech(): boolean {
    return this.thematicForm.controls.speech.value;
  }

  nameWorks(): boolean {
    return !this.nameTaken && !!this.thematicForm.controls.name.value;
  }

  formValid(): boolean {
    return this.thematicForm.valid
      && this.nameWorks()
      && (this.speechDescriptionsForms.every(form => form.valid)
        || !this.thematicForm.controls.speech.value)
      && (this.coachingDescriptionForms.every(form => form.valid)
        || !this.thematicForm.controls.coaching.value)
      && this.testimonialForms.every(form => form.valid)
      && this.keyNumberForms.every(form => form.valid);
  }

  getDynamics(): CreateDynamic[] {
    const result: CreateDynamic[] = [];
    if (this.thematicForm.controls.speech.value) {
      result.push({
        descriptions: this.speechDescriptionsForms.map(form => form.value),
        type: DynamicType.SPEECH,
        sherpasAllowed: this.speechSherpasAssociated
      });
    }
    if (this.thematicForm.controls.coaching.value) {
      result.push({
        descriptions: this.coachingDescriptionForms.map(form => form.value),
        type: DynamicType.COACHING,
        sherpasAllowed: this.coachingSherpasAssociated
      });
    }

    return result;
  }

  isValid(): boolean {
    this.errorMessages = [];

    if (this.nameTaken) {
      this.errorMessages.push('Le nom de thématique est déjà pris');
    }
    if (!this.thematicForm.controls.slug.valid) {
      this.errorMessages.push('Label manquant');
    }
    if (!this.thematicForm.controls.category.valid) {
      this.errorMessages.push('Catégorie manquante');
    }
    if (!this.thematicForm.controls.name.valid) {
      this.errorMessages.push('Nom affiché manquant');
    }
    if (!this.thematicForm.controls.title.valid) {
      this.errorMessages.push('Titre affiché manquant');
    }
    if (!this.thematicForm.controls.resume.valid) {
      this.errorMessages.push('Résumé manquant');
    }

    if(this.getDynamics().length === 0) {
      this.errorMessages.push("Cette thématique n'a pas de dynamique");
    }


    if (!this.speechDescriptionsForms.every(form => form.valid)
      && this.thematicForm.controls.speech.value) {
      this.errorMessages.push('Au moins une description de parole en groupe est vide');
    }
    if (!this.coachingDescriptionForms.every(form => form.valid)
      && this.thematicForm.controls.coaching.value) {
      this.errorMessages.push('Au moins une description de coaching en groupe est vide');
    }
    this.getDynamics().forEach(dynamic => {
      if (dynamic.type === DynamicType.COACHING) {
        if (this.gotCoaching && dynamic.sherpasAllowed.length === 0) {
          this.errorMessages.push("La dynamique coaching en groupe n'a pas de sherpa");
        }
      }
      if (dynamic.type === DynamicType.SPEECH) {
        if (this.gotSpeech && dynamic.sherpasAllowed.length === 0) {
          this.errorMessages.push("La dynamique parole en groupe n'a pas de sherpa");
        }
      }
    });

    if (this.keyNumberForms.some(form => !form.valid)) {
      this.errorMessages.push("Au moins un chiffre clé n'est pas rempli correctement");
    }
    if (this.testimonialForms.some(form => !form.valid)) {
      this.errorMessages.push("Au moins un témoignage n'est pas rempli correctement");
    }
    if (this.workingOnForms.some(form => !form.valid)) {
      this.errorMessages.push("Au moins un sujet 'sur quoi les talkers cont travailler et avancer' est vide");
    }

    if (!this.thematic?.id) {
      if (!this.file) {
        this.errorMessages.push('Image manquante');
      }
    }

    return !this.errorMessages.length;

    // return (!!this.file || !!this.thematic?.id)
    //   && this.getDynamics()
    //     .filter(dynamic =>
    //       !!dynamic.descriptions.length
    //       && !!dynamic.sherpasAllowed.length)
    //     .length === +this.gotCoaching + +this.gotSpeech
    //   && this.formValid();
  }

  title(): string {
    return this.thematic ? "Modification d'une thématique" : "Création d'une thématique";
  }

  updateCoachingSherpasAssociated(checkboxes: Checkbox[]) {
    this.coachingSherpasAssociated = checkboxes.filter(checkbox => checkbox.selected).map(checkbox => checkbox.id);
    this.modification$.next();
  }

  updateSpeechSherpasAssociated(checkboxes: Checkbox[]) {
    this.speechSherpasAssociated = checkboxes.filter(checkbox => checkbox.selected).map(checkbox => checkbox.id);
    this.modification$.next();
  }

  updateFile(file: File): void {
    this.file = file;
    this.modification$.next();
  }

  save(): void {

    if (!this.isValid()) {
      return;
    }

    this.funnelService.componentLoading$.next(true);

    const speechDynamic: CreateDynamic | undefined = this.gotSpeech ? {
      type: DynamicType.SPEECH,
      descriptions: this.speechDescriptionsForms.map(descriptionForm => descriptionForm.value),
      sherpasAllowed: this.speechSherpasAssociated
    } : undefined;
    const coachingDynamic: CreateDynamic | undefined = this.gotCoaching ? {
      type: DynamicType.COACHING,
      descriptions: this.coachingDescriptionForms.map(descriptionForm => descriptionForm.value),
      sherpasAllowed: this.coachingSherpasAssociated
    } : undefined;

    const result = {
      ...this.thematicForm.value,
      category: this.categories.find(c => capitalize(c.name) === this.thematicForm.controls.category.value)?.id,
      testimonials: this.testimonialForms.map(testimonialForm => testimonialForm.value) as CreateTestimonial[],
      keyNumbers: this.keyNumberForms.map(keyNumberForm => keyNumberForm.value) as CreateKeyNumber[],
      workingOn: this.workingOnForms.map(workingOnForm => workingOnForm.value),
      dynamics: [ speechDynamic, coachingDynamic ].filter((dynamic): dynamic is CreateDynamic => !!dynamic),

    } as CreateThematic;


    this.loading = true;
    if (this.thematic) {
      this.thematicRepository.patch(this.thematic.id, result)
        .subscribe(thematic => {
          const isReactivation = this.thematic?.isArchived;

          this.thematic = thematic;
          this.init();
          if (this.file) {
            this.uploadService.uploadFile(this.file, "thematics", thematic.id).subscribe(() => {
              this.snackBarService.pushMessage(isReactivation ? 'Thématique activée' : 'Modification enregistrée', 'success');
              this.funnelService.componentLoading$.next(false);

            });
          } else {
            this.snackBarService.pushMessage(isReactivation ? 'Thématique activée' : 'Modification enregistrée', 'success');
            this.funnelService.componentLoading$.next(false);
          }
          this.loading = false;
        });
    }
    if (!this.thematic && this.file) {
      this.thematicRepository.create(result)
        .subscribe(thematic => {
          this.thematic = thematic;
          this.init();
          if (this.file) {
            this.uploadService.uploadFile(this.file, "thematics", thematic.id).subscribe(() => {
              this.snackBarService.pushMessage('Thématique enregistrée', 'success');
              this.loading = false;
              this.funnelService.componentLoading$.next(false);
            });
          } else {
            this.loading = false;
            this.funnelService.componentLoading$.next(false);
          }
        });
    }
  }

  archiveThematic() {
    if (this.thematic?.id) {

      this.thematicRepository.deleteById(this.thematic.id)
        .subscribe(() => {
          this.snackBarService.pushMessage('Thématique archivée', 'success');
        });
    }
  }
}
