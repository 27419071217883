import { Thematic } from "./thematic.model";

export class ThematicTag {
  id!: string;

  name!: string;

  thematics!: Thematic[];
}

export type CreateThematicTag = {
  id?: string;
  name: string;
  thematics: string[];
}
