import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { debounceTime, Subject, takeUntil } from "rxjs";

@Directive({
  selector: '[isVisible]',
})

export class IsVisible implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  constructor(private ref: ViewContainerRef) {
  }

  @Input() id!: string;

  @Input() scroll$ = new Subject<void>();

  @Output() isVisible = new EventEmitter<string>();


  ngOnInit() {
    this.scroll$.pipe(
      debounceTime(100),
      takeUntil(this.destroy$)
    ).subscribe(() => this.checkIfVisible());
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  checkIfVisible() {
    const observedElement = this.ref.element.nativeElement.parentElement;

    const observer = new IntersectionObserver(([ entry ]) => {
      this.renderContents(entry.boundingClientRect.top <= observedElement.getBoundingClientRect().top && entry.boundingClientRect.top >= observedElement.getBoundingClientRect().bottom);
    });
    observer.observe(observedElement);
  }

  renderContents(isIntersecting: boolean) {
    this.isVisible.emit(isIntersecting ? this.id : '');
  }
}
