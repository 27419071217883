<div class="thematic-wrapper" [class.gray-filter]="thematic.isEnabled !== undefined && !thematic.isEnabled && !big" *ngIf="!tiny" [class.big]="big" [class.pointer]="!withoutTitle" (mouseenter)="onMouseOver()"
     (mouseleave)="onMouseLeave()">
  <div class="header">
    <div class="thematic-image" [class.big]="big">
      <app-image [height]="big ? 800 : 300" [width]="big ? 1200 : 450" [src]="thematic.imageUrl"
                     [alt]="thematic.slug" />
    </div>
    <div class="thematic-name-wrapper">
      <p class="thematic-name" [ngStyle]="{'background-color': colorMedium }">{{ thematic.name }}</p>
    </div>
  </div>

  <h3 *ngIf="!withoutTitle" class="thematic-title cooper"
      [ngStyle]="{'background-color': hovered ? colorLight : '' }">{{ thematic.title }}</h3>
</div>

<div *ngIf="tiny" class="tiny-wrapper" [class.gray-filter]="thematic.isEnabled !== undefined && !thematic.isEnabled"  (mouseenter)="onMouseOver()"
     (mouseleave)="onMouseLeave()">
  <app-image [height]="120" [width]="172" [src]="thematic.imageUrl"
                 [alt]="thematic.slug" />
  <div class="thematic-name-wrapper" [ngStyle]="{'background-color': hovered ? colorStrong : colorMedium }">
  </div>
  <p class="thematic-title cooper"
      [ngStyle]="{'background-color': hovered ? colorLight : '' }">{{ thematic.title }}</p>

</div>
