import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import {
  BehaviorSubject,
  catchError,
  delay,
  EMPTY,
  filter,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap
} from "rxjs";
import { map } from "rxjs/operators";

import { CGUComponent } from "../../shared/components/cgu/cgu.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { PhoneCode } from "../../shared/tool-functions/phone.number";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { IsEmailValidator } from "../../shared/validators/email.validator";
import { PhoneNumberValidatorFactory } from "../../shared/validators/phoneNumberValidator";
import { Role } from "../../users/models/users.entity";
import { PasswordForm } from "../reset-password/reset-password.component";
import { AuthenticationService } from "../services/authentication.service";

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: [ './activation.component.scss' ]
})
export class ActivationComponent implements OnInit, OnDestroy {
  firstName!: string;

  token!: string;

  email!: string;

  role!: Role;

  maxSteps = 4;

  stepsDone$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  passwordForm: EntityFormGroup<PasswordForm>;

  emailForm: EntityFormGroup<{ email: string }>;

  phoneForm: EntityFormGroup<{ phoneNumber: string }>;

  confirmationPhoneForm: EntityFormGroup<{ confirmationCode: string }>;

  expiredLink = false;

  alreadyActiveAccount = false;

  sent = false;

  loading = false;

  phoneRegionCode: PhoneCode = {
    code: 33,
    region: 'FR'
  };

  phoneVerifyCode = '';

  phoneCodeValidated = false;

  private destroy$ = new Subject<void>();


  constructor(private readonly formBuilder: FormBuilder,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly titleService: Title,
              private readonly authenticationService: AuthenticationService,
              private readonly dialog: DialogService) {
    this.passwordForm = this.formBuilder.group({
      password: new FormControl('', { validators: Validators.required, nonNullable: true }),
      confirmation: new FormControl('', { validators: Validators.required, nonNullable: true })
    });

    this.emailForm = this.formBuilder.group({
      email: new FormControl('', { validators: IsEmailValidator(), nonNullable: true }),
    });

    this.phoneForm = this.formBuilder.group({
      phoneNumber: new FormControl('', { nonNullable: true }),
    });
    this.confirmationPhoneForm = this.formBuilder.group({
      confirmationCode: new FormControl('', { validators: Validators.minLength(6), nonNullable: true }),
    });


    this.route.queryParamMap
      .pipe(
        switchMap(queryParams => {
          if (queryParams.has('token')) {
            this.token = queryParams.get('token') ?? '';
            return this.authenticationService.verifyActivationToken(this.token).pipe(catchError(() => of(false)));
          }
          this.expiredLink = true;

          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (user) => {
          if (typeof user === 'boolean') {
            this.alreadyActiveAccount = user;
          } else {
            this.role = user?.role;
            this.email = user?.email;
            this.firstName = capitalize(user?.firstName);
            this.titleService.setTitle(`Bienvenue ${ this.firstName }`);
            this.emailForm.controls.email.setValue(this.email);
          }
        },
        error: () => {
          this.expiredLink = true;
        }
      });
  }

  ngOnInit() {
    this.route.fragment
      .pipe(
        filter(fragment => !Number.isNaN(Number(fragment))),
        map(fragment => Number(fragment)),
        takeUntil(this.destroy$))
      .subscribe((fragment) => {
        this.stepsDone$.next(fragment);
      });

    this.phoneForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        this.phoneCodeValidated = false;
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  validate() {
    if (this.passwordForm.valid) {
      this.authenticationService.activate({
        email: this.email,
        password: this.passwordForm.controls.password.value,
        token: this.token,
        phoneNumber: this.phoneWork ? this.fullPhoneNumber : undefined
      }).subscribe(() => {
          this.router.navigate([ 'home' ]);
        }
      );
    }
  }

  get stepsDone(): number {
    return this.stepsDone$.getValue();
  }

  changeStep(step: number) {
    this.router.navigate([], { queryParamsHandling: "preserve", fragment: String(step) });
  }

  goToNextStep(): void {
    this.changeStep(Math.min(this.maxSteps, this.stepsDone + 1));
  }

  goToLastStep(): void {
    this.changeStep(this.maxSteps);
  }

  goToSpecificStep(step: number): void {
    this.changeStep(step);
  }

  get fullPhoneNumber(): string {
    return `+${ this.phoneRegionCode.code }${ this.phoneForm.controls.phoneNumber?.value }`;
  }

  get phoneWork(): boolean {
    const regionValidator = PhoneNumberValidatorFactory(this.phoneRegionCode.region);
    return !regionValidator(this.fullPhoneNumber);
  }


  sendActivationEmail() {
    if (!this.sent) {
      this.loading = true;
      this.authenticationService.sendActivationEmail(this.emailForm.controls.email.value)
        .pipe(
          catchError(() => of(null)), tap(() => {
            this.loading = false;
            this.sent = true;
          }),
          delay(5000),
          tap(() => {
            this.sent = false;
          }),
          takeUntil(this.destroy$))
        .subscribe();
    }
  }

  selectPhoneRegionCode(code: PhoneCode) {
    this.phoneRegionCode = code;
  }


  sendPhoneVerifyCode(): Observable<any> {
    if (this.phoneWork) {
      return this.authenticationService.sendPhoneCode(this.token, this.fullPhoneNumber);
    }
    return of(null);
  }

  sendFirstPhoneVerifyCode() {
    this.sendPhoneVerifyCode()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.goToNextStep();
      });
  }

  replaySendPhoneVerifyCode() {
    if (!this.sent) {
      this.loading = true;
      this.sendPhoneVerifyCode()
        .pipe(
          tap(() => {
            this.loading = false;
            this.sent = true;
          }),
          delay(5000),
          tap(() => {
            this.sent = false;
          }),
          takeUntil(this.destroy$))
        .subscribe();
    }
  }

  changePhoneCode(code: string) {
    this.phoneVerifyCode = code;
  }

  validatePhoneCode() {
    this.authenticationService.verifyPhoneCode(this.token, this.phoneVerifyCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe(validated => {
        this.phoneCodeValidated = validated;
        if (validated) {
          this.goToNextStep();
        }
      });
  }

  get mailButton(): string {
    if (this.loading) {
      return 'Envoi en cours...';
    }
    if (this.sent) {
      return 'Envoyé !';
    }
    return "Envoyer un email d'inscription";
  }


  get codeButton(): string {
    if (this.loading) {
      return 'Envoi en cours...';
    }
    if (this.sent) {
      return 'Envoyé !';
    }
    return "Renvoyer un sms";
  }

  openCGU(): void {
    this.dialog.open(CGUComponent)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }
}
