import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";

import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { getPhoneCodes, PhoneCode } from "../../shared/tool-functions/phone.number";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { PhoneNumberValidatorFactory } from "../../shared/validators/phoneNumberValidator";

@Component({
  selector: 'app-edit-phone',
  templateUrl: './edit-phone.component.html',
  styleUrls: ['./edit-phone.component.scss']
})
export class EditPhoneComponent extends DialogComponent<string, { phoneNumber: string }>() implements OnInit {
  phoneForm!: EntityFormGroup<{ phoneNumber: string }>;

  loading = false;

  phoneRegionCode: PhoneCode = {
    code: 33,
    region: 'FR'
  };

  constructor(
    @Inject(DIALOG_DATA)
    public override readonly data: { phoneNumber: string },
    private readonly formBuilder: FormBuilder,
    private readonly ref: DialogRef<string>,
  ) {
    super(ref);

    this.phoneForm = this.formBuilder.group({
      phoneNumber: new FormControl(data.phoneNumber ?? '', { nonNullable: true }),
    });
    if (this.data.phoneNumber) {
      let phoneCodeFound = getPhoneCodes().find(phoneCode => phoneCode.code === Number(data.phoneNumber.slice(1, data.phoneNumber.length - 10)));
      if (!phoneCodeFound) {
        phoneCodeFound = getPhoneCodes().find(phoneCode => phoneCode.code === Number(data.phoneNumber.slice(1, data.phoneNumber.length - 9)));
      }
      this.phoneRegionCode = phoneCodeFound ?? this.phoneRegionCode;

     this.phoneForm.controls.phoneNumber.setValue(data.phoneNumber.slice(String(this.phoneRegionCode.code).length + 1));
    }
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get fullPhoneNumber(): string {
    return `+${ this.phoneRegionCode.code }${ this.phoneForm.controls.phoneNumber?.value }`;
  }


  get phoneWork(): boolean {
    const regionValidator = PhoneNumberValidatorFactory(this.phoneRegionCode.region);
    return !regionValidator(this.fullPhoneNumber);
  }

  selectPhoneRegionCode(code: PhoneCode) {
    this.phoneRegionCode = code;
  }

  validate(isValidated: boolean): void {
    if (isValidated) {
      super.close(this.fullPhoneNumber);
    } else {
      super.close();
    }
  }
}
