import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidatorFactory(regionCode: string) {
  return (number: string): { [key: string]: any } | null => {
    let validNumber = false;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        number, regionCode
      );
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (e) {
      return {
        'wrongNumber':
          {
            value: number
          }
      };
    }

    return validNumber ? null : { 'wrongNumber': { value: number } };
  };
}
