<div class="header row gap">
  <h1 class="title">Veuillez insérer le nouveau nom</h1>
  <app-icon button type="close"
              title="Fermer"
              (click)="validate(false)"></app-icon>
</div>

<section>
  <form (submit)="validate(true)">
    <app-input [formControl]="nameForm" [error]="nameForbidden" error-message="Nom déjà utilisé" type="organization" placeholder="Nom de l'organisation"></app-input>
    <div class="add-button center-children">
      <app-button [disabled]="!formValidated" type="submit" title="Modifier">Modifier</app-button>
    </div>
  </form>
</section>
