<div class="pointer row gap tiny-margin-bottom" style="justify-content: flex-end" (click)="goToDynamicDistinction()"> <app-icon type="info" class="mobile-hidden" /> <a>En savoir plus</a></div>
<div class="wrap gap">
  <div class="dynamic column gap grow" *ngFor="let dynamic of getDynamics()">
    <h2>{{parseDynamic(dynamic).title}}</h2>
    <ul>
      <li *ngFor="let bulletPoint of parseDynamic(dynamic).bulletPoints">{{bulletPoint}}</li>
    </ul>

    <div class="center-children" *ngIf="canValidate">
      <app-button (click)="chooseDynamic(dynamic.type)" >S'inscrire dans cette dynamique</app-button>
    </div>

  </div>
</div>

