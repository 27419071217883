import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';

import { TrainMembership } from "../../../trains/models/membership.entity";
import { DEFAULT_FONT } from "../../utils/utils";
import { AbstractChartComponent } from "../abstract.chart.component";

@Component({
  selector: 'app-trains-per-talker',
  templateUrl: './trains-per-talker.component.html'
})
export class TrainsPerTalkerComponent extends AbstractChartComponent<'doughnut'> implements AfterViewInit, OnChanges {

  @Input() trainMemberships!: TrainMembership[];

  type: 'doughnut' = 'doughnut';

  chartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            family: DEFAULT_FONT
          }
        },
        position: 'bottom' as const
      }
    }
  };

  ngAfterViewInit(): void {
    this.chartData.labels = [ '1', '2', '3', '4' ];
    this.chart?.update();
  }


  ngOnChanges() {
    super.onChanges();
  }

  updateData() {
    const talkerTrainById: { id: string, count: number }[] = [];
    for (const trainMembership of this.trainMemberships) {
      if(trainMembership?.talkerId) {
        const talkerTrains = talkerTrainById.find(tTBI => tTBI.id === trainMembership.talkerId);

        if (talkerTrains) {
          talkerTrains.count += 1;
        } else {
          talkerTrainById.push({ id: trainMembership.talkerId, count: 1 });
        }
      }

    }

    this.chartData.datasets = [
      {
        label: 'Talkers',
        data: [
          talkerTrainById.filter(t => t.count === 1).length,
          talkerTrainById.filter(t => t.count === 2).length,
          talkerTrainById.filter(t => t.count === 3).length,
          talkerTrainById.filter(t => t.count === 4).length,
        ],
        backgroundColor: this.getCustomColors().slice(0, 5)
      }

    ];
  }


}
