import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { filter, of, switchMap, takeUntil } from "rxjs";

import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { DIALOG_DATA } from "../../shared/components/dialog/dialog.token";
import { OrganizationRepository } from "../repositories/organization.repository";

@Component({
  selector: 'app-edit-organization-name',
  templateUrl: './edit-organization-name.component.html',
  styleUrls: [ './edit-organization-name.component.scss' ]
})
export class EditOrganizationNameComponent extends DialogComponent<string, {
  name: string
}>() implements OnInit, OnDestroy {

  nameForm!: FormControl<string>;

  formValidated = false;

  loading = false;

  nameForbidden = false;

  constructor(
    @Inject(DIALOG_DATA)
    public override readonly data: { name: string },
    private readonly formBuilder: FormBuilder,
    private readonly organizationRepository: OrganizationRepository,
    private readonly ref: DialogRef<string>,
  ) {
    super(ref);
    this.nameForm = new FormControl(data.name, { nonNullable: true });

    this.nameForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((name) => name.length > 0),
        switchMap((name) => (name.trim().toUpperCase() === data.name.trim().toUpperCase() ? of(false) : this.organizationRepository.isNameTaken(name))))
      .subscribe(nameTaken => {
        this.nameForbidden = nameTaken;
        this.formValidated = !!this.nameForm.value && !nameTaken;

      });
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngOnDestroy() {
    super.onDestroy();
  }




  validate(isValidated: boolean): void {
    if (isValidated) {
      super.close(this.nameForm.value);
    } else {
      super.close();
    }
  }
}
