<ng-container *ngIf="canSeePage; else subscribeBlock">
  <div class="padding-wrapper ">
    <div *ngIf="loading" class="full-screen">
      <app-spinner/>
    </div>
    <div class="margin-bottom wrapper" *ngIf="!loading">
      <div *ngIf="availableTrainsByThematics.length">
        <h1 class="margin-bottom">Les prochains parcours</h1>
        <div class="available-train-slider">
          <app-slider [items]="availableTrains" [arrowInTopPosition]="true">
            <ng-template #slide let-item>
              <app-available-train [train]="item"></app-available-train>
            </ng-template>
          </app-slider>
        </div>
        <h1 class="margin-top-xl">Tous les parcours</h1>

        <div class="wrap margin-bottom">
          <div class="bubble pointer center-vertically-by-margin row gap center-children" [dropdownTriggerFor]="categoriesDropdown" dropdownPosition="start">Catégories
            <app-icon type="chevron-down"/>
          </div>
          <app-dropdown [closeOnClick]="false" #categoriesDropdown>
            <div class="wrap gap base-padding">
              <div class="bubble pointer" (click)="selectCategory(category.id)"
                  [style.border-color]="category.selected ? mediumCategoryColors[category.id]: 'grey'"
                  *ngFor="let category of categoryCheckboxes">{{capitalize(category.key)}}</div>
            </div>
          </app-dropdown>
          <div class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin" [dropdownTriggerFor]="dynamicsDropdown">Dynamique de groupe
            <app-icon type="chevron-down"/>
          </div><app-dropdown [closeOnClick]="false" #dynamicsDropdown>
          <div class="wrap gap base-padding ">
            <div class="bubble pointer" (click)="selectDynamic(dynamic.id)"
                [style.border-color]="dynamic.selected ? dynamic.id === DynamicType.COACHING ? '#8C5FB0': '#FFA135': ''"
                *ngFor="let dynamic of dynamicTypeCheckboxes">{{capitalize(dynamic.key)}}</div>
          </div>
        </app-dropdown>
          <form [formGroup]="dateForm" class="wrap gap">
            <app-input title="Du" formControlName="since" type="date"></app-input>
            <app-input title="Au" formControlName="until" type="date"></app-input>
          </form>
          <div *ngxPermissionsOnly="'talker'" class="bubble pointer margin-bottom row gap center-children center-vertically-by-margin" (click)="selectFavorite()">Mes favoris
            <app-icon [type]="onlyFavorites ? 'favorite' : 'favorite-outline'"/>
          </div>
          <div class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin" (click)="selectCategory(category.id)"
              [style.border-color]="category.selected ? mediumCategoryColors[category.id]: 'grey'"
          *ngFor="let category of categoryCheckboxesSelected">{{capitalize(category.key)}} <app-icon type="close"/></div>
          <div class="bubble pointer row gap center-children margin-bottom center-vertically-by-margin" (click)="selectDynamic(dynamic.id)"
              [style.border-color]="dynamic.selected ? dynamic.id === DynamicType.COACHING ? '#8C5FB0': '#FFA135': ''"
          *ngFor="let dynamic of dynamicTypeCheckboxesSelected">{{capitalize(dynamic.key)}} <app-icon type="close"/></div>
        </div>


        <app-thematic-trains *ngFor="let thematicTrains of availableTrainsByThematicsFiltered"
                            [trainsByThematics]="thematicTrains">
        </app-thematic-trains>
        <div class="text-black" *ngIf="availableTrainsByThematicsFiltered.length === 0">Aucun parcours pour la sélection
          donnée
        </div>
      </div>

      <div *ngIf="!availableTrainsByThematics.length"
          class="center-horizontally-by-margin primary-tile column gap center-children">
        <div class="text-xl color-primary outline no-border">
          <app-icon type="leaves" size="56"/>
        </div>
        <h2 class="text-center cooper">C’est ici que seront affichés les prochains parcours</h2>
        <p class="text-center margin-bottom">Découvrez les thématiques pour créer le parcours qui vous correspond le
          mieux.</p>
        <app-button (click)="goToThematics()" icon="arrow-right">Sélectionner vos thématiques</app-button>
      </div>

    </div>
  </div>
</ng-container>
<ng-template #subscribeBlock>
  <app-subscribe-page></app-subscribe-page>
</ng-template>