import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-feedback-statistics',
  templateUrl: './feedback-statistics.component.html',
  styleUrls: ['./feedback-statistics.component.scss']
})
export class FeedbackStatisticsComponent {
  @Input() canSeeStatistics!: boolean;


  getEmojiColorByType() {
    // TODO: Implement this method
  }

  getLatestRating() {
     // TODO: Implement this method
  }

  downloadCsv(): void {
     // TODO: Implement this method
  }
}