import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";


@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [ './checkbox.component.scss' ],
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => CheckboxComponent)
  } ]
})
export class CheckboxComponent implements ControlValueAccessor, OnDestroy {
  private onChange: (value: any) => void = (_: any) => {
  };

  private onTouched: () => void = () => {
  };

  @Input() checked: boolean = false;

  @Input() type: 'switch' | 'checkbox' = 'checkbox';

  @Input() disableEvent: boolean = false;

  @Input() readonly: boolean = false;

  @Output() valueChange = new EventEmitter();


  destroy$ = new Subject<void>();

  isDisabled = false;


  ngOnDestroy() {
    this.destroy$.next();
  }


  registerOnChange(fn: (value: boolean | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onModelChange(event: boolean) {
    this.checked = event;
    this.onChange(event);
    this.valueChange.emit();
  }

  writeValue(value: boolean): void {
    this.checked = value;
  }

  setDisabledState(state: boolean) {
    this.isDisabled = state;
  }
}
