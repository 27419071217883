import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { catchError, debounceTime, Observable, of, Subject, takeUntil, tap } from "rxjs";

type Card<T> = {
  data: T;
  entering: boolean;
  leaving: boolean;
};

@Component({
  selector: "app-content-cards-list",
  templateUrl: "./content-cards-list.component.html",
  styleUrls: ["./content-cards-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ContentCardsListComponent<T extends { id: string } = { id: string }>
  implements OnInit, OnDestroy {
  currentItems: Card<any>[] = [];

  public readonly cardTrackBy = (index: number, card: Card<T>) =>
    card.data[this.uniqueKey];

  isLoading = false;

  @Input() apiLoading$!: Observable<boolean>;
  
  showNoData = false;

  destroy$ = new Subject<void>();

  loadingNextPage = false;

  private animationDuration = 500;

  @ContentChild("content", { static: false })
  contentTemplateRef!: TemplateRef<any>;

  @ViewChild("bottomTag") lastItem!: ElementRef<HTMLElement>;

  @ViewChild("list") wrapper!: ElementRef<HTMLElement>;

  @Input() items!: Subject<T[]>;

  @Input("unique-key") uniqueKey: keyof T = "id";

  @Input() loading!: Subject<void>;

  @Input() scroll!: Subject<void>;

  @Input() itemsCount?: number;

  @Input() newItems$!: Subject<T[]>;

  @Input() hasFilters?: boolean;

  @Input() searchForm$!: any;

  @Input() filtersTemplate!: TemplateRef<any>;

  @Input() noItemsTemplate!: TemplateRef<any>;

  @Output() loadNewPage = new EventEmitter<void>();

  @Output() currentItemsLength = new EventEmitter<number>();

  private updateCurrentItems(items: Card<T>[]) {
    this.currentItems = items;
    this.currentItemsLength.emit(this.currentItems.length);
  }

  ngOnInit(): void {
    this.scroll
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe(() => this.onScrollEvent());

      this.loading.pipe(
        tap(() => {
          this.isLoading = true;
          this.showNoData = false;
        }),
        debounceTime(300),
        takeUntil(this.destroy$)
      ).subscribe();
    
      this.apiLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        this.showNoData = !loading;
      });

    this.items
      .pipe(
        tap(() => {
          this.isLoading = true;
        }),
        catchError(() => of(this.currentItems.map((item) => item.data) as T[])),
        tap((newItems: T[]) => {

          const newItemKeys = newItems.map((item) => item[this.uniqueKey]);
          const newItemMap = new Map(newItems.map(item => [item[this.uniqueKey], item]));
          
          this.currentItems = this.currentItems
            .map((item) => {
              const newItem = newItemMap.get(item.data[this.uniqueKey]);
              if (newItem) {
                newItemMap.delete(item.data[this.uniqueKey]);
                return {
                  data: newItem,
                  entering: true,
                  leaving: false,
                };
              } 
                return {
                  data: item.data,
                  entering: false,
                  leaving:
                    !this.loadingNextPage &&
                    !newItemKeys.includes(item.data[this.uniqueKey]),
                };
              
            })
            .concat(
              Array.from(newItemMap.values()).map((item) => ({
                data: item,
                entering: true,
                leaving: false,
              }))
            );
          this.updateCurrentItems(this.currentItems);
          this.loadingNextPage = false;
          this.isLoading = false;
        }),
        debounceTime(this.animationDuration),
        tap(() => {
          const filteredItems = this.currentItems
          .filter((item) => !item.leaving)
          .map((item) => ({
            data: item.data,
            entering: false,
            leaving: false,
          }));

          this.updateCurrentItems(filteredItems);
          this.isLoading = false;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.isLoading = false;

        if (!this.loadingNextPage) {
          this.wrapper.nativeElement.scrollTo({ top: 0, behavior: "smooth" });
          this.onScrollEvent();
        }
      });

    this.newItems$
      .pipe(
        tap((newItems) => {
          const updatedItems = this.currentItems.concat(
            newItems.map((item) => ({
              data: item,
              entering: true,
              leaving: false,
            }))
          );
          
          this.updateCurrentItems(updatedItems);
          this.isLoading = false;
        }),
        debounceTime(this.animationDuration),
        tap(() => {
          this.currentItems = this.currentItems.map((item) => ({
            data: item.data,
            entering: false,
            leaving: false,
          }));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onScrollEvent() {
    if (
      window.innerHeight >
        this.lastItem.nativeElement.getBoundingClientRect().top &&
      (this.itemsCount || 0) > this.currentItems.length
    ) {
      this.loadingNextPage = true;
      this.loadNewPage.emit();
    }
  }
}
