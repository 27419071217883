<div class="chart-wrapper">
  <canvas
    baseChart
    [data]="chartData"
    [type]="type"
    [options]="chartOptions"
    [plugins]="chartPlugins"
    [width]="width"
    [height]="height"
  ></canvas>
</div>