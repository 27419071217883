import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { EditConstantsComponent } from "./edit-constants/edit-constants.component";
import { EditTimeslotsComponent } from "./edit-timeslots/edit-timeslots.component";
import { ConfigurationRepository } from "./repositories/configuration.repository";

@NgModule({
  declarations: [EditConstantsComponent, EditTimeslotsComponent],
  imports: [CommonModule, SharedModule],
  providers: [ConfigurationRepository],
})
export class AdminModule {}
