import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";

import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { ConfigurationRepository } from "../repositories/configuration.repository";
import {
  ConstantsConfiguration,
  ConstantsConfigurationForm, DateDelta,
  DateSuffix,
  DateSuffixDescription
} from "../types/constant";

@Component({
  selector: 'app-edit-constants',
  templateUrl: './edit-constants.component.html',
  styleUrls: [ './edit-constants.component.scss' ]
})
export class EditConstantsComponent extends DialogComponent<ConstantsConfiguration>() implements OnInit, OnDestroy {

  delayUnits = Object.values(DateSuffixDescription);

  public readonly configurationForm!: EntityFormGroup<ConstantsConfigurationForm>;

  constructor(private readonly configurationRepository: ConfigurationRepository,
              private readonly ref: DialogRef<ConstantsConfiguration>,
              private readonly formBuilder: FormBuilder,
  ) {
    super(ref);
    this.configurationForm = this.formBuilder.group({
      minimumDelayToCreateTrainNumber: new FormControl(0, { validators: Validators.min(1), nonNullable: true }),
      minimumDelayToCreateTrainUnit: new FormControl('', { validators: Validators.required, nonNullable: true }),
      minimumDelayToJoinTrainNumber: new FormControl(0, { validators: Validators.min(1), nonNullable: true }),
      minimumDelayToJoinTrainUnit: new FormControl('', { validators: Validators.required, nonNullable: true }),
      sherpaMinimumDelayToLeaveTrainNumber: new FormControl(0, { validators: Validators.min(1), nonNullable: true }),
      sherpaMinimumDelayToLeaveTrainUnit: new FormControl('', { validators: Validators.required, nonNullable: true }),
      trainSessionCount: new FormControl(0, { validators: Validators.min(1), nonNullable: true }),
      trainTalkersMax: new FormControl(0, { validators: Validators.min(1), nonNullable: true }),
      sherpaMonthlyTrainsMax: new FormControl(0, { validators: Validators.min(1), nonNullable: true }),
      coachingHourlyRate: new FormControl(0, { validators: Validators.min(1), nonNullable: true }),
      speechHourlyRate: new FormControl(0, { validators: Validators.min(1), nonNullable: true }),
    });
  }

  ngOnInit(): void {
    super.onInit();

    this.configurationRepository.getConstantsConfiguration().subscribe(configuration => {
      this.configurationForm.controls.minimumDelayToCreateTrainNumber.setValue(Number(configuration.minimumDelayToCreateTrain?.slice(0, -1)));
      this.configurationForm.controls.minimumDelayToCreateTrainUnit.setValue(configuration.minimumDelayToCreateTrain ? DateSuffixDescription[configuration.minimumDelayToCreateTrain.slice(-1) as DateSuffix] : '');
      this.configurationForm.controls.minimumDelayToJoinTrainNumber.setValue(Number(configuration.minimumDelayToJoinTrain?.slice(0, -1)));
      this.configurationForm.controls.minimumDelayToJoinTrainUnit.setValue(configuration.minimumDelayToJoinTrain ? DateSuffixDescription[configuration.minimumDelayToJoinTrain.slice(-1) as DateSuffix] : '');
      this.configurationForm.controls.sherpaMinimumDelayToLeaveTrainNumber.setValue(Number(configuration.sherpaMinimumDelayToLeaveTrain?.slice(0, -1)));
      this.configurationForm.controls.sherpaMinimumDelayToLeaveTrainUnit.setValue(configuration.sherpaMinimumDelayToLeaveTrain ? DateSuffixDescription[configuration.sherpaMinimumDelayToLeaveTrain.slice(-1) as DateSuffix] : '');
      this.configurationForm.controls.trainSessionCount.setValue(configuration.trainSessionCount);
      this.configurationForm.controls.trainTalkersMax.setValue(configuration.trainTalkersMax);
      this.configurationForm.controls.sherpaMonthlyTrainsMax.setValue(configuration.sherpaMonthlyTrainsMax);
      this.configurationForm.controls.coachingHourlyRate.setValue(configuration.coachingHourlyRate);
      this.configurationForm.controls.speechHourlyRate.setValue(configuration.speechHourlyRate);
    });
  }

  get formIsValid(): boolean {
    return this.configurationForm.valid;
  }

  getDelaySuffix(suffix: string): string {
    return (Object.keys(DateSuffixDescription) as DateSuffix[]).find(key => DateSuffixDescription[key] === suffix) ?? '';
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  validate(validated: boolean = false) {
    if (this.formIsValid && validated) {
      super.close({
        minimumDelayToCreateTrain: [ this.configurationForm.controls.minimumDelayToCreateTrainNumber.value, this.getDelaySuffix(this.configurationForm.controls.minimumDelayToCreateTrainUnit.value) ].join('') as DateDelta,
        minimumDelayToJoinTrain: [ this.configurationForm.controls.minimumDelayToJoinTrainNumber.value, this.getDelaySuffix(this.configurationForm.controls.minimumDelayToJoinTrainUnit.value) ].join('') as DateDelta,
        sherpaMinimumDelayToLeaveTrain: [ this.configurationForm.controls.sherpaMinimumDelayToLeaveTrainNumber.value, this.getDelaySuffix(this.configurationForm.controls.sherpaMinimumDelayToLeaveTrainUnit.value) ].join('') as DateDelta,
        trainSessionCount: Number(this.configurationForm.controls.trainSessionCount.value),
        trainTalkersMax: Number(this.configurationForm.controls.trainTalkersMax.value),
        sherpaMonthlyTrainsMax: Number(this.configurationForm.controls.sherpaMonthlyTrainsMax.value),
        coachingHourlyRate: Number(this.configurationForm.controls.coachingHourlyRate.value),
        speechHourlyRate: Number(this.configurationForm.controls.speechHourlyRate.value),
      });
    } else {
      super.close();
    }
  }


}
