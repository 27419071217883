<div class="header row">
  <h1 class="title">Modifier votre genre</h1>
  <app-icon button type="close"
            (click)="validate(false)"></app-icon>
</div>

<section class="center-children">
  <form (submit)="validate(true)">
    <app-radio-buttons [options]="genderPossibilities"
      [formControl]="genderForm"></app-radio-buttons>

    <div class="add-button center-children">
      <app-button  type="submit" title="Modifier">Modifier
      </app-button>
    </div>
  </form>
</section>
