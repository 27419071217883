import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { QuestionForm } from "../../questions/questions.component";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { NewSherpaForm } from "../new-sherpa/new-sherpa.component";



@Injectable()
export class DemandsService {
  private readonly apiUrl;

  private readonly prefix;

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private readonly http: HttpClient,
              private readonly snackbarService: SnackbarService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
    this.prefix = 'demands';
  }

  sendNewSherpaDemand(form: NewSherpaForm): Observable<void> {
    return this.http.post<void>([ this.apiUrl, this.prefix, 'new-sherpa' ].join('/'), form);
  }

  sendQuestion(form: QuestionForm): Observable<void> {
    return this.http.post<void>([ this.apiUrl, this.prefix, 'question' ].join('/'), form);
  }


  askForMoreQuota(organizationId: string): Observable<void> {
    return this.http.get<void>([ this.apiUrl, 'organizations', organizationId, 'more-quota' ].join('/'))
      .pipe(tap(() => {
        this.snackbarService.pushMessage('Votre demande de quota a bien été envoyée', 'success');
      }));
  }

  askForMoreUnits(organizationId: string): Observable<void> {
    return this.http.get<void>([ this.apiUrl, 'organizations', organizationId, 'more-units' ].join('/'))
      .pipe(tap(() => {
        this.snackbarService.pushMessage("Votre demande de crédits a bien été envoyée", 'success');
      }));
  }
}
