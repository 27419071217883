<div class="padding-bottom available-train-wrapper">
    <div class="header mobile-column">
      <div class="half-part image-slot">
        <p [ngStyle]="{'background-color': colorMedium }" class="thematic-name thematic-name-wrapper"><strong>{{ train.dynamic.thematic.name }}</strong></p>
        <div class="image-wrapper center-by-relative-parent">
          <app-train-image [src]="train.dynamic.thematic.imageUrl" [alt]="train.dynamic.thematic.slug" />
        </div>
        <div class="header-background" [ngStyle]="{'background-color': colorLight }">
          <div class="title">
            <h2 class="cooper">{{train.dynamic.thematic.title}}</h2>
          </div>
        </div>
      </div>
      <div class="column no-gap half-part">
        <div class="half-part-content-wrapper column gap grow">
          <div class="row center-children-horizontally center-children-vertically purple-bg">
            <div class="megaphone-image-wrapper">
              <app-image width="200" height="200" src="assets/images/megaphone.png" alt="megaphone image"/>
            </div>
            <div class="column megaphone-image-texts">
              <div><span class="custom-text-xl text-primary text-bold"> {{ remainingPlaces }} </span> <span class="custom-text-xl text-primary text-bold text-margin-right">places</span> <span class="custom-text-xl text-bold">restantes</span></div>
              <div class="custom-text-xl">Cloture dans {{ remainingDays }} jours !</div>
            </div>
          </div>
          <div class="center-children">
            <div><span class="text-margin-right">{{train.dynamic.dynamicType}}</span> <span class="text-margin-right" *ngIf="train.sherpa">avec</span> <span *ngIf="train.sherpa"><strong>{{train.sherpa.fullName}}</strong></span> </div>
          </div>
          <strong>Lancement le {{dateFormat(train.getFirstSession().date, { full: true })}} à {{timeFormat(train.timeSlot)}}</strong>
          <app-button [primary]="true" (click)="selectTrain()">Je saute le pas</app-button>
        </div>
      </div>
    </div>
  </div>
  