<p *ngIf="title">{{title}}</p>
<div *ngIf="options" class="radio-buttons-wrapper no-select">
  <label (click)="writeValue(option)" class="radio-option" *ngFor="let option of options;">
    <input type="radio" [checked]="option === optionSelected"/>
    <span [class.text-primary]="option === optionSelected">{{option}}</span>
  </label>
</div>
<div *ngIf="!options" class="radio-buttons-wrapper no-select">
  <label (click)="writeValue(option === 'Oui')" class="radio-option" *ngFor="let option of booleanOptions;">
    <input type="radio" [checked]="option === optionSelected"/>
    <span [class.text-primary]="option === optionSelected">{{option}}</span>
  </label>
</div>
