import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subject, switchMap, takeUntil } from "rxjs";
import { map } from "rxjs/operators";

import { TrainRepository } from "../../trains/repositories/train.repository";
import { MeetService } from "../meet.service";
import { MeetInstance } from "../meet.type";

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html'
})
export class MeetingComponent implements OnInit, OnDestroy {

  private api?: MeetInstance;

  private readonly destroy$ = new Subject<void>();

  @ViewChild('meetIFrame') meetIFrame!: ElementRef<HTMLElement>;

  constructor(private readonly meetingService: MeetService,
              private readonly trainRepository: TrainRepository,
              private readonly route: ActivatedRoute) {
  }

  get isMeeting() {
    return this.meetingService.isMeeting;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(paramMap => {
        const id = paramMap.get('id');
        if (id) {
          this.trainRepository.findById(id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(train => {
              this.meetingService.startSession(train);
            });
        }
      });

    this.meetingService.getSessionEnd$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.api = undefined;
        this.meetIFrame.nativeElement.children.item(0)?.remove();
      });

    this.meetingService.getSessionStart$()
      .pipe(
        switchMap(train => this.meetingService.getTrainToken(train.id)
          .pipe(map(tokenResult => ({ train, token: tokenResult.token })))),
        takeUntil(this.destroy$))
      .subscribe(({ train, token }) => {
        this.api = this.meetingService.getInstance(token, this.meetIFrame, train);
        this.api.addListener('readyToClose', () => {
          this.meetingService.isMeeting = false;
          this.meetingService.closeSession();
        });
      });
  }
}
