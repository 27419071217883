import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { MsalService } from "@azure/msal-angular";
import { Observable, Subject, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { OrganizationStoreService } from "../../organizations/services/organization.store.service";
import { SignInMethod, StorageKey, StorageService } from "../../shared/storage/storage.service";
import { Profile, ProfileService } from "../../users/services/profile.service";
import {
  AccessToken,
  ActivationPayload,
  ModifyPasswordPayload,
  ResetPasswordPayload,
  UserCredentials,
  UserTokenCheckResponse
} from "../authentication.type";

import { GoogleService } from "./google.service";


@Injectable()
export class AuthenticationService {
  private readonly apiUrl;

  private readonly prefix;

  public profileChange = new Subject<void>();

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private readonly http: HttpClient,
              private readonly storageService: StorageService,
              private readonly profileService: ProfileService,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly googleService: GoogleService,
              private readonly msalService: MsalService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
    this.prefix = 'auth';
  }

  login(credentials: UserCredentials): Observable<AccessToken | null> {
    const headers = new HttpHeaders({
      [StorageKey.DEVICE_ID]: this.storageService.getDeviceId(),
      'access-control-expose-headers': StorageKey.REFRESH_TOKEN
    });
    return this.http.post<AccessToken>([ this.apiUrl, this.prefix, 'login' ].join('/'), credentials, {
      headers,
      observe: 'response'
    })
      .pipe(
        tap(response => {
          this.storageService.setRefreshToken(response.headers.get(StorageKey.REFRESH_TOKEN) ?? "");
        }),
        map(response => response.body),
        tap(body => this.storageService.setAccessToken(body?.accessToken ?? ""))
      );
  }

  loginByAzure(token: string): Observable<AccessToken | null> {
    const headers = new HttpHeaders({
      [StorageKey.DEVICE_ID]: this.storageService.getDeviceId(),
      'access-control-expose-headers': StorageKey.REFRESH_TOKEN
    });
    return this.http.post<AccessToken>([ this.apiUrl, this.prefix, 'azure', 'callback' ].join('/'), { token }, {
      headers,
      observe: 'response'
    })
      .pipe(
        tap(response => {
          this.storageService.setRefreshToken(response.headers.get(StorageKey.REFRESH_TOKEN) ?? "");
        }),
        map(response => response.body),
        tap(body => this.storageService.setAccessToken(body?.accessToken ?? ""))
      );
  }


  sendForgottenPasswordEmail(email: string): Observable<void> {
    return this.http.post<void>([ this.apiUrl, this.prefix, 'forgotten-password' ].join('/'), { email });
  }

  sendActivationEmail(email: string): Observable<void> {
    return this.http.post<void>([ this.apiUrl, this.prefix, 'send-activation-mail' ].join('/'), { email });
  }

  sendAccountRecoveringEmail(email: string): Observable<void> {
    return this.http.post<void>([ this.apiUrl, this.prefix, 'send-account-recovering-mail' ].join('/'), { email });
  }

  sendPhoneCode(token: string, phoneNumber: string): Observable<void> {
    return this.http.post<void>([ this.apiUrl, this.prefix, 'send-phone-code' ].join('/'), { token, phoneNumber });
  }

  verifyPhoneCode(token: string, phoneCode: string): Observable<boolean> {
    return this.http.post<boolean>([ this.apiUrl, this.prefix, 'verify-phone-code' ].join('/'), { token, phoneCode });
  }

  refreshToken(): Observable<AccessToken | null> {
    const headers = new HttpHeaders({
      [StorageKey.DEVICE_ID]: this.storageService.getDeviceId(),
      [StorageKey.REFRESH_TOKEN]: this.storageService.getRefreshToken()
    });
    return this.http.get<AccessToken>([ this.apiUrl, this.prefix, 'refresh' ].join('/'), {
      headers,
      observe: 'response'
    })
      .pipe(
        tap(response => this.storageService.setRefreshToken(response.headers.get(StorageKey.REFRESH_TOKEN) ?? "")),
        map(response => response.body),
        tap(body => this.storageService.setAccessToken(body?.accessToken ?? ""))
      );
  }

  impersonateUser(userId: string): Observable<Profile> {
    this.organizationStoreService.removeOrganization();
    this.storageService.setAdminRefreshToken(this.storageService.getRefreshToken());
    const headers = new HttpHeaders({
      [StorageKey.DEVICE_ID]: this.storageService.getDeviceId()
    });
    return this.http.get<AccessToken>([ this.apiUrl, this.prefix, userId, 'impersonate' ].join('/'), {
      headers,
      observe: 'response'
    })
      .pipe(
        tap(response => this.storageService.setRefreshToken(response.headers.get(StorageKey.REFRESH_TOKEN) ?? "")),
        map(response => response.body),
        tap(body => this.storageService.setAccessToken(body?.accessToken ?? "")),
        switchMap(() => this.profileService.loadProfile())
      );
  }

  endImpersonate(): Observable<Profile> {
    this.storageService.setRefreshToken(this.storageService.getAdminRefreshToken());
    this.storageService.removeAdminRefreshToken();
    this.organizationStoreService.removeOrganization();
    return this.refreshToken().pipe(switchMap(() => this.profileService.loadProfile()));
  }

  verifyActivationToken(token: string): Observable<UserTokenCheckResponse | boolean> {
    return this.http.get<UserTokenCheckResponse | boolean>([ this.apiUrl, this.prefix, 'verify-activation-token' ].join('/'), {
      params: { token }
    });
  }

  verifyResetToken(token: string): Observable<UserTokenCheckResponse> {
    return this.http.get<UserTokenCheckResponse>([ this.apiUrl, this.prefix, 'verify-reset-token' ].join('/'), {
      params: { token }
    });
  }


  verifyNewPlatformToken(token: string): Observable<UserTokenCheckResponse> {
    return this.http.get<UserTokenCheckResponse>([ this.apiUrl, this.prefix, 'verify-new-platform-token' ].join('/'), {
      params: { token }
    });
  }

  activate(payload: ActivationPayload): Observable<AccessToken | null> {
    const { email, password, phoneNumber, token } = payload;
    const headers = new HttpHeaders({
      [StorageKey.DEVICE_ID]: this.storageService.getDeviceId(),
      'access-control-expose-headers': StorageKey.REFRESH_TOKEN
    });
    return this.http.post<AccessToken>([ this.apiUrl, this.prefix, 'activate' ].join('/'), {
      email,
      password,
      phoneNumber
    }, {
      params: { token },
      headers,
      observe: 'response'
    })
      .pipe(
        tap(response => {
          this.storageService.setRefreshToken(response.headers.get(StorageKey.REFRESH_TOKEN) ?? "");
        }),
        map(response => response.body),
        tap(body => this.storageService.setAccessToken(body?.accessToken ?? ""))
      );
  }

  resetPassword(payload: ResetPasswordPayload): Observable<AccessToken | null> {
    const { email, password, token } = payload;
    const headers = new HttpHeaders({
      [StorageKey.DEVICE_ID]: this.storageService.getDeviceId(),
    });
    return this.http.post<AccessToken>([ this.apiUrl, this.prefix, 'reset-password' ].join('/'), {
      email,
      password
    }, {
      params: { token },
      headers,
      observe: 'response'
    })
      .pipe(
        tap(response => {
          this.storageService.setRefreshToken(response.headers.get(StorageKey.REFRESH_TOKEN) ?? "");
        }),
        map(response => response.body),
        tap(body => this.storageService.setAccessToken(body?.accessToken ?? ""))
      );
  }

  setNewPlatformPassword(payload: ResetPasswordPayload): Observable<AccessToken | null> {
    const { email, password, token } = payload;
    const headers = new HttpHeaders({
      [StorageKey.DEVICE_ID]: this.storageService.getDeviceId(),
    });
    return this.http.post<AccessToken>([ this.apiUrl, this.prefix, 'set-password-for-new-platform' ].join('/'), {
      email,
      password
    }, {
      params: { token },
      headers,
      observe: 'response'
    })
      .pipe(
        tap(response => {
          this.storageService.setRefreshToken(response.headers.get(StorageKey.REFRESH_TOKEN) ?? "");
        }),
        map(response => response.body),
        tap(body => this.storageService.setAccessToken(body?.accessToken ?? ""))
      );
  }

  modifyPassword(payload: ModifyPasswordPayload): Observable<void> {
    return this.http.patch<void>([ this.apiUrl, this.prefix, 'modify-password' ].join('/'), payload);
  }

  checkPassword(password: string): Observable<boolean> {
    return this.http.post<boolean>([ this.apiUrl, this.prefix, 'verify-password' ].join('/'), { password });
  }

  logout(): Observable<void> {
    const headers = new HttpHeaders({
      [StorageKey.DEVICE_ID]: this.storageService.getDeviceId()
    });

    if (this.storageService.getSignInMethod() === SignInMethod.GOOGLE) {
      this.googleService.logout();
    }

    if (this.storageService.getSignInMethod() === SignInMethod.AZURE) {
      this.storageService.logout();
      return this.msalService.logout();
    }


    return this.http.get<void>([ this.apiUrl, this.prefix, 'logout' ].join('/'), {
      headers
    }).pipe(tap(() => this.storageService.logout()));
  }

  sendNewReminders(userIds: string[]): Observable<void> {
    return this.http.post<void>([ this.apiUrl, this.prefix, 'send-activation-mails'].join('/'), {
      userIds
    });
  }

  updateDeviceId(token: string, { deviceId, refreshToken }: any): Observable<void> {
    const headers = { 'Authorization': `Bearer ${ token }` };
    return this.http.post<void>([ this.apiUrl, this.prefix, 'update-device-id' ].join('/'), { deviceId, refreshToken }, { headers });
  }

}