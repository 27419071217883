import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { takeUntil } from "rxjs";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { DialogComponent } from "../../shared/components/dialog/dialog.component";
import { DialogRef } from "../../shared/components/dialog/dialog.ref";
import { stringNumber } from "../../shared/tool-functions/string-number";
import { parseTimeSlotsToEdit } from "../../shared/tool-functions/time-slot";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { ConfigurationRepository } from "../repositories/configuration.repository";
import { DateSuffixDescription, WeekDaysNames } from "../types/constant";
import { DayTimeForm, WeeklyTimeslots } from "../types/weekly-timeslot";


export type TimeSlotPossibility = {
  hours: number;
  minutes: number;
  weekDay: number;
  selected: boolean;
  hidden?: boolean;
}

@Component({
  selector: 'app-edit-timeslots',
  templateUrl: './edit-timeslots.component.html',
  styleUrls: [ './edit-timeslots.component.scss' ]
})
export class EditTimeslotsComponent extends DialogComponent<WeeklyTimeslots>() implements OnInit, OnDestroy {

  dayTimes: TimeSlotPossibility[] = [];

  dayTimeForm!: EntityFormGroup<DayTimeForm>;

  delayUnits = Object.values(DateSuffixDescription).filter(suffix => suffix !== 'jours');

  daysCheckboxes: Checkbox[] = WeekDaysNames.map((day, index) => ({
    key: day,
    selected: false,
    id: String(index)
  })).sort((checkboxA, checkboxB) => this.sortWeekDayToPutSundayAtLeast(Number(checkboxA.id), Number(checkboxB.id)));

  constructor(private readonly ref: DialogRef<WeeklyTimeslots>,
              private readonly formBuilder: FormBuilder,
              private readonly adminRepository: ConfigurationRepository
  ) {
    super(ref);
    this.dayTimeForm = this.formBuilder.group({
      delayValue: new FormControl(0, { validators: Validators.required, nonNullable: true }),
      delayUnit: new FormControl('', { validators: Validators.required, nonNullable: true }),
      fromTime: new FormControl('', { validators: Validators.required, nonNullable: true }),
      toTime: new FormControl('', { validators: Validators.required, nonNullable: true }),
      weekDays: new FormControl([] as string[], { validators: Validators.required, nonNullable: true })
    });
    this.adminRepository.getWeeklyTimeSlots().subscribe(
      timeslots => {
        const metadata = parseTimeSlotsToEdit(timeslots);

        this.dayTimeForm.controls.delayValue.setValue(metadata.delayValue);
        this.dayTimeForm.controls.delayUnit.setValue(metadata.delayUnit);
        this.dayTimeForm.controls.fromTime.setValue(`${ stringNumber(metadata.startHour) }:${ stringNumber(metadata.startMinute) }`);
        this.dayTimeForm.controls.toTime.setValue(`${ stringNumber(metadata.endHour) }:${ stringNumber(metadata.endMinute) }`);
        this.dayTimeForm.controls.weekDays.setValue(metadata.selectedDays.map(String));

        this.dayTimes = metadata.timeSlotsPossibilities;
      }
    );

  }

  ngOnInit(): void {
    super.onInit();
    this.dayTimeForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateDayTimeSlots());
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  private sortWeekDayToPutSundayAtLeast(weekDayA: number, weekDayB: number): number {
    if (weekDayA === 0) {
      return 1;
    }
    if (weekDayB === 0) {
      return -1;
    }
    return weekDayA - weekDayB;
  }

  get weekDayAvailable(): number[] {
    return this.dayTimeForm.controls.weekDays.value.map(Number).sort(this.sortWeekDayToPutSundayAtLeast);
  }

  private updateDayTimeSlots(): void {
    if (this.dayTimeForm.valid) {
      const startTime = this.dayTimeForm.controls.fromTime.value;
      const endTime = this.dayTimeForm.controls.toTime.value;
      const delayInMinutes = this.dayTimeForm.controls.delayUnit.value === 'heures'
        ? (this.dayTimeForm.controls.delayValue.value ?? 0) * 60
        : this.dayTimeForm.controls.delayValue.value ?? 0;

      if (endTime < startTime || delayInMinutes > 24 * 60) {
        return;

      }
      const [ startHours, startMinutes ] = startTime.split(':').map(Number);

      const [ endHours, endMinutes ] = endTime.split(':').map(Number);
      const slotsCount = Math.floor(((endHours - startHours) * 60 + (endMinutes - startMinutes)) / delayInMinutes);

      this.dayTimes = Array.from(Array(slotsCount + 1))
        .flatMap((_, slotIndex) => Array.from(Array(7))
          .map((__, weekDay) => ({
            hours: Number(startHours) + Math.floor(slotIndex * delayInMinutes / 60),
            minutes: (Number(startMinutes) + (slotIndex * delayInMinutes)) % 60,
            weekDay,
            selected: true
          })));
    }
  }

  updateWeekDayCheckBoxes(checkboxes: Checkbox[]) {
    this.daysCheckboxes = checkboxes;
  }

  selectDayTime(index: number) {
    this.dayTimes[index].selected = !this.dayTimes[index].selected;
  }

  save(): void {
    super.close({
      timeslots: this.dayTimes.filter(dayTime => this.weekDayAvailable.includes(dayTime.weekDay)
        && dayTime.selected)
    });
  }
}
