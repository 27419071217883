<app-card class="pointer" (click)="goToDetails()">
  <ng-container left-part>
    <app-profile-icon [profile]="user" [primary]="!!user.activatedAt"></app-profile-icon>
  </ng-container>
  <ng-container>
    <div class="center-vertically-by-margin">
      <p><strong>{{ user.civility}} {{ user.fullName}}</strong></p>
      <p><strong>{{organizationAndRole}}</strong></p>
      <div>
        <span>{{infos}}</span>
      </div>
    </div>
  </ng-container>
</app-card>

