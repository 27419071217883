import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { CreateFeedbackPayload, Feedback, FeedbackStats } from "../models/feedbacks.entity";



@Injectable({ providedIn: 'root' })
export class FeedbacksRepository extends AbstractRepository<Feedback> {
  constructor(@Inject(APP_CONFIG)
  private readonly appConfig: AppConfig,
  protected override readonly http: HttpClient) {
    super(http, Feedback);
    this.apiUrl = this.appConfig.apiUrl;
  }

  override getEntityName(): string {
    return "feedbacks";
  }

  createFeedback(feedbackPayload: CreateFeedbackPayload): Observable<Feedback> {
    return this.http.post<Feedback>([ this.apiUrl, this.getEntityName() ].join('/'), feedbackPayload);
  }

  getLatestFeedback(): Observable<Feedback> {
    return this.http.get<Feedback>([ this.apiUrl, this.getEntityName(), 'latest-feedback' ].join('/'));
  }

  getLatestMergedFeedback(): Observable<Feedback> {
    return this.http.get<Feedback>([ this.apiUrl, this.getEntityName(), 'latest-merged-feedback' ].join('/'));
  }

  getFeedBackStats(selectedOrganizationIds: string []): Observable<FeedbackStats> {
    return this.http.post<FeedbackStats>([ this.apiUrl, this.getEntityName(), 'stats'].join('/'), selectedOrganizationIds);
  }

  getFeedBackMergedStats(selectedOrganizationIds: string []): Observable<FeedbackStats> {
    return this.http.post<FeedbackStats>([ this.apiUrl, this.getEntityName(), 'merged-stats'].join('/'), selectedOrganizationIds);
  }

  findAll(filters: string []): Observable<Feedback[]> {
    return this.http
      .post<Feedback[]>([this.apiUrl, this.getEntityName(), 'all'].join("/"), filters)
      .pipe(
        map((result) =>
          result.map((feedback) => this.instantiate(feedback))
        )
      );
  }
}
